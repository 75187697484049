import { observer } from 'mobx-react-lite';
import {Button } from '@material-ui/core';
import React, { useEffect, useMemo } from 'react';
import { CopilotInputItems, InputItem } from './components/CopilotInputItems';
import { EmbeddingModel, Provider, llmTypes } from './types';
import { CopilotStore } from './CopilotStore';
import { TenantConfig } from './models';

type CopilotSetupProps = {
    copilotStore: CopilotStore
}

export const CopilotConfig = observer(({ copilotStore }: CopilotSetupProps) => {

    const {
        selectedCustomer: customer,
        tenantConfig,
        saveTenantConfig
    } = copilotStore!

    const [enableCache, setEnableCache] = React.useState<boolean>(false);
    const [enableLanguage, setEnableLanguage] = React.useState<boolean>(false);
    const [preprocessPrompt, setPreprocessPrompt] = React.useState<boolean>(false);
    const [provider, setProvider] = React.useState<Provider>('OpenAI');
    const [embedding_model, setEmbedding_model] = React.useState<EmbeddingModel>('text-embedding-ada-002');
    const [loading, setLoading] = React.useState<boolean>(true);

    const embeddingModels = useMemo(() => {
        return provider === 'OpenAI' ?
            [{value: 'text-embedding-ada-002', label: 'text-embedding-ada-002'}, {value: 'text-embedding-3-large', label: 'text-embedding-3-large'}]
            : [{value: 'scop-embed', label: 'text-embedding-ada-002'}, {value: 'scop-text-embedding-3-large', label: 'text-embedding-3-large'}]
    }, [provider]);


    useEffect(() => {
        if (tenantConfig === undefined) {
            setEnableCache(false)
            setPreprocessPrompt(false)
            setProvider('OpenAI')
            setEmbedding_model('text-embedding-ada-002')
        }
        else {
            setEnableCache(tenantConfig.enable_cache || false)
            setPreprocessPrompt(tenantConfig.preprocess_prompt || false)
            setProvider(tenantConfig.provider || 'OpenAI')
            setEmbedding_model(tenantConfig.embedding_model || 'text-embedding-ada-002')
            setEnableLanguage(!!tenantConfig.enable_language)
        }
        setLoading(false)
    }, [tenantConfig])

    const inputItems: InputItem[] = [
        {
            type: 'selection',
            label: "Provider",
            onChange: (item?: any) => {
                setProvider(item)
                setEmbedding_model(item === 'OpenAI' ? 'text-embedding-ada-002' : 'scop-embed')
            },
            items: llmTypes,
            isReady: ()=> !loading,
            showLoading: () => true,
            selectedItem: provider,
            disabled: customer === undefined || tenantConfig !== undefined || loading
        }, {
            type: 'selection',
            label: "Embedding Model",
            onChange: (item?: any) => {
                setEmbedding_model(item)
            },
            items: embeddingModels,
            isReady: () => !loading,
            showLoading: () => true,
            selectedItem: embedding_model,
            disabled: customer === undefined || tenantConfig !== undefined || loading
        }, {
            type: 'checkbox',
            label: "Enable Cache",
            onChange: () => setEnableCache(!enableCache),
            value: enableCache,
            isReady: () => !loading,
            showLoading: () => true,
            disabled: loading
        }, {
            type: 'checkbox',
            label: "Enable Language",
            onChange: () => setEnableLanguage(!enableLanguage),
            value: enableLanguage,
            isReady: () => !loading,
            showLoading: () => true,
            disabled: loading
        }, {
            type: 'checkbox',
            label: "I&I",
            onChange: () => setPreprocessPrompt(!preprocessPrompt),
            value: preprocessPrompt,
            isReady: () => !loading,
            showLoading: () => true,
            disabled: loading
        }
    ]

    const saveConfig = () => {
        setLoading(true)
        const config: TenantConfig = { 
            enable_cache: enableCache, 
            preprocess_prompt: preprocessPrompt, 
            provider, 
            embedding_model,
            enable_language: enableLanguage
        }
        saveTenantConfig(customer!.name, config)
    }

    const saveEnabled = () => {
        return !loading && customer !== undefined && (enableCache !== tenantConfig?.enable_cache || preprocessPrompt !== tenantConfig?.preprocess_prompt
            || provider !== tenantConfig?.provider || embedding_model !== tenantConfig?.embedding_model || enableLanguage !== tenantConfig?.enable_language)
    }

    return (<>
        <div style={{ height: '85%' }}>
            <div style={{ paddingRight: 10, paddingTop: '10px',  display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Button variant='outlined'
                        style={{ width: '20%' }}
                        onClick={() => saveConfig()}
                        disabled = {!saveEnabled()}
                >
                    Save
                </Button>
                <CopilotInputItems inputItems={inputItems}/>
            </div>
        </div>
    </>)
})