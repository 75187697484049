import React, { useEffect } from 'react'
import { Folder } from '../../models/Folder';
import { makeStyles, Button, CircularProgress, Box } from '@material-ui/core'
import MultiAutocomplete from '../controls/MultiAutocomplete'
import { AutocompleteOption } from '../../models/AutocompleteOption';
import { MainStore } from '../../stores/MainStore';
import { CollectFile } from '../../models/CollectFile';
import { UploadedFilesGrid } from './UploadedFilesGrid';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '-webkit-fill-available'
      },
    rootContainer: {
        marginTop: 30, 
        justifyContent:'center', 
        flexDirection: 'column',
        display: 'inline-flex', 
        width: '-webkit-fill-available',
        padding: '0px 300px 70px'
    },
    buttonsMenu: {
        borderTop: "1px solid #E7E7E7",
        position: "fixed",
        display: 'block',
        left: "0",
        bottom: "0",
        height: "60px",
        width: "100%",
        background: '#F1F2F4',
        boxShadow: "0px -2px 3px rgba(50, 50, 50, 0.50)"
    },
    buttonsContainer: {
        display: 'flex', 
        justifyContent: 'flex-end', 
        padding: 8
    },
    downloadBtn: {
        color: 'white', 
        width: 100, 
        marginLeft: 20, 
        height: 42
    },
    autocompletegContainer: {
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'center'
    },
    gridProgress: {
        position: 'absolute',
        top: '50%',
        left: '48%',
        marginTop: '-60px'
    },
    datasets: {
        marginLeft: 10
    },
    gridContainer: {
        display: 'flex', 
        flexDirection: 'row'
    },
    collectProgress: {
        position: 'absolute',
        top: '50%',
        left: '55%',
        marginTop: '-12px',
        marginLeft: '-12px'
    }
}));

type CollectCustomersDsProps = {
    mainStore: MainStore,
    organizations: Folder[]
}

const DATASET_FILES = [
    'sc_wpi_recommendation',
    'sc_observations_wf',
    'sc_assets_required_maintenance',
    'sc_opi',
    'sc_obs_probability',
    'sc_observations',
    'sc_product_version',
    'sc_customer_nlg',
    'sc_customer',
    'sc_shotgun_analysis',
    'sc_parts',
    'sc_cost_deviation',
    'sc_obsol_heatmap',
    'sc_asset',
    'sc_workforce_nlg',
    'sc_events',
    'sc_workforce',
    'sc_agg_data',
    'sc_pairtrend',
    'sc_reference'
]

export const CollectCustomersDs = ({mainStore, organizations}: CollectCustomersDsProps) => {
    const [selectedOrgs, setSelectedOrgs] = React.useState<AutocompleteOption[]>([]);
    const [selectedDatasets, setSelectedDatasets] = React.useState<AutocompleteOption[]>([]);
    const [isLoadingCollect, setIsLoadingCollect] = React.useState<boolean>(false);
    const [isLoadingGrid, setIsLoadingGrid] = React.useState<boolean>(false);
    const [sessionId, setSessionId] = React.useState<string>("");
    const [uploadedFiles, setUploadedFiles] = React.useState<CollectFile[]>([]);
    const classes = useStyles();

    useEffect(() => {
        getCollectedFiles();
      }, []);

    const innerOrganizations = organizations.map(org => {
        const o: AutocompleteOption = { title: org.name }
        return o
    });
    const datasetOptions = DATASET_FILES.map(file => {
        const o: AutocompleteOption = { title: file }
        return o
    });

    const getCollectedFiles = async () => {
        setIsLoadingGrid(true);
        const collectedFiles = await mainStore.getCollectedFiles();
        setUploadedFiles(collectedFiles);
        setIsLoadingGrid(false);
    }

    const isCollectDisabled = () => {
        const isCollectDisabled = isLoadingCollect || isLoadingGrid || selectedDatasets.length === 0 || selectedOrgs.length === 0 ? true : false;
        return isCollectDisabled;
    }

    return (
        <div className={classes.rootContainer}>
            <div className={classes.autocompletegContainer}>
                <div>
                    <MultiAutocomplete items={innerOrganizations} label='Select Customers' placeholder='Customers' selectAllLabel='Select-all' noOptionsText='No Options Found' onChange={setSelectedOrgs as any}/>
                </div>
                <div className={classes.datasets}>
                    <MultiAutocomplete items={datasetOptions} limitTags={2} label='Select Datasets' placeholder='Datasets' selectAllLabel='Select-all' noOptionsText='No Options Found' onChange={setSelectedDatasets as any}/>
                </div>
            </div>
            <div className={classes.gridContainer}>
                <UploadedFilesGrid outputFiles={uploadedFiles} mainStore={mainStore} sessionId={sessionId}></UploadedFilesGrid>
                {isLoadingGrid && (
                    <CircularProgress
                        size={50}
                        className={classes.gridProgress}
                    />
                )}
            </div>
            <div className={classes.buttonsMenu}>
                <div className={classes.buttonsContainer}>
                    <Box style={{ position: 'relative' }}>
                        <Button 
                            className={classes.downloadBtn}
                            variant="contained"
                            color="primary"
                            disabled={isCollectDisabled()}
                            onClick={async() => {
                                if(selectedDatasets && selectedOrgs) {
                                    setIsLoadingCollect(true);
                                    const result = await mainStore.collectCustomersDs(selectedOrgs, selectedDatasets);
                                    if (result) {
                                        const interval = setInterval(async() => {
                                            const isUploaded = await mainStore.getIsFileUploaded(result);
                                            if(isUploaded) {
                                                setIsLoadingCollect(false);
                                                await getCollectedFiles();
                                                setSessionId(result);
                                                clearInterval(interval);
                                            }
                                        }, 20000);
                                    } else {
                                        setIsLoadingCollect(false);
                                    }
                                }
                                
                            }}
                            disableElevation
                        >
                            Collect
                        </Button>

                        {isLoadingCollect && (
                        <CircularProgress
                            size={28}
                            className={classes.collectProgress}
                        />
                        )}
                    </Box>
                </div>
            </div>
        </div>
    );
}