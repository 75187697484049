import { FormControl, FormControlLabel, FormLabel, RadioGroup, Select, TextField, Typography, Radio, MenuItem } from '@material-ui/core'
import { CSSProperties, useEffect, useState } from 'react'
import { NewSalesforceApp, SalesforceApp } from '../../models/SalesforceApp'

interface IAppSelectionProps {
  isConnected: boolean
  disableNew: boolean
  availableApps: SalesforceApp[]
  onAppSelected: (app: SalesforceApp) => void
}

export const AppSelectionComponent = (props: IAppSelectionProps) => {
    const {isConnected, disableNew, availableApps, onAppSelected} = props
    const [appCreationMode, setAppCreationMode] = useState<'create' | 'upgrade'>('upgrade')

    useEffect(() => {
        if (disableNew && appCreationMode !== 'upgrade') setAppCreationMode('upgrade')
    }, [disableNew])

    return (
        <div>
            <FormControl component="fieldset">
                <FormLabel component="legend">Creation Mode</FormLabel>
                <RadioGroup row aria-label="appMode" name="appMode1" value={appCreationMode} onChange={(e) => setAppCreationMode(e.target.value as any)}>
                    <FormControlLabel value="create" control={<Radio />} label="New" disabled={disableNew} />
                    <FormControlLabel value="upgrade" control={<Radio />} label="Existing" />
                </RadioGroup>
            </FormControl>
            <Typography>App</Typography>
            <div style={{ display: 'flex', flexDirection: 'row', }}>
                {appCreationMode === 'upgrade' && <SelectApp disabled={!isConnected} availableApps={availableApps} onAppSelected={onAppSelected}/>}
                {appCreationMode === 'create' && <CreateApp onAppCreated={onAppSelected}/>}
            </div>
        </div>
    )
}

interface ISelectAppProps {
    disabled: boolean
    availableApps: SalesforceApp[]
    onAppSelected: (app: SalesforceApp) => void
    style?: CSSProperties
}

export const SelectApp = (props: ISelectAppProps) => {
    const {disabled, availableApps, onAppSelected, style} = props
    const [selectedAppId, setSelectedAppId] = useState<string>("")

    return (
            <Select style={{ width: '100%', maxHeight: 40, background: '#ffffff', ...style}}
                        variant='outlined'
                        value={selectedAppId}
                        defaultValue=""
                        onChange={async (event) => {
                            const sfAppId = event.target.value as string
                            const sfApp = availableApps.find(a => a.id === sfAppId)
                            if (sfApp != undefined)
                            {
                                setSelectedAppId(sfAppId)
                                onAppSelected(sfApp)
                            }
                        }}
                        disabled={disabled}>
                <MenuItem key={-1} value={undefined}>None</MenuItem>
                {availableApps.sort().map((sfApp, i) => <MenuItem key={i} value={sfApp.id}>{`${sfApp.label} (${sfApp.version})`}</MenuItem>)}
            </Select>
    )
}

interface ICreateAppProps {
    onAppCreated: (app: SalesforceApp) => void
}

export const CreateApp = (props: ICreateAppProps) => {
    const {onAppCreated} = props
    const [newAppName, setNewAppName] = useState<string>()

    const onUpdateName = (name: string) => {
        setNewAppName(name)
        onAppCreated(NewSalesforceApp)
    }

    return (
        <TextField style={{ width: '100%' }}
                   label="App Name" value={newAppName} variant="outlined"
                   onChange={(event) => onUpdateName(event.target.value)} />
    )
}
