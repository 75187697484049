import { Box, createStyles, LinearProgress, LinearProgressProps, makeStyles, Theme, Typography, withStyles } from '@material-ui/core';
import React, { CSSProperties } from 'react'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

type ProgressBarProps = {
  fillColor: string,
  completed?: number,
  style?: CSSProperties;
  size?: 'xs' | 'small' | 'normal'
  disableText?: boolean
  radius? : number
}

const ProgressBar = (props: ProgressBarProps) => {
  const classes = useStyles()

  const { completed, fillColor, size, disableText, radius } = props;

  const BorderLinearProgress = withStyles((theme: Theme) =>
    createStyles({
      root: {
        height: size && size === 'small' ? 7 : size === 'xs'? 4 : 10,
        borderRadius: radius || 5,
      },
      colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
      },
      bar: {
        borderRadius: radius || 5,
        backgroundColor: fillColor,
      },
    }),
  )(LinearProgress);

  const LinearProgressWithLabel = (props: LinearProgressProps & { value?: number }) => {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={disableText ? 0 : 1}>
          <BorderLinearProgress variant={props.value === undefined ? "indeterminate" : "determinate"} {...props}/>
        </Box>
        {!disableText && props.value !== undefined && <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>}
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <LinearProgressWithLabel value={completed} />
    </div>
  );
};

export default ProgressBar;