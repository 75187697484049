import { Select, Typography,MenuItem } from '@material-ui/core'
import { CSSProperties, useState } from 'react'
import { SalesforceDashboard } from '../../models/SalesforceDashboard'

export type ConnectionState = 'noAuth' | 'inProgress' | 'auth'

interface ISelectDashboardProps {
  disabled: boolean
  availableDashboards: SalesforceDashboard[]
  onDashboardSelected: (dashboard: SalesforceDashboard) => void
  style?: CSSProperties
}

export const SelectDashboard = (props: ISelectDashboardProps) => {
    const {disabled, availableDashboards, onDashboardSelected, style} = props
    const [selectedDashId, setSelectedDashId] = useState<string>("")

    return ( 
        <Select style={{ width: '100%', maxHeight: 40, background: '#ffffff', ...style}}
                        variant='outlined'
                        value={selectedDashId}
                        defaultValue=""
                        onChange={async (event) => {
                            const sfDashId = event.target.value as string
                            const sfDash = availableDashboards.find(d => d.id === sfDashId)
                            if (sfDash != undefined)
                            {
                                setSelectedDashId(sfDashId)
                                onDashboardSelected(sfDash)
                            }
                        }}
                        disabled={disabled}>
                <MenuItem key={-1} value={undefined}>None</MenuItem>
                {availableDashboards.sort().map((sfDash, i) => <MenuItem key={i} value={sfDash.id}>{`${sfDash.label} (${sfDash.name})`}</MenuItem>)}
        </Select>
    )
}