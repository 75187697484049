import axios from 'axios';
import { IngestPayload, CachedItem, DocumentsRequest, ListFilesResponse, TenantConfig} from '../components/copilot setup/models';


// Prefixes
const filesApiPrefix        = "/files";
const adminApiPrefix        = "/admin";
const ingestApiPrefix       = "/ingest";

// URIs
const uploadUrls               = filesApiPrefix + "/{tenant_id}/upload_urls";
const updateMetadata           = filesApiPrefix + "/{tenant_id}/update_metadata";

const tenantInfo               = adminApiPrefix + "/tenant/{tenant_id}/info";
const tenantMetadata           = adminApiPrefix + "/tenant/metadata?tenant_id={tenant_id}";
const tenantConfig             = adminApiPrefix + "/tenant/{tenant_id}/config";

const getIngestModels          = ingestApiPrefix + "/models/{tenant_id}/list";
const getDocuments             = ingestApiPrefix + "/documents/{tenant_id}/list";
const addDocuments             = ingestApiPrefix + "/documents/{tenant_id}/add";
const deleteDocuments          = ingestApiPrefix + "/documents/{tenant_id}/delete";
const ingest                   = ingestApiPrefix + "/ingest/{tenant_id}/{model_name}";
const getIngestStatus          = ingestApiPrefix + "/ingest_info/{tenant_id}/{model_name}/status";
const getIngestStatusBulk      = ingestApiPrefix + "/ingest_info/{tenant_id}/status_bulk";
const getCacheItems            = ingestApiPrefix + "/cache/{tenant_id}/{model_name}/list";
const editCacheItems           = ingestApiPrefix + "/cache/{tenant_id}/{model_name}/edit";
const deleteCacheItems         = ingestApiPrefix + "/cache/{tenant_id}/{model_name}/delete";


class Backend {

    baseUrl: string = 'undefined_base_url'
    token: () => string | undefined

    constructor(baseUrl: string, getToken: () => string | undefined) {
        this.baseUrl = baseUrl
        this.token = getToken
    }

  private async post(url: string, headers: any, data?: any) {
    return await axios({
      method: "post",
      url: this.baseUrl + url,
      headers: headers,
      data: data
    });
  }

  private async get(url: string, headers: any) {
    return await axios({
      method: "get",
      url: this.baseUrl + url,
      headers: headers
    });
  }

  private getCommonHeaders() {
    return {
      "Authorization" : "Bearer " + this.token()
    };
  }

  public async getIngestModels(tenant_id: string){
    const response = await this.post(getIngestModels.replace('{tenant_id}', tenant_id), this.getCommonHeaders())
    return response.data
  }

  public async getUploadUrls(tenant_id: string, filenames: string[], requested_by_email: string){
    const response = await this.post(uploadUrls.replace('{tenant_id}', tenant_id) + `?requested_by=${requested_by_email}`, this.getCommonHeaders(), filenames)
    return response.data
  }

  public async updateMetadata(tenant_id: string, filenames: string[]){
    return this.post(updateMetadata.replace('{tenant_id}', tenant_id), this.getCommonHeaders(), filenames)
  }

  public async getFiles(tenant_id: string, model_name: string, offset?: number){
    const req = {
      tenant_models: [model_name],
      limit: 2000,
      offset: offset || 0
    }
    const response = await this.post(getDocuments.replace('{tenant_id}', tenant_id), this.getCommonHeaders(), req)
    return response.data as ListFilesResponse
  }

  public async addFiles(tenant_id: string, model_name: string, filenames: string[], requested_by_email: string){
    const req: DocumentsRequest = {
      model_name,
      document_names: filenames,
      requested_by_email
    }
    return this.post(addDocuments.replace('{tenant_id}', tenant_id), this.getCommonHeaders(), req)
  }

  public async deleteFiles(tenant_id: string, model_name: string, filenames: string[], requested_by_email: string){
    const req: DocumentsRequest = {
      model_name,
      document_names: filenames,
      requested_by_email
    }
    return this.post(deleteDocuments.replace('{tenant_id}', tenant_id), this.getCommonHeaders(), req)
  }

  public async ingest(tenant_id: string, model_name: string, ingest_payload: IngestPayload){
    const response = await this.post(ingest.replace('{tenant_id}', tenant_id).replace('{model_name}', model_name), this.getCommonHeaders(), ingest_payload)
    return response.status === 200 ? {"status": 'Success'} : {"status": 'Error'}
  }

  public async getIngestStatus(tenant_id: string, model_name: string){
    const response = await this.get(getIngestStatus.replace('{tenant_id}', tenant_id).replace('{model_name}', model_name), this.getCommonHeaders())
    return response.data
  }

  public async getIngestStatusBulk(tenant_id: string, model_names: string[]){
    const response = await this.post(getIngestStatusBulk.replace('{tenant_id}', tenant_id), this.getCommonHeaders(), model_names)
    return response.data
  }

  public async getTenantMetadata(tenant_id: string){
    const response = await this.get(tenantMetadata.replace('{tenant_id}', tenant_id), this.getCommonHeaders())
    return response.data
  }

  public async saveTenantInfo(tenant_id: string, sf_org_id: string){
    const req = { sf_org_id }
    const response = await this.post(tenantInfo.replace('{tenant_id}', tenant_id), this.getCommonHeaders(), req)
    return response.data
  }

  public async getTenantConfig(tenant_id: string){
    const response = await this.get(tenantConfig.replace('{tenant_id}', tenant_id), this.getCommonHeaders())
    return response.data
  }

  public async saveTenantConfig(tenant_id: string, tenant_config: TenantConfig){
    const response = await this.post(tenantConfig.replace('{tenant_id}', tenant_id), this.getCommonHeaders(), tenant_config)
    return response.data
  }

  public async getCacheItems(tenant_id: string, model_name: string){
    const req = {
      "page_size": 500,
      "page_no": 1
    }
    const response = await this.post(getCacheItems.replace('{tenant_id}', tenant_id).replace('{model_name}', model_name), this.getCommonHeaders(), req)
    return response.data.items as CachedItem[]
  }

  public async editCacheItems(tenant_id: string, model_name: string, items: CachedItem[]){
    const req = {items}
    return this.post(editCacheItems.replace('{tenant_id}', tenant_id).replace('{model_name}', model_name), this.getCommonHeaders(), req)
  }

  public async deleteCacheItems(tenant_id: string, model_name: string, item_ids: string[]){
    const req = {items: item_ids}
    return this.post(deleteCacheItems.replace('{tenant_id}', tenant_id).replace('{model_name}', model_name), this.getCommonHeaders(), req)
  }
}

export default Backend;