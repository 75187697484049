import { Button, Checkbox, CircularProgress, IconButton, makeStyles, MenuItem, Select, TextField, Tooltip, Typography} from '@material-ui/core'
import { useEffect, useState } from 'react';
import { MainStore } from '../../stores/MainStore';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DownloadIcon from '@material-ui/icons/GetApp';
import UploadIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import FileSaver from 'file-saver';

const useStyles = makeStyles((theme) => ({
  patch_editor: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 0
  },
  tool_bar: {
    backgroundColor: '#ECEDEF'
  },
  body: {
    flex: 1,
    overflow: 'auto',
    display: 'flex',
    paddingBottom: 10
  },
  patches: {
    width: 480,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  patch: {
    width: 400,
    justifyContent: 'left'
  },
  patch_error: {
    width: 400,
    justifyContent: 'left',
    color: "red"
  },
  patch_details: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 30,
    overflowY: 'scroll'
  },
  inner_patch_details: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  delete_button: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  delete_icon: {
    color: 'red'
  },
  description_input: {
    minWidth: 500
  },
  parameters: {
    margin: '20px 0px',
    fontWeight: 'bold'
  },
  arg: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px'
  },
  arg_name: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    width: 120
  },
  input: {
    flex: 1,
    overflow: 'auto'
  }

}));


interface IPatchEditorProps {
  store: MainStore
  templateVersion: string
  fileName: string
}

declare type Arg = {
  name: string
  type: string
  value?: any
  hasError?: boolean
}

export type PatchData = {
  description?: string
  name: string
  args: Arg[]
  hasError?: boolean
};

export type WidgetSchema = {
  pages: {[key: string]: string[]}
  steps: string[]
}

export const PatchEditor = ({store, templateVersion, fileName}: IPatchEditorProps) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true)
    const [patchFile, setPatchFile] = useState<File>()
    const [patches, setPatches] = useState<PatchData[]>([])
    const [patchConfigs, setPatchConfigs] = useState<PatchData[]>([])
    const [widgetSchema, setWidgetSchema] = useState<WidgetSchema>()
    const [selectedPatch, setSelectedPatch] = useState<PatchData>()

    useEffect(() => {
      const loaded = patches && patchConfigs && widgetSchema !== undefined
      setLoading(!loaded && templateVersion !== "")
    }, [patchConfigs, patches, widgetSchema, templateVersion])

    useEffect(() => {
      const getPatchConfigs = async () => {
        const patch_configs = await store.fetchPatchConfigs()
        setPatchConfigs(patch_configs ?? [])
      }
      getPatchConfigs()
    }, [store])

    useEffect(() => {
      resetEditor()
      if (templateVersion === "") return;

      const getPatches = async () => {
        const file = await store.fetchTemplateFile(templateVersion, fileName)
        if (file !== undefined) setPatchFile(file)

        const widget_schema = await store.fetchWidgetSchema(templateVersion)
        setWidgetSchema(widget_schema)


      }
      getPatches()
    }, [fileName, store, templateVersion])
    
    useEffect(() => {
      if (patchFile !== undefined){
        const fr = new FileReader()
        fr.readAsText(patchFile)
        fr.onload = () => {
          if (typeof(fr.result) === "string"){
            const patchList = JSON.parse(fr.result) as PatchData[]
            setPatches(patchList)
            setSelectedPatch(patchList[0])
          } ;
        }
      }
    }, [patchFile])  

    const resetEditor = () => {
      setPatchFile(undefined)
      setWidgetSchema(undefined)
      setPatches([])
      setSelectedPatch(undefined)
    }

    const onSave = () => {
      const file = new File([JSON.stringify(patches)], fileName)
      store.saveTemplateFile(templateVersion, file)
      setPatchFile(file)
    }

    const onAddPatch = () => {
      const updatedList = patches.map(patch => patch)
      const newPatch: PatchData = {description: "", name: "", args: []} 
      updatedList.push(newPatch)
      setPatches(updatedList)
      setSelectedPatch(newPatch)
    }

    const onDeletePatch= (patch: PatchData) => {
      const updatedList = patches.map(patch => patch)
      const index = updatedList.indexOf(patch, 0)
      updatedList.splice(index, 1)
      setPatches(updatedList)
    }

    const reRenderList = () => {
      const updatedList = patches.map(patch => patch)
      setPatches(updatedList)
    }
    
    const renderSelectArg = (arg: Arg, values: string[], multiselect=false) => {
      if (arg.value === undefined) arg.value = multiselect ? [] : ''

      let invalid_values: string[] = []
      if (multiselect) {
        invalid_values = (arg.value as string[]).filter(v => !values.includes(v))
      }
      else if (!values.includes(arg.value)) {
        invalid_values.push(arg.value)
      }
      
      arg.hasError = !loading && (arg.hasError || (invalid_values.length > 0))

      const renderSingleValue = (v: any) => !loading && invalid_values.includes(v) ? <div style={{color: 'red'}}>{v}</div> : v
      const renderMultiValue = (v: any) => <div>{(v as any[]).map(val => !loading && invalid_values.includes(val) ? <span style={{color: 'red'}}>{`${val},`}</span> :`${val},`)}</div>

      return  <Select
                className={classes.input}
                error={arg.hasError}
                multiple={multiselect}
                variant='outlined'
                value={arg.value}
                disabled={loading}
                onChange={event => {
                  arg.value = event.target.value
                  reRenderList()
                }}
                renderValue={v => multiselect ? renderMultiValue(v) : renderSingleValue(v)}>
              {invalid_values.map((i, index) => <MenuItem key={i+index} value={i} style={{color: 'red'}}>{i}</MenuItem>)}
              {values.map((i, index) => <MenuItem key={i+index} value={i}>{i}</MenuItem>)}
              </Select>      
    }

    const sort_func = (a: string, b: string) => {
      const num_a = a.match(/\d+/) ? a.match(/\d+/)![0] : '0'
      const num_b = b.match(/\d+/) ? b.match(/\d+/)![0] : '0'
      const str_a = a.replace(num_a, '')
      const str_b = b.replace(num_b, '')
      if (str_a > str_b) return 1;
      if (str_a < str_b) return -1;
      return +num_a - +num_b
    }

    const renderArgs = (args: Arg[]) => {
        const page_name: string = args.find(arg => arg.type === 'page')?.value
        const pages = widgetSchema ? Object.keys(widgetSchema["pages"]).sort() :  []
        const widgets = widgetSchema ? page_name ? widgetSchema["pages"][page_name].sort(sort_func) : Object.values(widgetSchema["pages"]).flat().sort(sort_func).filter((v,i,s) => s.indexOf(v) === i) : []
        const steps = widgetSchema ? widgetSchema["steps"].sort(sort_func) : []

        const values: {[key: string]: string[]} = {
          'page': pages,
          'pages': pages,
          'widget': widgets,
          'widgets': widgets,
          'step': steps,
          'steps': steps
        }

        return args.map(arg => {
          arg.hasError = !loading && arg.value === undefined
          return <div className={classes.arg}>
              <Typography className={classes.arg_name}>{arg.name + ":"}</Typography>
              {arg.type === 'any' && 
                <TextField
                  className={classes.input}
                  error={arg.hasError}
                  variant='outlined'
                  value={arg.value}
                  disabled={loading}
                  onChange={event => {
                    arg.value = event.target.value
                    reRenderList()
                  }}/>
              }
              {arg.type === 'bool' &&
                <Checkbox
                  style={{paddingLeft:0}}
                  color='primary' 
                  checked={arg.value}
                  disabled={loading}
                  onChange={event => {
                    arg.value = event.target.checked
                  }}/>
              }
              {(arg.type === 'page' || arg.type === 'pages' || arg.type === 'widget' || arg.type === 'widgets' || arg.type === 'step' || arg.type === 'steps') &&
                renderSelectArg(arg, values[arg.type], arg.type === 'widgets' || arg.type === 'steps' || arg.type === 'pages')
              }
          </div>
        })
    }

    const renderPatches = () => {
      return <div className={classes.patches}>
        {patches.map(patch => {
          patch.hasError = patch.description === "" || !(patch.args.every(arg => !arg.hasError))
          return(
            <Button 
              className={patch.hasError ? classes.patch_error : classes.patch}
              onClick={() => setSelectedPatch(patch)}
              variant={patch === selectedPatch ? "contained" : "text"}
              disableElevation>
              {patch.description === "" ? "Please add description" : patch.description}
            </Button>)
          })
        } </div>
    }

    const renderPatchDetails = (patch: PatchData) => {
      return <div className={classes.patch_details}>
        <div className={classes.inner_patch_details}>
        <TextField
          className={classes.description_input}
          error={patch.description === ''}
          size='small'
          variant='outlined'
          value={patch.description}
          label="description"
          disabled={loading}
          onChange={event => {
            patch.description = event.target.value
            reRenderList()
          }}/>
        <Typography className={classes.parameters} >Method:</Typography>
        <Select
          error={patch.name === ""}
          variant='outlined'
          value={patch.name}
          disabled={loading}
          onChange={event => {
            patch.name = event.target.value as string
            const args = patchConfigs?.find(p => p.name === patch.name)?.args
            patch.args = args ?? []
            reRenderList()
          }}>
          {patchConfigs.map(i => <MenuItem key={i.name} value={i.name}>{i.name}</MenuItem>)}
        </Select>
        <Typography className={classes.parameters} >Parameters:</Typography>
        {renderArgs(patch.args)}
        </div>
        <div className={classes.delete_button}>
          <IconButton 
            className={classes.delete_icon}
            disabled={loading} 
            onClick={() => onDeletePatch(patch)}><DeleteIcon/>
          </IconButton>
        </div>
      </div>
    }

    return (
      <div className={classes.patch_editor}>
        {loading && <div className={classes.body}><CircularProgress/></div>}
        {!loading && 
          <div className={classes.body}>
            {renderPatches()}
            {selectedPatch? renderPatchDetails(selectedPatch) : <div className={classes.patch_details}/>}
          </div>
        }
        <div className={classes.tool_bar}>
            <Tooltip title="Add Patch"><span><Button disabled={templateVersion === "" || loading} onClick={() => onAddPatch()}><AddIcon/></Button></span></Tooltip>
            <Tooltip title="Save"><Button disabled={templateVersion === "" || loading} onClick={onSave}><SaveIcon/></Button></Tooltip>
            <Tooltip title="Download"><Button disabled={patchFile === undefined || loading} onClick={() => FileSaver.saveAs(patchFile!, fileName)}><DownloadIcon/></Button></Tooltip>
            <Tooltip title="Upload"><Button disabled={templateVersion === "" || loading} onClick={() => document.getElementById("patches-upfile")?.click()}><UploadIcon/></Button></Tooltip>
        </div>
        <input id="patches-upfile"
              type="file" 
              accept="text/json"
              onChange={(event) => {
              setPatchFile(event.target.files ? new File([event.target.files[0]], fileName) : undefined)
                event.target.value = ''
              }} 
              hidden/>
      </div>)
}