import { observer } from 'mobx-react-lite';
import {LinearProgress, TextField, Typography, Select, MenuItem, MenuProps } from '@material-ui/core';
import { Checkbox, Stack } from '@mui/material';
import { SIAutocomplete, SIAutocompleteMultiSelect, SelectionItem } from '../../controls/Autocomplete';

export type InputItem = {
    type: 'select' | 'input' | 'checkbox' | 'selection' | 'multiselect',
    label: string,
    onChange: (item?: any) => void,
    items?: SelectionItem[],
    value?: string | boolean,
    isReady: () => boolean,
    showLoading: () => boolean,
    disabled?: boolean,
    selectedItem?: any, // selected item value (id) only!
    onAddSelectionItem?: (item: SelectionItem) => void,
    verifyAdd?: (item: SelectionItem) => boolean,
    canAdd?: () => boolean
}

type CopilotInputItemsProps = {
    inputItems: InputItem[]
}

const menuProps: Partial<MenuProps> = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
    getContentAnchorEl: null, // This prevents the menu from selecting the first item as its anchor
    PaperProps: {
        style: {
          transform: 'translate(-5px, 4px)'
        }
    }
  }


export const CopilotInputItems = observer(({ inputItems }: CopilotInputItemsProps) => {
    return (<Stack spacing={2} width='100%'>
                {inputItems.map(ii =>
                    <Stack spacing={0.5} key={ii.label}>
                        <Typography style={{color: ii.disabled ? '#0000004d' : 'black'}}>{ii.label}</Typography>
                        {ii.type === 'select' ?
                            ii.isReady() ?
                                <SIAutocomplete 
                                    items={ii.items!}
                                    initial_selection={ii.selectedItem} 
                                    onSelect={ii.onChange}
                                    onAddSelectionItem={ii.onAddSelectionItem}
                                    verifyAdd={ii.verifyAdd}
                                    style={{ width: '100%' }}
                                    canAdd={ii.canAdd}
                                    loadProgress={()=> ii.showLoading() as boolean}
                                    disabled={ii.disabled}
                                /> :
                                <div key={ii.label}>
                                    <TextField label="Loading" variant="outlined" style={{ width: '100%' }} disabled={true} />
                                    {ii.showLoading() && <LinearProgress />}
                                </div>
                            : ii.type === 'input' ? <TextField variant="outlined" value={ii.value} onChange={(e) => ii.onChange(e as any)}>{ii.value}</TextField>
                            : ii.type === 'checkbox' ? <Checkbox checked={ii.value as boolean} onChange={(e) => ii.onChange(e as any)} style={{ width: '20px' }} disabled={!ii.isReady()|| ii.disabled}></Checkbox>
                            : ii.type === 'selection' ?
                                <Select
                                  value={ii.selectedItem}
                                  onChange={event => ii.onChange(event.target.value as any)}
                                  variant='outlined'
                                  disabled={!ii.isReady()|| ii.disabled}
                                  MenuProps={menuProps}
                                >
                                    {ii.items!.map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
                                </Select>
                            :  ii.type === 'multiselect' ?
                                <SIAutocompleteMultiSelect
                                    items={ii.items!}
                                    initial_selection={ii.selectedItem}
                                    onSelect={ii.onChange} 
                                    style={{ width: '100%' }}
                                    loadProgress={()=> ii.showLoading() as boolean}
                                    disabled={ii.disabled}/>
                            : <></>}
                    </Stack>)}
            </Stack>)
})