import { makeStyles, Tab, Tabs } from '@material-ui/core'
import React, { useEffect } from 'react'
import { FieldsEditor } from './FieldsEditor';
import { MainStore } from '../../stores/MainStore';
import { VersionSelection } from './VersionSelection';
import { TemplateTestComponent } from './TestComponent';
import { DashboardSaver } from './DashboardSaver';
import { PatchEditor } from './PatchEditor';
import { CreateTemplateDialog, ICloneDetails } from './CreateTemplateDialog';

const useStyles = makeStyles((theme) => ({
    page: {
      margin: '50px',
      height: 'calc(100vh - 65px - 100px)',
      display: 'flex',
      flexDirection: 'column'
    },
    tabs_bar: {
      borderBottom: "solid 1px gray",
      marginBottom: '10px'
    },
    tab: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto'
    },
    version_bar: {
      display: 'flex',
      marginBottom: '20px'
    },
    version_label: {
      marginRight: 20
    },
    select: {
      paddingLeft: '15px'
    }
  }));

type TemplateEditorProps = {
  store: MainStore
}

const FIELDS_FILE = "field_templates.csv"
const PATCHS_FILE = "patches.json"
const DASHBD_FILE = "dashboard_template.json"

export const TemplateEditor = ({store}: TemplateEditorProps) => {
    const classes = useStyles();
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [versions, setVersions] = React.useState<string[]>([]);
    const [templateVersion, setTemplateVersion] = React.useState("");
    const [openCreateDialog, setOpenCreateDialog] = React.useState(false);

    useEffect(() => {
      const getVersions = async () => {
        const versions = await store.fetchTemplateVersions()
        setVersions(versions)
      }
      getVersions()
    },[])

    const onSetVersion = (version: string) => {
      setTemplateVersion(version)
      if (version && !versions.includes(version)) setOpenCreateDialog(true)
    }

    const onCancel = () => {
      setTemplateVersion("")
    }

    const onClose = () => {
      setOpenCreateDialog(false)
    }

    const onCreate = async (version: string, cloneDetails: ICloneDetails) => {
      if (cloneDetails.clone && versions.includes(cloneDetails.version)) {
        const template_files = []
        if (cloneDetails.fields) template_files.push(FIELDS_FILE)
        if (cloneDetails.patches) template_files.push(PATCHS_FILE)
        if (cloneDetails.dashboard) template_files.push(DASHBD_FILE)
        for (const fileName of template_files ) {
          const file = await store.fetchTemplateFile(cloneDetails.version, fileName)
          if (file) store.saveTemplateFile(version, file)
        }
      }
      setVersions([...versions, version])
      cloneDetails.dashboard ? setSelectedTab(1) : setSelectedTab(2)
    }

    const tabs = [
      {"label": "XMD Fields", "component": <FieldsEditor store={store} templateVersion={templateVersion} fileName={FIELDS_FILE}/>},
      {"label": "Patches", "component": <PatchEditor store={store} templateVersion={templateVersion} fileName={PATCHS_FILE}/>},
      {"label": "Dashboard", "component": <DashboardSaver store={store} templateVersion={templateVersion} fileName={DASHBD_FILE}/>}
    ]

    return (<div className={classes.page}>
                <CreateTemplateDialog versions={versions} version={templateVersion} open={openCreateDialog} onCreate={onCreate} onCancel={onCancel} onClose={onClose} />
                <div className={classes.version_bar}>
                  <VersionSelection versions={versions} version={templateVersion} onSetVersion={onSetVersion}/>
                  <TemplateTestComponent style={{marginLeft: '15px'}} store={store} test={tabs[selectedTab]["label"].toLowerCase()} template={templateVersion}/>
                </div>
                <Tabs value={selectedTab}
                      className={classes.tabs_bar}
                      onChange={(event, newValue) => setSelectedTab(newValue)}>
                        {tabs.map((tab, i) => <Tab key={i} label={tab["label"]}/>)}
                  </Tabs>
                <div className={classes.tab}>
                  {tabs[selectedTab]["component"]}
                </div>
            </div>)
}