import { Stack } from "@mui/material"
import { DataGrid, GridColDef } from "@material-ui/data-grid"
import { IngestModel } from "./models"


type BulkProgressTabProps = {
    ingestModels: IngestModel[],
    loading: boolean
}

export const BulkProgressTab = ({ ingestModels, loading }: BulkProgressTabProps) => {
    const renderCell = (params: any, backgroundColor: string) => {
        const total = params.row.total_files
        if (!params.value) backgroundColor = ''
        if (!total) backgroundColor = '#FFDAB9'
        return <div style={{ backgroundColor, borderRadius: '4px', flex: 1}}>
                    {`${params.value ?? '?'}/${total ?? '?'}`}
            </div>
    }

    const renderIngestedCell = (params: any, backgroundColor: string) => {
        const total = params.row.total_files 
        if (!(params.value && params.value === total)) backgroundColor = ''
        if (!total) backgroundColor = '#FFDAB9'
        return <div style={{ backgroundColor, borderRadius: '4px', flex: 1}}>
                    {`${params.value ?? '?'}/${total ?? '?'}`}
            </div>
    }

    const columns: GridColDef[] = [
        {
            field: 'model_name',
            headerName: 'Model',
            flex: 1,    
            width: 300,
            editable: false
        },
        {
            field: 'pending',
            headerName: 'Pending',
            width: 130,
            align: 'center',
            editable: false,
            renderCell: (params) => renderCell(params, '#D3D3D3')
        },
        {
            field: 'failed',
            headerName: 'Failed',
            width: 130,
            align: 'center',
            editable: false,
            renderCell: (params) => renderCell(params, '#ffcccc')
        },
        {
            field: 'processing',
            headerName: 'Processing',
            width: 141,
            align: 'center',
            editable: false,
            renderCell: (params) => renderCell(params, '#ADD8E6')
        },
        {
            field: 'ingested',
            headerName: 'Ingested',
            width: 130,
            align: 'center',
            editable: false,
            renderCell: (params) => renderIngestedCell(params, '#d4edda')
        }
    ];

    return (<Stack width='100%' height='95%' spacing={2} style={{ overflowY: 'scroll', marginTop: '10px' }}>
                <DataGrid rows={ingestModels}
                          columns={columns}
                          pageSize={10}
                          getRowId={row => row.model_id}
                          loading={loading}
                />
            </Stack>)
}