import React, { useEffect, useState, useCallback } from 'react'
import { Button, FormControlLabel, LinearProgress, Link, makeStyles, Radio } from '@material-ui/core'
import { MainStore } from '../../stores/MainStore';
import { observer } from 'mobx-react-lite';
import { useDropzone } from 'react-dropzone'
import { StorageFile, CSVStorageFile } from '../../models/StorageFile';
import FileSaver from 'file-saver';
import { DataGrid, GridCellParams, GridColDef, GridRowId } from '@material-ui/data-grid';
import { FileMetadata } from '../../models/MetadataCSV';
import { Operations } from '../../Operations';
import FeatureFlagsService from '../../services/FeatureFlagsService';
import CsvCopyDialog from './CsvCopyDialog';

const useStyles = makeStyles((theme) => ({
    separator: {
        width: '100%',
        minHeight: 75,
        backgroundColor: '#ffffff',
        borderStyle: 'dashed',
        border: '1px dashed #858F9F',
        boxSizing: 'border-box',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#858F9F'
    },
    root: {
        "& .MuiTypography-root": {
            minWidth: '100%',
        }
    },
    textbtn: {
        textTransform: 'none',
        backgroundColor: 'transparent',
        fontSize: "inherit",
        fontWeight: 'inherit',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        },
        lineHeight: '19px'
    },

}));

type SelectCsvPartialProps = {
    folderName: string
    store: MainStore
    disableUpload?: boolean
    disableActions?: boolean
}

export const SelectCsvPartial = observer(({ folderName, store, disableUpload, disableActions }: SelectCsvPartialProps) => {

    const [isDownloading, setIsDownloading] = useState<boolean>(false)
    const [uploadProgress, setUploadProgress] = useState<{ isUploading: boolean, uploadedFile?: string, position?: number, total?: number } | undefined>(undefined)
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
    const [partialSelectionModel, setPartialSelectionModel] = React.useState<GridRowId[]>([]);

    const [copyDialogData, setCopyDialogData] = useState<{ isOpen: boolean, sourcePath: string }>({ isOpen: false, sourcePath: '' })
    const copyAllowed = store.securityStore && store.securityStore?.isAllowed(Operations.CopyCsv)
    const copyFeatureFlag = FeatureFlagsService.getClient().variation('copy_csv_between_clients', false)

    const classes = useStyles()

    const MyDropzone = () => {
        const onDropAccepted = useCallback(acceptedFiles => {
            handleProcess(acceptedFiles[0])
        }, [])
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDropAccepted, accept: 'text/csv' })

        return (
            <div className={classes.separator}
                {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop The CSV File Here ...</p> :
                        <p>Drag & Drop your Partial CSV File or Click To Select File</p>
                }
            </div>
        )
    }

    const handleProcess = async (file: File) => {
        setUploadProgress({ uploadedFile: file.name, isUploading: true })
        const presignedData = await store.fetchUploadUrl(file.name, store.currentOrgPartialsFolder!)
        await store.uploadToPresignedUrl(file, presignedData, (position: number, total: number) => {
            setUploadProgress({ uploadedFile: file.name, isUploading: true, position: position, total: total })
        })
        store.fetchExistingFiles(true)
        setUploadProgress(undefined)
    }

    useEffect(() => {
        if (store.CsvFiles && store.CsvFiles.length > 0) {
            store.selectedCsvFile ? setSelectionModel([store.selectedCsvFile.url]) : setSelectionModel([store.CsvFiles[0].url])
        }

        if (store.PartialCsvFiles && store.PartialCsvFiles.length > 0) {
            store.selectedPartialCsvFile ? setPartialSelectionModel([store.selectedPartialCsvFile.url]) : setPartialSelectionModel([store.PartialCsvFiles[0].url])
        }


    }, [store.CsvFiles, store.selectedCsvFile, store.PartialCsvFiles, store.selectedPartialCsvFile ])


    const onDownload = async (storageFile: StorageFile) => {
        if (isDownloading) return
        setIsDownloading(true)
        FileSaver.saveAs(await store.getDownloadUrl(storageFile.url), storageFile.name)
        setIsDownloading(false)
    }

    const normalize = (value: number) => uploadProgress && uploadProgress.total ? (value - 0) * 100 / (uploadProgress.total - 0) : 0;

    const columns: GridColDef[] = [
        {
            field: "  ",
            headerName: "",
            editable: false,
            width: 62,
            renderCell: (params) => (<div>
                <Radio checked={selectionModel[0] === params.id}
                    value={params.id}
                    color="primary"
                    onChange={() => handleSelection([params.id])} /></div>

            )

        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 3,
            editable: false
        }
    ];

    const partialColumns: GridColDef[] = [
        {
            field: "  ",
            headerName: "",
            editable: false,
            width: 62,
            renderCell: (params) => (<div>
                <Radio checked={partialSelectionModel[0] === params.id}
                    value={params.id}
                    color="primary"
                    onChange={() => handlePartialSelection([params.id])} /></div>

            )

        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 3,
            editable: false
        },
        {
            field: " ",
            headerName: "",
            flex: 1,
            width: 100,
            sortable: false,
            disableClickEventBubbling: true,
            hide: disableActions,
            renderCell: (params: GridCellParams) => {
                return (<Link href="#" style={{ color: '#17B1E2' }} onClick={() => {
                    onDownload(params.row as StorageFile)
                }}>
                    Download
                </Link>)
            }
        },
    ];

    const handleSelection = (rowIds: GridRowId[]) => {
        const newSelectionModel = rowIds

        if (newSelectionModel.length > 1) {
            const selectionSet = new Set(selectionModel);
            const result: any = newSelectionModel.filter(
                (s) => !selectionSet.has(s)
            );

            setSelectionModel(result);
            store.setSelectedCsv(store.CsvFiles!.find(file => file.url === result[0]))
        } else {
            setSelectionModel(newSelectionModel);
            store.setSelectedCsv(store.CsvFiles!.find(file => file.url === newSelectionModel[0]))
        }
    }

    const handlePartialSelection = (rowIds: GridRowId[]) => {

        const newSelectionModel = rowIds


        if (newSelectionModel.length > 1) {
            const selectionSet = new Set(partialSelectionModel);
            const result: any = newSelectionModel.filter(
                (s) => !selectionSet.has(s)
            );

            setPartialSelectionModel(result);
            store.setSelectedPartialCsv(store.PartialCsvFiles!.find(file => file.url === result[0]))
        } else {
            setPartialSelectionModel(newSelectionModel);
            store.setSelectedPartialCsv(store.PartialCsvFiles!.find(file => file.url === newSelectionModel[0]))
        }
    }

    return (
        <div style={{ height: '65vh' }}>
            <div style={{ height: '65vh', marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '50%', height: '100%' }}>
                    {store.CsvFiles && <DataGrid
                        rows={store.CsvFiles}
                        columns={columns}
                        pageSize={10}
                        disableSelectionOnClick
                        onCellClick={(params: GridCellParams, event: React.MouseEvent) => { params.colDef.headerName !== "" && handleSelection([params.id]) }}
                        getRowId={row => row.url}
                        selectionModel={selectionModel}
                        onSelectionModelChange={(selection) => handleSelection(selection.selectionModel)}
                    />}
                </div>
                <div style={{ width: '10px' }} />
                <div style={{ width: '50%', height: '100%' }}>
                    <MyDropzone />
                    {uploadProgress && uploadProgress.isUploading &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <FormControlLabel disabled value={''} control={<Radio />} label={uploadProgress.uploadedFile} />
                            {uploadProgress.position && <LinearProgress variant="determinate" value={normalize(uploadProgress.position)} />}
                        </div>}
                    <div style={{height: 'calc(100% - 10px - 75px)', marginTop: '10px' }}>
                        {store.PartialCsvFiles && <DataGrid
                            rows={store.PartialCsvFiles}
                            columns={partialColumns}
                            pageSize={9}
                            disableSelectionOnClick
                            onCellClick={(params: GridCellParams, event: React.MouseEvent) => { params.colDef.headerName !== "" && handlePartialSelection([params.id]) }}
                            getRowId={row => row.url}
                            selectionModel={partialSelectionModel}
                            onSelectionModelChange={(selection) => handlePartialSelection(selection.selectionModel)}
                        />}
                    </div>  
                </div>
            </div>
        </div>
    );
})