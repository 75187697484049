export interface PushMessage {
    topic: string
}

export interface ProgressPushMessage extends PushMessage {
    subject: string,
    progress: number,
    message?: string,
    state?: 'success' | 'fail' | 'warn'
}

export interface ValidationMessage {
    message: string,
    state: 'info' | 'warning' | 'error',
    impact: 'low' | 'high'
}

export function instanceOfValidationPushMessage(object: any): object is ValidationPushMessage {
    return 'messages' in object;
}

export interface ValidationPushMessage extends PushMessage {
    messages: ValidationMessage[],
    state: 'info' | 'warning' | 'error',
    str_message: string
}

export interface ProgressFailMessage extends PushMessage {
    subject: string,
    message: string
    state: 'info' | 'warning' | 'error'
}

export interface InjectFailMessage extends ProgressFailMessage {
    subject: string,
    message: string
}

export interface CreateAppMessageItem extends PushMessage {
    name: string,
    total: number
    success: number
    fail: number
}

export interface CreateAppMessage extends PushMessage {
    is_complete: boolean,
    messages: CreateAppMessageItem[]
}