import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import FeatureFlagsService from './services/FeatureFlagsService';
import { Settings } from './Settings';

(async () => {
  const appSettings = await Settings.getSettings()
  const LDProvider = await asyncWithLDProvider({
    clientSideID: appSettings.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT || '624c27d00a85d95b3c3923dc',
    user: {
      "key": "unknown user",
      "email": "unknown user"
    },
    options: { /* ... */ }
  });

await FeatureFlagsService.init(appSettings.env.REACT_APP_LAUNCHDARKLY_SDK_CLIENT)

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <LDProvider>
      <App />
    </LDProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
})();