import { action, computed, makeAutoObservable, observable } from "mobx"
import FeatureFlagsService from "../../services/FeatureFlagsService"
import { MainApi } from "../../apis/MainApi"
import { TriageCustomer, TriageManufacturer, TriageModel, TriageObservation } from "../../models/TriageCustomer"
import { TriageProduct } from "../../models/TriageProduct"
import { ConfigItem } from "../../models/ConfigItem"
import { IngestPayload, IngestFile, CachedItem, TenantConfig } from "./models"
import Backend from "../../apis/KABackend"
import { activeIngestStatus } from "./types"

export class CopilotStore {
    mainApi?: MainApi
    backend?: Backend
    configuration?: ConfigItem[]
    user_email: string = ''
    featureFlags:any

    private selectedRegion: 'US' | 'EU' = 'US'
    private triageCustomers: TriageCustomer[] = []
    private triageProducts: TriageProduct[] = []
    private triageManufacturers: TriageManufacturer[] = []
    private triageModels: TriageModel[] = []
    private triageObservations: TriageObservation[] = []
    private files: IngestFile[] = []

    @observable selectedCustomer?: TriageCustomer
    @observable selectedProduct?: TriageProduct
    @observable selectedManufacturer?: TriageManufacturer
    @observable selectedModel?: TriageModel
    @observable selectedFiles: IngestFile[] = []

    @observable productsLoading: boolean = false
    @observable manufacturersLoading: boolean = false
    @observable modelsLoading: boolean = false
    @observable observationsLoading: boolean = false
    @observable filesLoading: boolean = false

    @observable tenantConfig?: TenantConfig
    @observable serverlessIndex: boolean = false
    
    constructor(api: MainApi) {
        makeAutoObservable(this)
        this.mainApi = api
        this.featureFlags = FeatureFlagsService.getAllFlags()
    }

    @computed get customers() {
        return this.triageCustomers
    }

    @computed get region() {
        return this.selectedRegion
    }

    @computed get products() {
        return this.triageProducts.filter(tp => tp.customerid === this.selectedCustomer?.id)
    }

    @computed get TriageProducts() {
        return this.triageProducts
    }

    @computed get manufacturers() {
        return this.triageManufacturers.filter(tp => tp.customer_id === this.selectedCustomer?.id)
    }

    @computed get models() {
        return  this.triageModels.filter(tm => (this.selectedProduct === undefined || tm.producttype_id === this.selectedProduct.id) &&
        (this.selectedManufacturer === undefined || tm.manufacturer_id === this.selectedManufacturer.id))
    }

    @computed get HasTriageModels() {
        return this.triageModels.length > 0
    }

    @computed get observations() {
        return this.triageObservations.filter(tm => this.selectedProduct === undefined || tm.producttype_id === this.selectedProduct.id)
                                      .slice().sort((c1, c2) => c1.observation_name.localeCompare(c2.observation_name))
    }

    @computed get folderName() {
        return this.selectedCustomer && this.selectedModel ? this.selectedCustomer.name + '/' + this.selectedModel.model_name.replaceAll('/', '-') : undefined
    }

    @computed get ingestFiles() {
        return this.filterFiles(this.files)
    }

    @computed get selectedFilesIds() {
        return this.selectedFiles?.map(row => row.id)
    }

    filterFiles = (files: IngestFile[]) => {
        return files.filter((file) => {
            return true
        })
    }

    @action
    setTriageCustomers = (customers: TriageCustomer[]) => {
        this.triageCustomers = customers
    }

    @action
    setTriageProducts = (products: TriageProduct[]) => {
        this.triageProducts = products
    }

    @action
    setTriageManufacturers = (manufacturers: TriageManufacturer[]) => {
        this.triageManufacturers = manufacturers
    }

    @action
    setTriageModels = (models: TriageModel[]) => {
        this.triageModels = models
    }

    @action
    setTriageObservations = (observations: TriageObservation[]) => {
        this.triageObservations = observations
    }

    @action
    setSelectedFiles = (selectedFiles:IngestFile[]) => {
        this.selectedFiles = selectedFiles
    }

    @action
    setSelectedCustomer = (id?: string) => {
        if (this.selectedCustomer?.id === id) return;

        this.selectedProduct = undefined
        this.selectedModel = undefined
        this.triageManufacturers = []
        this.triageModels = []
        this.files = []
        this.triageObservations = []
        this.serverlessIndex = false

        this.selectedCustomer = this.triageCustomers.find(tc => tc.id === id)
        if (this.selectedCustomer) {
            // set tenant info in db if not already set
            this.backend!.saveTenantInfo(this.selectedCustomer.name, this.selectedCustomer.organization_id ?? '')
            this.fetchTenantConfig(this.selectedCustomer.name)
            this.fetchTriageManufacturers(id)
            this.fetchModels(id)
            this.fetchTriageObservations(id)
            this.backend!.getTenantMetadata(this.selectedCustomer.name).then((metadata) => {
                this.serverlessIndex = metadata.serverless_index
            })
        }
    }

    @action
    setTenantConfig = (config: TenantConfig) => {
        this.tenantConfig = config
    }

    @action
    setSelectedProduct = (id?: string) => {
        this.selectedProduct = this.triageProducts.find(tc => tc.id === id)
    }

    @action
    setSelectedManufacturer = (id?: string) => {
        this.selectedManufacturer = this.triageManufacturers.find(tc => tc.id === id)
    }

    @action
    setSelectedRegion = (region: 'US' | 'EU') => {
        this.selectedRegion = region
        this.resetSelections()
        this.refreshStore()
    }

    @action
    setSelectedModel = (id?: string) => {
        if (this.selectedModel?.id === id) return;
        this.setFiles([])
        this.selectedModel = this.models.find(tc => tc.id === id)
        this.fetchIngestFiles()
    }

    @action
    addNonTriageModel = (id: string) => {
        const model : TriageModel = {
            id: id,
            customer_id: this.selectedCustomer!.id,
            model_name: id,
            manufacturer_id: "",
            producttype_id: ""
        }
        this.triageModels = [...this.triageModels, model]
    }

    @action
    setFiles = (files: IngestFile[]) => {
        this.files = files
        this.selectedFiles = []
    }

    @action
    setTriageProductsLoading = (loading: boolean) => {
        this.productsLoading = loading
    }

    @action
    setTriageManufacturersLoading = (loading: boolean) => {
        this.manufacturersLoading = loading
    }

    @action
    setTriageModelsLoading = (loading: boolean) => {
        this.modelsLoading = loading
    }

    @action
    setTriageObservationsLoading = (loading: boolean) => {
        this.observationsLoading = loading
    }

    @action
    setFilesLoading = (loading: boolean) => {
        this.filesLoading = loading
    }

    initStore = (access_token: () => string | undefined, user_email: string, ka_base_url: string, config?: ConfigItem[]) => {
        this.configuration = config
        this.backend = new Backend(ka_base_url, access_token)
        this.user_email = user_email
        this.fetchTriageCustomers(config)
        this.fetchTriageProducts()
    }

    refreshStore = async () => {
        this.fetchTriageCustomers(this.configuration)
        this.fetchTriageProducts()
    }

    setConfiguration = (config?: ConfigItem[]) => {
        this.configuration = config
        if (this.triageCustomers)
            this.setTriageSchemaConfig(this.triageCustomers, config)
    }

    fetchTriageCustomers = async (config?: ConfigItem[]) => {
        this.mainApi!.getTriageCustomers(this.region).then((items) => {
            this.setTriageCustomers(items)
            this.setTriageSchemaConfig(items, config)
        })
    }

    fetchTriageProducts = async () => {
        this.setTriageProductsLoading(true)
        this.mainApi!.getTriageProducts(this.region).then((items) => {
            this.setTriageProducts(items)
            this.setTriageProductsLoading(false)
        })
    }

    fetchTriageManufacturers = async (customer_id?: string) => {
        this.setTriageManufacturersLoading(true)
        this.mainApi!.getTriageManufacturers(customer_id, this.region).then((items) => {
            this.setTriageManufacturers(items)
            this.setTriageManufacturersLoading(false)
        })
    }

    fetchTenantConfig = async (tenant_name: string) => {
        const response = await this.backend!.getTenantConfig(tenant_name)
        if (response !== null) {
            this.setTenantConfig(response)
        }
    }

    saveTenantConfig = async (tenant_name: string, config: TenantConfig) => {
        const response = await this.backend!.saveTenantConfig(tenant_name, config)
        this.setTenantConfig(response)
    }

    fetchModels = async (customer_id?: string, producttype_id?: string) => {
        this.setTriageModelsLoading(true)
        try {
            const response = await this.mainApi!.getTriageModels(customer_id, producttype_id, this.region)
            const models = response.filter((v, i, a) => a.findIndex(t => (t.model_name === v.model_name)) === i)
            const customer_name = this.customers.find(c => c.id === customer_id)?.name
            if (customer_name) {
                try {
                    const kaModels = await this.backend!.getIngestModels(customer_name)
                    for (const model of kaModels) {
                        if (models.find(m => m.model_name === model)) continue
                        models.push({
                            id: model,
                            customer_id: "",
                            model_name: model,
                            manufacturer_id: "",
                            producttype_id: ""
                        })
                }}
                catch (error) {
                    console.log('Error fetching KA models', error)
                }
            }
            this.setTriageModels(models.sort((a, b) => a.model_name.localeCompare(b.model_name)))
        } finally {
            this.setTriageModelsLoading(false)
        }
    }

    fetchTriageObservations = async (customer_id?: string, producttype_id?: string) => {
        this.setTriageObservationsLoading(true)
        this.mainApi!.getTriageObservations(customer_id, producttype_id).then((items) => {
            this.setTriageObservations(items)
            this.setTriageObservationsLoading(false)
        })
    }

    getIngestFiles = async (customerName: string, model_name: string) => {
        let response = await this.backend!.getFiles(customerName, model_name)
        const documents = response.documents
        while (response.has_next) {
            documents.push(...response.documents)
            response = await this.backend!.getFiles(customerName, model_name, documents.length - 1)
        }
        return documents
    }

    fetchIngestFiles = async () => {
        if (!this.selectedCustomer || !this.selectedModel) return []
        this.setFilesLoading(true)
        this.getIngestFiles(this.selectedCustomer!.name, this.selectedModel!.model_name).then((documents) => {
            this.setFiles(documents)
        }).catch(() => {console.log('Error fetching files')
        }).finally(() => { this.setFilesLoading(false) })
    }
    
    addIngestFiles = async (fileNames: Array<string>) => {
        return await this.backend!.addFiles(this.selectedCustomer!.name, this.selectedModel!.model_name, fileNames, this.user_email)
    }

    deleteIngestFiles = async (files: IngestFile[]) => {
        return await this.backend!.deleteFiles(this.selectedCustomer!.name, this.selectedModel!.model_name, files.map(f => f.document_name), this.user_email)
    }

    copilotIngest = async (customerName: string, model_name: string, ingest_payload: IngestPayload) => {
        return await this.backend!.ingest(customerName, model_name, ingest_payload)
    }

    copilotIngestStatus = async (customerName: string, model_name: string) => {
        return await this.backend!.getIngestStatus(customerName, model_name)            
    }

    copilotIngestStatusBulk = async (customerName: string, model_names: string[]) => {
        try {
            return await this.backend!.getIngestStatusBulk(customerName, model_names)            
        } catch (error) {
            return undefined
        }
    }

    fetchKaUploadUrlByFileName = async (fileNames: Array<string>) => {
        const filePaths = fileNames.map(fn => this.folderName + '/' + fn)
        const response = this.backend!.getUploadUrls(this.selectedCustomer!.name, filePaths, this.user_email)
        return response
    }

    updateFilesContentMetadata = async (fileNames: Array<string>, folderName: string) => {
        const filePaths = fileNames.map(fn => folderName + fn)
        const response = this.backend!.updateMetadata(this.selectedCustomer!.name, filePaths)
        return response
    }

    fetchCacheItems = async (tenant_name: string, modelName: string) => {
        const response = this.backend!.getCacheItems(tenant_name, modelName)
        return response
    }

    editCacheItems = async (tenant_name: string, modelName: string, cachedItems:Array<CachedItem>) => {
        const response = this.backend!.editCacheItems(tenant_name, modelName, cachedItems)
        return response
    }

    deleteCacheItems = async (tenant_name: string, modelName: string, toDeleteIds: string[]) => {
        const response = this.backend!.deleteCacheItems(tenant_name, modelName, toDeleteIds)
        return response
    }

    @action
    resetSelections = () => {
        this.triageCustomers = []
        this.triageProducts = []
        this.triageManufacturers = []
        this.triageModels = []

        this.setSelectedCustomer(undefined)
        this.setSelectedProduct(undefined)
        this.setSelectedManufacturer(undefined)
        this.setSelectedModel(undefined)
    }

    @action
    refreshFiles = async () => await this.fetchIngestFiles()

    @action
    refreshFileStatus = async () => {
        if (!this.selectedCustomer || !this.selectedModel) return
        this.backend!.getFiles(this.selectedCustomer!.name, this.selectedModel!.model_name).then((resp) => {
            for (const file of this.files) {
                const updatedFile = resp.documents.find(f => f.document_name === file.document_name)
                if (!updatedFile) continue
                file.status = updatedFile.status
                if (activeIngestStatus.includes(file.status) && this.selectedFilesIds
                    && this.selectedFilesIds.includes(file.id)) {
                    this.setSelectedFiles(this.selectedFiles.filter(f => f.id !== file.id))
                }
            }
            this.files = [...this.files]
        }).catch(() => {console.log('Error fetching files')
        })
    }

    private setTriageSchemaConfig(items: TriageCustomer[], config?: ConfigItem[]) {
        if (config) {
            const index = config.findIndex(item => item.name === 'triage_schema')
            if (index !== -1) {
                config[index].options = items.slice().sort((a, b) => a.name.localeCompare(b.name));
                config[index].value = config[index].value ?
                    items.find(i => i.name === config![index].value.name) :
                    config[index].value
            }
        }
    }
}

