import { Button, Hidden, makeStyles, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { MainStore } from '../../stores/MainStore';
import { SalesforceDashboard } from '../../models/SalesforceDashboard';
import { observer } from 'mobx-react-lite';
import { CustomerInformation } from './CustomerInformation';
import { LinkGenerator } from './LinkGenerator';
import { SelectInputCSV } from './SelectInputCSV';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ValueGenerator } from './ValueGenerator';
import { SalesforceApp } from '../../models/SalesforceApp';
import { HubspotIntegration } from './HubspotIntegration';
import { MailTemplatesSettings } from './MailTemplatesSettings'
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
    page: {
      margin: '50px',
    }
  }));

type DigestWizardProps = {
  mainStore: MainStore
}

export const DigestWizard = observer(({mainStore}: DigestWizardProps) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState<string | false>('panel1');
    const [appVersion, setAppVersion] = useState<string>()
    const [selectedDashboard, setSelectedDashboard] = useState<SalesforceDashboard>()
    const { digestFile } = mainStore
    const [templatesOpen, setTemplatesOpen] = useState<boolean>(false)

    useEffect(() => {
      mainStore.connectTestNotificationService()
    }, [])    

    const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

    const onSelectApp = (app?: SalesforceApp) => {
      setAppVersion(app?.version)
    }

    const hubspotDisabledCheck = () => {
      return !digestFile
      || mainStore.outputFile === undefined
      || mainStore.organizationMetadata?.digest?.hubspot_company_id === undefined
      || mainStore.organizationMetadata?.digest?.hubspot_company_id === ''
    }

    return (<div className={classes.page}>
              <div style={{width: '100%', display: 'flex', flexDirection:'row-reverse'}}>
                <Button variant='outlined' 
                  style={{ right: 0}} 
                  onClick={() => setTemplatesOpen((prev)=> !prev)}>
                  <SettingsIcon style={{marginRight: 5}} /> Manage Mail Templates
                </Button>
              </div>
              {templatesOpen && <Accordion expanded={true} onChange={()=>setTemplatesOpen((prev)=> !prev)}>
                <AccordionSummary expandIcon={<CloseIcon />}>
                  <Typography>Mail Templates Settings</Typography>
                </AccordionSummary>
                <AccordionDetails style={{minHeight: '400px'}}>
                  <MailTemplatesSettings mainStore={mainStore}/>
                </AccordionDetails>
              </Accordion>}
              {!templatesOpen && <>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Customer Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomerInformation mainStore={mainStore} onSelectApp={onSelectApp} onSelectDashboard={setSelectedDashboard}/>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} disabled={mainStore.CsvFiles === undefined}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Select Data</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SelectInputCSV mainStore={mainStore}  />
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} disabled={mainStore.outputFile === undefined}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Generate Values</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <ValueGenerator mainStore={mainStore}  />
                </AccordionDetails>
              </Accordion>
              {/* <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} disabled={selectedDashboard === undefined}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Generate Links</Typography>
                </AccordionSummary>
                <AccordionDetails>
                <LinkGenerator mainStore={mainStore} appVersion={appVersion} selectedDashboard={selectedDashboard}/>
                </AccordionDetails>
              </Accordion> */}
              <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} disabled={hubspotDisabledCheck()}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>HubSpot Integration</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <HubspotIntegration mainStore={mainStore} selectedDashboard={selectedDashboard} />
                </AccordionDetails>
              </Accordion>
              </>}
            </div>)
})