import { Button, Slider, TextField, Typography } from '@material-ui/core'
import React from 'react'


type WPIProps = {
}

export const WPI = (props: WPIProps) => {
    const [wpi_visits_score, set_wpi_visits_score] = React.useState(1.5);
    const [wpi_fcr_score, set_wpi_fcr_score] = React.useState(1.5);
    const [wpi_ftf_score, set_wpi_ftf_score] = React.useState(1.5);
    const [wpi_mtbv_score, set_wpi_mtbv_score] = React.useState(1.5);
    const [wpi_mttr_score, set_wpi_mttr_score] = React.useState(1.5);
    const [wpi_part_cps_score, set_wpi_part_cps_score] = React.useState(1.5);
    const [wpi_labor_cps_score, set_wpi_labor_cps_score] = React.useState(1.5);
    
    const [savedScore, setSavedScore] = React.useState<number | undefined>(undefined);

    const renderItem = (header: string, value: number, onUpdate: (value: number) => void, min: number = 1, max: number = 2) => {
        return (<div>
            <Typography>{header}</Typography>
            <div style={{display: 'flex', flexDirection:'row', alignItems: 'flex-end'}}>
            <Slider value={value} onChange={(event: any, newValue: any) => onUpdate(newValue)} min={min} max={max} step={0.01} />
            <TextField style={{marginLeft: 10}} variant='outlined' value={value} onChange={(event: any) => onUpdate(event.target.value)}></TextField>
            </div>
        </div>)
    }

    const wpi_field_index =
        0.4 * wpi_visits_score +
        0.1 * wpi_fcr_score +
        0.2 * wpi_ftf_score +
        0.4 * wpi_mtbv_score +
        0.1 * (1 / wpi_mttr_score) +
        0.3 * (1 / wpi_part_cps_score) +
        0.1 * (1 / wpi_labor_cps_score)

    const wpi_score = ((wpi_field_index - 1.35) / (2.7 - 1.35)) * 1000

    if (!savedScore) setSavedScore(wpi_field_index)

    return (
        <>
            <div style={{ margin: 30 }}>
                <Typography>wpi_field_index: {wpi_field_index}</Typography>
                <Typography>wpi_score: {wpi_score}</Typography>
                {savedScore && <Typography>change: {Math.abs(savedScore - wpi_field_index)}</Typography>}
                {<Typography>far from hero: {(2.1223609832957835 - wpi_field_index)}</Typography>}
                <Button onClick={()=> setSavedScore(undefined)}>RESET</Button>
                <div style={{minHeight: 20}}></div>
                {renderItem('wpi_visits_score', wpi_visits_score, set_wpi_visits_score)}
                {renderItem('wpi_fcr_score', wpi_fcr_score, set_wpi_fcr_score)}
                {renderItem('wpi_ftf_score', wpi_ftf_score, set_wpi_ftf_score)}
                {renderItem('wpi_mtbv_score', wpi_mtbv_score, set_wpi_mtbv_score)}
                {renderItem('wpi_mttr_score', wpi_mttr_score, set_wpi_mttr_score)}
                {renderItem('wpi_part_cps_score', wpi_part_cps_score, set_wpi_part_cps_score)}
                {renderItem('wpi_labor_cps_score', wpi_labor_cps_score, set_wpi_labor_cps_score)}
            </div>
        </>
    )
}