import FailedMetricEditor from "../components/wizard/FailedMetricEditor";
import NlpProductEditor from "../components/wizard/NlpProductSelect";
import TriageProductSelect from "../components/wizard/TriageProductSelect";
import { MainStore } from "../stores/MainStore";
import { NlpCustomer } from "./NlpCustomer";
import { TriageCustomer } from "./TriageCustomer";
import { TriageProduct } from "./TriageProduct";


export interface CustomItemRenderProps {
    val: any
    setVal: (updated: any) => void
    itemsSelection?: string[]
}

export enum LogicalItem{
    OR,
    AND
}

export interface ConfigItem {
    name: string
    mergeFrom?: string
    label?: string
    value?: any
    isRequired:boolean
    type: 'int' | 'bool' | 'string' | 'select' | 'custom'| 'free_text'
    labelValueDependent?:boolean
    dependencies?:{dependencyValues:string[], logicalContext:LogicalItem}
    options?: any[]
    displayProperty?: string
    hasEmptyItem?: boolean
    featureFlag?:{name: string, trueValue: boolean}//feature flag enam
    render?: (p: CustomItemRenderProps) => JSX.Element
    allowMultiple?: boolean,
    category?: string
    isInvisible?: boolean
    valueSelectedObserved?:boolean
    valueSelectedObservedFilter?: (p: any, store: MainStore) => any
    tooltip?: string
    observerOn?: string
}

export const defaultConfigItems: ConfigItem[] = [
    // {name: 'asset_tco', type:'bool', isRequired:false},
    // required
    { name: 'investigation_id', label: '', type: 'string', isRequired: true, value: 'investigation_id'},
    { name: 'producttype_t2', mergeFrom: 'producttype_name', label: '', type: 'string', isRequired: true, value: 'producttype_name', labelValueDependent: true},
    { name: 'asset_id', label: '',type: 'string', isRequired: true, value: 'asset_id' },
    { name: 'field_workforce_name', label: '', type: 'string', isRequired: true, value:'field_agent_name' },
    { name: 'part_id', label: '', type: 'string', isRequired: true, value: 'part_id' },
    { name: 'part_description', label: '', type: 'string', isRequired: true, value: 'part_description' },
    { name: 'event_category', label: '', type: 'string', isRequired: true, value: 'event_category' },
    { name: 'event_type', label: '', type: 'string', isRequired: true, value: 'event_type' },
    { name: 'visit_date', label: '', type: 'string', isRequired: true, value: 'visit_date' },
    { name: 'install_date', label: '', type: 'string', isRequired: true, value: 'install_date' },
    { name: 'customer_name_t0', label: '', type: 'string', isRequired: true, value: 'customer_name_t0' , labelValueDependent: true},

    // Optional### If you don't have these fields in your data set put 'n/a', we will make assumptions about each field
    { name: 'producttype_t1', mergeFrom: 'manufacturer_name', label: '', type: 'string', isRequired: false, labelValueDependent: true, value: 'manufacturer_name'},
    { name: 'producttype_t0', mergeFrom: 'model_name', label: '', type: 'string', isRequired: false, value: 'model_name', labelValueDependent:true},
    { name: 'observation_name', label: '', type: 'string', isRequired: false, value: 'observation_name' },
    { name: 'solution_name', label: '', type: 'string', isRequired: false, value: 'solution_name' },
    { name: 'case_creation_date', label: '', type: 'string', isRequired: false, value: 'case_creation_date' },
    { name: 'field_workforce_id', label: '', type: 'string', isRequired: false, value: 'field_workforce_id' },
    { name: 'remote_workforce_id', label: '', type: 'string', isRequired: false, value: 'remote_workforce_id' },
    { name: 'remote_workforce_name', label: '', type: 'string', isRequired: false, value: 'remote_workforce_name', 
            tooltip: 'custom label will be overwritten by field_workforce_name custom label if specified' },
    { name: 'event_agent_group_name', label: '', type: 'string', isRequired: false, value: 'team_group', labelValueDependent:true
        , dependencies:{dependencyValues:['field_workforce_team_name', 'remote_workforce_team_name'], logicalContext:LogicalItem.OR}, featureFlag: {name: 'team_t1_hierarchy', trueValue: true}},

    { name: 'field_workforce_team_name', label: '', type: 'string', isRequired: false, value: 'field_workforce_team_name'},
    { name: 'remote_workforce_team_name', label: '', type: 'string', isRequired: false, value: 'remote_workforce_team_name', 
            tooltip: 'custom label will be overwritten by field_workforce_team_name custom label if specified'},
    { name: 'parent_event_id', label: '', type: 'string', isRequired: false, value: 'parent_event_id' },  // for datasets that have a parent work order or case
    { name: 'part_quantity', label: '', type: 'string', isRequired: false, value: 'part_quantity' },
    { name: 'labor_rate', label: '', type: 'string', isRequired: false, value: 'labor_rate' },
    { name: 'labor_duration', label: '', type: 'string', isRequired: false, value: 'labor_duration' },
    { name: 'part_unit_cost', label: '', type: 'string', isRequired: false, value: 'part_unit_cost' },
    { name: 'role', label: '', type: 'string', isRequired: false, value: 'role' },
    { name: 'travel_rate', label: '', type: 'string', isRequired: false, value: 'travel_rate' },
    { name: 'travel_duration', label: '', type: 'string', isRequired: false, value: 'travel_duration' },
    { name: 'asset_country', label: '', type: 'string', isRequired: false, value: 'asset_country' },
    { name: 'asset_state', label: '', type: 'string', isRequired: false, value: 'asset_state' },
    { name: 'asset_city', label: '', type: 'string', isRequired: false, value: 'asset_city' },
    { name: 'part_name', label: '', type: 'string', isRequired: false, value: 'part_name' }, //  # temporary field until removed from FE
    { name: 'customer_name_t1', label: '', type: 'string', isRequired: false, value: 'customer_name_t1' , labelValueDependent: true},
    { name: 'customer_name_t2', label: '', type: 'string', isRequired: false, value: 'customer_name_t2' , labelValueDependent: true},
    { name: 'version', label: '', type: 'string', isRequired: false, value: 'version' },
    { name: 'manufacture_date', label: '',type: 'string', isRequired: false, value: 'manufacture_date', featureFlag: {name: 'product-quality', trueValue: true} },
    { name: 'product_quality_filter', label: '',type: 'string', isRequired: false, value: 'product_quality_filter', featureFlag: {name: 'product-quality', trueValue: true} },
    { name: 'custom_data', label: '', type: 'string', isRequired: false, value: 'custom_data' ,labelValueDependent: true, 
            tooltip: 'This column will be used in the reference table', featureFlag: {name: 'reference_custom_text_column', trueValue: true}},
    { name: 'record_id', label: '',type: 'string', isRequired: false, value: 'record_id'},
    { name: 'zip_code', label: '',type: 'string', isRequired: false, value: 'zip_code'},
    { name: 'Triage_Obs_Cross_Reference', label: '',type: 'string', isRequired: false, value: 'Triage_Obs_Cross_Reference'},

    // # Parameters
    { name: 'dayfirst', type: 'bool', isRequired: false, value: false },
    { name: 'failed_metric', type: 'custom', isRequired: true, value: {default_threshold: 30, thresholds: {}}, render: FailedMetricEditor },
    { name: 'customer_events_threshold', type: 'int', isRequired: true, value: 5 },
    { name: 'wpi_events_threshold', type: 'int', isRequired: true, value: 10 },
    { name: 'wpi_heroes_percentile', type: 'int', isRequired: true, value: 70 },
    { name: 'wpi_challengers_percentile', type: 'int', isRequired: true, value: 30 },

    // '# How many months taken into consideration for trend analysis in agg_data and parts
    { name: 'long_trend', type: 'int', isRequired: true, value: 12 },
    { name: 'med_trend', type: 'int', isRequired: true, value: 6 },
    { name: 'short_trend', type: 'int', isRequired: true, value: 4 },
    //  '# window represents how many rolling months to use in measure
    { name: 'asset_upper_window', type: 'int', isRequired: true, value: 24 },
    { name: 'asset_lower_window', type: 'int', isRequired: true, value: 1 },
    { name: 'customer_upper_window', type: 'int', isRequired: true, value: 3 },
    { name: 'customer_lower_window', type: 'int', isRequired: true, value: 1 },
    { name: 'parts_upper_window', type: 'int', isRequired: true, value: 6 },
    { name: 'parts_lower_window', type: 'int', isRequired: true, value: 1 },
    // { name: 'workforce_wpi_lower_window', type: 'int', isRequired: true, value: 1 },
    // { name: 'workforce_wpi_upper_window', type: 'int', isRequired: true, value: 12 },
    // { name: 'workforce_metrics_lower_window', type: 'int', isRequired: true, value: 1 },
    // { name: 'workforce_metrics_upper_window', type: 'int', isRequired: true, value: 3 },
    { name: 'workforce_lower_window', type: 'int', isRequired: true, value: 1 },
    { name: 'workforce_upper_window', type: 'int', isRequired: true, value: 12 },
    { name: 'aquant_case', type: 'string', isRequired: false},
    { name: 'wpi_normalization', type: 'bool', isRequired: false, value: false },
    { name: 'wpi_split_classification', type: 'bool', isRequired: false, value: false },
    { name: 'observations_coverage_threshold', type: 'int', isRequired: false, value: 0.35 },
    { name: 'validate_outliers', type: 'bool', isRequired: false, value: true },
    { name: 'outliers_validation_percentile_upper_threshold', type: 'int', isRequired: false, value: 0.95 },
    { name: 'outliers_validation_percentile_lower_threshold', type: 'int', isRequired: false, value: 0.05 },
    { name: 'outliers_validation_z_score_threshold', type: 'int', isRequired: false, value: 2.5 },
    { name: 'outliers_validation_min_values_sample', type: 'int', isRequired: false, value: 30 },
    { name: 'determine_install_date', type: 'bool', isRequired: false, value: true },
    { name: 'opi_easy_percentile', type: 'int', isRequired: false, value: 20 },
    { name: 'opi_normal_percentile', type: 'int', isRequired: false, value: 50 },
    { name: 'opi_hard_percentile', type: 'int', isRequired: false, value: 90 },
    { name: 'opi_use_soft_solutions', type: 'bool', isRequired: false, value: true },
    { name: 'opi_hard_solutions_weight', type: 'int', isRequired: false, value: 0.67 },
    { name: 'workforce_dominant_role_enabled', type: 'bool', isRequired: false, value: true },
    { name: 'field_events_percentage', type: 'int', isRequired: false, value: 5 },
    { name: 'predict_unresolved_events_ftf', type: 'bool', isRequired: false, value: true },

    // PME
    { name: 'pme_investigation_count_threshold', type: 'int', isRequired: false, value: 4 },
    { name: 'pme_length_of_local_trend_factor', type: 'int', isRequired: false, value: 0.8 },
    { name: 'pme_score_classification_threshold', type: 'int', isRequired: false, value: 500 },
    { name: 'pme_use_reverse_log_final_score', type: 'bool', isRequired: false, value: true },
    { name: 'arm_immediate_trend_portion', type: 'int', isRequired: false, value: 0.5 },
    { name: 'arm_pre_threshold_date_factor', type: 'int', isRequired: false, value: 0.5 },
    { name: 'arm_life_length_ratio', type: 'int', isRequired: false, value: 1.5 },
    { name: 'arm_time_with_no_maintenance_portion', type: 'int', isRequired: false, value: 1 },
    { name: 'arm_relative_increase_minimum', type: 'int', isRequired: false, value: 2 },
    { name: 'arm_service_count_to_keep', type: 'int', isRequired: false, value: 5 },

    //  customization
    { name: 'currency', type: 'select', isRequired: true, value: 'USD-$', options: ['CAD-$', 'EUR-€', 'GBP-£', 'NIS-₪', 'USD-$', 'CHF-₣']},
    { name: 'labor_duration_agg', type: 'select', isRequired: true, value: 'Max', options: ['Sum', 'Mean', 'Max']},
    { name: 'shotgun_minimum_cost_per_part', type: 'int', isRequired: false, value: 100 },
    { name: 'shotgun_z_score_threshold', type: 'int', isRequired: false, value: 3.5 },
    { name: 'shotgun_min_overall_technician_work_order', type: 'int', isRequired: false, value: 100 },
    { name: 'shotgun_min_technician_work_order_per_product', type: 'int', isRequired: false, value: 50 },
    { name: 'shotgun_min_data_points_per_product', type: 'int', isRequired: false, value: 50 },
    { name: 'shotgun_last_event_months_before', type: 'int', isRequired: false, value: 12 },
    { name: 'shotgun_minimum_events_per_tech_and_part', type: 'int', isRequired: false, value: 3 },
    { name: 'work_order_link_root', type: 'free_text', isRequired: false},
    { name: 'customize_first_time_fix_label', type: 'free_text', isRequired: false, value: "First Time Fix"},

    { name: 'triage_schema', type: 'select', isRequired: false, value: 'None', valueSelectedObserved:true, valueSelectedObservedFilter: (p: TriageCustomer, store: MainStore) => {
        const ret = p ? store.copilotStore!.TriageProducts.slice().filter(i => i.customerid === p.id) : []
        return ret.map(i => i.producttype_name)}, displayProperty: 'name', hasEmptyItem: true, featureFlag: {name: 'preventable', trueValue: true}, category: 'Triage'},
    { name: 'Investigation_Part__c', type: 'free_text', isRequired: false, value: 'part__r__stark__externalid__c', featureFlag: {name: 'preventable', trueValue: true}, category: 'Triage'},
    { name: 'Investigation_Solution_New__c', type: 'free_text', isRequired: false, value: 'investigation__r__stark__externalid__c', featureFlag: {name: 'preventable', trueValue: true}, category: 'Triage'},
    { name: 'Investigation_Selected_Solution__c', type: 'free_text', isRequired: false, value: 'investigation__r__stark__externalid__c', featureFlag: {name: 'preventable', trueValue: true}, category: 'Triage'},
    { name: 'Investigation_Solution__c', type: 'free_text', isRequired: false, value: 'investigation__r__stark__externalid__c', featureFlag: {name: 'preventable', trueValue: true}, category: 'Triage'},
    { name: 'Investigation__c', type: 'free_text', isRequired: false, value: 'ExternalID__c', featureFlag: {name: 'preventable', trueValue: true}, category: 'Triage'},
    { name: 'Investigation_Observation__c', type: 'free_text', isRequired: false, value: 'investigation__r__stark__externalid__c', category: 'Triage'},
    { name: 'SI_Triage_Formatting', type: 'free_text', isRequired: false, value: '$investigation_id', featureFlag: {name: 'preventable', trueValue: true}, category: 'Triage'},
    { name: 'First_obs_nlp', type: 'free_text', isRequired: false, value: 'First obs nlp', featureFlag: {name: 'preventable', trueValue: true}, category: 'Triage'},
    { name: 'triage_product_combination', type: 'custom', isRequired: true, category: 'Triage', value:{matchings:{}}, render: TriageProductSelect, observerOn: 'triage_schema'},

    { name: 'top_performance_strategy', type: 'select', isRequired: true, category: 'Triage', value:'All', options:['All','Team', 'Product type'], observerOn: 'triage_schema', featureFlag: {name: 'insight_anywhere_top_performance', trueValue: true}},

    { name: 'fix_part_description', type: 'bool', isRequired: false, value: false },
    { name: 'fix_product_types', type: 'bool', isRequired: false, value: true },
    { name: 'fix_dominant_workforce_hierarchy', type: 'bool', isRequired: false, value: true },

    { name: 'enable_nlp_first_observation', type: 'bool', isRequired: false, value: false }, 

    { name: 'enable_nlp', type: 'bool', isRequired: false, value: false, category: 'NLP', isInvisible: true },
    { name: 'nlp_customer', type: 'select', isRequired: true, valueSelectedObserved:true, valueSelectedObservedFilter: (p: NlpCustomer) => p.products ? p.products.map((item:any) => item.producttype_name) : [], value: '', displayProperty: 'customer_name', category: 'NLP', featureFlag: {name: 'nlp_aas', trueValue: true}},
    { name: 'nlp_product_combination', type: 'custom', isRequired: true, category: 'NLP', value:{matchings:{}}, render: NlpProductEditor , featureFlag: {name: 'nlp_aas', trueValue: true}, observerOn: 'nlp_customer'},
    { name: 'observation_columns', type: 'select', isRequired: true, value: [], allowMultiple: true, category: 'NLP', featureFlag: {name: 'nlp_aas', trueValue: true}},
    { name: 'override_existing_observations_labels', type: 'bool', isRequired: false, value: false, category: 'NLP' , featureFlag: {name: 'nlp_aas', trueValue: true}},
    { name: 'solution_columns', type: 'select', isRequired: true, value: [], allowMultiple: true, category: 'NLP', featureFlag: {name: 'nlp_aas', trueValue: true}},
    { name: 'override_existing_solution_labels', type: 'bool', isRequired: false, value: false, category: 'NLP' , featureFlag: {name: 'nlp_aas', trueValue: true}},
    { name: 'observation_cost_min_events', type: 'int', isRequired: false, value: 5, featureFlag: {name: 'observation_cost_refactor', trueValue: true}},
    { name: 'observation_cost_trend_months', type: 'int', isRequired: false, value: 12, featureFlag: {name: 'observation_cost_refactor', trueValue: true} },

    { name: 'si_copilot_demo_enabled', type: 'bool', isRequired: false, value: false}
]