import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Link, makeStyles, MenuItem, Select, Theme, Typography } from "@material-ui/core";
import React from "react";
import { InjectInfo } from "../../models/InjectInfo";
import { ProgressPushMessage } from "../../models/ProgressPushMessage";
import { SalesforceApp } from "../../models/SalesforceApp";
import { SalesforceDashboard } from "../../models/SalesforceDashboard";
import { MainStore } from "../../stores/MainStore"
import ProgressBar from "../controls/ProgressBar";
import { SalesforceLogin } from "../Salesforce components/SalesforceLogin";
import { observer } from 'mobx-react-lite';
import FileSaver from "file-saver";



const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    itemRoot: {
        width: '100%',
        backgroundColor: 'white',
        border: "1px solid #BFC5CD",
        margin: '5px 0',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightRegular, // this fails compilation
    },
    position: {
        width: '50%',
        margin: 'auto',
        padding: '10px'
    }

}))


type MetadataProps = {
    store: MainStore
}

export const Metadata = observer(({ store }: MetadataProps) => {

    const [state, setState] = React.useState(0)
    const { injectProgress, processProgress, overallProgress, user } = store
    const classes = useStyles();
    const [downloadStatus, setDownloadStatus] = React.useState(0);
    const [app, setApp] = React.useState<string>("")
    const [availableApps, setAvailableApps] = React.useState<SalesforceApp[]>([])
    const [availableDashboards, setAvailableDashboards] = React.useState<SalesforceDashboard[]>([])
    const [dashboard, setDashboard] = React.useState<string>("")


    const inProgress = () => {
        let result = false
        if (processProgress.length > 0) {
            result = result || processProgress[0].progress < 100 && processProgress[0].progress > 0
        }
        if (injectProgress.length > 0) {
            result = result || injectProgress[0].progress < 100 && injectProgress[0].progress > 0
        }

        return result

    }

    React.useEffect(() => {

        if (store.SFToken) {
            const onConnectionAuth = async () => {
                const info: InjectInfo = { app_name: "", ...store.SFToken! }
                setAvailableApps(await store.getAvailableApps(info))
                store.getPackageVersions()
            }
            onConnectionAuth()
        }
    }, [store.SFToken])
    const onDownload = () => {
        setDownloadStatus(1)
        store.getDownloadUrlMetadata().then((response) => {
            FileSaver.saveAs(response, "metadata_analysis.zip")
            setDownloadStatus(0)
        }).catch(() => {
            setDownloadStatus(2)
        })

    }

    const onClick = async () => {
        if (app !== "" && dashboard !== "" && store.SFToken) {

            const info: InjectInfo = { app_name: availableApps.find(a => a.id === app)!.label, ...store.SFToken }

            setState(1)
            if (user) {
                store.setNotificationService(user, 'internal_org', 'metadata_process_injection').then(() => {
                    store.processInjectMetadata(availableDashboards.find(d => d.id === dashboard)!.name, info)
                        .then(() => setState(3))
                        .catch((err) => {
                            setState(2)
                        })
                })
            } else {
                console.error("User should be logged in to perform metadata analysis!")
            }
        }
    }

    const renderItem = (message: ProgressPushMessage) => {
        return <div key={message.subject} className={classes.itemRoot} style={{ marginBottom: message.subject === 'Complete Process' ? 20 : 0 }}>
            <Accordion
                expanded={message.state === 'fail'}>
                <AccordionSummary
                    expandIcon={<React.Fragment />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <Typography className={classes.heading} style={{ marginRight: 20, marginLeft: 20, width: 160 }}>{message.subject === 'Complete Process' ? 'Overall' : message.subject}</Typography>
                        <ProgressBar
                            style={{ marginLeft: 20, backgroundColor: 'red' }}
                            fillColor={message.state && message.state === 'fail' ? message.subject.toLowerCase() === 'validation' ? 'yellow' : 'red' : message.subject === 'Complete Process' ? '#57D89D' : '#17B1E2'}
                            completed={message.progress} />
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {message.message && message.message.split('\n').map((i, key) => {
                            return <Typography key={key} paragraph variant="body1">{i}</Typography>;
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    }

    const renderWithCredentials = () => {
        return <form className={classes.root} noValidate autoComplete="off">
            <h2>Compute Metadata</h2>
            <div style={{ width: '100%' }}>
                <Box style={{ display: "inline-flex", alignItems: "center" }}>
                    <p style={{ fontSize: "120%" }}>Download the last metadata
                        <Button style={{ marginLeft: "5px" }} variant='outlined' onClick={onDownload}>Download</Button>
                    </p>
                    {downloadStatus === 1 && <CircularProgress />}
                    {downloadStatus === 2 && <Typography style={{ marginLeft: "5px", color: "red" }}>Can't find any files</Typography>}
                </Box>
                <p style={{ fontSize: "120%" }}>Inject metadata to Salesforce</p>

            </div>


            <div style={{ width: '100%', marginBottom: '15px' }}><SalesforceLogin store={store} /></div>
            <div style={{ width: '100%' }}>
                <Typography>App</Typography>
                <Select style={{ minWidth: '80%', maxHeight: 40, background: '#ffffff' }}
                    variant='outlined'
                    value={app}
                    defaultValue=""

                    onChange={async (event) => {
                        const appId = event.target.value as string
                        setApp(appId)
                        if (appId === "") setDashboard("")
                        else {
                            const info: InjectInfo = { app_name: appId, ...store.SFToken! }
                            setAvailableDashboards(await store.getDashboards(info))
                        }
                    }
                    }
                    disabled={!store.SFToken || inProgress()}
                >
                    <MenuItem key={-1} value="">None</MenuItem>
                    {availableApps.slice().sort().map((sfApp, i) => <MenuItem key={i} value={sfApp.id}>{`${sfApp.label} (${sfApp.version})`}</MenuItem>)}
                </Select>
            </div>
            <div style={{ width: '100%' }}>
                <Typography>Dashboard</Typography>
                <Select style={{ minWidth: '80%', maxHeight: 40, background: '#ffffff' }}
                    variant='outlined'
                    value={dashboard}
                    defaultValue=""

                    onChange={(event) => {
                        setDashboard(event.target.value as string)
                    }}
                    disabled={app === "" || inProgress()}>
                    <MenuItem key={-1} value="">None</MenuItem>
                    {availableDashboards.map((sfDash, i) => <MenuItem key={i} value={sfDash.id}>{`${sfDash.label} (${sfDash.name})`}</MenuItem>)}
                </Select>
            </div>
            <Button variant='outlined' onClick={onClick} disabled={!store.SFToken || app === "" || dashboard === "" || inProgress()} >Inject Metadata</Button>

        </form>
    }


    return (

        <>
            <div className={classes.position}>
                {renderWithCredentials()}

                {overallProgress.length > 0 &&
                    <div>
                        <h3>Overall Progress</h3>
                        {overallProgress.map(item => renderItem(item))}
                    </div>
                }

                {processProgress.length > 0 &&
                    <div>
                        <h3>Process</h3>
                        {processProgress.map(item => renderItem(item))}
                    </div>
                }

                {injectProgress.length > 0 &&
                    <div>
                        <h3>Injection</h3>
                        {injectProgress.map(item => renderItem(item))}

                    </div>
                }

                {state === 1 && processProgress.length === 0 && <CircularProgress />}
                {/* {state === 0 && injectProgress.length === 0 && renderWithCredentials()} */}
                {/* {state === 1 && injectProgress.length === 0 && <CircularProgress />} */}
                {state === 2 && <Typography>Error</Typography>}
            </div>
        </>
    )

}
)