import axios from 'axios';
import { ActionsPayload } from '../models/ActionsPayload';
import { Config } from '../models/Config';
import { ConfigItem } from '../models/ConfigItem';
import { TemplateFile } from '../models/TemplateFile';
import { Folder } from '../models/Folder';
import { InjectInfo, TestInfo, InjectPatchInfo } from '../models/InjectInfo';
import { NlpCustomer } from '../models/NlpCustomer';
import { SFAuthCode, SFToken } from '../models/Profile';
import { StorageFile } from '../models/StorageFile';
import { TriageCustomer, TriageManufacturer, TriageModel, TriageObservation } from '../models/TriageCustomer';
import { CollectFile } from '../models/CollectFile';
import { GenerateLinkInfo, DigestMD } from '../models/Digest';
import { WidgetSchema } from '../components/template editor/PatchEditor';
import { PartialApplyResult } from '../models/PartialResult';
import { TriageProduct } from '../models/TriageProduct';
import { SalesforceUser, SalesforceUserResponse } from '../models/SalesforceUser';


export class MainApi {

    token: () => string | undefined
    apiBaseAddress: string
    notificationServiceAddress: string

    constructor(getToken: () => string | undefined, baseUrl: string, notificationsBaseUrl: string) {
        this.token = getToken
        this.apiBaseAddress = baseUrl
        this.notificationServiceAddress = notificationsBaseUrl
    }

    updateToken = (token: string) => {
        
    }

    getToken = () => {
        const actualToken = this.token()
        // console.log('token value is: ' + actualToken)
        return actualToken
    }

    fetchSid = (input: RequestInfo, init?: RequestInit) => {
        return fetch(input, { ...init, headers: { ...init?.headers, 'Authorization': `Bearer ${this.getToken()}` } })
    }

    getOrganizations = async (): Promise<Folder[]> => {
        const response = await this.fetchSid(this.apiBaseAddress + '/orgs')
        const result = await response.json()
        return result as Folder[]
    }

    getUserDetail = async () => {
        const response = await this.fetchSid(this.apiBaseAddress + '/users/me/detail')
        const result = await response.json()
        return result
    }
    
    getTriageCustomers = async (region: string | undefined = undefined): Promise<TriageCustomer[]> => {
        const suffix = region ? '?region=' + region : ''
        const response = await this.fetchSid(this.apiBaseAddress + '/triageCustomers' + suffix)
        const result = await response.json()
        return result as TriageCustomer[]
    }

    getTriageManufacturers = async (customer_id?: string, region: string | undefined = undefined): Promise<TriageManufacturer[]> => {
        const suffix = customer_id ? '?customer_id=' + customer_id + (region ? '&region=' + region : '') : region ? '?region=' + region : ''
        const response = await this.fetchSid(this.apiBaseAddress + '/triageManufacturers' + suffix)
        const result = await response.json()
        return result as TriageManufacturer[]
    }

    getTriageModels = async (customer_id?: string, producttype_id?: string, region: string | undefined = undefined): Promise<TriageModel[]> => {
        var suffix = customer_id ? producttype_id ? '?customer_id=' + customer_id + '&producttype_id' + producttype_id : '?customer_id=' + customer_id : ''
        suffix += region ? suffix.length > 0 ? '&region=' + region : '?region=' + region : ''
        const response = await this.fetchSid(this.apiBaseAddress + '/triageModels' + suffix)
        const result = await response.json()
        return result as TriageModel[]
    }

    getTriageProducts = async (region: string | undefined = undefined): Promise<TriageProduct[]> => {
        const suffix = region ? '?region=' + region : ''
        const response = await this.fetchSid(this.apiBaseAddress + '/triageProducts' + suffix)
        const result = await response.json()
        return result as TriageProduct[]
    }

    getTriageObservations = async (customer_id?: string, producttype_id?: string): Promise<TriageObservation[]> => {
        const suffix = customer_id ? producttype_id ? '?customer_id=' + customer_id + '&producttype_id' + producttype_id : '?customer_id=' + customer_id : ''
        const response = await this.fetchSid(this.apiBaseAddress + '/triageObservations' + suffix)
        const result = await response.json()
        return result as TriageObservation[]
    }

    saveRawConfig = async (config: ConfigItem[], file_path: string) => {

        const response = await this.fetchSid(`${this.apiBaseAddress}/config/?file_path=${file_path}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(config)
        })

        return await response.json()
    }

    uploadFile = async (file: File, folderName: string) => {
        var data = new FormData()
        data.append("file", file, `${folderName}/${file.name}`);

        const response = await this.fetchSid(`${this.apiBaseAddress}/uploadfile2`, {
            method: 'PUT',
            body: data
        })

        return await response.json()
    }

    uploadToPresignedUrl = async (file: File, presignedData: { url: string, fields: any }, onProgress: (position: number, total: number) => void) => {        
        return axios.request({
            method: "put",
            url: presignedData.url,
            headers: {
                'x-ms-blob-type': 'BlockBlob',
                'Content-Type': presignedData.fields.file_type,
                'x-ms-blob-content-disposition': 'inline',
            },
            data: file,
            onUploadProgress: (p) => {
                onProgress(p.loaded, p.total)
            }
        }).catch(err => {
            throw err
        });
    }


    fetchUploadUrlByFileName = async (fileName: string, folderName: string) => {
        console.log(`${folderName}/${fileName}`)
        const response = await this.fetchSid(`${this.apiBaseAddress}/uploadUrl?file_path=${folderName}/${fileName}`)
        return await response.json()
    }

    getFiles = async (folderName: string, includeSubFolders: boolean = false): Promise<StorageFile[]> => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/${folderName}/files?include_sub_folders=${includeSubFolders}`)
        const result = await response.json()
        return result as StorageFile[]
    }

    getSchema = async (fileName: string): Promise<string> => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/schema?file_path=${fileName}`)
        const result = await response.json()
        return result
    }

    copyCustomerFolder = async (sourceFolder: string, destinationCustomer: string,includeOutput: boolean) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/copy_customer_folder?source_path=${sourceFolder}&destination_customer=${destinationCustomer}&include_output=${includeOutput}`,
        {
            method: 'POST',
        }
        )
        const result = await response.json()
        return result
    }

    getTemplateVersions = async () => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/template_versions`)
        const result = await response.json()
        return result as string[]
    }

    getTemplateFile = async (fileName: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/template_file?file_path=${fileName}`)
        const result = await response.json()
        return result
    }

    saveTemplateFile = async (templateFile: TemplateFile) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/save_template_file`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(templateFile)
            })
        return await response.json()
    }

    getWidgetSchema = async (templateVersion: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/widget_schema?version=${templateVersion}`)
        const result = await response.json()
        return result as WidgetSchema
    }

    getpatchConfigs = async () => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/patch_configs`)
        const result = await response.json()
        return result
    }

    concatReferencePartial = async (reference:string|undefined, partial:string|undefined, newFolder: string|undefined) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/upload_partial?reference=${reference}&partial=${partial}&newFolder=${newFolder}`,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        })
        await response.json()
    }

    finalizePartialMerge = async (sourceFolderPath:string|undefined, destFolder:string|undefined, csvFileName: string|undefined) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/finalize_merged_partial?sourceFolderPath=${sourceFolderPath}&destFolder=${destFolder}&csvFileName=${csvFileName}`,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        })

        const result = await response.json()
        return result as PartialApplyResult;
    }

    testTemplate = async (info: TestInfo) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/test_template`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(info)
        })

        return await response.json()
    }

    process = async (output_path: string, config: Config, csv_path: string, runValidations: boolean) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/process?output_path=${output_path}&csv_path=${csv_path}&validate=${runValidations}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(config)
        })

        return await response.json()
    }

    collectCustomersDs = async (customers: string[], datasets: string[]) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/collect`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({customers: customers, datasets: datasets})
        });

        const sessionId = await response.json();
        return sessionId;
    }

    getIsFileUploaded = async (sessionId: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/is_file_uploaded?session_id=${sessionId}`);
        const isUploaded = await response.json();
        return isUploaded;
    }

    getCollectedFiles =async () => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/get_collected_files`);
        const usersFiles = await response.json();
        return usersFiles as CollectFile[];
    }

    processInject2 = async (config: Config, actionsPayload: ActionsPayload) => {

        const response = await this.fetchSid(`${this.apiBaseAddress}/processinject2?`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({action_payload: {...actionsPayload, config}})
        })

        return response.json()
    }

    validate = async (output_path: string, config: Config, csv_path: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/validate?output_path=${output_path}&csv_path=${csv_path}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(config)
        })

        return await response.json()
    }

    inject = async (path: string, injectInfo: InjectInfo, csv_path: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/inject?path=${path}&csv_path=${csv_path}&app_name=Test2`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(injectInfo)
        })

        return await response.json()
    }

    processInjectMetadata = async (injectInfo: InjectPatchInfo) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/process_inject_metadata/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(injectInfo)
        })

        return await response.json()
    }

    testConnection = async (injectInfo: InjectInfo) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/testConnection?`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(injectInfo)
        })

        return await response.json()
    }

    getAvailableApps = async (injectInfo: InjectInfo) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(injectInfo)
        })

        const apps = response.json()

        return await apps
    }

    cancelOperation = async (cancellationTokenId: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/process/cancel?token_id=${cancellationTokenId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        })

        return await response.json()
    }

    downloadFile = async (filePath: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/config/?file_path=${filePath}`)
        const result = await response.json()
        return result
    }

    downloadCollectFile = async (filePath: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/download_collect_file/?file_path=${filePath}`)
        const result = await response.json()
        return result
    }

    getDownloadUrl = async (filePath: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/files?file_path=${filePath}`)
        const result = await response.json()
        return result
    }

    getDownloadUrlMetadata = async () =>{
        const response = await this.fetchSid(`${this.apiBaseAddress}/metadata_url/`)
        const result = await response.json()
        return result
    }

    getUserPermissions = async () => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/users/me/permissions`)
        const result = await response.json()
        return result
    }

    getAppDashboards = async (appId: string, injectInfo: InjectInfo) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/${appId}/dashboards`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(injectInfo)
        })

        return await response.json()
    }

    getAppDashboard = async (dashboardId: string, injectInfo: InjectInfo) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/dashboards/${dashboardId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(injectInfo)
        })

        return await response.json()
    }

    backupAppDashboard = async (dashboardId: string, injectInfo: InjectInfo, outputFolder: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/dashboards/${dashboardId}/backup?path=${outputFolder}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(injectInfo)
        })

        return await response.json()
    }

    restoreAppDashboard = async (dashboardName: string, injectInfo: InjectInfo, backupFilePath: string) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/dashboards/${dashboardName}/restore?backup_file_path=${backupFilePath}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(injectInfo)
        })

        return await response.json()
    }

    getInstalledPackageVersion = async (injectInfo: InjectInfo) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/installed_package_version`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(injectInfo)
        })
        return await response.json()
    }

    getPackageVersions = async () => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/apps/package_versions`)
        return await response.json()
    }

    getSFToken = async (auth_code: SFAuthCode) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/users/me/sf_token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(auth_code)
        })
        
        const result = await response.json()
        return result as SFToken
    }

    refreshSFToken = async (token: SFToken) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/users/me/refresh_sf_token`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(token)
        })
        
        const result = await response.json()
        return result as SFToken
    }

    getNlpCustomers = async (): Promise<NlpCustomer[]> => {
        const response = await this.fetchSid(this.apiBaseAddress + '/nlp')
        const result = await response.json()
        return result as NlpCustomer[]
    }
    
    updateOrgDefinitions = async(orgName: string, industries?: string[], orgType?: string) => {
        await this.fetchSid(this.apiBaseAddress + '/update_org_defs?org_name=' + orgName,
        {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                industries: industries,
                org_type: orgType
            })
        })
    }

    processDigest = async (outputFolder: string) => {
        await this.fetchSid(`${this.apiBaseAddress}/digest/process?output_folder=${outputFolder}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        })
    }

    setViews = async (generateInfo: GenerateLinkInfo, outputFolder: string) => {
        await this.fetchSid(`${this.apiBaseAddress}/digest/save_views?output_folder=${outputFolder}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(generateInfo)
        })
    }

    getLastDigest = async (companyId: string) => {
        const result = await this.fetchSid(`${this.apiBaseAddress}/digest/get_last?company_id=${companyId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })

        const json = await result.json()

        return json
    }

    updateDigestMD = async (digestMD: DigestMD, customer_name: string) => {
        await this.fetchSid(`${this.apiBaseAddress}/digest/update_metadata?customer_name=${customer_name}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(digestMD)
        })
    }

    updateHubspotProps = async (rowsData: any, company_id: string, generateInfo: GenerateLinkInfo) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/update_hubspot_props?company_id=${company_id}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({rows: [...rowsData], info: generateInfo})
        })
    }

    getDigestTemplates = async () => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/templates/get_all`,{
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        })
    }

    addNewSentence = async (model: string, cause: string, subCause: string) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/templates/add_sentence`,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "model": model,
                "cause": cause,
                "sub_cause": subCause
            })
        })
    }

    deleteSentence = async (model: string, cause: string, subCause: string, index: number) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/templates/delete_sentence`,{
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "model": model,
                "cause": cause,
                "sub_cause": subCause,
                "index": index
            })
        })
    }

    updateSentence = async (model: string, cause: string, subCause: string, index: number, newText: string) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/templates/update_sentence`,{
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "model": model,
                "cause": cause,
                "sub_cause": subCause,
                "index": index,
                "text": newText
            })
        })
    }

    addNewSubCause = async (model: string, cause: string) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/templates/add_subcause`,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "model": model,
                "cause": cause
            })
        })
    }

    updateSubCause = async (model: string, cause: string, subCause: string, newSubCause: string) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/templates/rename_subcause?new_sub_cause=${newSubCause}`,{
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "model": model,
                "cause": cause,
                "sub_cause": subCause
            })
        })
    }

    deleteSubCause = async (model: string, cause: string, subCause: string) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/templates/delete_subcause`,{
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "model": model,
                "cause": cause,
                "sub_cause": subCause
            })
        })
    }

    addNewCause = async (model: string) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/templates/add_cause`,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "model": model
            })
        })
    }

    updateCause = async (model: string, cause: string, newCause: string) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/templates/rename_cause?new_cause=${newCause}`,{
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "model": model,
                "cause": cause
            })
        })
    }

    deleteCause = async (model: string, cause: string) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/templates/delete_cause`,{
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "model": model,
                "cause": cause
            })
        })
    }

    getSentencesByRow = async (rowData: any) => {
        return await this.fetchSid(`${this.apiBaseAddress}/digest/nlg/sentences_by_row`,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(rowData)
        })
    }

    finalizeOutput = async (outputFolder: string) => {
        await this.fetchSid(`${this.apiBaseAddress}/finalizer/finalize?output_folder=${outputFolder}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        })
    }

    getSalesforceUsers = async (token: SFToken) => {
        const response = await this.fetchSid(`${this.apiBaseAddress}/users/sf_users`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(token)
        })
        
        const result = await response.json()
        return result as SalesforceUserResponse
    }

    setSalesforceUsers = async (token: SFToken, users: SalesforceUser[]) => {
        await this.fetchSid(`${this.apiBaseAddress}/users/set_sf_users`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({token: token, users: users})
        })
    }

    getSessionMessages = async (sessionId: string) => {
        const response = await this.fetchSid(`${this.notificationServiceAddress}/persistence/session_messages`,{
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                session_id: sessionId,
                route: 'web'
            })
        })
        const result = await response.json()
        const messages = result.map((m: string) => JSON.parse(m))
        return messages
    }
}