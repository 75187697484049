import { makeStyles, TextField, Typography, Select, MenuItem, FormControlLabel, Checkbox, FormControl, FormLabel, RadioGroup, Radio, Tooltip, LinearProgress, Link } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { InjectInfo } from '../../models/InjectInfo'
import { SalesforceApp } from '../../models/SalesforceApp'
import { SalesforceDashboard } from '../../models/SalesforceDashboard'
import { Operations } from '../../Operations'
import { MainStore } from '../../stores/MainStore'
import { InstallationItem } from '../../models/InstallationItem'
import { ActionsPayload } from '../../models/ActionsPayload'
import ProgressBar from '../controls/ProgressBar'
import { autorun } from 'mobx'
import { useDropzone } from 'react-dropzone'
import { SalesforceLogin } from '../Salesforce components/SalesforceLogin'
import { GoButton } from '../controls/GoButton'
import { LEGACY_MODE } from '../../App'
import { ConfigItem } from '../../models/ConfigItem'
import FailedValidationsDialog from './FailedValidationsDialog'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    itemRoot: {
        width: '100%',
        backgroundColor: 'white',
        border: "1px solid #BFC5CD",
        margin: '5px 0',
    },
    heading: {
        width: '100%',
        margin: '15px 5%', 
        fontWeight: 'bold'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    paper: {
        width: '80%',
        maxHeight: '90vh',
    },
    separator: {
        width: '100%',
        minHeight: 75,
        backgroundColor: '#ffffff',
        borderStyle: 'dashed',
        border: '1px dashed #858F9F',
        boxSizing: 'border-box',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#858F9F'
    },
    itemHeader: {
        display: 'flex',
        gap: '8px',
        margin: '15px 0px'
    },
    progressLabel: {
        width: '50px',
        height: '25px',
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        borderRadius: '16px',
        color: 'white'
    }
}))

interface ProcessAndInjectProps3 {
    store: MainStore,
    mode?: 'Process' | 'Manual Injection'
}

export const ProcessAndInject3 = observer(({ store, mode }: ProcessAndInjectProps3
) => {
    const classes = useStyles()

    const [state, setState] = useState<'idle' | 'running'>('idle')
    const { injectProgress, processProgress, cancelProgress, appProgress, patchProgress, overallProgress, createAppProgress, validationProgress } = store

    const [availableApps, setAvailableApps] = useState<SalesforceApp[]>([])
    const [app, setApp] = useState<string>("")
    const [availableDashboards, setAvailableDashboards] = useState<SalesforceDashboard[]>([])
    const [dashboard, setDashboard] = useState<string>("")
    const [availableTemplates, setAvailableTemplates] = useState<string[]>([])
    const [template, setTemplate] = useState<string>("")
    const [newAppName, setNewAppName] = useState<string>("")
    const [installPackage, setInstallPackage] = useState<boolean>(false)
    const [upgradeAvailable, setUpgradeAvailable] = useState<boolean>(false)
    const [installUpgradeAvailable, setInstallUpgradeAvailable] = useState<boolean>(false)
    const [selectedInstallationItem, setSelectedInstallationItem] = useState<string[]>([])
    const [hoveredItem, setHoveredItem] = useState<string>("")
    const [lastHoveredItem, setLastHoveredItem] = useState<string>("")
    const [appCreationMode, setAppCreationMode] = useState<'create' | 'upgrade'>('upgrade')
    const [processMode, setProcessMode] = useState<'process' | 'noValidations' | 'validationsOnly'>('process')
    const [uploadProgress, setUploadProgress] = useState<{ isUploading: boolean, uploadedFile?: string, position?: number, total?: number } | undefined>(undefined)

    useEffect(() => {
        if (store.SFToken) 
        {
            const onConnectionAuth = async () => {
                const info: InjectInfo = { app_name: "", instance_url: store.SFToken!.instance_url, access_token: store.SFToken!.access_token}
                setAvailableApps(await store.getAvailableApps(info))
                await store.getInstalledPackageVersions(info)
            }

            onConnectionAuth()
        }
    }, [store.SFToken])


    useEffect(() => {
        if (appCreationMode === "create" && !store.PackageVersion) setInstallPackage(true)
    }, [appCreationMode, store.PackageVersion])


    useEffect(() => {
        if (app === ""){
            setUpgradeAvailable(false)
            setInstallUpgradeAvailable(false)
            setInstallPackage(false)
            setDashboard("")
            setTemplate("")
        }
        else {
            const sfApp = availableApps.find(item => item.id === app)!
            const canUpgrade = store.PackageVersion !== undefined && (+sfApp.version < +store.PackageVersion)
            setUpgradeAvailable(canUpgrade)
            const canInstallUpgrade = store.PackageVersion !== undefined && store.LatestPackageVersion !== undefined && (+sfApp.version < +store.LatestPackageVersion)
            setInstallUpgradeAvailable(canInstallUpgrade)
            setInstallPackage(canInstallUpgrade && !canUpgrade)
            
            const getDashboards = async () => {
                const info: InjectInfo = { app_name: app, ...store.SFToken! }
                setAvailableDashboards(await store.getDashboards(info))
            }
            getDashboards()
            setTemplate(sfApp.version)
        }

    }, [app, availableApps, store, store.LatestPackageVersion, store.PackageVersion])


    useEffect(() => {
        const getTemplates = async () => {
          const templates = await store.fetchTemplateVersions()
          setAvailableTemplates(templates.slice().reverse())
        }
        getTemplates()
      },[store])
    

    const onLogout = () => {
        setApp("")
        setAvailableApps([])
        setAvailableDashboards([])
    }

    const Divider =  <div style={{ borderTop: "1px solid #DADCDF", marginTop: '20px', height: '20px' }}></div>

    const renderAppMode = () => {
        return (
        <div>
            <Typography style={{fontWeight: "bold", marginBottom: "10px"}}>Actions</Typography>           
            <FormControl component="fieldset" style={{marginBottom: "10px"}}>
                <RadioGroup row aria-label="appMode" name="appMode1" value={appCreationMode} onChange={(e) => setAppCreationMode(e.target.value as any)}>
                    <FormControlLabel value="create" control={<Radio color="primary"/>} label="Create an app"/>
                    <FormControlLabel value="upgrade" control={<Radio color="primary"/>} label="Upgrade existing app" />
                </RadioGroup>
            </FormControl>
            {renderAppSelection()}
            { store.SFToken &&
                <div>
                    {Divider}
                    <Typography style={{fontWeight: "bold", marginBottom: "10px"}}>Service Insights Package</Typography>             
                    <Typography style={{fontStyle: "italic", marginBottom: "30px"}}>Current version installed: {store.PackageVersion ?? "None"}</Typography>
                    { appCreationMode === 'upgrade' && (upgradeAvailable || installUpgradeAvailable) && 
                        <FormControl component="fieldset">
                            <RadioGroup value={String(installPackage)} onChange={(e) => setInstallPackage(e.target.value === 'true')}>
                            <Typography variant="overline" style={{marginLeft: "31px", lineHeight: 0, fontSize: "0.65rem"}} color="primary">NEW VERSION AVAILABLE</Typography>
                            {installUpgradeAvailable && <FormControlLabel value="true" control={<Radio color="primary"/>} label={`Install & Upgrade to version ${store.LatestPackageVersion}`}/>}
                            {upgradeAvailable && <FormControlLabel value="false" control={<Radio color="primary"/>} label={`Upgrade to version ${store.PackageVersion}`}/>}
                            </RadioGroup>
                        </FormControl>
                    }
                    { appCreationMode === 'upgrade' && app !== "" && !upgradeAvailable && !installUpgradeAvailable && 
                        <Typography>App version is up-to-date</Typography>
                    }
                    { appCreationMode === 'create' && store.installPackageAvailable && 
                        <FormControl component="fieldset">
                            <RadioGroup value={String(installPackage)} onChange={(e) => setInstallPackage(e.target.value === 'true')}>
                            <Typography variant="overline" style={{marginLeft: "31px", lineHeight: 0, fontSize: "0.65rem"}} color="primary">NEW VERSION AVAILABLE</Typography>
                            <FormControlLabel value="true" control={<Radio color="primary"/>} label={`Install & Deploy version ${store.LatestPackageVersion}`}/>
                            {store.PackageVersion && <FormControlLabel value="false" control={<Radio color="primary"/>} label={`Deploy version ${store.PackageVersion}`}/>}
                            </RadioGroup>
                        </FormControl>
                    }
                </div>
            }
        </div>)
    }

    const renderAppSelection = () => {
        return (
            <div>
                {appCreationMode === 'upgrade' &&
                    <FormControl fullWidth>
                        <Typography style={{marginBottom: "10px"}}>Select App</Typography> 
                        <Select style={{ width: '100%', maxHeight: 40, background: '#ffffff'}}
                            variant='outlined'
                            value={app}
                            defaultValue=""
                            onChange={async (event) => {
                                const appId = event.target.value as string
                                setApp(appId)

                            }}
                            disabled={!store.SFToken}
                        >
                            <MenuItem key={-1} value="">None</MenuItem>
                            {availableApps.slice().sort().map((sfApp, i) => <MenuItem key={i} value={sfApp.id}>{`${sfApp.label} (${sfApp.version})`}</MenuItem>)}
                        </Select>
                    </FormControl>}

                {appCreationMode === 'create' &&
                    <FormControl fullWidth>
                        <Typography style={{marginBottom: "10px"}}>Enter app name</Typography> 
                        <TextField
                            label="App Name"
                            style={{ width: '100%' }}
                            value={newAppName} 
                            variant="outlined"
                            onChange={(event) => {
                                const value = event.target.value;
                                const ALLOW_REGEX = /^[\w\-\s,"(",")"]+$/;
                                if (value !== "" && !ALLOW_REGEX.test(value)) {
                                return;
                                }
                                setNewAppName(event.target.value)
                            }} />
                    </FormControl>}
            </div>
        )
    }

    const renderDashboardSelection = () => {
        return ( <div>
            {store.securityStore?.isAllowed(Operations.UpdateDashboard) && <div style={{ width: '100%', marginTop: '10px' }}>
            {appCreationMode === 'upgrade' && <Typography style={{marginBottom: '10px'}}> Select Dashboard</Typography>}
            {appCreationMode === 'upgrade' && <Select style={{ minWidth: '100%', maxHeight: 40, background: '#ffffff' }}
                variant='outlined'
                value={dashboard}
                defaultValue=""
                onChange={(event) => { setDashboard(event.target.value as string) }}
                disabled={app === ""}
            >
                <MenuItem key={-1} value="">None</MenuItem>
                {availableDashboards.map((sfDash, i) => <MenuItem key={i} value={sfDash.id}>{`${sfDash.label} (${sfDash.name})`}</MenuItem>)}
            </Select>}
            </div>}
            </div>
        )
    }

    const renderTemplateSelection = () => {
        return ( <div>
            {store.securityStore?.isAllowed(Operations.EditTemplate) && <div style={{ width: '100%', marginTop: '10px' }}>
            {appCreationMode === 'upgrade' && <Typography style={{marginBottom: '10px'}}> Select Template</Typography>}
            {appCreationMode === 'upgrade' && <Select style={{ minWidth: '100%', maxHeight: 40, background: '#ffffff'}}
                variant='outlined'
                value={template}
                onChange={(event) => { setTemplate(event.target.value as string) }}
                disabled={app === ""}
            >
                {availableTemplates.map((template, i) => <MenuItem key={i} value={template}>{template}</MenuItem>)}
            </Select>}
            </div>}
            </div>
        )
    }

    useEffect(() => {
        if (selectedInstallationItem.includes("Inject") && !selectedInstallationItem.includes("Configure Dashboard"))
        {
            selectedInstallationItem.push("Configure Dashboard")
            setSelectedInstallationItem(selectedInstallationItem.filter(() => true))
        }
    }, [selectedInstallationItem.length])

    var installationItems: InstallationItem[] = [
        (!mode || mode === 'Process') ? {
            'header': 'Process', 'description': "Process data using Aquant's revolutionary algorithm",
            isValid: () => {
                const overallProgress = processProgress.find((i: any) => i.subject === 'Complete Process')
                return (!overallProgress || !overallProgress.state || overallProgress.progress === 100) 
            },
            progress: () => {
                if (processProgress.find((i: any) => i.subject === 'Complete Process')?.progress === 100) return 100

                const total_items = processProgress.find((i: any) => i.subject === 'Total Items')?.progress
                if (total_items === undefined) return 0
                return processProgress.filter(item => item.subject !== 'Complete Process' && item.subject !==  'Total Items')
                                      .reduce((overall, item) => overall + item.progress/total_items, 0)
            },
            content: () => {
                return (
                <div>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Process Mode</FormLabel>
                        <RadioGroup aria-label="appMode" name="appMode1" value={processMode} onChange={(e) => setProcessMode(e.target.value as any)}>
                            <FormControlLabel value="process" control={<Radio />} label="Process" />
                            <FormControlLabel value="noValidations" control={<Radio />} label="Without Validations" />
                            <FormControlLabel value="validationsOnly" control={<Radio />} label="Validations Only" />
                        </RadioGroup>
                    </FormControl>
                </div>)
            },
            extraData: () => {
                const cancel = cancelProgress.find((i: any) => i.subject === 'process')
                if (cancel && cancel.message) return cancel.message
                const overallProgress = processProgress.find((i: any) => i.subject === 'Complete Process')
                if (overallProgress && overallProgress.message) return overallProgress.message
                if (validationProgress && validationProgress.str_message) return validationProgress.str_message
                return ""
            }
        } :
        {
            'header': 'Manual Injection', 'description': "Inject data you processed on your own",
            isValid: () => uploadProgress !== undefined && uploadProgress.uploadedFile !== undefined,
            progress: () => {   
                const overallProgress = processProgress.find((i: any) => i.subject === 'Complete Process')
                return (overallProgress && overallProgress.progress) || 0
            },
            content: () => {
                const MyDropzone = () => {
                    const onDrop = useCallback(async acceptedFiles => {
                        const file = acceptedFiles[0]
                        setUploadProgress({ uploadedFile: file.name, isUploading: true })
                        const presignedData = await store.fetchUploadUrlByFileName('output.zip', store.currentOrgManualInjectionFolder!)
                        await store.uploadToPresignedUrl(file, presignedData, (position, total) => setUploadProgress({ uploadedFile: file.name, isUploading: true, position: position, total: total }))
                        setUploadProgress({ uploadedFile: file.name, isUploading: false })
                    }, [])
                    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: ['application/zip', 'application/x-zip-compressed', 'multipart/x-zip'] })
                    return (
                        <div className={classes.separator}
                            {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop The output.zip File Here ...</p> :
                                    <p>Drag & Drop your ZIP File or Click To Select File</p>
                            }
                        </div>
                    )
                }

                const normalize = (value: number) => uploadProgress && uploadProgress.total ? (value - 0) * 100 / (uploadProgress.total - 0) : 0;

                return (<div>
                        <MyDropzone/>
                        {uploadProgress && uploadProgress.isUploading &&
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography>{uploadProgress.uploadedFile}</Typography>
                            {uploadProgress.position && <LinearProgress variant="determinate" value={normalize(uploadProgress.position)} />}
                        </div>}
                        {uploadProgress && !uploadProgress.isUploading && <Typography style={{marginTop: 10}}>{uploadProgress.uploadedFile}</Typography>}
                    </div>)
            },
            extraData: () => {
                const overallProgress = processProgress.find((i: any) => i.subject === 'Complete Process')
                if (overallProgress && overallProgress.message) return overallProgress.message
                if (validationProgress && validationProgress.str_message) return validationProgress.str_message
                return ""
            }
        },
        {
            'header': 'Create/Upgrade App', 'description': 'Allows creating a new app or upgrading an existing app',
            isValid: () => store.SFToken !== undefined && ((appCreationMode === 'create' && newAppName.trim() !== '') ||
                (appCreationMode === 'upgrade' && app !== '' && (upgradeAvailable || installUpgradeAvailable))),
            progress: () => {
                const overallProgress = appProgress.find((i: any) => i.subject === 'Complete Process')
                return (overallProgress && overallProgress.progress) || 0
            },
            content: () => {
                return (<div>
                     <SalesforceLogin store={store} onLogout={onLogout}/>
                     {Divider}
                    {renderAppMode()}
                </div>)
            },
            extraData: () => {
                const cancel = cancelProgress.find((i: any) => i.subject === 'app')
                if (cancel && cancel.message) return cancel.message
                if (createAppProgress && createAppProgress.messages[0]) {
                    const msg = createAppProgress.messages[0]
                    if (msg.name.includes('App error')) return msg.name;
                    return `${msg.name}: total ${msg.total}, success ${msg.success}, failed ${msg.fail}`
                } 
                return ""
            }
        },
        {
            'header': 'Inject', 'description': 'Inject processed data into Salesforce environment',
            isValid: () => store.SFToken !== undefined && ((app !== '' && dashboard !== '') || (appCreationMode === 'create')),
            progress: () => {
                const overallProgress = injectProgress.find((i: any) => i.subject === 'Complete Process')
                if (overallProgress && overallProgress.progress === 1){
                    const preparingProgress = injectProgress.find((i: any) => i.subject === 'preparing')
                    if (preparingProgress && preparingProgress.progress < 100) return preparingProgress.progress
                    const downloadProgress = injectProgress.find((i: any) => i.subject === 'downloading')
                    if (downloadProgress && downloadProgress.progress <100) return downloadProgress.progress
                }
                return (overallProgress && overallProgress.progress) || 0
            },
            content: () => {
                return (<div>
                            <SalesforceLogin store={store} onLogout={onLogout}/>
                            {Divider}
                            {renderAppSelection()}
                            {renderDashboardSelection()}
                            {renderTemplateSelection()}
                        </div>)
            },
            extraData: () => {
                const cancel = cancelProgress.find((i: any) => i.subject === 'inject')
                if (cancel && cancel.message) return cancel.message
                const error = injectProgress.find((i: any) => i.subject === 'Overall')
                if (error && error.message) return error.message
                const overallProgress = injectProgress.find((i: any) => i.subject === 'Complete Process')
                if (overallProgress && overallProgress.progress === 1){
                    const preparingProgress = injectProgress.find((i: any) => i.subject === 'preparing')
                    if (preparingProgress && preparingProgress.progress < 100) return "Preparing Data"
                    const downloadProgress = injectProgress.find((i: any) => i.subject === 'downloading')
                    if (downloadProgress && downloadProgress.progress <100) return "Retrieving Data"
                }
                return ""
            }
        },
        {
            'header': 'Configure Dashboard', 'description': 'IMPORTANT: Automatically selected when Injecting data to Salesforce',
            isValid: () => store.SFToken !== undefined && ((app !== '' && dashboard !== '') || (appCreationMode === 'create')),
            progress: () => {
                const overallProgress = patchProgress.find((i: any) => i.subject === 'Complete Process')
                return (overallProgress && overallProgress.progress) || 0
            },
            content: () => {
                return (<div>
                            <SalesforceLogin store={store} onLogout={onLogout}/>
                            {Divider}
                            {renderAppSelection()}
                            {renderDashboardSelection()}
                            {renderTemplateSelection()}
                        </div>)
            },
            extraData: () => {
                const cancel = cancelProgress.find((i: any) => i.subject === 'patch')
                if (cancel && cancel.message) return cancel.message
                return ""
            }
        }
    ]

    if (store.featureFlags['nlp_aas'] === true){
        installationItems = [
            {
                'header': 'NLP', 'description': 'NOTE: you must map observation_name and solution_name',
                isValid: () => isNlpValid(store.configuration),
                progress: () => {
                    const overallProgress = processProgress.find((i: any) => i.subject === 'NLP')
                    return (overallProgress && overallProgress.progress) || 0
                },
                content: () => {
                    return (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Typography>observation_columns is mapped to: </Typography>
                            <Typography style={{marginLeft: '10px'}}>{(store.configuration && store.configuration.findIndex(item => item.name === 'observation_columns') > -1 && 
                            store.configuration[store.configuration.findIndex(item => item.name === 'observation_columns')].value.length) || 0}</Typography>
                            <Typography style={{marginLeft: '10px'}}>columns</Typography>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Typography>observation_name is mapped to: </Typography>
                            <Typography style={{marginLeft: '10px'}}>{(store.configuration && store.configuration.findIndex(item => item.name === 'observation_name') > -1 && 
                            store.configuration[store.configuration.findIndex(item => item.name === 'observation_name')].value) || 'not mapped'}</Typography>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Typography>solution_columns is mapped to: </Typography>
                            <Typography style={{marginLeft: '10px'}}>{(store.configuration && store.configuration.findIndex(item => item.name === 'solution_columns') > -1 && 
                            store.configuration[store.configuration.findIndex(item => item.name === 'solution_columns')].value.length) || 0}</Typography>
                            <Typography style={{marginLeft: '10px'}}>columns</Typography>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Typography>solution_name is mapped to: </Typography>
                            <Typography style={{marginLeft: '10px'}}>{(store.configuration && store.configuration.findIndex(item => item.name === 'solution_name') > -1 && 
                            store.configuration[store.configuration.findIndex(item => item.name === 'solution_name')].value) || 'not mapped'}</Typography>
                        </div>
                    </div>
                    )
                },
            },
            ... installationItems]
    }

    const isNlpValid = (configuration?: ConfigItem[]) => {
        if (!configuration) return false

        const obs_columns_index = configuration.findIndex(item => item.name === 'observation_columns')
        const obs_index = configuration.findIndex(item => item.name === 'observation_name')
        const solution_columns_index = configuration.findIndex(item => item.name === 'solution_columns')
        const sol_index = configuration.findIndex(item => item.name === 'solution_name')
        
        if ((obs_columns_index === -1 || configuration[obs_columns_index].value.length === 0) && 
        (solution_columns_index === -1 || configuration[solution_columns_index].value.length === 0)){
            return false
        }

        return ((obs_columns_index === -1 || configuration[obs_columns_index].value.length === 0) ||  
        (obs_index > -1 && configuration[obs_index].value !== 'n/a')) && 
        ((solution_columns_index === -1 || configuration[solution_columns_index].value.length === 0) ||  
        (sol_index > -1 && configuration[sol_index].value !== 'n/a'))
    }


    autorun(() => {
        const op = overallProgress.find((i: any) => i.subject === 'Complete Process')
        if (op && op.progress === 100 && state !== 'idle') setState('idle')
    })

    const goDisabled = () => {
        return installationItems.every(i => !selectedInstallationItem.includes(i.header)) ||
        (!installationItems.every(i => !selectedInstallationItem.includes(i.header) || i.isValid()))
    }

    const cancelDisabled = () => {
        return !store.correlationId ||
        !selectedInstallationItem.includes("Process") || 
        processProgress.find((i: any) => i.subject === 'Complete Process')?.progress === 100
    }

    const onProcessAndInject = (e: any = undefined, ignoreErrors: boolean = false) => {     
            const actionsPayload: ActionsPayload = {
                auth: store.SFToken!,
                customer: { name: store.currentOrg!, outputFolder: mode === 'Manual Injection' ? store.currentOrgManualInjectionFolder! : store.currentOrgOutputFolder! },
                app: {name: appCreationMode === 'create' ? newAppName : availableApps.find(item => item.id === app)?.label,
                      id: appCreationMode === 'create' ? "" : availableApps.find(item => item.id === app)?.id,
                      templateId: selectedInstallationItem.includes("Create/Upgrade App") ? " " : availableApps.find(item => item.id === app)?.templateId,
                      templateVersion: selectedInstallationItem.includes("Create/Upgrade App") ? (installUpgradeAvailable ? store.LatestPackageVersion : store.PackageVersion) : template,
                      dashboardName: appCreationMode === 'create' ? "" : availableDashboards.find(d => d.id === dashboard)?.name,
                      dashboardId: appCreationMode === 'create' ? "" : availableDashboards.find(d => d.id === dashboard)?.id},
                actions: {
                    process: {
                        isEnabled: selectedInstallationItem.includes("Process"),
                        ignoreErrors: ignoreErrors,
                        mode: processMode,
                        csv_path: mode === 'Manual Injection' ? store.currentOrgManualInjectionFolder!  + '/output.zip' : store.selectedCsvFile!.url
                    },
                    app: {
                        isEnabled: selectedInstallationItem.includes("Create/Upgrade App"),
                        mode: appCreationMode,
                        installLatestPackage: installPackage
                    },
                    inject: {
                        isEnabled: selectedInstallationItem.includes("Inject")
                    },
                    patch: {
                        isEnabled: selectedInstallationItem.includes("Configure Dashboard")
                    }
                }
            }

            if (store.configuration) {
                const index = store.configuration.findIndex(item => item.name === 'enable_nlp')
                if (index !== -1) {
                    store.configuration[index].value = selectedInstallationItem.includes('NLP')
                }

                const tsIndex = store.configuration.findIndex(item => item.name === 'triage_schema')
                if (tsIndex !== -1) {
                    if (store.configuration[tsIndex].value === "") store.configuration[tsIndex].value = undefined
                }
            }

            setState('running')
            store.processInject2(actionsPayload)
                .catch(() => setState('idle'))
    }

    installationItems.forEach(element => {
        if (selectedInstallationItem.includes(element.header) && element.disabled && element.disabled())
            setSelectedInstallationItem(selectedInstallationItem.filter(fl => fl !== element.header))
    });

    const renderVersionBar = () => {
        const latest = `latest app: v${LEGACY_MODE ? process.env.REACT_APP_LATEST_VERSION : store.LatestPackageVersion}`
        const legacy = `legacy app: v${LEGACY_MODE ? store.LatestPackageVersion : store.LegacyPackageVersion}`
        const href = LEGACY_MODE ? 'https://aq-si-fe.herokuapp.com/' : 'https://aq-si-fe-legacy.herokuapp.com/'
        return (
        <div style={{ width: '100%', padding:'3px 2px', backgroundColor: 'white', margin: '10px 0px', pointerEvents: state === 'running' ? 'none' : undefined }}>
            <Typography style={{ width: '100%', margin: '5px 2%', fontWeight: 'bold'}}>This processor is compatibale with our {LEGACY_MODE ? legacy : latest}</Typography>        
            <Link href={href} style={{ width: '100%', margin: '5px 2%' }}>click here for our {LEGACY_MODE ? latest : legacy}</Link>        
        </div>)
    }

    return (
        <div>
        {renderVersionBar()}
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
            <div style={{ width: '60%' }}>
            {installationItems.map((item, i) =>
                    <div key={item.header}>
                        <div
                            onMouseOver={() => {
                                setHoveredItem(item.header)
                                setLastHoveredItem(item.header)
                            }}
                            onMouseOut={() => setHoveredItem("")}
                            onClick={() => {
                                if (state === 'running') return

                                if (selectedInstallationItem.includes(item.header)) {
                                    setSelectedInstallationItem(selectedInstallationItem.filter(fl => fl !== item.header))
                                }
                                else {
                                    selectedInstallationItem.push(item.header)
                                    setSelectedInstallationItem(selectedInstallationItem.filter(() => true))
                                }
                            }}
                            style={{
                                width: '100%', height: selectedInstallationItem.includes(item.header) ? '15vh' : 'calc(15vh + 4px)', display: 'flex', flexDirection: 'row', marginTop: i > 0 ? '10px' : '0px',
                                backgroundColor: item.header === hoveredItem || item.header === lastHoveredItem ? 'lightgrey' : 'white',
                                opacity: item.disabled && item.disabled() ? 0.4 : 1, pointerEvents: item.disabled && item.disabled() ? 'none' : undefined
                            }}>
                            <div style={{ minWidth: '10px', height: '100%', background: selectedInstallationItem.includes(item.header) ? '#5F96FB' : 'transparent' }} />
                            <div style={{ width: '90%', marginLeft: '10px' }}>
                                <div className={classes.itemHeader}>
                                    <Typography variant='h6' style={{ fontWeight: 'bold' }}>{item.header}</Typography>
                                    {item.progress() > 0 &&
                                        <div className={classes.progressLabel} style={{backgroundColor: item.extraData?.().includes('error') ? 'red' : '#5F96FB'}}>
                                            <Typography style={{fontWeight: 'bold'}}>{item.extraData?.().includes('error') ? 'X' : cancelProgress.length > 0 ? '!' : `${Math.round(item.progress())}%`}</Typography>
                                        </div>}
                                </div>
                                <Typography style={{ marginLeft: '3px' }}>{item.description}</Typography>
                                {item.extraData && <Tooltip title={
                                    <Fragment>
                                        {item.extraData().split('\n').map((i, key) => {
                                            return <Typography key={key} paragraph variant="body1">{i}</Typography>
                                        })}
                                    </Fragment>
                                }>
                                    <Typography  noWrap variant='body2' style={{ marginLeft: '3px', marginTop: '5px', textOverflow: "ellipsis", overflow: "hidden" }}>{item.extraData()}</Typography></Tooltip>}
                                    {state === 'running' && selectedInstallationItem.includes(item.header) && (item.progress() < 1) && <Typography style={{ marginLeft: '3px', fontSize: 18, fontWeight: 'bold' }}>Queued</Typography>}
                            </div>
                            <Checkbox style={{ color: '#5F96FB', pointerEvents: 'none' }} checked={selectedInstallationItem.includes(item.header)} />
                        </div>
                        {selectedInstallationItem.includes(item.header) && 
                        <ProgressBar fillColor={item.extraData?.().includes('error') ? 'red' : '#5F96FB'} size='xs' completed={item.progress()} disableText={true} radius={0.0001} />}
                    </div>
                )}
            </div>
            <div style={{ width: '40%', backgroundColor: 'white', marginLeft: '10px', padding: '5px', pointerEvents: state === 'running' ? 'none' : undefined }}>
                <Typography variant='h6' style={{ width: '100%', margin: '15px 5%', fontWeight: 'bold' }}>Details</Typography>
                <div style={{ display: 'flex', flexDirection: 'column', margin: '0 5%' }}>
                    {installationItems.find(item => item.header === lastHoveredItem)?.content()}
                </div>
            </div>
            <GoButton 
                style={{ marginLeft: '10px', minWidth: '86px' }}
                state={state} 
                disabled={goDisabled()}
                cancel_disabled={cancelDisabled()}
                onGo={onProcessAndInject} 
                onCancel={() => store.cancelOperation()} />
            <FailedValidationsDialog store={store} onProcessAndInject={onProcessAndInject}></FailedValidationsDialog>
        </div>
        </div>
    )
})