import { DialogActions, Dialog, makeStyles, List, ListItem, DialogContent, DialogContentText, Button, Tabs, Typography, DialogTitle, Divider, Box, Theme, withStyles, createStyles} from '@material-ui/core'
import { observer } from 'mobx-react-lite';
import { useState, ChangeEvent, useEffect } from 'react';
import Tab from '@mui/material/Tab';
import FileSaver from 'file-saver'
import DownloadIcon from '@mui/icons-material/Download';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { PrimaryButton, TeriaryButton } from '../controls/buttons';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AquantCancelIcon from '../../assets/aq-cancel.svg';
import AquantAlertIcon from '../../assets/alert.svg';
import AquantErrorIcon from '../../assets/error.svg';
import AquantDownloadIcon from '../../assets/download.svg';
import { MainStore } from '../../stores/MainStore';
import { ValidationMessage } from '../../models/ProgressPushMessage'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '-webkit-fill-available'
    },
    dialogTitleContainer: {
        display: 'flex', 
        alignItems: 'center', 
        flex: 5
    },
    dialogTitle: {
        flexGrow: 4
    },
    cancelDialogImg: {
        marginLeft: 15, 
        cursor: 'pointer'
    },
    dialogContentText: {
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.04em',
        color: '#4A4A4A'
    },
    tabsContainer: {
        borderBottom: 1, 
        borderColor: 'divider', 
        display: 'flex'
    },
    downloadBtn: {
        color: "#2468FF", 
        fontSize: "13px",
        textTransform: 'capitalize',
        fontWeight: 500,
        padding: '0px'
    },
    downloadIcon: {
        fill: "#2468FF", 
        marginRight: "7px"
    },
    tabs: {
        flex: 2,
        "& button": { 
            minHeight: '0px', 
            fontWeight: 400, 
            padding: '10px 0px', 
            marginRight: '30px', 
            fontSize: '14px', 
            letterSpacing: '0.04em',
            lineHeight: '24px',
            textTransform: 'capitalize',
            opacity: 1,
            color: '#616264'
        },
        "& button.Mui-selected": { color: '#616264' } 
    },
    errorIcon: {
        fill: "#ED4D4D",
        fontSize: "15px"
    },
    mainSentence: {
        fontWeight: 500
    },
    secondarySentence: {
        fontWeight: 400,
        marginTop: '4px'
    },
    listItem: {
        display: 'list-item', 
        paddingLeft: '0px',
        fontSize: '13px',
        fontWeight: 400,
        letterSpacing: '0.1px'
    },
    listContainer: {
        listStyleType: 'disc', 
        marginLeft: '14px'
    },
    tab: {
        fontWeight: 400,
        fontSize: '14px',
        letterSpacing: '0.04em'
    }
}));

type FailedValidationDialogProps = {
    store: MainStore,
    onProcessAndInject: (e: any, ignoreErrors: boolean) => void
}

export const DialogCard = withStyles((theme: Theme) => createStyles({
    paper: {
        borderRadius: '14px',
        minWidth: '40%'
    }
}))(Dialog)

interface TabPanelProps {
    children?: JSX.Element | JSX.Element[];
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
        >
            {value === index && (
                <div>{children}</div>
          )}
        </div>
    );
}

function getTabProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}   

const FailedValidationsDialog = observer((props: FailedValidationDialogProps) => {

    const { validationProgress, outputValidationFile } = props.store
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState(0);
    const [lowImpactMessages, setLowImpactMessages] = useState<ValidationMessage[]>([]);
    const [highImpactMessages, setHighImpactMessages] = useState<ValidationMessage[]>([]);
    const classes = useStyles();

    useEffect(() => {
        if (validationProgress && validationProgress.messages.find(msg => msg.state === 'error')) {
            let highMessages = validationProgress.messages.filter(msg => msg.state === 'error' && msg.impact === 'high');
            let lowMessages = validationProgress.messages.filter(msg => msg.state === 'error' && msg.impact === 'low');
            setLowImpactMessages(lowMessages);
            setHighImpactMessages(highMessages);

            setIsOpen(true);

            if(highMessages.length === 0) setTabValue(1);
        }
      }, [ validationProgress ]);

    const handleTabChange = (event: ChangeEvent<{}>, newTabValue: number) => {
        setTabValue(newTabValue);
    };
    
    const handleCloseDialog = () => {
        setIsOpen(false);
        props.store.setValidationProgressMessages();
    }

    const handleKeepProcessing = () => {
        props.onProcessAndInject(undefined, true);
        handleCloseDialog()
    }   

    return (
        <DialogCard open={isOpen} >
            <DialogTitle>
                <div className={classes.dialogTitleContainer}>
                    <Typography variant='h3' className={classes.dialogTitle} >Failed Validations</Typography>
                    <img src={AquantCancelIcon} onClick={handleCloseDialog} alt='cancel icon' className={classes.cancelDialogImg}/>
                </div>
            </DialogTitle>

            <Divider />
            
            <DialogContent>
                <DialogContentText className={classes.dialogContentText}>
                    <div className={classes.mainSentence}>The {lowImpactMessages.length + highImpactMessages.length} following validations have stopped the process.</div>
                    <div className={classes.secondarySentence}>To keep processing the data, the associated events will be 
                    excluded from the data. Please confirm the next action.</div>
                </DialogContentText>
                <Box className={classes.tabsContainer}>
                    <Tabs TabIndicatorProps={{ style: { backgroundColor: "#2468FF", width: "155px" }}} className={classes.tabs} value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                        {<Tab icon={<img src={AquantErrorIcon} alt='alert icon'/>} iconPosition='start' disabled={highImpactMessages.length === 0} label={<Typography className={classes.tab}>High Impact ({highImpactMessages.length})</Typography>} {...getTabProps(0)} />}
                        {<Tab icon={<img src={AquantAlertIcon} alt='error icon'/>} iconPosition='start' disabled={lowImpactMessages.length === 0} label={<Typography className={classes.tab}>Low Impact ({lowImpactMessages.length})</Typography>} {...getTabProps(1)} />}
                    </Tabs>
                    <Button className={classes.downloadBtn} onClick={async () => {
                        if(outputValidationFile) {
                            FileSaver.saveAs(await props.store.getDownloadUrl(outputValidationFile.url), 'validations.zip')
                            await props.store.fetchExistingFiles()
                        }
                    }}>
                        <img src={AquantDownloadIcon} alt='download icon' className={classes.downloadIcon}/> Download
                    </Button>
                </Box>
                <Divider />
                    <TabPanel value={tabValue} index={0}>
                    <List className={classes.listContainer}>
                    {highImpactMessages.map(msg => 
                        <ListItem className={classes.listItem} >{msg.message}</ListItem>
                    )}
                    </List>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <List className={classes.listContainer}>
                        {lowImpactMessages.map(msg => 
                            <ListItem className={classes.listItem} >{msg.message}</ListItem>
                        )}
                    </List>
                </TabPanel>
                <DialogActions style={{padding: '0px'}}>
                    <TeriaryButton style={{padding: '5px 30px'}} onClick={handleCloseDialog}>Cancel</TeriaryButton>
                    <PrimaryButton style={{padding: '5px 30px'}} onClick={handleKeepProcessing}>Keep Processing</PrimaryButton>
                </DialogActions>
            </DialogContent>
        </DialogCard>
    )
})

export default FailedValidationsDialog