import { Checkbox, Chip, LinearProgress, TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export interface SelectionItem  {
    value: string;
    label: string;
}

export interface SIAutocompleteProps {
    items: SelectionItem[]
    initial_selection?: SelectionItem
    onSelect: (item?: SelectionItem) => void
    onAddSelectionItem?: (item: SelectionItem) => void
    verifyAdd?: (item: SelectionItem) => boolean
    style?: React.CSSProperties
    disableSpecialCharacters?: boolean,
    canAdd?: () => boolean,
    loadProgress?: () => boolean,
    disabled?: boolean
}


export const SIAutocomplete = ({ items, initial_selection, onSelect, onAddSelectionItem, verifyAdd,  style, disableSpecialCharacters, canAdd, loadProgress, disabled}: SIAutocompleteProps) => {
    const [selectedItem, setSelectedItem] = useState<SelectionItem | undefined>(initial_selection)
    const [error, setError] = useState<boolean>(false)

    const setSelectItem = (item?: SelectionItem ) => {
        setSelectedItem(item)
        onSelect(item)
    }

    return <Autocomplete
        value={selectedItem}
        disabled={disabled}
        onChange={(event, item) => {
            if(error || !item) {
                setSelectItem()
            } else if (typeof item !== 'string') {
                if (onAddSelectionItem && item.label.startsWith('Add ')){
                    item.label = item.value
                    onAddSelectionItem(item)
                }
                setSelectItem(item)
            }
        }}
        filterOptions={(options, params) => {
            const filtered = createFilterOptions<SelectionItem>({stringify: (option: SelectionItem) => option.label})(options, params)

            const regex = /^[a-zA-Z0-9 !\-_.*'()+]*$/
            const isInputValid = params.inputValue.match(regex)

            // Suggest the creation of a new value
            if (isInputValid && canAdd && canAdd() && onAddSelectionItem && params.inputValue !== '' && !error) {
                const newItem = {
                    value: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                }
                if (!verifyAdd || verifyAdd(newItem)) filtered.push(newItem)
            }

            return filtered
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={items}
        getOptionLabel={(option) => option ? option.label || '' : ''}
        renderOption={option => option.label}
        style={style}
        freeSolo
        renderInput={params => (
            items.length || !loadProgress || !loadProgress() ? 
            <TextField
                {...params}
                variant="outlined"
                error={error}
                onChange={ (event) =>{
                    if (disableSpecialCharacters) {
                        const inputValue = event.target.value
                        const regex = /^[a-zA-Z0-9 !\-_.*/'()+]*$/
                        const isInputValid = inputValue.match(regex)
                        if(!inputValue || isInputValid){
                            setError(false)
                        } else if (inputValue && !isInputValid) {
                            setError(true)
                        }
                    }}
                }
                onBlur={() => setError(false)}
                />
            :
            <div>
                <TextField {...params} label="Loading" variant="outlined" />
                <LinearProgress/>
            </div>
        )}
    />
}

export interface SIAutocompleteMultiSelectProps {
    items: SelectionItem[],
    initial_selection?: SelectionItem[],
    limitTags?: number,
    onSelect: (item?: SelectionItem[]) => void,
    style?: React.CSSProperties,
    loadProgress?: () => boolean,
    disabled?: boolean
}

export const SIAutocompleteMultiSelect = ({items, initial_selection, limitTags, onSelect, style, loadProgress, disabled}: SIAutocompleteMultiSelectProps) => {
    const [selectedItems, setSelectedItems] = useState<SelectionItem[]>([]);
    const [open, setOpen] = useState(false);

    const isAllSelected = items.length === selectedItems.length;
    const limitTagsVal = limitTags ?? 5;
    
    useEffect(() => {
        if (disabled) {
            setOpen(false);
        }
    }, [disabled]);

    useEffect(() => {
        if (initial_selection ) {
            if (JSON.stringify(initial_selection) !== JSON.stringify(selectedItems)) {
                setSelectedItems(initial_selection)
            }
        }
    }, [initial_selection]);

    const setSelectItems = (items: SelectionItem[] ) => {
        setSelectedItems(items)
        onSelect(items)
    }

    const handleToggleSelectAll = (isSelected: Boolean) => {
        setSelectItems(isSelected ? items : [])
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const getOptionSelected = (option: SelectionItem, anotherOption: SelectionItem) => option.value === anotherOption.value;
    const filter = createFilterOptions();

    const selectAllLabel = 'Select All'
    return <Autocomplete
                open={open}
                onOpen={() => !disabled && setOpen(true)}
                onClose={() => setOpen(false)}
                disabled={disabled || !items.length}
                multiple
                id="checkboxes"
                limitTags={limitTagsVal}
                options={items}
                disableCloseOnSelect
                value={selectedItems}
                onChange={(e, options, reason) => {
                    if (reason === "select-option" || reason === "remove-option") {
                        if (options.find(option => option.label === selectAllLabel)) {
                            handleToggleSelectAll(!isAllSelected);
                        } else {
                            setSelectItems(options);
                        }
                    } else if (reason === "clear") {
                        setSelectItems([]);
                    }
                }}
                getOptionSelected={getOptionSelected}
                getOptionLabel={option => option.label}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params as any) as any;
                    const result = selectAllLabel ? [{ value: selectAllLabel, label: selectAllLabel }, ...filtered] : [...filtered]
                    return result;
                }}
                renderOption={(option, { selected }) => {
                    const selectAllProps =
                        option.label === selectAllLabel
                            ? { checked: isAllSelected }
                            : {};

                    return (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                {...selectAllProps}
                            />
                            {option.label}
                        </React.Fragment>
                    );
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                style={style}
                renderTags={(value, getTagProps) => {
                    const numTags = value.length;
            
                    return (
                      <>
                        {value.slice(0, limitTagsVal).map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={option.value}
                            label={option.label}
                          />
                        ))}
            
                        {numTags > limitTagsVal && ` +${numTags - limitTagsVal}`}
                      </>
                    );
                  }}
                renderInput={params => (
                    items.length || !loadProgress || !loadProgress() ? 
                    <TextField {...params} variant="outlined" /> :
                    <div>
                        <TextField {...params} label="Loading" variant="outlined" />
                        <LinearProgress/>
                    </div>
                )}
            />  
}