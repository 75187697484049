import React, { useEffect, useState, useCallback } from 'react'
import { Button, FormControlLabel, LinearProgress, Link, makeStyles, Radio } from '@material-ui/core'
import { MainStore } from '../../stores/MainStore';
import { observer } from 'mobx-react-lite';
import { useDropzone } from 'react-dropzone'
import { StorageFile, CSVStorageFile } from '../../models/StorageFile';
import FileSaver from 'file-saver';
import { DataGrid, GridCellParams, GridColDef, GridRowId } from '@material-ui/data-grid';
import { FileMetadata } from '../../models/MetadataCSV';
import { Operations } from '../../Operations';
import FeatureFlagsService from '../../services/FeatureFlagsService';
import CsvCopyDialog from './CsvCopyDialog';

const useStyles = makeStyles((theme) => ({
    separator: {
        width: '100%',
        minHeight: 75,
        backgroundColor: '#ffffff',
        borderStyle: 'dashed',
        border: '1px dashed #858F9F',
        boxSizing: 'border-box',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#858F9F'
    },
    root: {
        "& .MuiTypography-root": {
            minWidth: '100%',
        }
    },
    textbtn: {
        textTransform: 'none',
        backgroundColor:'transparent',
        fontSize:"inherit",
        fontWeight:'inherit',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        },
        lineHeight: '19px'
    },
    
}));

type SelectCsv2Props = {
    folderName: string
    store: MainStore
    disableUpload?: boolean
    disableActions?: boolean
}

export const SelectCsv2 = observer(({ folderName, store, disableUpload, disableActions }: SelectCsv2Props) => {

    const [isDownloading, setIsDownloading] = useState<boolean>(false)
    const [uploadProgress, setUploadProgress] = useState<{ isUploading: boolean, uploadedFile?: string, position?: number, total?: number } | undefined>(undefined)
    const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

    const [copyDialogData, setCopyDialogData] = useState<{ isOpen: boolean, sourcePath: string}>({ isOpen: false, sourcePath: ''})
    const copyAllowed = store.securityStore && store.securityStore?.isAllowed(Operations.CopyCsv)
    const copyFeatureFlag = FeatureFlagsService.getClient().variation('copy_csv_between_clients', false)

    const classes = useStyles()

    const MyDropzone = () => {
        const onDropAccepted = useCallback(acceptedFiles => {
            handleProcess(acceptedFiles[0])
        }, [])
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDropAccepted, accept: 'text/csv' })

        return (
            <div className={classes.separator}
                {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop The CSV File Here ...</p> :
                        <p>Drag & Drop your CSV File or Click To Select File</p>
                }
            </div>
        )
    }

    const handleProcess = async (file: File) => {
        setUploadProgress({ uploadedFile: file.name, isUploading: true })
        console.log('Upload file name is: ' + file.name)
        const presignedData = await store.fetchUploadUrl(file.name, store.newCSVFolder!)
        await store.uploadToPresignedUrl(file, presignedData, (position: number, total:number) => {
            setUploadProgress({ uploadedFile: file.name, isUploading: true, position: position, total: total })
        })
        store.fetchExistingFiles(true)
        setUploadProgress(undefined)
    }

    useEffect(() => {
        if (store.CsvFiles && store.CsvFiles.length > 0) {
            store.selectedCsvFile ? setSelectionModel([store.selectedCsvFile.url]) : setSelectionModel([store.CsvFiles[0].url])
        }

    }, [store.CsvFiles, store.selectedCsvFile])


    const onDownload = async (storageFile: StorageFile) => {
        if (isDownloading) return
        setIsDownloading(true)
        FileSaver.saveAs(await store.getDownloadUrl(storageFile.url), storageFile.name)
        setIsDownloading(false)
    }

    const normalize = (value: number) => uploadProgress && uploadProgress.total ? (value - 0) * 100 / (uploadProgress.total - 0) : 0;

    const columns: GridColDef[] = [
        {
            field: "  ",
            headerName: "",
            editable: false,
            width: 62,
            renderCell: (params) => (<div>
                              <Radio checked={selectionModel[0] === params.id} 
                     value={params.id} 
                     color="primary"
                     onChange={() => handleSelection([params.id])}/></div>

            )

        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 3,
            editable: false
        },
        {
            field: 'url',
            headerName: 'URL',
            width: 350,
            flex: 4,
            editable: false,
            hide: true
        },
        {
            field: 'size',
            headerName: 'Size',
            type: 'number',
            flex: 2,
            width: 90,
            editable: false,
            hide: true
        },
        {
            field: 'last_modified',
            headerName: 'Last Modified',
            type: 'date',
            flex: 2,
            width: 200,
            editable: false,
            valueFormatter: (value: any) => {
                const localDate = new Date(Date.parse(value.value))
                return localDate.toLocaleString()
            }
        },
        {
            field: 'last_injected',
            headerName: 'Last Injected',
            type: 'date',
            flex: 2,
            width: 200,
            editable: false,
            valueFormatter: (value: any) => {
                const localDate = new Date(Date.parse(value.value))
                return isNaN(localDate.getTime()) ? "" : localDate.toLocaleString()
            }
        },
        {
            field: 'app',
            headerName: 'App',
            flex: 3,
            width: 200,
            editable: false,
        },
        {
            field: " ",
            headerName: "",
            flex: 1,
            width: 100,
            sortable: false,
            disableClickEventBubbling: true,
            hide: disableActions,
            renderCell: (params: GridCellParams) => {
                return (<Link href="#" style={{ color: '#17B1E2' }} onClick={() => {
                    onDownload(params.row as StorageFile)
                }}>
                    Download
                </Link>)
            }
        },
        {
            field: "   ",
            headerName: "",
            flex: 1,
            width: 100,
            sortable: false,
            disableClickEventBubbling: true,
            hide: disableActions || (copyFeatureFlag ? !copyAllowed : true),
            renderCell: (params: GridCellParams) => {
                return (
                    <Button
                        href="#"
                        style={{ color: copyFeatureFlag && copyAllowed ? '#17B1E2' : '#bdbdbd'}}
                        className={classes.textbtn}
                        component={Link}
                        disabled={ copyFeatureFlag ? !copyAllowed : true }
                        disableRipple
                        onClick={() => {
                            // Get clicked file path without filename (folder only)
                            const sourcePath = params.id.toString().split('/').slice(0,-1).join('/') + '/'

                            setCopyDialogData({ sourcePath: sourcePath, isOpen: true })
                        }
                    }>
                        Copy
                    </Button>
                )
            }
        }
    ];

    const handleSelection = (rowIds: GridRowId[]) => {
        const newSelectionModel = rowIds

        if (newSelectionModel.length > 1) {
            const selectionSet = new Set(selectionModel);
            const result: any = newSelectionModel.filter(
                (s) => !selectionSet.has(s)
            );

            setSelectionModel(result);
            store.setSelectedCsv(store.CsvFiles!.find(file => file.url === result[0]))
        } else {
            setSelectionModel(newSelectionModel);
            store.setSelectedCsv(store.CsvFiles!.find(file => file.url === newSelectionModel[0]))
        }
    }

    const updateCsvFiles = (csvFiles: StorageFile[]) => {
        if (store.organizationMetadata) {
                const updatedFiles: StorageFile[] = 
                csvFiles.map(f => {
                    const file_name = f.url.slice(f.url.indexOf('/') + 1)
                    const csv_data = store.organizationMetadata!.input_file_items.find((fm: FileMetadata) => fm.file_name === file_name)
                    if (csv_data && csv_data.last_successful_injection) {
                        const date = new Date(`${csv_data.last_successful_injection.date}, ${csv_data.last_successful_injection.time}`)
                        const app = `${csv_data.last_successful_injection.app} [${csv_data.last_successful_injection.org}]`
                        const updated_file: CSVStorageFile = {...f, last_injected: date, app: app}
                        return updated_file
                    }
                    else return f
                })
                return updatedFiles
        }
        return csvFiles
    }

    

    // setInterval(() => { setUploadProgress({'isUploading': true, 'position': Math.floor(Math.random() * 100), 'total': 100}) }, 5000);


    return (
        <div style={{ height: '60vh' }}>
            {!disableUpload && <MyDropzone />}
            {uploadProgress && uploadProgress.isUploading &&
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControlLabel disabled value={''} control={<Radio />} label={uploadProgress.uploadedFile} />
                    {uploadProgress.position && <LinearProgress variant="determinate" value={normalize(uploadProgress.position)} />}
                </div>}
            <div style={{ height: '60vh', marginTop: 10 }}>
                {store.CsvFiles && <DataGrid
                    rows={updateCsvFiles(store.CsvFiles)}
                    columns={columns}
                    pageSize={9}
                    disableSelectionOnClick
                    onCellClick={(params: GridCellParams, event: React.MouseEvent) => {params.colDef.headerName !== "" && handleSelection([params.id])}}
                    getRowId={row => row.url}
                    selectionModel={selectionModel}
                    onSelectionModelChange={(selection) => handleSelection(selection.selectionModel)}
                />}
            </div>
            
            {/* Copy Button Dialog */}
            {copyFeatureFlag && <CsvCopyDialog store={store} isOpen={ copyDialogData.isOpen } sourcePath={ copyDialogData.sourcePath } toggleOpen={()=>setCopyDialogData({...copyDialogData, isOpen:!copyDialogData.isOpen})} />}
        </div>
    );
})