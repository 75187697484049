import { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import { WizardItem } from '../../models/WizardItem'
import { Breadcrumbs, Typography, withStyles } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { SecurityStore } from '../../stores/SecurityStore'
import StepConfirmation, { ConfirmationDialogProps } from '../controls/StepConfirmation'
import { LEGACY_MODE } from '../../App'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        nextButton: {
            color: 'white',
            width: 100,
            height: 42
        },
        backButton: {
            marginRight: theme.spacing(1),
            color: 'white',
            width: 100,
            height: 42
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        labelContainer: {
            "& $alternativeLabel": {
                marginTop: 0
            }
        },
        step: {
            color: '#C0C5CC',
            "& $completed": {
                color: "#57D89D"
            },
            "& $active": {
                color: "#57D89D"
            },
        },
        alternativeLabel: {},
        active: {}, //needed so that the &$active tag works
        completed: {},
        disabled: {},
        footer: {
            borderTop: "1px solid #E7E7E7",
            position: "fixed",
            display: 'block',
            left: "0",
            bottom: "0",
            height: "60px",
            width: "100%",
            background: '#F1F2F4',
            boxShadow: "0px -2px 3px rgba(50, 50, 50, 0.50)"
        },
        custom: {
            marginRight: theme.spacing(1)
        }
    }),
)

const WizardHeaderTypography = withStyles({
    root: {
        color: "#023342"
    }
})(Typography)

type WizardProps = {
    steps: WizardItem[]
    selectedOrg?: string
    selectedFileUrl?: string
    securityStore?: SecurityStore
    isMappingValid: boolean
}

export const Wizard = observer((props: WizardProps) => {
    const {steps, selectedOrg, selectedFileUrl, securityStore, isMappingValid} = props
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    const [stepConfirmation, setStepConfirmation] = useState(false)
    const [confirmationDialogs, setConfirmationDialogs] = useState<ConfirmationDialogProps[]>()

    const availableSteps = steps.slice()
        .filter(item => !item.operationId || (securityStore && securityStore.isAllowed(item.operationId)))


    const handleNext = () => {
        setActiveStep(activeStep + 1)
    }

    const handleBack = () => {
        setActiveStep(activeStep - 1)
    }
    
    const handleReset = () => {
        setActiveStep(0)
    }

    const step = availableSteps[activeStep]

    return (
        <div style={{ margin: '0 300px' }}>
            <Stepper activeStep={activeStep} alternativeLabel style={{ background: 'transparent' }}>
                {availableSteps.map(item => (
                    <Step key={item.title} classes={{ root: classes.step }}>
                        <StepLabel
                            classes={{
                                alternativeLabel: classes.alternativeLabel,
                                labelContainer: classes.labelContainer
                            }}
                            StepIconProps={{
                                classes: {
                                    root: classes.step,
                                    completed: classes.completed,
                                    active: classes.active,
                                }
                            }}>
                            {item.title}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div style={{ borderTop: "1px solid #DADCDF", margin: '0 -300px' }}></div>
            {selectedOrg && <Breadcrumbs style={{ padding: '0 40px', marginTop: 10 }} aria-label="breadcrumb">
                <Typography color="textPrimary">
                    {selectedOrg}
                </Typography>
                <Typography color="textPrimary">
                    {selectedFileUrl}
                </Typography>
            </Breadcrumbs>}
            {/* Content */}
            <div style={{ padding: '0 40px 70px 40px', marginTop: 24 }}>
                <WizardHeaderTypography variant="h6" color='primary'>{step && step.description}</WizardHeaderTypography>
                <div style={{ marginLeft: 10, marginTop: 24 }}>
                    {step && step.content && step.content()}
                </div>
            </div>
            {/* Content */}
            <br />
            <br />
            <br />
            <br />
            {/* Footer */}
            <div className={classes.footer}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 8 }}>
                    <Typography variant='h6' color="secondary" style={{alignSelf: 'center'}}>{LEGACY_MODE ? 'LEGACY MODE' : ''}</Typography>
                    <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
                        {step.footer && <div className={classes.custom}>
                        {step.footer!()}
                    </div>}
                    {activeStep === availableSteps.length ?
                        <Button
                            onClick={handleReset}
                            variant="contained"
                            className={classes.backButton}
                            color='secondary'
                            disableElevation>
                            Reset
                    </Button>
                        :
                        <div>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                variant="contained"
                                className={classes.backButton}
                                color='secondary'
                                disableElevation
                            >
                                Back
                            </Button>
                            <Button 
                                className={classes.nextButton}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    const stepConfirmationProps = step.getConfirmationProps ? step.getConfirmationProps() 
                                        : undefined
                                    setConfirmationDialogs(stepConfirmationProps)

                                    if (stepConfirmationProps) {
                                        setStepConfirmation(true)
                                    }
                                    else handleNext()

                                    if (step.onNext){
                                        step.onNext()
                                    }
                                }}
                                disabled={!step.canMoveNext()}
                                disableElevation
                            >
                                {activeStep === availableSteps.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                        }
                </div>
            </div>
            </div>
            {stepConfirmation && confirmationDialogs && <StepConfirmation 
                confirmationDialogs={confirmationDialogs} 
                finish={() => {
                    setStepConfirmation(false)
                    handleNext()
                }}
                cancel={() => setStepConfirmation(false)}
                isValid={isMappingValid}
            />}
        </div>
    )
})