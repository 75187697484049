import { useRef, useEffect } from "react";

function useInterval(callback: any, delay: any, isActive: boolean = true) {
    const savedCallback = useRef();
    const intervalId = useRef<NodeJS.Timeout | null>(null);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            const current = savedCallback.current as any
            current();
        }
        if (isActive && delay !== null) {
            intervalId.current = setInterval(tick, delay);
            return () => {
                if (intervalId.current !== null) {
                    clearInterval(intervalId.current);
                    intervalId.current = null;
                }
            };
        } else {
            if (intervalId.current !== null) {
                clearInterval(intervalId.current);
                intervalId.current = null;
            }
        }
    }, [delay, isActive]);
}

export default useInterval;