import { useEffect, useState } from 'react'
import { GridColDef, GridRowData, GridValueFormatterParams } from '@material-ui/data-grid';
import FileSaver from 'file-saver';
import { FunctionalDataGrid } from './FunctionalDataGrid';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  data_grid: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
}));

interface ICSVDataGridProps {
  columns: GridColDef[]
  csv: File
  onSave: (csv: File) => void
  disabled?: boolean
}

export const CSVDataGrid = (props: ICSVDataGridProps) => {
    const classes = useStyles();
    const{csv, onSave, disabled} = props
    const [rows, setRows] = useState<GridRowData[]>([]);
    const [columns, setColumns] = useState<GridColDef[]>([]);

    useEffect(() => {
      loadCSV(csv)
    }, [csv])

    useEffect(() => {
      props.columns.forEach(c => c.valueFormatter = escape_sequence_formatter)
      setColumns(props.columns)
    }, [])

    const escape_sequence_formatter = (params: GridValueFormatterParams) => {
      const escape_sequence = '"\\""'
      var cell_value = params.value
        if (cell_value?.toString().includes(',')) {
          cell_value = cell_value?.toString().replaceAll('"', '');
          cell_value = cell_value?.toString().replaceAll('\\', '');
          cell_value = `${escape_sequence}${cell_value}${escape_sequence}`
        }
      return cell_value
    }

    const loadCSV = (file: File | undefined) => {
      if (file === undefined) return undefined
      const fr = new FileReader()
      fr.readAsText(file)
      fr.onload = () => {
        const csvString = fr.result
        if (typeof csvString == 'string') {
          setRows(parseCSV(csvString))
        }
      }
    }

    const split_line_to_row = (l: string) => {
      const escape_sequence = '"\\""'
      if (l.includes(escape_sequence)) {
        const split_array = []
        while (l && l.includes(',')) {
          var comma_index = l.indexOf(',')
          const escape_sequence_index = l.indexOf(escape_sequence)
          if (escape_sequence_index < comma_index) {
            const closing_index = l.indexOf(escape_sequence, escape_sequence_index + 1)
            comma_index = l.indexOf(',', closing_index)
          }
          if (comma_index === -1) {
            split_array.push(l)
            break
          }
          split_array.push(l.substring(0,comma_index))
          l = l.substring(comma_index + 1)         
        }
        return split_array
      }
      else return l.split(',')
    }

    const parseCSV = (csv: string) => {
      const lines = csv.replaceAll('\r', '').split('\n').map(l => split_line_to_row(l))
      const fieldIndexes = Object.assign({}, ...columns.map(c => ({[c.field]: lines[0].indexOf(c.field)})))
      const rows = lines.slice(1)
      const gridRows = rows.map((r, i) => {
        const row: any= {id: i}
        columns.forEach(c => {
          if (fieldIndexes[c.field] !== -1) {
            row[c.field] = r[fieldIndexes[c.field]]}
        })
        return row
      })
      return gridRows
    }

    const dumpCSV = (rows: GridRowData[]) => {
        const fields = columns.map(c => c.field)
        var csvData = rows.map(r => Object.entries(r).filter(([key,val]) => fields.includes(key)).map(([key,val]) => val))
        csvData.unshift(fields)
        return csvData.map(e => e.join(",")).join("\n");
    }

    const onSaveRows = (rows: GridRowData[]) => {
      onSave(new File([dumpCSV(rows)], csv.name))
      setRows(rows)
    }

    const onDownload = (rows: GridRowData[]) => FileSaver.saveAs(new Blob([dumpCSV(rows)], { type: "text/csv" }), csv.name)
    
    return (<div className={classes.data_grid}>
            <FunctionalDataGrid rows={rows} 
                                columns={columns}
                                onSave={onSaveRows}
                                onDownload={onDownload}
                                onUpload={file => loadCSV(file)}
                                disabled={disabled}
                                /></div>)
}