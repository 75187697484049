import { Button, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import FileSaver from "file-saver";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ProgressPushMessage } from "../../models/ProgressPushMessage";
import { MainStore } from "../../stores/MainStore";
import ProgressBar from "../controls/ProgressBar";

const useStyles = makeStyles((theme) => ({
    page: {
      margin: '0px 50px 20px 50px',
    }
  }));

interface ISelectInputCSVProps {
    mainStore: MainStore
}

export const ValueGenerator = observer(({mainStore}: ISelectInputCSVProps) => {
    const { injectProgress, currentOrgOutputFolder,  digestFile} = mainStore

    const [generateStarted, setGenerateStarted] = useState(false)
    const [done, setDone] = useState(false)
    const classes = useStyles();
    
    const renderItem = (message: ProgressPushMessage) => {
        if (message.subject === 'finalizing' && message.progress === 100 && !done) mainStore.fetchExistingFiles().then(()=>{setDone(true); setGenerateStarted(false)})

        return <div key={message.subject} style={{ marginBottom: 20, marginTop: 20, display: 'flex', alignItems: 'start'}}>
                    <div style={{ display: 'flex', alignItems: 'center', width: 300, marginRight: 20}}>
                        <Typography style={{ marginRight: 40, width: 160 }}>{message.subject}</Typography>
                        <ProgressBar
                            fillColor={message.state && message.state === 'fail' ? 'red' : '#17B1E2'}
                            completed={message.progress} />
                    </div>
                    {message.message && <div>
                        {message.message.split('\n').map((line, i) => {
                            return <Typography key={i} variant="body2" style={{color: 'red'}}>{line}</Typography>
                        })}
                    </div>}
                    {done && message.subject === 'finalizing' && <Typography style={{ marginRight: 40, width: 160 }}>Done, output ready</Typography>}
               </div>
    }
    
    return (<div className={classes.page}>
                <Button variant='outlined' 
                    style={{ marginRight: '10px'}} 
                    onClick={() => {
                        setDone(false)
                        setGenerateStarted(true)
                        mainStore.processDigest(currentOrgOutputFolder!)
                    }} 
                    disabled={(!currentOrgOutputFolder) || (generateStarted && !done)}>
                    Generate
                </Button>
                <Button variant='outlined' 
                    onClick={async () => {
                        FileSaver.saveAs(await mainStore.getDownloadUrl(digestFile!.url))
                    }}
                    disabled={!digestFile}>
                    Download
                </Button>
                {generateStarted && injectProgress.length === 0 &&
                <div style={{display:"flex", flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress style={{margin: 20}} />
                    <Typography variant="body2">Server loading, please wait...</Typography>
                </div>}
                {injectProgress.length > 0 && injectProgress.map(item => renderItem(item))}
            </div>)
})