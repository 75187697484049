import { Button, CircularProgress, Link, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { SFAuthCode } from '../../models/Profile'
import { Operations } from '../../Operations'
import { MainStore } from '../../stores/MainStore'

export type OrgType = 'production' | 'sandbox'
export const SF_DOMAINS: Record<OrgType, string> = {
  'production': "https://login.salesforce.com",
  'sandbox': "https://test.salesforce.com"
}

interface ISalesforceLoginProps {
  store: MainStore
  onLogout?: () => void
}

export const SalesforceLogin = observer((props: ISalesforceLoginProps) => {
  const {store, onLogout} = props;
  const [code, setCode] = useState("")
  const [orgType, setOrgType] = useState<OrgType>('production')
  const [org, setOrg] = useState("")

  useEffect(() => {
    setOrg(store.SFToken?.instance_url?.replace('https://','') ?? "")

    if (code && !store.SFToken){
      const authCode: SFAuthCode = {
        auth_code: code,
        sf_domain: SF_DOMAINS[orgType]
      }
      const getToken = async () => {
        await store.getSFToken(authCode)
        setCode("")
        setOrgType('production')
      } 
      getToken()
    } 
  }, [code, store.SFToken])
 
  const w = 600;
  const h = 600;
  const features = [
    `width=${w}`,
    `height=${h}`,
    `top=${window.top!.outerHeight / 2 + window.top!.screenY - ( h / 2)}`,
    `left=${window.top!.outerWidth / 2 + window.top!.screenX - ( w / 2)}`]
    
  const login = (orgType: OrgType) => {
    setOrgType(orgType)
    const login_window = window.open(`sflogin?type=${orgType}`, 'Salesforce Login', features.join(','));
    (window as any).setCode = (code: string) => {
      setCode(code);
      login_window!.close();
    }
  }

  const logout = () => {
    store.logoutSF()
    onLogout && onLogout()
  }

  return (store.SFToken ?
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                <Typography>Logged in to {org}</Typography>
                <Link style={{ marginLeft: '5px' }} onClick={() => logout()}>logout</Link>
            </div> :
            code ?
            <div style ={{ height: '100%', display: 'flex', alignItems: 'center', justifyItems: 'center'}}>
                <CircularProgress />
            </div> :
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
              <Button style ={{ height: '100%'}} variant='outlined' onClick={() => login('production')}>Login to Salesforce</Button>
              {store.securityStore?.isAllowed(Operations.EditTemplate) &&
              <Link style={{ marginTop: '5px', cursor: 'pointer' }} onClick={() => login('sandbox')}>login to scratch org</Link>}
            </div>
            )
               
})