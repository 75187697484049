import { action, makeAutoObservable, observable, computed } from 'mobx'
import { UserInfo } from 'os'
import { MainApi } from '../apis/MainApi'
import { ActionsPayload } from '../models/ActionsPayload'
import { Config } from '../models/Config'
import { AutocompleteOption } from '../models/AutocompleteOption'
import { ConfigItem, defaultConfigItems } from '../models/ConfigItem'
import { TemplateFile } from '../models/TemplateFile';
import { Folder } from '../models/Folder'
import { InjectInfo, InjectPatchInfo, TestInfo } from '../models/InjectInfo'
import { MetadataCSV } from '../models/MetadataCSV'
import { LoggedInUser, RefreshableSFToken, SFAuthCode, SFToken, Token } from '../models/Profile'
import { CreateAppMessage, InjectFailMessage, ProgressFailMessage, ProgressPushMessage, PushMessage, ValidationPushMessage, ValidationMessage, instanceOfValidationPushMessage } from '../models/ProgressPushMessage'
import { SalesforceApp } from '../models/SalesforceApp'
import { SalesforceDashboard } from '../models/SalesforceDashboard'
import { NlpCustomer } from '../models/NlpCustomer'
import { StorageFile } from '../models/StorageFile'
import { Template } from '../models/Template'
import { TriageCustomer, TriageManufacturer, TriageModel, TriageObservation } from '../models/TriageCustomer'
import FeatureFlagsService from '../services/FeatureFlagsService'
import NotificationService from '../services/NotificationService'
import TokenRefreshService, { TokenRefreshArgs } from '../services/TokenRefreshService'
import { getInnerConfig, getMultipleAssignments, matchHeaders, updateConfig , isDependentConfigItemsValid, verifyCopiedConfig} from '../Utils'
import { SecurityStore } from './SecurityStore'
import logger from '../Logger'
import { DigestMD, DigestProperties, GenerateLinkInfo, ViewsList } from '../models/Digest'
import { ThirtyFpsSelect } from '@mui/icons-material'
import { TriageProduct } from '../models/TriageProduct'
import { SalesforceUser } from '../models/SalesforceUser'
import { CopilotStore } from '../components/copilot setup/CopilotStore'
import { Settings } from '../Settings'


export class MainStore {
    @observable user?: LoggedInUser

    @observable csvHeaders: string[] = []

    @observable organizations: Folder[] = []
    @observable currentOrg?: string
    @observable orgDefs: {industries?: string[], org_type?: string} = {}
    

    @observable nonOutputExistingFiles?: StorageFile[]
    @observable allExistingFiles?: StorageFile[]
    @observable selectedCsvFile?: StorageFile
    @observable selectedPartialCsvFile?: StorageFile
    @observable selectedJsonUrl?: string
    @observable loadingConfig: boolean = false
    @observable configuration?: ConfigItem[] = defaultConfigItems
    @observable selectedMappingItems: ConfigItem[] = []
    @observable loadedConfigMultipleAssignments?: { [value: string]: ConfigItem[] }
    @observable organizationMetadata: MetadataCSV | undefined
    @observable partialMergeApplied: boolean = false

    notificationService?: NotificationService

    @observable correlationId?: string

    @observable processProgress: ProgressPushMessage[] = []
    @observable validationProgress?: ValidationPushMessage
    @observable appProgress: ProgressPushMessage[] = []
    @observable patchProgress: ProgressPushMessage[] = []
    @observable injectProgress: ProgressPushMessage[] = []
    @observable overallProgress: ProgressPushMessage[] = []
    @observable cancelProgress: ProgressPushMessage[] = []
    @observable partialMergeProgress: ProgressPushMessage[] = []
    @observable createAppProgress?: CreateAppMessage
    @observable templates?: Template[]

    
    testNotificationService?: NotificationService
    @observable testProgress: ProgressPushMessage[] = []

    @observable PackageVersion?: string
    @observable LatestPackageVersion?: string
    @observable nlpCustomers?: NlpCustomer[] = []

    @observable lastDigestResult?: DigestProperties | undefined
    @observable digestTemplates?: any

    @observable LegacyPackageVersion?: string
    mainApi?: MainApi
    securityStore?: SecurityStore
    copilotStore?: CopilotStore
    featureFlags:any
    triage_table_names: string[] = ["Case", "Investigation_Observation__c", 'Investigation_Part__c', 'Investigation_Selected_Solution__c', 'Investigation_Solution_New__c', 'Investigation___C', 'LogicSetting__c', 'Support_Call__c']
    settings?: Settings

    constructor() {
        makeAutoObservable(this)
        this.featureFlags = FeatureFlagsService.getAllFlags()
    }

    @computed get newCSVFolder() {
        if (!this.currentOrg) return undefined
        const loadedOnFileFormat = (new Date()).toISOString().replaceAll(':', '-').split('.')[0]
        return this.currentOrg + "/" + loadedOnFileFormat
    }

    @computed get tempCSVFolder() {
        if (!this.currentOrg) return undefined
        return this.currentOrg + "/temp"
    }


    @computed get currentOrgManualInjectionFolder() {
        if (!this.currentOrg) return undefined
        return this.currentOrg + "/ManualInjections"
    }

    @computed get currentOrgPartialsFolder() {
        if (!this.currentOrg) return undefined
        return this.currentOrg + "/partials"
    }

    @computed get currentOrgOutputFolder() {
        if (!this.currentCsvFolder) return undefined
        return this.currentCsvFolder + '/output'
    }

    @computed get mappingValid() {
        if (!this.configuration) return false
        const mappingValid = !this.configuration.some(item =>
            (item.isRequired && item.value === 'n/a'))
        
        const labelsMandatoryValid = this.featureFlags['allow-mandatory-labels'] && !this.configuration.some(item => 
            (item.labelValueDependent && item.label === '' && item.value !== 'n/a' ))

        const mappingDependenciesValid = this.featureFlags['allow-mandatory-labels'] ? isDependentConfigItemsValid(this.configuration) : true
                
        return mappingValid && (!this.featureFlags['allow-mandatory-labels'] || labelsMandatoryValid) && mappingDependenciesValid
    }

    @action
    setCsvHeaders = (items: string[]) => {
        this.csvHeaders = items

        if (this.configuration)
            this.setNlpHeaderConfigs(this.csvHeaders)
    }

    fetchOrganizations = async () => {
        const items = await this.mainApi!.getOrganizations()
        this.setOrganizations(items)
    }

    @action
    setOrganizations = (items: Folder[]) => {
        this.organizations = items
    }

    @action
    setLoadingConfig = (loading: boolean) => {
        this.loadingConfig = loading
    }

    @action
    setPartialMergeApplied = (partialMerge: boolean) => {
        this.partialMergeApplied = partialMerge
    }

    @action
    setOrganization = async (org?: string) => {
        this.setConfiguration(undefined)
        this.setIndustries(undefined)
        this.setOrgType(undefined)
        this.currentOrg = org
        if (org) await this.fetchExistingFiles()
    }


    @action
    updateOrgDefinitions = async() => {
        if (!this.currentOrg) return
        
        if (this.organizationMetadata) {
            this.mainApi!.updateOrgDefinitions(this.currentOrg, 
                this.orgDefs.industries, 
                this.orgDefs.org_type)
        }
    }

    @action
    connectNotificationService = async () => {
        await this.setNotificationService(this.user, this.currentOrg, this.selectedCsvFile?.name)
        if (this.notificationService) await this.resetSessionMessages(this.notificationService.session_id)
        console.log("connecting notification service")
    }

    @action
    setNotificationService = async (user?: LoggedInUser, currentOrg?: string, csvFileName?: string) => {
        if (this.mainApi && user && currentOrg) {
            if (this.notificationService) {
                this.notificationService.close()
            }
            if (currentOrg && csvFileName) {
                this.clearAllProgress()
                const appSettings = await this.getSettings()
                this.notificationService = new NotificationService(appSettings.env.REACT_APP_NOTIFICATION_SERVER_URL, 
                    this.onProcessUpdate, this.mainApi.getToken, user.profile.email, currentOrg, csvFileName)
                await this.notificationService.open()
            }
        }
    }

    @action
    connectTestNotificationService = async () => {
        if (this.mainApi && this.user) {
            if (this.testNotificationService) return
            this.clearAllProgress()
            const appSettings = await this.getSettings()
            this.testNotificationService = new NotificationService(appSettings.env.REACT_APP_NOTIFICATION_SERVER_URL, 
                this.onProcessUpdate, this.mainApi.getToken, this.user.profile.email, "", "")
            await this.testNotificationService.open()
        }
    }

    uploadRawConfig = async (items: ConfigItem[], folderName: string) => {
        const innerConfig: Config = {}
        items.forEach(i => innerConfig[i.name] = i.value as any)
        const finalConfig = await this.mainApi!.saveRawConfig(items, folderName)
        this.setSelectedMappingItems([])
        return finalConfig
    }

    @action
    addSelectedMappingItems = (item: ConfigItem) => {
        this.selectedMappingItems.push(item)
    }

    @action
    setSelectedMappingItems = (items: ConfigItem[]) => {
        this.selectedMappingItems = items
    }

    uploadToPresignedUrl = async (file: File, presigedData: { url: string, fields: any }, onProgress: (position: number, total: number) => void) => {
        const response = await this.mainApi!.uploadToPresignedUrl(file, presigedData, onProgress).catch(err => { 
            logger.error("Upload to presigned url failed for user " + this.user?.profile.email + " - ", err);
        });

        return response
    }

    fetchUploadUrl = async (file_name: string, folderName: string) => {
        const response = this.mainApi!.fetchUploadUrlByFileName(file_name, folderName)
        return response
    }

    fetchUploadUrlByFileName = async (fileName: string, folderName: string) => {
        const response = this.mainApi!.fetchUploadUrlByFileName(fileName, folderName)
        return response
    }

    fetchExistingFiles = async (force: boolean = false) => {
        const files = await this.mainApi!.getFiles(this.currentOrg!)
        this.setRawExistingFiles(files)
        this.setExistingFiles(files, force)
        this.setOrganizationMetadata(files)
    }

    fetchSchema = async () => {
        if (!this.selectedCsvFile) return

        const schemaString: string = await this.mainApi!.getSchema(this.selectedCsvFile.url)
        const headers = schemaString.replaceAll("'", "").split(',')
        this.setCsvHeaders(headers)
    }

    collectCustomersDs = async (selectedOrgs: AutocompleteOption[], selectedDatasets: AutocompleteOption[]) => {
        const customers = selectedOrgs.map(org => org.title);
        const datasets = selectedDatasets.map(dataset => dataset.title);
        const sessionId = await this.mainApi!.collectCustomersDs(customers, datasets);
        return sessionId;
    }

    getCollectedFiles = async () => {
        const usersfiles = await this.mainApi!.getCollectedFiles();
        return usersfiles;
    }

    getIsFileUploaded = async (sessionId: string) => {
        const isUploaded = await this.mainApi!.getIsFileUploaded(sessionId);
        return isUploaded;
    }

    fetchTemplateVersions = async () => {
        return await this.mainApi!.getTemplateVersions()
    }

    fetchTemplateFile = async (template_version: string, file_name: string) => {
        const content = await this.mainApi!.getTemplateFile(`${template_version}/${file_name}`)
        return content ? new File([content], file_name) : undefined
    }

    saveTemplateFile = (templateVersion: string, file: File) => {
        if (templateVersion === '') return
        const fr = new FileReader()
        fr.readAsText(file)
        fr.onload = () => {
            const fileString = fr.result
            if (typeof fileString == 'string') {
                const templateFile: TemplateFile = {
                    name: `${templateVersion}/${file.name}`,
                    content: fileString
                }
                this.mainApi!.saveTemplateFile(templateFile)
            }
        }
    }

    fetchWidgetSchema = async (templateVersion: string) => {
        return await this.mainApi!.getWidgetSchema(templateVersion)
    }

    fetchViews = async () => {
        if (this.viewsFile) {
            const result = await this.mainApi!.downloadFile(this.viewsFile.url)
            const views_list = JSON.parse(result) as ViewsList
            return views_list.views
        }
    }

    fetchDigestCustomers = async () => {
        if (this.digestCustomersFile) {
            const result = await this.mainApi!.downloadFile(this.digestCustomersFile.url)
            const customers_data = JSON.parse(result)
            return customers_data
        }
    }

    fetchPatchConfigs = async () => {
        return await this.mainApi!.getpatchConfigs()
    }

    fetchConfig = async () => {
        if (!this.selectedCsvFile) return
        const split_csv_url = this.selectedCsvFile!.url.split('/')
        split_csv_url.pop()
        const folder_url = split_csv_url.join('/')

        var configItems = defaultConfigItems
        var newConfigFile = true

        this.setLoadingConfig(true)
        var relevantConfigFound = true
        if (this.ConfigFiles && this.ConfigFiles.length > 0){
            var config_file = this.ConfigFiles.find(c => c.url.includes(folder_url))
            if (config_file === undefined) {
                // File not found, try the first file from the ORG 
                relevantConfigFound = false
                config_file = this.ConfigFiles[0]
                this.setSelectedJsonUrl(undefined)
            }
            else newConfigFile = false
            if (this.selectedJsonUrl !== config_file.url) {
                const config = await this.mainApi!.downloadFile(config_file.url)
                this.setSelectedJsonUrl(folder_url)
                const configFileItems = JSON.parse(config)
                configItems = updateConfig(defaultConfigItems, configFileItems, ['failed_metric'])
            }
            else configItems = this.configuration!
        }

        configItems = matchHeaders(configItems, this.csvHeaders)
        if (!relevantConfigFound){
            configItems = verifyCopiedConfig(configItems, this.csvHeaders)
        }
        
        this.setConfiguration(configItems)
        this.setLoadingConfig(false)

        // to prevent popping the dialog on confirmed multiple assignments
        this.setLoadedConfigMultiplAassignments(getMultipleAssignments(configItems))

        if (newConfigFile) {
            this.uploadRawConfig(configItems, folder_url)
            this.fetchExistingFiles()
        }
    }

    downloadFile = async (file: StorageFile) => {
        return await this.mainApi!.downloadFile(file.url)
    }

    getDownloadUrl = async (file_path: string) => {
        return await this.mainApi!.getDownloadUrl(file_path)
    }

    getDownloadUrlMetadata = async () => {
        return await this.mainApi!.getDownloadUrlMetadata()
    }

    downloadCollectFile = async (file_path: string) => {
        return await this.mainApi!.downloadCollectFile(file_path);

    }

    copyCustomerFolder = async (sourceFolder: string, destinationCustomer: string, includeOutput: boolean) => {
        return await this.mainApi!.copyCustomerFolder(sourceFolder, destinationCustomer, includeOutput)
    }

    @action
    setOrganizationMetadata = async (files: StorageFile[]) => {
        const metadataFile = files.find(file => file.name.toLowerCase().endsWith('.json') && file.name.includes("metadata"))
        if (metadataFile) {
            const result = await this.downloadFile(metadataFile)
            const md: MetadataCSV = JSON.parse(result)
            this.organizationMetadata = md

            this.setIndustries(md.industries || [])
            this.setOrgType(md.org_type)
        }
    }

    @action
    setExistingFiles = async (files: StorageFile[], force: boolean = false) => {
        this.nonOutputExistingFiles = files.filter(item => !item.url.startsWith(`${this.currentOrgOutputFolder!}/`))

        if (this.CsvFiles && this.CsvFiles.length > 0) {
            const newItem = (!force && this.CsvFiles.find(i => this.selectedCsvFile && i.url === this.selectedCsvFile.url)) || this.CsvFiles[0]

            if (this.selectedCsvFile?.url !== newItem.url)
                this.setSelectedCsv(newItem)
        }
    }

    @action
    setRawExistingFiles = async (files: StorageFile[]) => {
        this.allExistingFiles = files
    }

    @action
    setSelectedCsv = async (file?: StorageFile) => {
        if (this.selectedCsvFile?.url !== file?.url) {
            this.selectedCsvFile = file
            await this.fetchSchema()
            this.fetchConfig()
        }
    }

    @action
    setSelectedPartialCsv = async (file?: StorageFile) => {
        if (this.selectedPartialCsvFile?.url !== file?.url) {
            this.selectedPartialCsvFile = file
        }
    }

    @action
    setSelectedJsonUrl = (url?: string) => {
        this.selectedJsonUrl = url
    }

    @action
    setConfiguration = (config?: ConfigItem[]) => {
        this.configuration = config

        if (this.nlpCustomers)
            this.setNlpCustomerConfig(this.nlpCustomers)
        if (this.csvHeaders)
            this.setNlpHeaderConfigs(this.csvHeaders)
        if (this.copilotStore)
            this.copilotStore.setConfiguration(this.configuration)
        }

    @action
    setLoadedConfigMultiplAassignments = (configuration?: { [value: string]: ConfigItem[] }) => {
        this.loadedConfigMultipleAssignments = configuration
    }

    @action
    initUser = async (access_token: () => string | undefined) => {

        const appSettings = await this.getSettings()
        this.mainApi = new MainApi(access_token, appSettings.env.REACT_APP_SERVER_URL, appSettings.env.REACT_APP_NOTIFICATION_SERVER_URL)
        
        const userDetail = await this.mainApi.getUserDetail()
        this.user = {
        profile: {
            email: userDetail.email, familyName: userDetail.first_name, givenName: userDetail.last_name,
            userId: userDetail.user_id, imageUrl: userDetail.profile_picture_url, name: userDetail.user_name
        }, 
        token: {
            access_token: access_token() || "", expires_in: 0}
        };

        const operations = await this.mainApi.getUserPermissions()
        this.securityStore = new SecurityStore(operations.map((i: any) => i.id))
        this.copilotStore = new CopilotStore(this.mainApi)
        this.fetchOrganizations()
        setTimeout(() => {
            this.fetchNlpCustomers()
            this.getPackageVersions()
            this.initCopilotStore(access_token, userDetail.email)
        }, 500)
    }

    initCopilotStore = async (access_token: () => string | undefined, user_email: string) => {
        const appSettings = await this.getSettings()
        this.copilotStore!.initStore(access_token, user_email, appSettings.env.REACT_APP_KA_API_SERVER_URL)
    }

    @action
    updateToken = async (token: Token) => {
        if (!this.user) return

        this.user.token = token
        if (this.mainApi) this.mainApi.updateToken(token.access_token)
        await this.connectNotificationService()
    }

    @action
    concatReferencePartial = async (reference: string|undefined, partial: string|undefined, newFolder: string|undefined) => {
        this.clearAllProgress()
        this.mainApi?.concatReferencePartial(reference, partial, newFolder)
    }

    @action
    finalizePartialMerge = async (sourceFolderPath:string|undefined, destFolder:string|undefined, csvFileName: string|undefined) => {
        return this.mainApi?.finalizePartialMerge(sourceFolderPath, destFolder, csvFileName)
    }



    process = async (runValidations: boolean) => {
        if (this.currentOrg && this.selectedCsvFile && this.configuration && this.currentOrgOutputFolder) {
            this.clearAllProgress()

            const innerConfig = getInnerConfig(this.configuration)
            const cancellationToken = await this.mainApi!.process(this.currentOrgOutputFolder, innerConfig, this.selectedCsvFile.url, runValidations)
            return cancellationToken
        }
    }

    validate = async () => {
        if (this.currentOrg && this.selectedCsvFile && this.configuration && this.currentOrgOutputFolder) {
            this.clearAllProgress()

            const innerConfig = getInnerConfig(this.configuration)
            await this.mainApi!.validate(this.currentOrgOutputFolder, innerConfig, this.selectedCsvFile.url)
        }
    }

    cancelOperation = async (cancellationTokenId?: string) => {
        const cancellationId = cancellationTokenId ?? this.correlationId
        if (!cancellationId) return

        this.mainApi!.cancelOperation(cancellationId)
        this.correlationId = undefined
    }

    inject = async (dashboardName: string, injectInfo: InjectInfo) => {
        const innerConfig = getInnerConfig(this.configuration!)
        const injectPatchInfo: InjectPatchInfo = {
            ...injectInfo,
            dashboard_name: dashboardName,
            template_version: "",
            config: innerConfig
        }
        if (this.currentOrgOutputFolder && this.selectedCsvFile) {
            this.injectProgress = []
            await this.mainApi!.inject(this.currentOrgOutputFolder, injectPatchInfo, this.selectedCsvFile.url)
        }
    }

    processInjectMetadata = async (dashboardName: string, injectInfo: InjectInfo) => {

        this.clearAllProgress()
        const injectPatchInfo: InjectPatchInfo = {
            ...injectInfo,
            dashboard_name: dashboardName,
            template_version: "",
        }

        await this.mainApi!.processInjectMetadata(injectPatchInfo)
    }

    processInject2 = async (actionsPayload: ActionsPayload) => {
        if (this.currentOrg && this.selectedCsvFile && this.configuration && this.currentOrgOutputFolder) {
            this.clearAllProgress()

            const innerConfig = getInnerConfig(this.configuration)
            logger.info("Calling processor with configuration:")
            logger.info(innerConfig)
            const cancellationToken = await this.mainApi!.processInject2(innerConfig, actionsPayload)

            this.correlationId = cancellationToken
        }
    }

    getDashboards = async (injectInfo: InjectInfo) => {
        const result: [SalesforceDashboard] = await this.mainApi!.getAppDashboards(injectInfo.app_name, injectInfo)
        return result
    }

    getDashboard = async (dashboard: SalesforceDashboard, injectInfo: InjectInfo) => {
        await this.mainApi!.getAppDashboard(dashboard.id, injectInfo)
    }

    getPackageVersions = async () => {
        if (!this.mainApi) return
        let result = await this.mainApi!.getPackageVersions()
        this.setLatestPackageVersion(result['latest'])
        this.setLegacyPackageVersion(result['legacy'])
    }

    getInstalledPackageVersions = async (injectInfo: InjectInfo) => {
        let result = await this.mainApi!.getInstalledPackageVersion(injectInfo)
        this.setPackageVersion(result)
    }

    backupDashboard = async (dashboard: SalesforceDashboard, injectInfo: InjectInfo) => {
        await this.mainApi!.backupAppDashboard(dashboard.id, injectInfo, this.currentOrgOutputFolder!)
    }

    restoreDashboard = async (dashboard: SalesforceDashboard, injectInfo: InjectInfo, backupFilePath: string) => {
        await this.mainApi!.restoreAppDashboard(dashboard.name, injectInfo, backupFilePath)
    }

    testConnection = async (injectInfo: InjectInfo) => {
        return await this.mainApi!.testConnection(injectInfo)
    }

    getAvailableApps = async (injectInfo: InjectInfo,) => {
        return await this.mainApi!.getAvailableApps(injectInfo) as SalesforceApp[]
    }

    @computed get CsvFiles() {
        return this.SortedFiles && this.SortedFiles.filter(file => !file.url.toLowerCase().includes('/partials/') && !file.url.toLowerCase().includes('/temp/') && file.name.toLowerCase().endsWith('.csv'))
    }

    @computed get PartialCsvFiles() {
        return this.SortedFiles && this.SortedFiles.filter(file => file.url.toLowerCase().includes('/partials/') && !file.url.toLowerCase().includes('/temp/') && file.name.toLowerCase().endsWith('.csv'))
    }

    @computed get ConfigFiles() {
        return this.SortedFiles && this.SortedFiles.filter(file => file.name.toLowerCase().endsWith('.json') && file.name.includes("config"))
    }

    @computed get MetadataFile() {
        return this.SortedFiles && this.SortedFiles.find(file => file.name.toLowerCase().endsWith('.json') && file.name.includes("metadata"))
    }

    @computed get BackupFiles() {
        return this.SortedFiles && this.SortedFiles.filter(file => file.name.toLowerCase().endsWith('.backup'))
    }


    @computed get SortedFiles() {
        return this.nonOutputExistingFiles && this.nonOutputExistingFiles.slice().sort((a: any, b: any) => Date.parse(b.last_modified) - Date.parse(a.last_modified))
    }

    @computed get outputFile() {
        return this.allExistingFiles && this.allExistingFiles.find(item => this.currentOrgOutputFolder && item.url.toLowerCase() === this.currentOrgOutputFolder.toLowerCase() + '/output.zip')
    }

    @computed get digestFile() {
        return this.allExistingFiles && this.allExistingFiles.find(item => this.currentOrgOutputFolder && item.url.toLowerCase() === this.currentOrgOutputFolder.toLowerCase() + '/digest.xlsx')
    }

    @computed get viewsFile() {
        return this.allExistingFiles && this.allExistingFiles.find(item => this.currentOrgOutputFolder && item.url.toLowerCase() === this.currentOrgOutputFolder.toLowerCase() + '/views.json')
    }

    @computed get digestCustomersFile() {
        return this.allExistingFiles && this.allExistingFiles.find(item => this.currentOrgOutputFolder && item.url.toLowerCase() === this.currentOrgOutputFolder.toLowerCase() + '/digest_customers.json')
    }

    @computed get outputValidationFile() {
        return this.allExistingFiles && this.allExistingFiles.find(item => this.currentOrgOutputFolder && item.url.toLowerCase() === this.currentOrgOutputFolder.toLowerCase() + '/validations.zip')
    }

    @computed get outputNlpFile() {
        return this.allExistingFiles && this.allExistingFiles.find(item => this.currentOrgOutputFolder && item.url.toLowerCase() === this.currentOrgOutputFolder.toLowerCase() + '/nlp.zip')
    }

    @computed get currentCsvFolder() {
        if (!this.selectedCsvFile) return undefined

        const splitUrl = this.selectedCsvFile.url.split('/')
        splitUrl.pop()
        return splitUrl.join('/')
    }

    @action resetSessionMessages = async (session_id: string) => {
        let result = await this.mainApi!.getSessionMessages(session_id)
        const keys: string[] = []
        const messages: any[] = []
        const reversed = result.reverse()
        reversed.every((item: any) => {
            const key = `${item['topic']}_${item['type']}_${item['subject']}`
            if (!(key in keys)){
                    keys.push(key)
                    messages.push(item)
            }
            //break loop if last process initiation reached
            return !(item['subject'] === 'Complete Process' && item['topic'] === 'overall_progress' && item['progress'] === 0)
        })
        messages.reverse().forEach((item: any) => {
            this.onProcessUpdate(JSON.stringify(item))
        })
    }

    onProcessUpdate = (data: any) => {
        const pushItem = JSON.parse(data) as PushMessage
        if (pushItem.topic === 'process_progress') {
            this.handleProgressNotification(pushItem)
        }
        else if (pushItem.topic === 'inject_progress') {
            this.handleInjectNotification(pushItem)
        }
        else if (pushItem.topic === 'process_message') {
            this.handleProgressFailNotification(pushItem)
        }
        else if (pushItem.topic === 'injection_failed') {
            this.handleInjectFailNotification(pushItem)
        }
        else if (pushItem.topic === 'create_app_message') {
            this.handleCreateAppNotification(pushItem)
        }
        else if (pushItem.topic === 'app_progress') {
            this.handleAppProgressNotification(pushItem)
        }
        else if (pushItem.topic === 'patch_progress') {
            this.handlePatchProgressNotification(pushItem)
        }
        else if (pushItem.topic === 'overall_progress') {
            this.handleOverallProgressNotification(pushItem)
        }
        else if (pushItem.topic === 'cancel_message') {
            this.handleCancelNotification(pushItem)
        }
        else if (pushItem.topic === 'test_progress') {
            this.handleTestProgressNotification(pushItem)
        }
        else if (pushItem.topic === 'partial_merge_progress') {
            this.handlePartialMergeProgressNotification(pushItem)
        }
        else if (pushItem.topic === 'validation') {
            this.handleValidationMessagesNotification(pushItem)
        }

    }

    @action
    private handleInjectNotification(pushItem: PushMessage) {
        const progressPushItem = pushItem as ProgressPushMessage
        if (this.injectProgress.filter(item => item.subject === progressPushItem.subject).length === 0)
            this.injectProgress.push(progressPushItem)
        else {
            const item = this.injectProgress.filter(item => item.subject === progressPushItem.subject)[0]
            if (!item.progress || item.progress < progressPushItem.progress)
                item.progress = progressPushItem.progress
        }
    }

    @action
    private clearAllProgress = () => {
        this.processProgress = []
        this.injectProgress = []
        this.appProgress = []
        this.patchProgress = []
        this.overallProgress = []
        this.cancelProgress = []
        this.partialMergeProgress = []
        this.createAppProgress = undefined
        this.validationProgress = undefined
        this.correlationId = undefined
    }

    @action
    private handleProgressNotification(pushItem: PushMessage) {
        const progressPushItem = pushItem as ProgressPushMessage
        if (this.processProgress.filter(item => item.subject === progressPushItem.subject).length === 0)
            this.processProgress.push(progressPushItem)
        else {
            const item = this.processProgress.filter(item => item.subject === progressPushItem.subject)[0]
            if (!item.progress || item.progress < progressPushItem.progress)
                item.progress = progressPushItem.progress
        }

        // when finished processing re-fetch all files
        if (progressPushItem.subject === 'Complete Process' && progressPushItem.progress === 100) {
            this.fetchExistingFiles()
        }
    }
        
    private handleValidationMessagesNotification(pushItem: PushMessage) {
        const validationItem = pushItem as ValidationPushMessage
        if (instanceOfValidationPushMessage(validationItem)){
            this.setValidationProgress(validationItem)
        }
    }

    @action
    public setValidationProgress(validationMessage?: ValidationPushMessage) {
        this.validationProgress = validationMessage
    }

    @action
    public setValidationProgressMessages(validationMessages: ValidationMessage[] = []) {
        if(this.validationProgress) this.validationProgress.messages = validationMessages;
    }

    @action
    private handleAppProgressNotification(pushItem: PushMessage) {
        const progressPushItem = pushItem as ProgressPushMessage
        if (this.appProgress.filter(item => item.subject === progressPushItem.subject).length === 0)
            this.appProgress.push(progressPushItem)
        else {
            const item = this.appProgress.filter(item => item.subject === progressPushItem.subject)[0]
            if (!item.progress || item.progress < progressPushItem.progress)
                item.progress = progressPushItem.progress
        }

        // when finished processing re-fetch all files
        if (progressPushItem.subject === 'Complete Process' && progressPushItem.progress === 100) {
            // this.getAvailableApps()
        }
    }

    @action
    private handlePatchProgressNotification(pushItem: PushMessage) {
        const progressPushItem = pushItem as ProgressPushMessage
        if (this.patchProgress.filter(item => item.subject === progressPushItem.subject).length === 0)
            this.patchProgress.push(progressPushItem)
        else {
            const item = this.patchProgress.filter(item => item.subject === progressPushItem.subject)[0]
            if (!item.progress || item.progress < progressPushItem.progress)
                item.progress = progressPushItem.progress
        }

        // when finished processing re-fetch all files
        if (progressPushItem.subject === 'Complete Process' && progressPushItem.progress === 100) {
            // this.getAvailableApps()
        }
    }

    @action
    private handlePartialMergeProgressNotification(pushItem: PushMessage) {
        const progressPushItem = pushItem as ProgressPushMessage
        console.log("partial progress is " + JSON.stringify(progressPushItem))
        if (this.partialMergeProgress.filter(item => item.subject === progressPushItem.subject).length === 0)
            this.partialMergeProgress.push(progressPushItem)
        else {
            const item = this.partialMergeProgress.filter(item => item.subject === progressPushItem.subject)[0]
            if (!item.progress || item.progress < progressPushItem.progress)
                item.progress = progressPushItem.progress
                item.message = progressPushItem.message
        }
    }

    @action
    private handleTestProgressNotification(pushItem: PushMessage) {
        this.testProgress.push(pushItem as ProgressPushMessage)
    }

    @action
    private handleOverallProgressNotification(pushItem: PushMessage) {
        const progressPushItem = pushItem as ProgressPushMessage
        if (this.overallProgress.filter(item => item.subject === progressPushItem.subject).length === 0)
            this.overallProgress.push(progressPushItem)
        else {
            const item = this.overallProgress.filter(item => item.subject === progressPushItem.subject)[0]
            if (!item.progress || item.progress < progressPushItem.progress)
                item.progress = progressPushItem.progress
        }
    }

    @action
    private handleCancelNotification(pushItem: PushMessage) {
        const cancelMessage = pushItem as ProgressPushMessage
        this.cancelProgress = this.cancelProgress.filter(item => item.subject !== cancelMessage.subject)
        this.cancelProgress.push(cancelMessage)
    }

    @action
    private handleProgressFailNotification(pushItem: PushMessage) {
        const progressPushItem = pushItem as ProgressFailMessage
        if (this.processProgress.filter(item => item.subject === progressPushItem.subject).length === 0) {
            //this.processProgress.push(progressPushItem)
        }
        else {
            const item = this.processProgress.filter(item => item.subject === progressPushItem.subject)[0]
            item.message = progressPushItem.message
            item.state = progressPushItem.state === 'info' ? 'success' : progressPushItem.state === 'warning' ? 'warn' : 'fail'
        }
    }

    @action
    private handleInjectFailNotification(pushItem: PushMessage) {
        const failurePushItem = pushItem as InjectFailMessage;
        const progressPushItem: ProgressPushMessage = {
            ...failurePushItem,
            progress: 100,
            state: 'fail',
        }
        this.injectProgress.push(progressPushItem)
    }

    private handleCreateAppNotification(pushItem: PushMessage) {
        this.setCreateAppProgress(pushItem as CreateAppMessage)
    }

    @action
    public setCreateAppProgress(appMessage?: CreateAppMessage) {
        this.createAppProgress = appMessage
    }

    @action
    private setPackageVersion(version: string) {
        this.PackageVersion = version
    }

    @action
    private setLatestPackageVersion(version: string) {
        this.LatestPackageVersion = version
    }

    @action
    private setLegacyPackageVersion(version: string) {
        this.LegacyPackageVersion = version
    }

    @computed
    public get installPackageAvailable() {
        return this.LatestPackageVersion && (this.PackageVersion === undefined || +this.PackageVersion < +this.LatestPackageVersion)
    }

    @action
    updateConfigItem = (item: ConfigItem, value: any) => {
         item.value = value
    }

    public testTemplate = async (info: TestInfo) => {
        this.testProgress = []
        this.mainApi!.testTemplate(info)
    }

    @action
    setIndustries = (industries?: string[]) => {
        this.orgDefs.industries = industries
    }
    
    @action
    setOrgType = (orgType?: string) => {
        this.orgDefs.org_type = orgType
    }

    @action 
    private setSfToken = (token: RefreshableSFToken | undefined) => this.user!.sfToken = token

    @action
    public getSFToken = async (code: SFAuthCode) => {
        const result = await this.mainApi!.getSFToken(code)
        if (result) {
            const newToken = result as RefreshableSFToken
            const args: TokenRefreshArgs = {
                expiresInMs: 86100000, //24hrs - 5min
                refreshFunc: () => this.refreshSFToken(newToken),
                isSuccessful: () => this.SFToken !== undefined
            }
            newToken.refresh_service = new TokenRefreshService(args)
            this.setSfToken(newToken)
        }
    }

    @action
    public refreshSFToken = async (token: SFToken) => {
        const result = await this.mainApi!.refreshSFToken(token)
        if (result) {
            const refreshedToken = result as RefreshableSFToken
            refreshedToken.refresh_service = this.user!.sfToken!.refresh_service
            this.setSfToken(refreshedToken)
        }
        else this.logoutSF()
    }

    @action
    public logoutSF = () => {
        if (this.user) {
            this.user.sfToken?.refresh_service?.stop()
            this.setSfToken(undefined)
        }
    }

    @computed
    public get SFToken() { return this.user?.sfToken}

    private setNlpCustomerConfig(items: NlpCustomer[]) {
        if (this.configuration) {
            const index = this.configuration.findIndex(item => item.name === 'nlp_customer')
            if (index !== -1) {
                this.configuration[index].options = items.slice().sort((a, b) => a.customer_name.localeCompare(b.customer_name));
                this.configuration[index].value = this.configuration[index].value ?
                    items.find(i => i.customer_name === this.configuration![index].value.customer_name) :
                    this.configuration[index].options![0]
            }
        }
    }

    private setNlpHeaderConfigs(items: string[]) {        
        if (this.configuration) {
            ['observation_columns', 'solution_columns', 'partdescription_columns'].forEach(element => {
                const index = this.configuration!.findIndex(item => item.name === element)
                if (index !== -1) {
                    this.configuration![index].options = items.slice().sort((a, b) => a.localeCompare(b));
                } 
            });
        }
    }

    private fetchNlpCustomers = async () => {
        this.mainApi!.getNlpCustomers().then((items) => this.setNlpCustomers(items))
    }

    @action
    setNlpCustomers = (items: NlpCustomer[]) => {
        this.nlpCustomers = items
    }
    public processDigest = async (outputFolder: string) => {
        this.clearAllProgress()
        await this.mainApi!.processDigest(outputFolder)
    }

    public setViews = async (generateInfo: GenerateLinkInfo, outputFolder: string) => {
        this.clearAllProgress()
        await this.mainApi!.setViews(generateInfo, outputFolder)
    }
    
    public setLastDigestResult = (result: DigestProperties | undefined) => {
        this.lastDigestResult = result
    }

    public getLastDigest = async (companyId: string) => {
        const result = await this.mainApi!.getLastDigest(companyId)
        this.setLastDigestResult(result as DigestProperties)
    }

    public getDigestMD = () => {
        return this.organizationMetadata?.digest
    }

    public updateDigestMD = async (digestMD: DigestMD) => {
        // call fetchExistingFiles to update store with new MD
        await this.mainApi!.updateDigestMD(digestMD, this.currentOrg!).then(()=>this.fetchExistingFiles())
    }

    public updateHubspotProps = async (rowsData: any, dashboard_id: string) => {
        const info: GenerateLinkInfo  = {dashboard_id: dashboard_id, 
            views: [],
            ...this.user!.sfToken!}
        return await this.mainApi!.updateHubspotProps(rowsData,
            this.organizationMetadata?.digest?.hubspot_company_id!,
            info)
    }

    public getDigestTemplates = async () => {
        const result = await this.mainApi!.getDigestTemplates()
        const json = await result.json()
        this.setDigestTemplates(json)
    }

    public setDigestTemplates = (result: any) => {
        this.digestTemplates = result
    }

    public addNewSentence = async (model: string, cause: string, subCause: string) => {
        const result = await this.mainApi!.addNewSentence(model, cause, subCause)
        const json = await result.json()
        if(json && !json.error)
            this.setDigestTemplates(json.data)
    }

    public deleteSentence = async (model: string, cause: string, subCause: string, index: number) => {
        const result = await this.mainApi!.deleteSentence(model, cause, subCause, index)
        const json = await result.json()
        if(json && !json.error)
            this.setDigestTemplates(json.data)
    }

    public updateSentence = async (model: string, cause: string, subCause: string, index: number, newText: string) => {
        const result = await this.mainApi!.updateSentence(model, cause, subCause, index, newText)
        const json = await result.json()
        if(json && !json.error)
            this.setDigestTemplates(json.data)
    }

    public addNewSubCause = async (model: string, cause: string) => {
        const result = await this.mainApi!.addNewSubCause(model, cause)
        const json = await result.json()
        if(json && !json.error)
            this.setDigestTemplates(json.data)
    }

    public updateSubCause = async (model: string, cause: string, subCause: string, newSubCause: string) => {
        const result = await this.mainApi!.updateSubCause(model, cause, subCause, newSubCause)
        const json = await result.json()
        if(json && !json.error)
            this.setDigestTemplates(json.data)
    }

    public deleteSubCause = async (model: string, cause: string, subCause: string) => {
        const result = await this.mainApi!.deleteSubCause(model, cause, subCause)
        const json = await result.json()
        if(json && !json.error)
            this.setDigestTemplates(json.data)
    }

    public addNewCause = async (model: string) => {
        const result = await this.mainApi!.addNewCause(model)
        const json = await result.json()
        if(json && !json.error)
            this.setDigestTemplates(json.data)
    }

    public updateCause = async (model: string, cause: string, newCause: string) => {
        const result = await this.mainApi!.updateCause(model, cause, newCause)
        const json = await result.json()
        if(json && !json.error)
            this.setDigestTemplates(json.data)
    }

    public deleteCause = async (model: string, cause: string) => {
        const result = await this.mainApi!.deleteCause(model, cause)
        const json = await result.json()
        if(json && !json.error)
            this.setDigestTemplates(json.data)
    }

    public getSentencesByRow = async (rowData: any) => {
        const result = await this.mainApi!.getSentencesByRow(rowData)
        const json = await result.json()
        // if(!json || json.error) return null
        return json
    }

    public finalizeOutput = async (outputFolder: string) => {
        this.clearAllProgress()
        await this.mainApi!.finalizeOutput(outputFolder)
    }

    getSalesforceUsers = async (token: SFToken) => {
        return await this.mainApi!.getSalesforceUsers(token)
    }

    setSalesforceUsers = async (token: SFToken, users: SalesforceUser[]) => {
        return await this.mainApi!.setSalesforceUsers(token, users)
    }

    getSettings = async () => {
        if (!this.settings) this.settings = await Settings.getSettings()
        return this.settings!
    }
}

