import { LDClient } from 'launchdarkly-js-client-sdk'
import LaunchDarkly from 'launchdarkly-js-client-sdk'

class FeatureFlagsService {
    private client?: LDClient

    getClient = () => {
        return this.client!
    }

    getAllFlags = () => {
        return this.client?.allFlags()
    }

    public async init(sdkClient: string) {
        this.client = LaunchDarkly.initialize(sdkClient, { key: 'unknown user', email: 'unknown user' })
        await this.client.waitForInitialization()
    }
}

export default new FeatureFlagsService()