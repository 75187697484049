import { observer } from 'mobx-react-lite';
import { MainStore } from '../../stores/MainStore';
import {Grid, Button, Input, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { SelectionItem } from '../controls/Autocomplete';
import { IngestPayload, IngestFile, IngestModel } from './models';
import { CopilotTab, CopilotTabs } from './components/CopilotTabs';
import { CopilotInputItems, InputItem } from './components/CopilotInputItems';
import { activeIngestStatus, regions } from './types';
import { BulkProgressTab } from './BulkProgressTab';
import { useOktaAuth } from '@okta/okta-react';
import { loadCSV } from '../../CSVUtils';

let timer: NodeJS.Timeout

type CopilotSupportProps = {
    mainStore: MainStore
}

export const CopilotSupport = observer(({ mainStore }: CopilotSupportProps) => {
    const {
        user_email,
        customers, 
        models,
        selectedCustomer: customer,
        tenantConfig,
        modelsLoading,
        setSelectedCustomer,
        copilotIngest,
        resetSelections,
        setSelectedRegion,
        getIngestFiles,
        region
    } = mainStore.copilotStore!

    const {
        initCopilotStore
    } = mainStore

    const {oktaAuth} = useOktaAuth()
    const [selectedModelIds, setSelectedModelIds] = React.useState<string[]>([]);
    const [idsToLoad, setIdsToLoad] = React.useState<string[]>([]);
    const [ingestModels, setIngestModels] = React.useState<IngestModel[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false)
    const [loadingDelay, setLoadingDelay] = React.useState<boolean>(false)
    const [launchingIngest, setLaunchingIngest] = React.useState<boolean>(false)
    const [refreshCoolDown, setRefreshCoolDown] = React.useState<boolean>(false)

    const ingestModelsRef = React.useRef(ingestModels)
    useEffect(() => {
        ingestModelsRef.current = ingestModels
    }, [ingestModels])

    useEffect(() => {
        setSelectedModelIds([])
        setIdsToLoad([])
        setIngestModels([])
    }, [customer])

    useEffect(() => {
        if (idsToLoad.length === 0) {
            setLoading(false)
            return
        }
        setLoading(true)
        const countToProcess = Math.min(20, idsToLoad.length);
        const idsToProcess = idsToLoad.slice(0, countToProcess);
        const modelsToLoad = ingestModels.filter(m => idsToProcess.includes(m.model_id));
        const update = async () => {
            await updateStatus(modelsToLoad)
            setIdsToLoad(idsToLoad.slice(countToProcess))
        }
        update()
    } , [idsToLoad])

    const updateStatus = async (models?: IngestModel[]) => {
        if (models === undefined) models = ingestModels
        for (const model of models) {
            const files = await getIngestFiles(customer!.name, model.model_name)
            model.total_files = files.length
            model.pending = files.filter((f: IngestFile) => f.status === 'Uploaded').length
            model.failed = files.filter((f: IngestFile) => f.status === 'Failed').length
            model.processing = files.filter((f: IngestFile) => activeIngestStatus.includes(f.status)).length
            model.ingested = files.filter((f: IngestFile) => f.status === 'Done').length

            if (model.total_files === 0) model.status = 'Waiting'
            else if (model.processing) model.status = 'Running'
            else model.status = 'Done'

            setIngestModels(ingestModelsRef.current.map(m => {
                if (m.model_id === model.model_id) return model
                return m
            }))
    }
    }

    const onIngest = async () => {
        setLaunchingIngest(true)
        const localModels: IngestModel[] = [...ingestModels]
        for (const model of localModels) {
            if (model.pending || model.failed) {
                const ingest_payload: IngestPayload = {
                    document_names: [],
                    recreate: false,
                    requested_by_email: user_email,
                }
                await copilotIngest(customer!.name, model.model_name, ingest_payload)
                await updateStatus([model])
            }
        }
        setLaunchingIngest(false)
    }

    const onRefresh = async () => {
        setRefreshCoolDown(true)
        await updateStatus(ingestModels)
        setTimeout(() => {
            setRefreshCoolDown(false)
        }, 2000)
    }

    const handleSelectModelsChange = (selections: string[]) => {
        setLoadingDelay(true)
        clearTimeout(timer);

        const newSelections = (selections.filter(s => !selectedModelIds.includes(s)))
        setSelectedModelIds(selections)
        const localModels = ingestModels.filter(s => selections.includes(s.model_id))
        for (const model_id of newSelections) {
            localModels.push({model_id: model_id, 
                              model_name: models.find(m => m.id === model_id)?.model_name || '',
                              status: 'Loading'})
        }
        setIngestModels(localModels)     

        timer = setTimeout(() => {
            setIdsToLoad(ingestModels.filter(im => im.status === 'Loading').map(im => im.model_id).concat(newSelections))
            setLoadingDelay(false)
        }, 2000)
    }

    const model_disabled = loading || launchingIngest || customer?.name === undefined || modelsLoading

    const modelLabelWithCSV = () => {
        const handleFileChange = (event: any) => {
            const file = event.target.files[0];
            loadCSV(file, processCSV)
            event.target.value = ''
          };
        
          const processCSV = (rows: string[][]) => {
            const selectedModelIds = []
            for (const row of rows) {
                const model = models.find(m => m.model_name === row[0])
                if (model) selectedModelIds.push(model.id)
            }
            if (selectedModelIds.length > 0) handleSelectModelsChange(selectedModelIds)
          };
      
        return (<div style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between'}}>
            <Typography style={{color: model_disabled ? '#0000004d' : 'black'}}>Model</Typography>
            <Input
                disabled={model_disabled}
                type="file"
                onChange={handleFileChange}
                inputProps={{
                accept: ".csv",
                style: {
                    display: 'none',
                },
                }}
                id="csv-input"
            />
            <label htmlFor="csv-input">
                <Button variant="outlined" component="span" disabled={model_disabled} style={{height: '20px', fontSize: '12px'}}>
                    Select w. CSV
                </Button>
          </label>
        </div> as unknown as string);

    }

    const inputItems: InputItem[] = [
        {
            type: 'selection',
            label: 'Region',
            onChange: (item?: any) => {
                setSelectedRegion(item)
                resetSelections()
                initCopilotStore(oktaAuth.getAccessToken, user_email)
            },
            items: regions,
            isReady: () => true,
            showLoading: () => true,
            selectedItem: region,
            disabled: loading || launchingIngest
        },
        {
            type: 'select',
            label: 'Customer',
            onChange: (item?: SelectionItem) => setSelectedCustomer(item?.value),
            items: customers.slice().sort((c1, c2) => c1.name.localeCompare(c2.name)).map(tc => {return {value: tc.id, label: tc.name}}),
            selectedItem: customer ? {value: customer.id, label: customer.name} : undefined,
            isReady: () => customers?.length > 0,
            showLoading: () => true,
            disabled: loading || launchingIngest
        },
        {
            type: 'multiselect',
            label: modelLabelWithCSV(),
            onChange: (items: SelectionItem[]) => handleSelectModelsChange(items.map(i => i.value)),
            items: models.map(tc => {return {value: tc.id, label: tc.model_name}}),
            isReady: () => models.length > 0,
            showLoading: () => customer?.name !== undefined && modelsLoading,
            selectedItem: models.filter(m => selectedModelIds.includes(m.id)).map(tc => {return {value: tc.id, label: tc.model_name}}),
            disabled: model_disabled
        }
    ]

    const tabs: CopilotTab[]  = [
        {label: 'Progress', 
        component: <BulkProgressTab ingestModels={ingestModels} loading={false}/>
        }
    ]

    return (<>
        <Grid container spacing={6} style={{ flex:1, padding: 10}}>
            <Grid item xs={6} style={{ height: '100%', overflow: 'auto'}}>
                <Stack direction={'row'}>
                    <CopilotInputItems inputItems={inputItems}/>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <CopilotTabs tabs={tabs}/>
                <Stack direction="row" gap={1} justifyContent='flex-end' style={{ paddingRight: 10}}>
                    <Button variant='outlined'
                        style={{ width: '20%' }}
                        onClick={onRefresh}
                        disabled={loading || loadingDelay || launchingIngest || selectedModelIds.length === 0 || !tenantConfig || refreshCoolDown}>
                        Refresh
                    </Button>
                    <Button variant='outlined'
                        style={{ width: '20%' }}
                        onClick={onIngest}
                        disabled={loading || loadingDelay || launchingIngest || selectedModelIds.length === 0 || !tenantConfig}>
                        {loading || loadingDelay ? 'Loading': launchingIngest ? 'Launching Ingest' : 'Ingest'}
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    </>)
})