import { createMuiTheme } from "@material-ui/core";



export const sidTheme = createMuiTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#57D89D',
        dark: '#002884',
        contrastText: '#4A4A4A',
      },
      secondary: {
        light: '#ff7961',
        main: '#023342',
        dark: '#4A4A4A',
        contrastText: '#000',
      },
      background: {
        default: '#F1F2F4'
      },
    },
    typography: {
      fontFamily: 'Noto Sans Display',    
      h1: {
        fontSize: 52,
        fontWeight: 300,
        lineHeight: '64px',
        letterSpacing: 0.2
      },
      h3: {
        fontSize: 30,
        fontWeight: 300,
        lineHeight: '42px',
        letterSpacing: 0.1
      },
      h6: {
        fontSize: 20,
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: 0.2
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: 0.1
      },
      subtitle2: {
        fontSize: 16,
        fontWeight: 300,
        lineHeight: '22px',
        letterSpacing: 0.1
      },
      body1: {
        fontSize: 16,
        fontWeight: 300,
        lineHeight: '20px',
      },
      body2: {
        fontSize: 13,
        fontWeight: 300,
        lineHeight: '19px',
        letterSpacing: 0.1
      },
    }
  })