import { Checkbox, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react'
import { MainStore } from '../../stores/MainStore';
import { GeneralDialog } from '../controls/GeneralDialog';
import ProgressBar from '../controls/ProgressBar';
import {OrganizationSelection} from './OrganizationSelection';


type CopyDialogProps = {
    sourcePath: string,
    isOpen: boolean,
    store: MainStore,
    toggleOpen: Function
}

const TEST_ORGS = ['ANNA_TEST','SI Indresh Test','Test_Meir','Test_Meir_2','Test_QA','Test_Tal','Test_Zivan',
                   'Test_Idan','test_asaf','test_dekel','test_hagay','test_p_rename','test_renana','test_sanity',
                   'test_sanity_oded','yaakovs_test','Test_Liron','Test_Lena','test_noa']

const CsvCopyDialog = observer((props: CopyDialogProps) => {
    
    const [copyDialogData,setCopyDialogData] = useState<{
        sourcePath: string, destinationOrg: string, isOpen: boolean, includeOutput: boolean, isLoading: boolean, completed: number, isFailed: boolean, message: string
    }>(
        {
            sourcePath: props.sourcePath, destinationOrg: '', isOpen: props.isOpen, includeOutput: false, isLoading: false, completed: 0, isFailed: false, message: ''
        }
    )

    const handleCloseDialog = () => {
        setCopyDialogData({ isOpen:false, sourcePath:'', destinationOrg: '', includeOutput: false, isLoading: false, completed: 0, isFailed: false, message: ''})
        props.toggleOpen()
    }

    const store = props.store
    
    useEffect(()=>{
        setCopyDialogData({...copyDialogData, sourcePath: props.sourcePath, isOpen: props.isOpen})
    },[props])

  return (
    <GeneralDialog 
        title={`Copying ${copyDialogData.sourcePath}`}
        text='Select a customer to copy CSV & json to'
        applyText={copyDialogData.completed === 100 || copyDialogData.isFailed ? 'Close' : 'Copy'}
        open={copyDialogData.isOpen}
        onClose={() => handleCloseDialog()}
        onSave={async () => {
            // if copy progress is finished - close dialog
            if(copyDialogData.completed === 100 || copyDialogData.isFailed){
                handleCloseDialog()
                return;
            }
            // if copy progress not started already and a destination was chosen - start copy
            if(copyDialogData.sourcePath && copyDialogData.destinationOrg && copyDialogData.isLoading === false){
                setCopyDialogData({...copyDialogData, isLoading: true, completed: 0, message: 'Copy in progress...'})
                const copyResult = await store.copyCustomerFolder(copyDialogData.sourcePath,copyDialogData.destinationOrg,copyDialogData.includeOutput)

                if(copyResult.destination_path){
                    setCopyDialogData({...copyDialogData, isLoading: true, completed: 100, message: 'Done! Created a copy at: ' + copyResult.destination_path})
                } else {
                    setCopyDialogData({...copyDialogData, isLoading: true, completed: 0, isFailed: true, message: 'Copy failed! ' + copyResult?.error})
                }
            }
        }}
        saveDisabled={!copyDialogData.isFailed && copyDialogData.isLoading && copyDialogData.completed < 100}
    >
        <FormGroup>
            <OrganizationSelection
                organizations={store.organizations.filter(org=>TEST_ORGS.includes(org.name))} 
                onSelection={(value)=>{
                    setCopyDialogData({
                        ...copyDialogData,destinationOrg: value || ''
                    })
                    return true;
                }} 
            />
            <FormControlLabel
                control={<Checkbox
                    checked={copyDialogData.includeOutput}
                    onChange={(event: any) => setCopyDialogData({...copyDialogData,includeOutput: event.target.checked})}
                    name="includeOuput"
                    color="primary"
                />}
                label="Include Output folder"
                />
            {(copyDialogData.completed === 100 || copyDialogData.isFailed || (copyDialogData.isLoading && copyDialogData.message) ) &&
                <FormLabel style={{color: copyDialogData.isFailed ? 'red' : '#57D89D'}}>
                    {copyDialogData.message}
                </FormLabel>}
            {copyDialogData.isLoading &&<div style={{ marginTop: 20 }}>
                <ProgressBar
                fillColor='#57D89D'
                completed={copyDialogData.completed} />
            </div>}
        </FormGroup>
    </GeneralDialog>
  )
})

export default CsvCopyDialog