import { observer } from 'mobx-react-lite';
import { MainStore } from '../../stores/MainStore';
import {Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { CopilotSetup } from './CopilotSetup';
import { CopilotSupport } from './CopilotSupport';

const groupsWithSupportAccess = ['Technical Success', 'Aquant QA']

type CopilotMainProps = {
    mainStore: MainStore
}

export const CopilotMain = observer(({ mainStore }: CopilotMainProps) => {
    const { authState, oktaAuth } = useOktaAuth();
    const [enableSupport, setEnableSupport] = React.useState<boolean>(false);
    const [supportPageOpen, setSupportPageOpen] = React.useState<boolean>(false);
    
    useEffect(() => {
        if (authState!.isAuthenticated) {
            oktaAuth.getUser().then(user => {
                const hasSupportAccess = groupsWithSupportAccess.some(group => (user.groups as [string]).includes(group))
                setEnableSupport(hasSupportAccess)
        })}
    }, [authState, oktaAuth]);

    useEffect(() => {
        const {setSelectedModel, setSelectedProduct, setSelectedManufacturer} = mainStore.copilotStore!
        setSelectedModel(undefined)
        setSelectedProduct(undefined)
        setSelectedManufacturer(undefined)
    }, [supportPageOpen])

    return (
        <div style={{ height: 'calc(100vh - 64px)', overflow: 'hidden', display: 'flex', flexDirection: 'row' }}>
            {enableSupport && <Button variant='contained' style={{ width: '120px', position: 'absolute', top: 75, right: 10, zIndex: 100}} 
                                         onClick={() => setSupportPageOpen(!supportPageOpen)}>
                                    {supportPageOpen ? 'Main' : 'Support'}
                                </Button>}
            {supportPageOpen ? <CopilotSupport mainStore={mainStore}/> : <CopilotSetup mainStore={mainStore}/>}
        </div>)
})