import logger from "../Logger";

export default class NotificationService {

    pushNotificationsWebSocketEndpoint: string
    ws?: WebSocket = undefined
    updateFunc: (data: any) => void
    token: () => string | undefined
    session_id: string
    slack_id: string

    constructor(notificationsUrl: string, onUpdate: (data: any) => void, token: () => string | undefined, session_id: string, org: string, csvFile: string) {
        this.pushNotificationsWebSocketEndpoint = notificationsUrl.replace('http', 'ws')
        this.updateFunc = onUpdate
        this.token = token
        this.slack_id = session_id
        this.session_id = `${session_id}_${org}_${csvFile}`
    }

    public async open() {
        try {
            this.ws = new WebSocket(`${this.pushNotificationsWebSocketEndpoint}/ws?session_id=${this.session_id}&slack_id=${this.slack_id}`)
            this.registerEvents(this.updateFunc)
    
            if (this.ws && !this.ws.onclose) {
                this.ws.onclose = async (event) => {
                    logger.info('connection close, trying to reopen..., ' +  event);
                    await this.open()
                }
            }   
        } catch (error) {
            logger.error('connection open failed, retrying...', error);
            await new Promise(r => setTimeout(r, 3000));
            await this.open()
        }
    }

    public async close(){
        try{
            if (this.ws && this.ws.onclose) {
                logger.info('closing connection')
                this.ws.onclose = null;
                this.ws.close();
            }
        }catch (error) {
            logger.error('connection open failed', error);
        }
    }

    private registerEvents(onUpdate: (data: any) => void) {
        if (!this.ws) return

        this.ws.onopen = (event) => {
            console.log('connection open', event);
            this.ws?.send(this.token() ?? "")
        }
        this.ws.onerror = (event) => console.error('connection error', event);
        this.ws.onmessage = (event) => onUpdate(event.data);
    }
}