import Select from '@material-ui/core/Select';
import { Box, FormControl, ClickAwayListener, Divider, List, MenuItem, makeStyles, styled, TextField, Theme, Typography } from "@material-ui/core"
import { useState } from "react"
import { TeriaryButton } from "../controls/buttons"
import BinIcon from '../../assets/bin.svg'


export interface correspondingProdType {
    matchings: {[k: string]: string}
}

const makeClasses = makeStyles((theme: Theme) => ({
    dataGrid: {
        border: 'unset',
        '& .MuiDataGrid-row:hover': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'unset',
        },
        '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: theme.palette.background.default
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'unset',
            cursor: 'text'
        },
        '& .MuiDataGrid-cell': {
            borderBottom: 'unset'
        },
        '& .MuiDataGrid-columnsContainer': {
            borderBottom: 'unset'
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-row': {
            backgroundColor: 'unset'
        }
    },
    description: {marginTop: 5, marginBottom: 10, color: theme.palette.primary.contrastText},
    tableCellContainer: {
        width: '90%', 
        height: 38, 
        padding: 5, 
        border: '1px solid #dadcdf', 
        borderRadius: 6, 
        display: 'flex', 
        alignItems: 'center'
    }
}))


const validName = (val?: string) =>  !!(val && val.length > 0)


const CleanField = styled(TextField)(theme => ({
    '& fieldset': {
        borderRadius: 7
    }
}))

const NlpProductEditor = (props: {val: any, setVal: (val: any) => void, itemsSelection?: string[]}) => {
    const {itemsSelection, setVal, val} = props
    const classes = makeClasses()

    const [editedRow, setEditedRow] = useState<{internalProdType: string, correspondingProdType: string}>({internalProdType: '', correspondingProdType: ''})

    const [showValidations, setShowValidations] = useState(false)

    const canAddRow = () => {
        if (showValidations) {
            return validName(editedRow.internalProdType)
        }
        return false
    }

    const tryAddRow = () => {
        if (canAddRow()) {
            setVal({
                matchings: {...val.matchings, [editedRow.internalProdType]: editedRow.correspondingProdType}
            })
            setShowValidations(false)
            setEditedRow({internalProdType: '', correspondingProdType: ''})
        }
    }

    return <div style={{width: '100%', display: 'flex', flexDirection: 'column', margin: 20 }} >

            {itemsSelection && <Box width='65%' >
                <Divider style={{marginBottom: 20, marginTop: 10}} />

                <Typography className={classes.description} variant='body2'  >
                    Define below customized NLP products for specific product type
                </Typography>
                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 30 }}>
                    <div style={{display: 'flex', flexDirection: 'column', marginRight: 50 }} >
                        <Typography style={{marginBottom: 15}} >Product Type</Typography>
                        <CleanField
                            variant='outlined' 
                            size='small'
                            value={editedRow.internalProdType} 
                            type='text' 
                            onChange={e => {
                                setShowValidations(true)
                                setEditedRow({...editedRow, internalProdType: e.target.value})
                            }} 
                            error={showValidations && !validName(editedRow.internalProdType)}
                            required
                            helperText={showValidations && !validName(editedRow.internalProdType) && 'Please enter product type name'}
                        />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}} >
                    <Typography style={{marginBottom: 15}} >NLP Product Type</Typography>
                        <Box boxShadow={1}>
                            <FormControl fullWidth>
                                <Select value={editedRow.correspondingProdType} style={{ minWidth: '100%', maxHeight: 40, background: '#ffffff' }}
                                    variant='outlined'
                                    defaultValue={itemsSelection![0]}
                                    onChange={(event) => setEditedRow({...editedRow, correspondingProdType: event.target.value as string})}>
                                    {itemsSelection!.map((product, index) => <MenuItem key={index} value={product}>
                                        {product}
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>

                    <TeriaryButton 
                        style={{marginLeft: 5, marginRight: 5, paddingTop: 50, maxHeight: 50}} 
                        disabled={!canAddRow()}
                        onClick={tryAddRow} 
                    >
                        Add
                    </TeriaryButton>
                </div>

                <Divider style={{marginTop: 20, marginBottom: 20}} />
                
                <List style={{width: '65'}} >
                    {Object.keys(val.matchings).map(k => <div 
                        key={k} 
                        style={{display: 'flex', width: '50%', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 5}}
                    >
                        <div style={{width: 5, height: 5, border: '5px solid black', borderRadius: '100%', marginRight: 10}} />
                        <Typography style={{marginRight: 10}} > {k} </Typography>
                        <Typography style={{marginRight: 10}} > {'>'} </Typography>
                        <Typography style={{marginRight: 10}} > {val.matchings[k]} </Typography>
                        <img 
                            src={BinIcon}
                            alt='remove-item'
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                let ts = val.matchings
                                delete ts[k]
                                setVal({...val, matchings:ts})
                            }}
                        />
                    </div> )}
                </List>
            </Box>}
        </div>
}


export default NlpProductEditor