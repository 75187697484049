import { Typography, Tooltip, makeStyles } from '@material-ui/core'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { observer } from 'mobx-react-lite';
import { MainStore } from '../../stores/MainStore'
import { SelectCsv2 } from '../wizard/SelectCsv2'

const useStyles = makeStyles((theme) => ({
    page: {
      margin: '0px 50px 20px 50px',
      flex: 1
    }
  }));

interface ISelectInputCSVProps {
    mainStore: MainStore
}

export const SelectInputCSV = observer(({mainStore}: ISelectInputCSVProps) => {
    const classes = useStyles();
    
    return (<div className={classes.page}>
              <div style={{marginBottom: '10px', display: 'flex', alignItems: 'center'}}>
                <Typography> Input File </Typography>
                <Tooltip title="Select input file currenlty injected to the dashboard" placement="right" arrow style={{marginLeft: '5px'}}>
                    <InfoOutlinedIcon color='primary'></InfoOutlinedIcon>
                </Tooltip>
                {!mainStore.outputFile && <Typography variant="body2" style={{marginLeft: '10px', color: 'red'}}>Selected input file is missing the neccessary output data!</Typography>}
              </div>
                <SelectCsv2 store={mainStore} folderName={''} disableActions disableUpload/>
            </div>)
})