import { Button, Card, CardContent, CardHeader, CircularProgress, Link, makeStyles, Paper, Tooltip, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { SalesforceDashboard } from "../../models/SalesforceDashboard";
import { MainStore } from "../../stores/MainStore";

const useStyles = makeStyles((theme) => ({
    page: {
      margin: '0px 50px 20px 50px',
    },
    outputTableRoot: {
        maxWidth: '95%',
        "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
          display: "none"
        }
      }
    
  }));

interface IHubspotIntegrationProps {
    mainStore: MainStore
    selectedDashboard?: SalesforceDashboard
}

interface IRowData {
    mainStore: MainStore,
    row: any
}

export const HubspotIntegration = observer(({mainStore, selectedDashboard}: IHubspotIntegrationProps) => {

    const classes = useStyles();    

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [customersOutput, setCustomersOutput] = useState<any>({})
    const [selectedRowsData, setSelectedRowsData] = useState<any[]>([])
    const defaultResponse = {error: false, message: ''}
    const [hubspotResponse, setHubspotResponse] = useState<{error: boolean, message: string}>(defaultResponse)
    const [hubSpotLoading, setHubSpotLoading] = useState(false)

    const handleLastDigestClick = () => {
        if(mainStore.organizationMetadata?.digest?.hubspot_company_id.length! > 0){
            setIsLoading(true)
            mainStore.getLastDigest(mainStore.organizationMetadata?.digest?.hubspot_company_id!)
        }
    }

    useEffect(() => {
      if(mainStore.lastDigestResult && isLoading){
        setIsLoading(false)
      }
    }, [isLoading, mainStore.lastDigestResult])

    const renderLastDigest = () => {
        const generalSentences = Object.entries(mainStore.lastDigestResult!).filter(([key, value])=>key.includes('general'))
        const subCauseSentences = Object.entries(mainStore.lastDigestResult!).filter(([key, value])=>key.includes('subcause'))
        const links = Object.entries(mainStore.lastDigestResult!).filter(([key, value])=>key.includes('link'))
        const customer1GeneralSentences = generalSentences.filter(([key, value])=>key.includes('customer1'))
        const customer1SubCauseSentences = subCauseSentences.filter(([key, value])=>key.includes('customer1'))
        const customer1Links = links.filter(([key, value])=>key.includes('customer1'))
        const customer2GeneralSentences = generalSentences.filter(([key, value])=>key.includes('customer2'))
        const customer2SubCauseSentences = subCauseSentences.filter(([key, value])=>key.includes('customer2'))
        const customer2Links = links.filter(([key, value])=>key.includes('customer2'))
        // const customer3GeneralSentences = generalSentences.filter(([key, value])=>key.includes('customer3'))
        // const customer3SubCauseSentences = subCauseSentences.filter(([key, value])=>key.includes('customer3'))
        // const customer3Links = links.filter(([key, value])=>key.includes('customer3'))
        const dashboardLink: any = links.filter(([key, value])=>key.includes('dashboard'))
        const customersLink: any = links.filter(([key, value])=>key.includes('customers'))

        const renderCustomerCard = (title: string, general: any, subcause: any, links: any) => <Card variant="outlined" style={{maxWidth: '40%', minWidth: '40%', marginTop: 20}}>
                    <CardContent>
                    <CardHeader title={title} />
                        <div style={{marginBottom: 5}}>
                            <Typography align='center' style={{borderBottom: '1px solid lightgray'}}>General</Typography>
                            {general.map(([key, sentence]:[any,any])=>sentence && <Tooltip title={key} placement='left' arrow>
                                    <Typography>{sentence}</Typography>
                                </Tooltip>)}
                        </div>
                        <div style={{marginBottom: 5}}>
                            <Typography align='center' style={{borderBottom: '1px solid lightgray'}}>Sub Cause</Typography>
                            {subcause.map(([key, sentence]:[any,any])=>sentence && <Tooltip title={key} placement='left' arrow>
                                    <Typography>{sentence}</Typography>
                                </Tooltip>)}
                        </div>
                        <div style={{marginBottom: 5, display: 'flex', flexDirection: 'column'}}>
                            <Typography align='center' style={{borderBottom: '1px solid lightgray'}}>Links</Typography>
                            {links.map((link: any)=>link[1] && <Link target="_blank" variant="body1" href={link[1]}>{link[0]}</Link>)}
                        </div>
                    </CardContent>
            </Card>

        return mainStore.lastDigestResult && (<div style={{display: 'flex', flexDirection:'column'}}>
            <Paper style={{marginTop: 20, padding: 20}}>

            <div>
                <Typography variant="h6"    >
                    Hubspot current values - {mainStore.currentOrg}
                </Typography>
                <Typography variant="subtitle2" style={{marginTop: 10}}>
                    Last updated: {new Date(mainStore.lastDigestResult.hs_lastmodifieddate).toLocaleDateString()}, {new Date(mainStore.lastDigestResult.hs_lastmodifieddate).toLocaleTimeString()}
                </Typography>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                {renderCustomerCard('Customer 1', customer1GeneralSentences, customer1SubCauseSentences, customer1Links)}
                {renderCustomerCard('Customer 2', customer2GeneralSentences, customer2SubCauseSentences, customer2Links)}
                {/* {renderCustomerCard('Customer 3', customer3GeneralSentences, customer3SubCauseSentences, customer3Links)} */}
            </div>
            <div style={{display: 'flex', flexDirection:'column', marginTop: 20}}>
                <Typography>Dashboard Link: <Link target="_blank" variant="h6" href={dashboardLink[0][1]}>{dashboardLink[0][0]}</Link></Typography>
                <Typography>Customers Tab Link: <Link target="_blank" variant="h6" href={customersLink[0][1]}>{customersLink[0][0]}</Link></Typography>
            </div>
            </Paper>

        </div>)
    }

    const handleGenerateOutputClick = () => {
        const get_customers_output = async () => {
            const result = await mainStore.fetchDigestCustomers()
            setCustomersOutput(result ?? [])
          }
          
          if (mainStore.digestCustomersFile) get_customers_output()
    }

    const renderCustomersOutput = (data: any) => {
        const newColumns = data.columns.map((column: any)=>{
            return {...column,
                width: 150
            }
        })

        function toString(o: any) {
            if(o !== null && typeof o === 'object'){
                Object.keys(o).forEach(k => {
                    if (typeof o[k] !== 'string') {
                    return toString(o[k]);
                    }
                    
                    o[k] = '' + o[k];
                });
                return o;
            }

            return o;
            
        }

        const newRows = data.rows.map((row: any)=> row ? toString(row): row)

        const tableData = {
            ...data,
            columns: newColumns,
            rows: newRows
        }

        return <div style={{ height: '400px', width: '100%', marginTop: 20, marginBottom: 60 }}>
            <Typography variant="body1" style={{ marginBottom: 20 }}>Select 2 customers to generate new email:</Typography>
            <DataGrid
            className={classes.outputTableRoot}
            rows={tableData.rows}
            columns={tableData.columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            disableColumnMenu
            disableColumnSelector
            disableColumnFilter
            checkboxSelection
            onSelectionModelChange={(newSelection) => {
                if(newSelection.selectionModel.length <= 2){
                    setSelectedRowsData(newSelection.selectionModel);
                }
                setHubspotResponse(defaultResponse)
              }}
            isRowSelectable={(params: any) => {
                if(selectedRowsData.length === 2 && selectedRowsData.includes(params.row.id)){
                    return true
                } else if(selectedRowsData.length === 2 && !selectedRowsData.includes(params.row.id)){
                    return false
                }
                return true
            }}
            density='compact'

            />
        </div>
    }

    const renderEmailPreview = () => {
        const selected = customersOutput.rows.filter((row: any)=>selectedRowsData.includes(row.id))
        return <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
            {selected.map((selectedRow: any)=><RowDataPreview mainStore={mainStore} row={selectedRow}/>)}
            </div>
    }
    
    const renderHubSpotUpdateBtn = () => {

        const styleProps = {
            margin: 20,
            padding: '10px 30px',
            alignSelf: 'center'
        }

        const handleHubSpotUpdateClick = async () => {
            setHubSpotLoading(true)
            const selected = customersOutput.rows.filter((row: any)=>selectedRowsData.includes(row.id))
            const response = await mainStore.updateHubspotProps(selected, selectedDashboard?.id!)
            const result = await response.json()
            
            setHubspotResponse(result)
            setHubSpotLoading(false)
        }

        return <Button
        variant='outlined'
        style={styleProps}
        disabled={hubSpotLoading || hubspotResponse.message.length > 0}
        onClick={()=>handleHubSpotUpdateClick()}>
            Send to HubSpot
            </Button>
    }

    return (<div className={classes.page} style={{width: '100%'}}>
                {/* <FormControl style={{width: '100%', marginBottom: 20}}>
                    <InputLabel id="hubspot-select-label">Please select company name from hubspot:</InputLabel>
                    <Select
                        labelId="hubspot-select-label"
                        id="hubspot-select"
                        value={selectedCompany.id ? JSON.stringify(selectedCompany) : ''}
                        label="Company"
                        onChange={handleHSCompanyChange}
                    >
                        {hubspotCompanies.map(comp => <MenuItem key={comp.id} value={JSON.stringify(comp)}>{comp.name}</MenuItem>)}
                    </Select>
                </FormControl> */}
                <Button variant='outlined' 
                    style={{ marginRight: '10px'}} 
                    onClick={() => handleLastDigestClick()}>
                    Get Current Digest Values
                </Button>
                <Button variant='outlined' 
                    onClick={() => {handleGenerateOutputClick()}}
                    disabled={false}>
                    Display Customers Attribution Output 
                </Button>
                <div style={{display: 'flex', maxWidth: '100%', flexDirection: 'column'}}>
                    {isLoading && <CircularProgress style={{margin: 20}}/>}
                    {!isLoading && mainStore.lastDigestResult && renderLastDigest()}
                    {Object.keys(customersOutput).length > 0 && renderCustomersOutput(customersOutput)}
                    {selectedRowsData.length > 0 && renderEmailPreview()}
                    {selectedRowsData.length === 2 && renderHubSpotUpdateBtn()}
                    {hubSpotLoading && <CircularProgress style={{ alignSelf:'center', margin: 10 }} />}
                    {hubspotResponse.message.length > 0 &&
                    <Typography align="center" style={{color: hubspotResponse.error ? 'red' : '#57D89D', fontWeight: 'bold'}}>{hubspotResponse.message}</Typography>}
                </div>
                <div>

                </div>
            </div>)
})

const RowDataPreview = observer(({mainStore, row}: IRowData) => {

    const [sentences, setSentences] = useState({data: {}, error: ''})
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {
        mainStore.getSentencesByRow(row).then((data)=>{
        setSentences(data)
        setIsLoading(false)
    })
    }, [row])
    
    return <Card variant="outlined" style={{maxWidth: '40%', minWidth: '40%'}}>
            <CardHeader title={row['customer_name']} subheader={new Date(row['group_date']).toLocaleString('default', { month: 'long', year: 'numeric' }) + ', Cause: ' + row['cause']} />
            {!isLoading ?
                <CardContent>
                    {!sentences || sentences.error ?
                    <div style={{color: 'red'}}>{sentences.error || 'Problem generating sentence'}</div>
                    :
                    Object.entries(sentences.data).map(([subCause, sentences]:[any,any]) =>
                    sentences && sentences.length > 0 &&
                    <div style={{marginBottom: 5}}>
                        <Typography align='center' style={{borderBottom: '1px solid lightgray'}}>{subCause}</Typography>
                        {sentences.map((sentence: any)=><Typography>{sentence}</Typography>)}
                    </div>
                    )}
                </CardContent>
            :   <div style={{width: '100%', textAlign: 'center'}}>
                    <CircularProgress style={{ margin: 10 }} />
                </div>}
        </Card>
})