import { Button, createStyles, makeStyles, Snackbar, Theme } from '@material-ui/core'
import FileSaver from 'file-saver'
import { observer } from 'mobx-react-lite'
import React, { useRef, useState } from 'react'
import { Inject } from './components/wizard/Inject'
import { getMappingConfirmProps, Mapper } from './components/wizard/Mapper'
import {OrganizationSelection} from './components/wizard/OrganizationSelection'
import { Process } from './components/wizard/Process'
import { Wizard } from './components/wizard/Wizard'
import { ConfigItem, defaultConfigItems } from './models/ConfigItem'
import { WizardItem } from './models/WizardItem'
import { MainStore } from './stores/MainStore'
import copy from 'clipboard-copy'
import { SelectCsv2 } from './components/wizard/SelectCsv2'
import { Operations } from './Operations'
import { Validate } from './components/wizard/Validate'
import { getInnerConfig } from './Utils'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        custom: {
            marginRight: theme.spacing(1)
        }
    }),
);

type HomeProps = {
    mainStore: MainStore
}

// eslint-disable-next-line no-empty-pattern
export const Home = observer(({ mainStore }: HomeProps) => {
    const { csvHeaders, organizations, currentOrg, currentCsvFolder, outputFile, configuration, outputValidationFile } = mainStore
    const [open, setOpen] = useState<boolean>(false)
    const [message, setMessage] = useState<string>("")
    const classes = useStyles()
    const clearedAssignments = useRef<ConfigItem[]>([])

    const handleClose = () => {
        setOpen(false)
        setMessage("")
    }

    const saveConfig = () => {
        if (!configuration) return

        mainStore.uploadRawConfig(configuration, currentCsvFolder || '')
        .catch(() => {
            setMessage("Configuration Save Failed!!")
        })
        .then(() => {
            setMessage("Configuration Saved!")
        })
    }

    const clearAssignment = (item: ConfigItem) => clearedAssignments.current.push(item)
    
    const cancelAssignments = () => clearedAssignments.current = []

    const saveAssignments = () => clearedAssignments.current.forEach(item => mainStore.updateConfigItem(item, 'n/a'))

    const orgDefProps = {
        orgIndustries: mainStore.orgDefs.industries,
        orgType: mainStore.orgDefs.org_type,
        metadataLoaded: () => !!(mainStore.organizationMetadata),
        updateDefs: (industries?: string[], orgType?: string) => {
            mainStore.setIndustries(industries)
            mainStore.setOrgType(orgType)
        },
    }

    const wizardSteps: WizardItem[] = [
        {
            title: 'Select Customer',
            description: 'Type/Select Customer Name',
            canMoveNext: () => currentOrg !== undefined,
            content:
                () => <OrganizationSelection 
                    organizations={organizations} 
                    onSelection={async value => await mainStore.setOrganization(value)} 
                    selectedOrg={currentOrg} 
                    orgDefsProps={mainStore.securityStore?.isAllowed('edit_organization_definitions') ?
                        orgDefProps : undefined
                    }
                />,
            onNext: mainStore.updateOrgDefinitions
        },
        {
            title: 'Select CSV', description: '', canMoveNext: () => mainStore.selectedCsvFile !== undefined && csvHeaders.length > 0, content:
                () => currentOrg ? <SelectCsv2 folderName={currentOrg} store={mainStore} /> : <React.Fragment />,
                getConfirmationProps: () => undefined,
                onNext: async () => await mainStore.connectNotificationService()
            },
        {
            title: 'Mapping', description: '', canMoveNext: () => mainStore.selectedCsvFile !== undefined, content:
                () => currentCsvFolder && csvHeaders && csvHeaders.length > 0 && configuration ?
                    <Mapper mainStore={mainStore} /> : <React.Fragment />,
            footer: () =>
                <>
                    {configuration ? <div>
                        <Button variant="contained"
                            className={classes.custom}
                            style={{ color: 'white', width: 180, height: 42 }}
                            color='secondary'
                            disableElevation onClick={() => { mainStore.setConfiguration(defaultConfigItems) }}>Reset Config</Button>
                        <Button variant="contained"
                            className={classes.custom}
                            style={{ color: 'white', width: 180, height: 42 }}
                            color='secondary'
                            disableElevation onClick={() => {
                                const innerConfig = getInnerConfig(configuration)
                                const configJson = JSON.stringify(innerConfig, null, 2)
                                copy(configJson)
                            }}>Copy to Clipboard</Button>
                        <Button variant="contained"
                            className={classes.custom}
                            style={{ color: 'white', width: 180, height: 42 }}
                            color='secondary'
                            disableElevation onClick={() => {
                                const innerConfig = getInnerConfig(configuration)
                                const configJson = JSON.stringify(innerConfig, null, 2)
                                var blob = new Blob([configJson], { type: "application/json" });
                                FileSaver.saveAs(blob, 'configuration.json')
                            }}>Download Config</Button>
                        <Button variant="contained"
                            style={{ color: 'white', width: 140, height: 42 }}
                            color='secondary'
                            disableElevation onClick={() => {
                                mainStore.uploadRawConfig(configuration, currentCsvFolder || '')
                                .catch(() => {
                                    setMessage("Configuration Save Failed!!")
                                    setOpen(true)                                    
                                })
                                .then(() => {
                                    setMessage("Configuration Saved!")
                                    setOpen(true)
                                })
                            }}>Save Config</Button>
                    </div> : <React.Fragment />}
                </>,
                getConfirmationProps: () => getMappingConfirmProps(mainStore, 
                    saveConfig, 
                    clearAssignment, 
                    saveAssignments, 
                    cancelAssignments)
        },
        {
            title: 'Process', description: '', canMoveNext: () => true, operationId: Operations.Process, content:
                () => currentOrg ? <Process store={mainStore}></Process> : <React.Fragment />,
            footer: () =>
                <>
                    {outputValidationFile &&
                        <Button
                            className={classes.custom}
                            onClick={async () => {
                                FileSaver.saveAs(await mainStore.getDownloadUrl(outputValidationFile.url), 'validations.zip')
                                await mainStore.fetchExistingFiles()
                            }}
                            variant="contained"
                            style={{ color: 'white', width: 180, height: 42 }}
                            color='secondary'
                            disableElevation>
                            Download Validations
                    </Button>}
                    {outputFile &&
                        <Button
                            onClick={async () => {
                                FileSaver.saveAs(await mainStore.getDownloadUrl(outputFile.url), 'output.zip')
                                await mainStore.fetchExistingFiles()
                            }}
                            variant="contained"
                            style={{ color: 'white', width: 100, height: 42 }}
                            color='secondary'
                            disableElevation>
                            Download
                    </Button>}
                </>,
            getConfirmationProps: () => undefined
        },
        {
            title: 'Validate', description: '', canMoveNext: () => true, operationId: Operations.Validate, content:
                () => currentOrg ? <Validate store={mainStore}></Validate> : <React.Fragment />,
            footer: () =>
                <>
                    {outputValidationFile &&
                        <Button
                            className={classes.custom}
                            onClick={async () => {
                                FileSaver.saveAs(await mainStore.getDownloadUrl(outputValidationFile.url), 'validations.zip')
                                await mainStore.fetchExistingFiles()
                            }}
                            variant="contained"
                            style={{ color: 'white', width: 180, height: 42 }}
                            color='secondary'
                            disableElevation>
                            Download Validations
                    </Button>}
                    {outputFile &&
                        <Button
                            onClick={async () => {
                                FileSaver.saveAs(await mainStore.getDownloadUrl(outputFile.url), 'output.zip')
                                await mainStore.fetchExistingFiles()
                            }}
                            variant="contained"
                            style={{ color: 'white', width: 100, height: 42 }}
                            color='secondary'
                            disableElevation>
                            Download
                    </Button>}
                </>,
            getConfirmationProps: () => undefined
        },
        {
            title: 'Inject', description: '', canMoveNext: () => true, operationId: Operations.Inject, content:
                () => currentOrg ? <Inject store={mainStore}></Inject> : <React.Fragment />,
                getConfirmationProps: () => undefined
            },
        {
            title: 'Summary', description: '', canMoveNext: () => false, operationId: Operations.Process, content:
                () => <React.Fragment />,
                getConfirmationProps: () => undefined
            }
    ]

    return (
        <div>
            <div style={{ marginTop: 5, padding: 5, display: 'inline-flex', flexDirection: 'column', width: '-webkit-fill-available' }}>
                <Wizard 
                    selectedOrg={mainStore.currentOrg} 
                    selectedFileUrl={mainStore.selectedCsvFile && mainStore.selectedCsvFile.url} 
                    steps={wizardSteps} 
                    securityStore={mainStore.securityStore} 
                    isMappingValid={mainStore.mappingValid}
                />
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                style={{ bottom: 80 }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
            />
        </div>
    )
})