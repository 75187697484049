import { Button, CircularProgress, Tooltip, makeStyles } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { CSSProperties, useEffect, useState } from "react"
import { InjectInfo, TestInfo } from "../../models/InjectInfo"
import { SalesforceApp } from "../../models/SalesforceApp"
import { SalesforceDashboard } from "../../models/SalesforceDashboard"
import { MainStore } from "../../stores/MainStore"
import { SelectApp } from "../Salesforce components/AppSelectionComponent"
import { SelectDashboard } from "../Salesforce components/DashboardSelectionComponent"
import { SalesforceLogin } from "../Salesforce components/SalesforceLogin"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Stack } from "@mui/material"
import { FunctionalDataGrid } from "../data grid/FunctionalDataGrid"
import { DataGrid, GridColDef, GridEditRowsModel, GridRowData } from "@material-ui/data-grid"
import { SalesforceUser, SalesforceUserResponse } from "../../models/SalesforceUser"
import { SFToken } from "../../models/Profile"
import { Footer } from "../data grid/Footer"

const useStyles = makeStyles((theme) => ({
    page: {
      margin: '50px',
      height: 'calc(100vh - 65px - 100px)',
      display: 'flex',
      flexDirection: 'column'
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px'
    },
    update_button: {
      marginBottom: '5px',
      width: '245px'
    },
    body: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto'
    }
  }));
  
interface ISalesforceUserManagerProps {
    store: MainStore
}

export const SalesforceUserManager = observer((props: ISalesforceUserManagerProps) => {
    const {store} = props
    const classes = useStyles();
    const [rows, setRows] = useState<GridRowData[]>([])
    const [columns, setColumns] = useState<GridColDef[]>([])
    const [inProgress, setInProgress] = useState<boolean>(false)

    useEffect(() => {
        if (store.SFToken) {
            setInProgress(true)
            get_user_info()
        }
        else {
            setRows([])
        }
    }, [store, store.SFToken])

    const get_user_info = async () => {
      const resp = await store.getSalesforceUsers(store.SFToken as SFToken)
      const users = resp.users
      console.log(JSON.parse(JSON.stringify(resp)))
      const col_attr = column_attributes(resp)
      const columns = Object.keys(users[0]).map(k => { 
        const value = users[0][k as keyof typeof users[0]]
        const type =  typeof value == "boolean" ? "boolean" : "string"
        const attributes = k in col_attr ? col_attr[k as keyof typeof col_attr] : {}
        return {
          field: k,
          flex: 1,
          type: type,
          editable: true,
          ...attributes
      }})
      setColumns(columns)
      
      const rows = users.map((u,i) => { return {id: i, ...u}})
      setRows(rows)
      setInProgress(false)
    }

    const set_user_info = async () => {
      setInProgress(true)
      await store.setSalesforceUsers(store.SFToken as SFToken, rows as SalesforceUser[])
      get_user_info()
    }

    const column_attributes = (resp: SalesforceUserResponse) => {
      return {
        user_id: {
          hide: true
        },
        name: {
          editable: false
        },
        admin: {
          hide: true
        },
        group: {
          editable: resp.has_group_field,
          description: resp.has_group_field ? "Service Insights CoPilot will filter results based on assigned group. If no group is assigned, results will not be filtered.":
          "Please add 'Group' field to the User object in your org."
        },
        team: {
          editable: resp.has_team_field,
          description: resp.has_group_field ? "Service Insights CoPilot will filter results based on assigned team. If no team is assigned, results will not be filtered.":
          "Please add 'Team' field to the User object in your org."
        },
        lwc_permission_set: {
          description: "This permission set grants permissions neccasary for the Service Insigths App. This is not required for admins."
        }
      }
    }

    const onEdit = (model: GridEditRowsModel) => {
        const rowId = Number(Object.keys(model)[0])
        if (!Number.isNaN(rowId)) {
          const field = Object.keys(model[rowId])[0]
          const value = model[rowId][field]['value']
          rows[rowId][field] = value
        }
    }

    return (
        <div className={classes.page}>
                <div className={classes.header}>
                  {store.SFToken && <Button variant='outlined' className={classes.update_button} onClick={() => set_user_info()} disabled={inProgress}> {inProgress ? <CircularProgress size={25}/> : "Update Org"}</Button>}
                  <SalesforceLogin store={store}/>
                </div>
                <div className={classes.body}>
                  <DataGrid rows={rows} columns={columns} onEditRowModelChange={(params) => onEdit(params.model)}/>
                </div>
            </div>
    )
})