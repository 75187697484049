import { Config } from "./models/Config";
import { ConfigItem, LogicalItem } from "./models/ConfigItem";
import FeatureFlagsService from "./services/FeatureFlagsService";

export const updateConfig = (primaryConfig: ConfigItem[], secondaryConfig: ConfigItem[], secondaryOverrides?: string[]) => {

    const updatedConfig: ConfigItem[] = []
    const overrides = secondaryOverrides || []

    var primaryConfig_dict: {[name: string]: ConfigItem} = toDictionary(primaryConfig)
    var secondaryConfig_dict: {[name: string]: ConfigItem} = toDictionary(secondaryConfig)

    for (let primaryKey in primaryConfig_dict) {
        var primaryItem = primaryConfig_dict[primaryKey]
        var found = false
        var secondaryItem = secondaryConfig_dict[primaryKey]

        if (primaryItem.featureFlag === undefined || (FeatureFlagsService.getClient().variation(primaryItem.featureFlag.name, false) === primaryItem.featureFlag.trueValue)){
            if (!!secondaryItem){
                found = true

                if (secondaryItem.type !== primaryItem.type) {
                    secondaryItem.type = primaryItem.type
                    if (!overrides.includes(secondaryItem.name)) {
                        secondaryItem.value = primaryItem.value
                    }
                }
                
                for (var prop in primaryItem) {
                    if (Object.prototype.hasOwnProperty.call(primaryItem, prop) && !['name', 'type', 'value', 'label'].includes(prop)) {

                        (secondaryItem as any)[prop] = (primaryItem as any)[prop]
                    }
                }

                if (!!primaryItem.mergeFrom){
                    var mergeFromItem = secondaryConfig_dict[primaryItem.mergeFrom]
                    if (!! mergeFromItem){
                        secondaryItem = {...mergeFromItem, name: primaryItem.name}

                        if (secondaryItem.type !== primaryItem.type) {
                            secondaryItem.type = primaryItem.type
                            if (!overrides.includes(secondaryItem.name)) {
                                secondaryItem.value = primaryItem.value
                            }
                        }
            
                        for (var prop in primaryItem) {
                            if (!['name', 'type', 'value', 'label'].includes(prop)) {
                                (secondaryItem as any)[prop] = (primaryItem as any)[prop]
                            }
                        }
                    }
                }
                updatedConfig.push(secondaryItem)
            }
            if (!found) {updatedConfig.push(primaryItem)}
        }
    }
    
    return updatedConfig
}

export const toDictionary = (configItems: ConfigItem[]) => {
    const items: {[name: string]: ConfigItem} = {}

    for (const item of configItems) {
        items[item.name] = item
    }
    return items
}


export const verifyCopiedConfig = (configItems: ConfigItem[], headers: string[]) => {
    //remove all multiple selection
    for (var item of configItems){
        if (item.allowMultiple){
            var multiplValues = item.value as string[]
            item.value = multiplValues.filter((column:string) => headers.includes(column))
        }
    }
    return configItems
}


export const matchHeaders = (configItems: ConfigItem[], headers: string[]) => {
    const unify = (input: string) => input.toLowerCase().replace(" ", "").replace("_", "")
    
    configItems.forEach(i => {
        const header = headers.find(h => typeof i.value === 'string' && unify(h) === unify(i.value))
        if (header) i.value = header
    })

    return configItems
}

export const getInnerConfig = (config: ConfigItem[]) => {
    const innerConfig: Config = {}
    const custom_labels: Config = {}
    config.forEach(i => {
        innerConfig[i.name] = i.value
        if (i.label) {custom_labels[i.name] = i.label}
    })
    innerConfig['custom_labels'] = custom_labels
    return innerConfig
}

export const getMultipleAssignments = (configuration?: ConfigItem[]) => {
    if (!configuration) return undefined

    const allAssignements = configuration.reduce<{[value: string]: ConfigItem[]}>((valuesToFields, configItem) => {
        if (typeof(configItem.value) == 'string' && configItem.value !== 'n/a') {
            if (!valuesToFields[configItem.value]) {    
                valuesToFields[configItem.value] = []
            }

            valuesToFields[configItem.value].push(configItem)
        }
        return valuesToFields
    }, {})

    let res: {[value: string]: ConfigItem[]} | undefined = undefined

    for (const value in allAssignements) {
        if (allAssignements[value].length > 1) {
            if (!res) res = {}
            res[value] = allAssignements[value] 
        }
    }

    return res
}

export const shouldDisplayMultipleAssignments = (a?: {[value: string]: ConfigItem[]}, b?: {[value: string]: ConfigItem[]}) => {
    if (!(a && b)) return true
    
    let eq = false

    for (const value in a) {
        const [loaded, current] = [b[value], a[value]]

        if (loaded && current && !eq) {
            if (current.length > loaded.length) {
                eq = true
                break
            }

            const currentNames = current.map(item => item.name).sort()
            const loadedNames = loaded.map(item => item.name).sort()

            for (let i = 0; i < currentNames.length; i++) {
                if (currentNames[i] !== loadedNames[i]) {
                    eq = true
                    break
                }
            }
        }
        else {
            eq = true
            break
        }
    }

    return eq
}

export const getResultByLogicalExpression = (container:ConfigItem[], item:ConfigItem) =>{

    const output:boolean[] = []
    for (const dependency of item.dependencies!.dependencyValues){
        output.push(container.some(depend => (depend.name == dependency) && (depend.value != 'n/a')))
    }
    var result = false

    if (item.dependencies?.logicalContext == LogicalItem.OR){
        result  = output.some(item => item === true)
    }
    else{
        result = output.every(item => item === true)
    }
    return result
}


export const isDependentConfigItemsValid = (configuration:ConfigItem[]) =>{
    var output = true
    if (configuration){
        for (var item of configuration){
            if (!item.dependencies || item.value =='n/a') continue
            const dependencyResult = getResultByLogicalExpression(configuration, item)

            if (dependencyResult != true){
                output = false
                break
            }
        }
    }
    return output
}
