import React, { useEffect, useState } from 'react'
import { Button, Checkbox, makeStyles, Typography } from '@material-ui/core'
import { MainStore } from '../../stores/MainStore';
import { observer } from 'mobx-react-lite';
import { DataGrid, GridColDef, GridEditCellPropsParams } from '@material-ui/data-grid';
import StepConfirmation, { ConfirmationDialogProps, defaultConfirmationDialogProps } from '../controls/StepConfirmation';
import AquantWarningIcon from '../../assets/aq-warning.svg'
import { CachedItem } from './models';

const useStyles = makeStyles((theme) => ({
    separator: {
        width: '100%',
        minHeight: 75,
        backgroundColor: '#ffffff',
        borderStyle: 'dashed',
        border: '1px dashed #858F9F',
        boxSizing: 'border-box',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#858F9F'
    },
    root: {
        "& .MuiTypography-root": {
            minWidth: '100%',
        }
    },
    textbtn: {
        textTransform: 'none',
        backgroundColor: 'transparent',
        fontSize: "inherit",
        fontWeight: 'inherit',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        },
        lineHeight: '19px'
    },

}));


export const CopilotCaching = observer(({ store }: { store: MainStore }) => {

    const [isDoingAction, setIsDoingAction] = useState<boolean>(false)
    const [cachedItems, setCachedItems] = React.useState<Array<CachedItem>>([])
    const [deleteSelectedItems, setDeleteSelectedItems] = React.useState<Array<string>>([])
    const [changedItems, setChangedItems] = React.useState(new Set())
    

    const [isFetchingFiles, setIsFetchingFiles] = useState<boolean>(false)
    const [deleteDialogProps, setDeleteDialogProps] = useState<ConfirmationDialogProps | undefined>(undefined)

    const classes = useStyles()
    const fetchFiles = () => {
        if (store?.copilotStore?.selectedCustomer && store.copilotStore?.selectedModel?.model_name){
            setIsFetchingFiles(true)
            const cacheItems = store.copilotStore!.fetchCacheItems(store.copilotStore!.selectedCustomer!.name
                , store.copilotStore!.selectedModel?.model_name!)
    
            cacheItems.then((result) => {
                console.log("got: " + result)
                setCachedItems(result)
            }).then(() => setIsFetchingFiles(false))
        }
    }

    useEffect(() => {
        fetchFiles()
    }, [store?.copilotStore?.selectedCustomer, store.copilotStore?.selectedModel?.model_name])


    const onCheckChanged = (value:boolean, id:string) => {
        if (value == true){
            setDeleteSelectedItems(prevItems => [...prevItems, id]);
        }
        else{
            setDeleteSelectedItems(prevItems => prevItems.filter(prevItem => prevItem !== id));
        }
    }

    const columns: GridColDef[] = [
        {
            field: ' ',
            headerName: '',
            width: 62,
            renderCell: (params) => (
              <Checkbox
                checked={params.value as boolean}
                onChange={(event) => { onCheckChanged(event.target.checked, String(params.id))}}
              />
            ),
        },
        {
            field: 'id',
            headerName: 'Id',
            flex: 3,
            editable: false,
            hide:true
        },
        {
            field: 'text',
            headerName: 'Text',
            width: 350,
            flex: 4,
            editable: true,
        },
        {
            field: 'question',
            headerName: 'Question',
            flex: 2,
            width: 90,
            editable: false,
        }
    ];

    const getDeleteConfirmationDialog = (onDelete: () => void) => {
        const saveConfirmProps: ConfirmationDialogProps = {
            ...defaultConfirmationDialogProps,
            title: 'Confirm Delete?',
            content: <>
                <div style={{ display: 'flex', marginBottom: 15 }} >
                    <img src={AquantWarningIcon} alt='warning-icon' style={{ marginRight: 5 }} />
                    <Typography variant='subtitle2'>
                        You are about to delete a cached item.
                    </Typography>
                </div>
                <Typography variant='body1' style={{marginTop: 15}}>
                    Are you sure you want to proceed?
                </Typography>
            </>,
            onYes: () => { onDelete() },
            yesLabel: 'Yes',
            closeLabel: 'No',
        }

        return saveConfirmProps
    }

    const onSave = () => {
        setIsDoingAction(true)
        store.copilotStore!.editCacheItems(store.copilotStore!.selectedCustomer!.name
            , store.copilotStore!.selectedModel?.model_name!, Array.from(changedItems) as Array<CachedItem>)
            .then(fetchFiles)
            .then(() => setIsDoingAction(false))
    }

    const onDelete = () => {
        setIsDoingAction(true)
        store.copilotStore!.deleteCacheItems(store.copilotStore!.selectedCustomer!.name
            , store.copilotStore!.selectedModel?.model_name!, deleteSelectedItems)
            .then(fetchFiles)
            .then(() => setIsDoingAction(false))
    }

    const handleEditCellChange = (params: GridEditCellPropsParams) => {
        const item = cachedItems.find(element => element.id == params.id)
        if (item != undefined && params.field == 'text'){
            const tempValue = String(params.props.value)
            if (item.text !== tempValue){
                item.text = String(params.props.value)
                setChangedItems(prev => new Set(prev.add(item)))
            }
        }
    }

    return (
        <div style={{ height: '85%' }}>
            <div style={{ paddingRight: 10, paddingTop: '10px',  display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', gap: '10px' }}>
                    <Button variant='outlined'
                        style={{ width: '20%' }}
                        onClick={() => onSave()}
                        disabled = {changedItems.size == 0}
                    >
                        Save
                    </Button>
                    <Button variant='outlined'
                        style={{ width: '20%' }}
                        disabled = {deleteSelectedItems.length == 0}
                        onClick={() => setDeleteDialogProps(getDeleteConfirmationDialog(() => onDelete()))}
                    >
                        Delete
                    </Button>
            </div>

            <div style={{ height: '100%', marginTop: 10 }}>
                {cachedItems && <DataGrid
                    rows={cachedItems}
                    columns={columns}
                    pageSize={9}
                    disableSelectionOnClick
                    getRowId={row => row.id}
                    onEditCellChange={handleEditCellChange}
                    loading={isFetchingFiles || isDoingAction}
                />}
            </div>
            {deleteDialogProps && <StepConfirmation
                confirmationDialogs={[deleteDialogProps]}
                finish={() => { setDeleteDialogProps(undefined)}}
                cancel={() => setDeleteDialogProps(undefined)}
                isValid={true}
            />}

        </div>
    );
})