export const loadCSV = (file: File | undefined, onload: (rows: any[]) => void) => {
    if (file === undefined) return undefined
    if (file && file.type !== 'text/csv') alert('Please select a valid CSV file.');

    const fr = new FileReader()
    fr.readAsText(file)
    fr.onload = () => {
      const csvString = fr.result
      if (typeof csvString == 'string') {
        onload(parseCSV(csvString))
      }
    }
  }

  const parseCSV = (csv: string) => {
    const rows = csv.replaceAll('\r', '').split('\n').map(l => split_line_to_row(l))
    return rows
  }

  const split_line_to_row = (l: string) => {
    l = l.replace(/(".*?")/g, (match) => {
        return match.replace(/,/g, '<comma>').replace(/"/g, '');
    });

    let row = []

    const escape_sequence = '"\\""'
    if (l.includes(escape_sequence)) {
      const split_array = []
      while (l && l.includes(',')) {
        var comma_index = l.indexOf(',')
        const escape_sequence_index = l.indexOf(escape_sequence)
        if (escape_sequence_index < comma_index) {
          const closing_index = l.indexOf(escape_sequence, escape_sequence_index + 1)
          comma_index = l.indexOf(',', closing_index)
        }
        if (comma_index === -1) {
          split_array.push(l)
          break
        }
        split_array.push(l.substring(0,comma_index))
        l = l.substring(comma_index + 1)         
      }
      row = split_array
    }
    else row = l.split(',')
    
    row = row.map(e => e.replace(/<comma>/g, ','))

    return row
  }

