import { createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Theme, Typography, withStyles } from "@material-ui/core"
import { useState } from "react"
import AquantCancelIcon from '../../assets/aq-cancel.svg'
import { TeriaryButton, PrimaryButton, SecondaryButton } from "./buttons"


export interface ConfirmationDialogProps {
    onClose: () => void
    closeLabel?: string
    onYes?: () => void
    yesLabel?: string
    onNo?: () => void
    noLabel?: string
    title?: string
    content?: JSX.Element
}

export const defaultConfirmationDialogProps: ConfirmationDialogProps = {
    onClose: () => {},
}

export interface StepConfirmationProps {
    confirmationDialogs: ConfirmationDialogProps[]
    finish: () => void
    cancel: () => void
    isValid: boolean
}

export const DialogCard = withStyles((theme: Theme) => createStyles({
    paper: {
        borderRadius: '14px'
    }
}))(Dialog)


const StepConfirmation = ({confirmationDialogs, finish, cancel, isValid}: StepConfirmationProps) => {
    const [currentDialog, setCurrentDialog] = useState(0)

    const advance = () => {
        if (currentDialog === confirmationDialogs.length - 1) {
            if (isValid) finish()
            else cancel()
        }
        else setCurrentDialog(currentDialog + 1)
    }

    const extendedDialog: ConfirmationDialogProps = {
        ...confirmationDialogs[currentDialog], 
        onYes: () => {
            confirmationDialogs[currentDialog].onYes && confirmationDialogs[currentDialog].onYes!()
            advance()
        },
        onClose: () => {
            confirmationDialogs[currentDialog].onClose()
            setCurrentDialog(0)
            cancel()
        },
        onNo: () => {
            confirmationDialogs[currentDialog].onNo && confirmationDialogs[currentDialog].onNo!()
            advance()
        },
    }

    const progress = <div style={{display: 'flex', padding: 12, flexGrow: 1, position: 'relative', marginRight: 23}}>
        {confirmationDialogs.map((_, i) => <><div 
                key={i}
                style={{ 
                    position: 'absolute',
                    height: 8, 
                    width: 8, 
                    border: '0.08px solid #2468FF', 
                    borderRadius: 50,
                    left: 23 * i,
                    background: currentDialog === i ? '#2468FF' : undefined,    
                }}
            />
            {i < confirmationDialogs.length - 1 && <div
                style={{ 
                    position: 'absolute',
                    borderTop: currentDialog >= i  ? '0.08em solid #2468FF' : undefined, 
                    width: 15,
                    left: 8 + 23 * i,
                    marginTop: 3
                }}
            />}
        </>)}
    </div>

    return <DialogCard open={true} onClose={extendedDialog.onClose} >
        <DialogTitle>
            <div style={{display: 'flex', alignItems: 'center', flex: 5}}>
                {progress}  
                <Typography variant='h3' style={{flexGrow: 4}} > {extendedDialog.title || 'Confirm'} </Typography>
                <img src={AquantCancelIcon} onClick={cancel} alt='cancel icon' style={{marginLeft: 15}} />
            </div>
        </DialogTitle>

        <Divider />

        <DialogContent>
            {extendedDialog.content ?? <DialogContentText>Please Confirm Action</DialogContentText>}
        </DialogContent>
        <DialogActions>
            <TeriaryButton onClick={extendedDialog.onClose}> {extendedDialog.closeLabel ?? 'Close'} </TeriaryButton>
            {confirmationDialogs[currentDialog].onYes && <PrimaryButton 
                onClick={extendedDialog.onYes}
            > 
                {extendedDialog.yesLabel ?? 'Yes'} 
            </PrimaryButton>
            }
            {confirmationDialogs[currentDialog].onNo && <SecondaryButton 
                onClick={extendedDialog.onNo}
            > 
                {extendedDialog.noLabel ?? 'No'} 
            </SecondaryButton>
            }
        </DialogActions>

    </DialogCard>
}

export default StepConfirmation