import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core'

interface ISaveDialogProps {
  open: boolean
  onClose: () => void
  onSave: () => void
  title?: string
  text?: string
}

export const SaveDialog = ({open, onClose, onSave, title, text}: ISaveDialogProps) => {
    return (<Dialog open={open} onClose={onClose}>
              <DialogTitle>{title ? title : "Save Changes"}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {text ? text : "Do you want to save changes?"}  
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={onSave}>Save</Button>
                <Button onClick={onClose}>Don't Save</Button>
                <Button onClick={onClose}>Cancel</Button>
              </DialogActions>
            </Dialog>)
}