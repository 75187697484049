import { makeAutoObservable, observable } from "mobx"

export class SecurityStore {

    @observable allowedOperationIds: [string]

    constructor(operationIds: [string]) {
        makeAutoObservable(this)
        this.allowedOperationIds = operationIds
    }

    isAllowed = (operationId: string) => {
        return this.allowedOperationIds.indexOf(operationId) > -1
    }
}
    