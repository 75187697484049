import { CircularProgress, Divider, FormHelperText, LinearProgress, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { Folder } from '../../models/Folder'
import { Stack } from '@mui/material'

type OrganizationSelectionProps = {
    organizations: Folder[]
    onSelection: (org?: string) => void
    selectedOrg?: string
    orgDefsProps?: DefsEditorProps
}

type DefsEditorProps = {
    orgIndustries?: string[]
    orgType?: string
    metadataLoaded: () => boolean
    updateDefs: (industries?: string[], orgType?: string) => void
}

interface OrgOptionType {
    inputValue?: string
    title: string
}

const industriesNames = [
    'Healthcare',
    'Automotive',
    'Comms/Energy',
    'Heavy Equipment',
    'Business Services',
    'Hightech & Electronics',
    'Light Equipment',
    'Facilities',
    'Construction & Agri Equipment',
]

const orgTypes = ['Customer', '7DC', 'Dev']

const deduceOrgType = (name?: string) => {
    if (name && name.toLowerCase().includes('test')) return 'Dev'
    else return 'Customer'
}

const OrgDefsEditor = ({orgIndustries, orgType, orgName, updateDefs}: DefsEditorProps & {orgName?: string}) => {
    const [industries, setIndustries] = useState<string[] | undefined>(orgIndustries)
    const [currentOrgType, setOrgType] = useState<string | undefined>(orgType || deduceOrgType(orgName))

    return <Stack spacing={3}>
        <Divider />
        <Stack direction='row' spacing={3}>
            <Autocomplete
                value={currentOrgType}
                options={orgTypes}
                onChange={(e, val, reason, details) => {
                    setOrgType(val || undefined)
                    updateDefs(industries, val || undefined)
                    
                }}
                renderInput={params => <TextField style={{ width: 300, backgroundColor: 'white' }} {...params} label="Organization Type" variant="outlined" />}
            />
            <Autocomplete
                multiple
                value={industries ? industries : []}
                options={industriesNames}
                onChange={(e, vals, reason, details) => {
                    setIndustries(vals)
                    updateDefs(vals, currentOrgType)
                }}
                renderInput={params => <TextField  {...params} label="Industries" variant="outlined" />}
                style={{ width: 500, backgroundColor: 'white' }}
            />
        </Stack>
    </Stack>
}

export const OrganizationSelection = (props: OrganizationSelectionProps) => {
    const { organizations, onSelection, selectedOrg, orgDefsProps} = props

    const [value, setValue] = React.useState<OrgOptionType | null>(selectedOrg ? {title: selectedOrg } : null)
    const [error, setError] = React.useState<boolean>(false)

    const setSelectItem = (org: OrgOptionType | null) => {
        setValue(org)
        org ? onSelection(org.title) : onSelection(undefined)
    }

    const innerOrganizations: OrgOptionType[] = organizations.map(org => ({title: org.name}))

    return <Stack spacing={4}>
        <Autocomplete
        value={value}
        onChange={(event, newValue) => {
            if(error) {
                setSelectItem(null)
            } else if (typeof newValue === 'string') {
                setSelectItem({
                    title: newValue,
                })
            } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setSelectItem({title: newValue.inputValue})
            } else {
                setSelectItem(newValue)
            }
        }}
        filterOptions={(options, params) => {
            const filtered = createFilterOptions<OrgOptionType>()(options, params)

            // Suggest the creation of a new value
            if (params.inputValue !== '' && !error) {
                filtered.push({
                    inputValue: params.inputValue,
                    title: `Add "${params.inputValue}"`,
                })
            }

            return filtered
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        disabled={!organizations.length}
        options={innerOrganizations}
        getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') return option
            // Add "xxx" option created dynamically
            if (option.inputValue) return option.inputValue
            // Regular option
            return option.title
        }}
        renderOption={option => option.title}
        style={{ width: 300, backgroundColor: 'white' }}
        freeSolo
        renderInput={params => (
            organizations.length ? 
            <TextField
                {...params}
                label="Customer"
                variant="outlined"
                error={error}
                onChange={ (event) =>{
                        const inputValue = event.target.value
                        const regex = /^[a-zA-Z0-9 !\-_.*'()]*$/
                        const isInputValid = inputValue.match(regex)
                        if(!inputValue || isInputValid){
                            setError(false)
                        } else if (inputValue && !isInputValid) {
                            setError(true)
                        }
                    }
                }
                onBlur={() => setError(false)}
                />
            :
            <div>
                <TextField {...params} label="Loading" variant="outlined" />
                <LinearProgress/>
            </div>
        )}
    />
    {error && 
    <FormHelperText error style={{ margin: 0 }}>
        Special characters not allowed!
    </FormHelperText>}
    {orgDefsProps?.orgIndustries && value && <OrgDefsEditor 
        orgIndustries={orgDefsProps.orgIndustries}
        metadataLoaded={orgDefsProps.metadataLoaded}
        orgType={orgDefsProps.orgType}
        updateDefs={orgDefsProps.updateDefs}
        orgName={selectedOrg} 
    />}
    {orgDefsProps && !orgDefsProps?.orgIndustries && value && <Stack sx={{m:3, p:3}} > <CircularProgress /> </Stack>}
    </Stack>
}
