import { Button, makeStyles, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import DownloadIcon from '@material-ui/icons/GetApp';
import UploadIcon from '@material-ui/icons/Publish';
import { GridFooter, GridFooterContainer } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  innerContainer: {
    display: 'flex',
    justifyContent: "space-between",
    width: "100%"
  }
}));

export interface IFooterProps {
  onAddRow?: () => void
  onRemoveRow?: () => void
  onSave?: () => void
  onRefresh?: () => void
  onDownload?: () => void
  onUpload?: (file: File | undefined) => void
}

export const Footer = (props: IFooterProps) => {
  const classes = useStyles();
  const {onAddRow, onRemoveRow, onSave, onRefresh, onDownload, onUpload} = props

  return (
    <GridFooterContainer className={classes.innerContainer}>
      <div >
        <Tooltip title="Add Row"><span><Button disabled={!onAddRow} onClick={() => onAddRow!()} ><AddIcon/></Button></span></Tooltip>
        <Tooltip title="Remove Row"><span><Button disabled={!onRemoveRow} onClick={() => onRemoveRow!()}><RemoveIcon/></Button></span></Tooltip>
        <Tooltip title="Save"><span><Button disabled={!onSave} onClick={() => onSave!()}><SaveIcon/></Button></span></Tooltip>
        <Tooltip title="Refresh"><span><Button disabled={!onRefresh} onClick={() => onRefresh!()}><RefreshIcon/></Button></span></Tooltip>
        <Tooltip title="Download"><span><Button disabled={!onDownload} onClick={() => onDownload!()}><DownloadIcon/></Button></span></Tooltip>
        <Tooltip title="Upload"><span><Button disabled={!onUpload} onClick={() => document.getElementById("footer-upfile")?.click()}><UploadIcon/></Button></span></Tooltip>
        <input id="footer-upfile"
            type="file" 
            accept="text/csv"
            onChange={(event) => {
              onUpload!(event.target.files ? event.target.files[0] : undefined)
              event.target.value = ''
            }} 
            hidden/>
      </div>
      <GridFooter/>
    </GridFooterContainer>
  )
}