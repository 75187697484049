import { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridEditRowsModel, GridRowData } from '@material-ui/data-grid';
import { Footer, IFooterProps } from './Footer';
import { SaveDialog } from '../controls/SaveDialog';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  data_grid: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  }
}));

interface IDataGridProps {
  columns: GridColDef[]
  rows: GridRowData[]
  onSave: (rows: GridRowData[]) => void
  onDownload: (rows: GridRowData[]) => void
  onUpload: (file: File) => void
  disabled?: boolean
}

export const FunctionalDataGrid = (props: IDataGridProps) => {
    const classes = useStyles()
    const{columns, onSave, onDownload, onUpload, disabled} = props
    const [inputRows, setInputRows] = useState<string>("[]")
    const [rows, setRows] = useState<GridRowData[]>([]);
    const [selectedRowId, setSelectedRowId] = useState<number>();
    const [saveDialogOpen, setSaveDialogOpen] = useState(false)

    useEffect(() => {
        props.rows.forEach((r,i) => r['id'] = i)
        const indexed_rows = JSON.stringify(props.rows)
        setInputRows(indexed_rows)
        setRows(JSON.parse(indexed_rows))
    }, [props.rows])

    const footerProps : IFooterProps = disabled ? {} : {
      onAddRow: () => {
        const editableRows = rows.map(row => row)
        const newRow: {[field: string]: any} = { id: editableRows.length }
        columns.forEach(c => newRow[c.field] = '')
        editableRows.push(newRow)
        setRows(editableRows)
      },
      onRemoveRow: selectedRowId !== undefined ? () => {
        const filteredRows = rows.filter(r => r['id'] !== selectedRowId)
        filteredRows.forEach((r, i) => r['id'] = i)
        setRows(filteredRows)
        if (selectedRowId === filteredRows.length) setSelectedRowId(undefined)
      } : undefined,
      onSave: () => setSaveDialogOpen(true),
      onRefresh: () => setRows(JSON.parse(inputRows)),
      onDownload: () => onDownload(rows),
      onUpload: (file: File | undefined) =>  file && onUpload(file)
    }

    const onEdit = (model: GridEditRowsModel) => {
        const rowId = Number(Object.keys(model)[0])
        if (!Number.isNaN(rowId)) {
          const field = Object.keys(model[rowId])[0]
          const value = model[rowId][field]['value']
          rows[rowId][field] = value
        }
    }
    
    return (<div className={classes.data_grid}>
              <SaveDialog open={saveDialogOpen}
                          onClose={() => setSaveDialogOpen(false)}
                          onSave={() => {
                            onSave!(rows)
                            setSaveDialogOpen(false)}}/>
              <DataGrid onRowSelected={(row) => setSelectedRowId(row.data['id'])}
                        rows={rows} 
                        columns={columns} 
                        components={{Footer: Footer}}
                        componentsProps={{footer: footerProps}}
                        onEditRowModelChange={(params) => onEdit(params.model)}/>
              
            </div>)
}