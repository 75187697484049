import { Typography } from '@material-ui/core';
import { useEffect } from 'react'
import { MainStore } from '../stores/MainStore';
import { OrganizationSelection } from './wizard/OrganizationSelection';
import { ProcessAndInject3 } from './wizard/ProcessAndInject3';

type ManualInjectionProps = {
    store: MainStore
}

export const ManualInjection = ({store}: ManualInjectionProps) => {
    const {organizations, currentOrg} = store

    useEffect(() => {
        store.setNotificationService(store.user!, store.currentOrg!, 'output.zip')
    }, [])

    return (
        <div style={{ padding: "20px" }}>
              {(!organizations || organizations.length === 0) && <Typography>Loading....</Typography>}
              {organizations && organizations.length > 0 && <OrganizationSelection 
                organizations={organizations} 
                onSelection={async value => await store.setOrganization(value)} 
                selectedOrg={currentOrg} 
              />}
              <div style={{ height: '10px' }}></div>
              {currentOrg && <ProcessAndInject3 store={store} mode='Manual Injection' />}
        </div>
    )
}