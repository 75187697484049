import { Button, CircularProgress, makeStyles, Tooltip, Typography} from '@material-ui/core'
import { useEffect, useState } from 'react';
import { MainStore } from '../../stores/MainStore';
import SaveIcon from '@material-ui/icons/Save';
import DownloadIcon from '@material-ui/icons/GetApp';
import UploadIcon from '@material-ui/icons/Publish';
import FileSaver from 'file-saver';

const useStyles = makeStyles((theme) => ({
  d_saver: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: 0
  },
  tool_bar: {
    backgroundColor: '#ECEDEF'
  },
  message: {
    flex: 1,
    overflow: 'auto',
  }
}));


interface IDashboardSaverProps {
  store: MainStore
  templateVersion: string
  fileName: string
}

export const DashboardSaver = ({store, templateVersion, fileName}: IDashboardSaverProps) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(true)
    const [dashboardFile, setDashboardFile] = useState<File>()
    const [dashboard, setDashboard] = useState<string>('')

    useEffect(() => {
      setDashboard('')
      if (templateVersion === "" || store.testProgress.find(m => m.progress !== 100)) return;
      const getDashboard = async () => {
        setDashboard('')
        setLoading(true)
        const file = await store.fetchTemplateFile(templateVersion, fileName)
        setDashboardFile(file)
      }
      getDashboard()
    }, [store, templateVersion, store.testProgress, store.testProgress.length, fileName])

    useEffect(() => {
      if (dashboardFile === undefined) setDashboard("")
      else {
        const fr = new FileReader()
        fr.readAsText(dashboardFile)
        fr.onload = () => {
          if (typeof(fr.result) === "string") setDashboard(JSON.stringify(JSON.parse(fr.result), undefined, 2));
          setLoading(false)
        }
      }
      setLoading(false)
    }, [dashboardFile])  

    return (
      <div className={classes.d_saver}>
        {loading && <CircularProgress/>}
        <div className={classes.message}>
          {!loading && !dashboardFile && <Typography>{`Dashboard template file not found for version ${templateVersion}`}</Typography>}
          <pre>{dashboard}</pre>
        </div>
        <div className={classes.tool_bar}>
            <Tooltip title="Save"><Button disabled={templateVersion === "" || dashboardFile === undefined || loading} onClick={() => store.saveTemplateFile(templateVersion, dashboardFile!)}><SaveIcon/></Button></Tooltip>
            <Tooltip title="Download"><Button disabled={dashboardFile === undefined || loading} onClick={() => FileSaver.saveAs(dashboardFile!, fileName)}><DownloadIcon/></Button></Tooltip>
            <Tooltip title="Upload"><Button disabled={templateVersion === "" || loading} onClick={() => document.getElementById("dashboard-upfile")?.click()}><UploadIcon/></Button></Tooltip>
        </div>
        <input id="dashboard-upfile"
              type="file" 
              accept="text/json"
              onChange={(event) => {
                setDashboardFile(event.target.files ? new File([event.target.files[0]], fileName) : undefined)
                event.target.value = ''
              }} 
              hidden/>
      </div>)
}