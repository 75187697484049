import { makeStyles} from '@material-ui/core'
import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import { useEffect, useState } from 'react';
import { MainStore } from '../../stores/MainStore';
import { CSVDataGrid } from '../data grid/CSVDataGrid';

const useStyles = makeStyles((theme) => ({
    data_grid: {
      flex: 1,
      backgroundColor: '#ECEDEF',
      display: 'flex',
      flexDirection: 'column'
    }
  }));


interface IFieldsEditorProps {
  store: MainStore
  templateVersion: string
  fileName: string
}

export const FieldsEditor = ({store, templateVersion, fileName}: IFieldsEditorProps) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [csv, setCsv] = useState(new File([],fileName));

    useEffect(() => {
      setCsv(new File([],fileName))
      if (templateVersion === "") return;

      const getCsv = async () => {
        setLoading(true)
        const file = await store.fetchTemplateFile(templateVersion, fileName)
        if (file !== undefined) setCsv(file)
        setLoading(false)
      }
      getCsv()
    }, [fileName, store, templateVersion])  

    const onSave = (csv: File) => {
      if (templateVersion === "") return;
      store.saveTemplateFile(templateVersion, csv)
    }

    const validate = (params: GridCellParams) => {
      console.log(params)
      if (params.value === '' || params.value === undefined) {
        return <div style={{color: 'red'}}>REQUIRED</div>
      }
      return <div>{params.value}</div>
    }

    const columns: GridColDef[] = [
      { field: 'dataset', headerName: 'Dataset', width: 150, editable: true, renderCell: validate},
      { field: 'field', headerName: 'Field', width: 200, editable: true, renderCell: validate },
      { field: 'label', headerName: 'Default Label', width: 200, editable: true },
      { field: 'format', headerName: 'Format', width: 300, editable: true },
      { field: 'config_field', headerName: 'SI Config Field', width: 300, editable: true }
    ];


    return (<div className={classes.data_grid}>
              <CSVDataGrid csv={csv} columns={columns} onSave={onSave} disabled={loading || templateVersion === ""}/>
            </div>)
}