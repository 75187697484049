import {LinearProgress, Typography, createStyles, makeStyles, Theme, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button'
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { MainStore } from '../../stores/MainStore';
import FileSaver from 'file-saver';
import { Box } from '@material-ui/core';
import MergeIcon from '@mui/icons-material/Merge';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';

type PartialProcessProps = {
    folderName: string
    store: MainStore
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            "& .MuiLinearProgress-colorSecondary": {
                backgroundColor: "#DA4444",
            },
            "& .MuiLinearProgress-barColorSecondary": {
                backgroundColor: "#DA4444",
            },
        },
        mergeIcon: {
            transform: "rotate(90deg)"
        },
        numberHeader: {
            fontFamily: 'Georgia, Times, “Times New Roman”, serif',
            marginRight: '12px',
            fontSize: '36px',
            textAlign: 'center', 
            fontWeight: 600
        },
        descriptionHeader: {
            marginRight: theme.spacing(1),
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '22px'

        },
        fileHeader: {
            marginRight: theme.spacing(1),
            fontSize: '14px',
            textAlign: 'left',
            color: '#818183'
        },
        fileName: {
            marginRight: theme.spacing(1),
            fontSize: '13px',
            textAlign: 'left',
            width: '150px'
        },
        activatedButton: {
            color: 'white',
            background: '#38D89F',
            width: '160px',
            height: '35px'
        },
        deactivatedButton: {
            color: 'white',
            background: '#C1C3CD',
            width: '160px',
            height: '35px'
        },
        linearProgressStyle : {
            margin : "10px 0px",
            height : '7px'
        },
        boxStyle:{
            backgroundColor: '#FFFFFF',
            padding : '20px 2%',
            margin : "10px 0px"
        },
        errorStyle:{
            fontSize: '14px',
            textAlign: 'left',
            color: '#ED4D4D',
            padding : '1px 5px',
        }
    })
);

export const PartialProcess = observer(({ folderName, store }: PartialProcessProps) => {

    const [downloadPath, setDownloadPath] = useState<string>('')
    const [partialMergeResult, setPartialMergeResult] = useState<string>('')
    const [partialApplyResult, setPartialApplyResult] = useState<string>('')
    const [doAction, setDoAction] = useState<boolean>(false)
    const [canMerge, setCanMerge] = useState<boolean>(true)
    const [canApply, setCanApply] = useState<boolean>(false)

    const { partialMergeProgress} = store
    const classes = useStyles()

    const handleMerge = () => {
        setDoAction(false)
        setCanMerge(false)
        store.fetchExistingFiles()
        if (store.CsvFiles && store.CsvFiles.length > 0) {
            store.concatReferencePartial(store.selectedCsvFile?.url, store.selectedPartialCsvFile?.url, store.tempCSVFolder)
        }
    }
    

    const handleMergeProgress = () => {
        const overallProgress = partialMergeProgress.find((i: any) => i.subject === 'Complete Process')
        console.log("Progress is " + JSON.stringify(overallProgress))

        if(overallProgress?.progress === 100 && overallProgress?.message !== '' && downloadPath === '') {
            console.log('partialMergeProgress complete')
            setDownloadPath(overallProgress.message!)
            setCanApply(true)
            setCanMerge(false)
        } else if(overallProgress?.progress === 99 && overallProgress?.message !== '' && partialMergeResult === ''){
            setPartialMergeResult(overallProgress?.message || 'Failed')
        }

        return (overallProgress && overallProgress.progress) || 0
    }

    const handleDownload = async () => {
        setDoAction(true)
        FileSaver.saveAs(await store.getDownloadUrl(downloadPath), 'temp.csv')        
        setDoAction(false)
    }

    const handleApprove = async () => {
        setDoAction(true)
        const split_csv_url = downloadPath.split('/')
        split_csv_url.pop()
        const folder_url = split_csv_url.join('/')

        store.finalizePartialMerge(folder_url, store.newCSVFolder, store.selectedPartialCsvFile?.name).then(
            (result) => {
                if (result?.success){
                    store.setSelectedCsv(result.file)
                    store.setPartialMergeApplied(true)
                }
                else{
                    setPartialApplyResult(result?.failReason || 'Failed')
                }
                setCanApply(false)
                setDoAction(false)
            }
        )
    }

    return (
        <div style={{ borderBottom: 1, borderColor: 'divider' }}>
            <Box className={classes.boxStyle}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginBottom: '20px'}}>
                    <Typography className={classes.numberHeader}>1   </Typography>
                    <Typography className={classes.descriptionHeader}> MERGE FILES </Typography>
                </div>
                <Divider />
                <div style={{display: 'flex',flexDirection: 'row', padding: '20px 0px'}}>
                    <div style={{display: 'flex',flexDirection: 'column', alignItems: 'strech'}}>
                        <Typography className={classes.fileHeader}>Original File</Typography>
                        <Typography className={classes.fileName}>{store.selectedCsvFile?.name}</Typography>
                    </div>
                    <MergeIcon className={classes.mergeIcon}/>
                    <div style={{display: 'flex',flexDirection: 'column', alignItems: 'strech'}}>
                        <Typography className={classes.fileHeader}>Partial File</Typography>
                        <Typography className={classes.fileName}>{store.selectedPartialCsvFile?.name}</Typography>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', padding: '4px 30px', marginLeft:'2%'}}>
                        <Button className={!doAction && canMerge ? classes.activatedButton : classes.deactivatedButton} 
                            onClick={() => handleMerge()} disabled={doAction || !canMerge} variant='contained' >Merge Files</Button>
                        <div className={classes.root}>
                            <LinearProgress color={partialMergeResult == '' ? 'primary' : 'secondary'} variant="determinate" 
                                value={partialMergeResult == '' ? handleMergeProgress() : 0} className={classes.linearProgressStyle}/>
                        </div>
                    </div>
                    <div style={{display: 'flex', padding: '10px 0px'}}>
                        {partialMergeResult != '' && <ReportOutlinedIcon color='error' fontSize='small'/>}
                        {partialMergeResult != '' && <Typography className={classes.errorStyle}>{partialMergeResult} </Typography>}
                    </div>
                </div>
            </Box>

            <Box className={classes.boxStyle}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginBottom: '20px'}}>
                    <Typography className={classes.numberHeader}>2   </Typography>
                    <Typography className={classes.descriptionHeader}> CHECK OUTPUT </Typography>
                </div>
                <Divider />
                <div style={{display: 'flex',flexDirection: 'row', verticalAlign: "baseline", margin: '20px', padding: '20px 0px'}}>
                    <div style={{display: 'flex',flexDirection: 'column', alignItems: 'strech'}}>
                        <Typography className={classes.fileHeader}>Please check the merged files before moving forward</Typography>
                    </div>
                    <div style={{display: 'flex', padding: '4px 30px', marginLeft:'5%'}}>
                        <Button className={!doAction && downloadPath != '' ? classes.activatedButton: classes.deactivatedButton} 
                            onClick={() => handleDownload()} disabled={doAction || downloadPath == ''} variant='contained' >Download File</Button>
                    </div>
                </div>
            </Box>

            <Box className={classes.boxStyle}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginBottom: '20px'}}>
                    <Typography className={classes.numberHeader}>3   </Typography>
                    <Typography className={classes.descriptionHeader}> APPLY MERGED DATA </Typography>
                </div>
                <Divider />
                <div style={{display: 'flex',flexDirection: 'row', verticalAlign: "baseline", margin: '20px;', padding: '20px 0px'}}>
                    <div style={{display: 'flex',flexDirection: 'column', alignItems: 'strech'}}>
                        <Typography className={classes.fileHeader}>Please confirm the merge to move forward</Typography>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', padding: '4px 30px', marginLeft:'10%'}}>
                        <Button className={!doAction && canApply ? classes.activatedButton : classes.deactivatedButton} 
                            onClick={() => handleApprove()} disabled={doAction || !canApply} variant='contained' >CONFIRM MERGING</Button>
                        {partialApplyResult != '' && <Typography>{partialApplyResult} </Typography>}
                    </div>
                </div>
            </Box>
        </div>
    );
})