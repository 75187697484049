import axios from "axios"

export class Settings {
    env: any = undefined
    
    private constructor(data: any) {
        this.env = data
    }

    static async getSettings() {
        try {
            const data = await axios.get("/env")
            const instance = new Settings(data.data)
            return instance
        }
        catch {
            // falling back to static env vars
            const data = process.env
            const instance = new Settings(data)
            return instance
        }
    }
}