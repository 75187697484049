import { useEffect, useMemo} from 'react'
import { useLocation } from 'react-router-dom'
import background from '../../assets/login_background.svg'
import { OrgType, SF_DOMAINS } from './SalesforceLogin';

export const SFLoginAdapter = () => {
  const { search } = useLocation();
  const search_params = useMemo(() => new URLSearchParams(search), [search]);

  const org_type = search_params.get("type") as OrgType
  const domain = SF_DOMAINS[org_type]
  const client_id = process.env.REACT_APP_SFDC_APP_CLIENT_ID
  const callback_url = window.origin + '/sflogin'
  const login_url = `${domain}/services/oauth2/authorize?`
                    + `client_id=${client_id}`
                    + `&redirect_uri=${callback_url}`
                    + '&response_type=code&prompt=login+consent'
                    + '&display=popup';

  useEffect(() => {
    const code = search_params.get("code")
    if (code) (window.opener as any).setCode(code)
    else window.location.href = login_url
  }, [search])

  return (<img src={background} alt="background" style={{ opacity: '0.75', width: '100%', height: '100%' }} />) 
}