import { observer } from 'mobx-react-lite';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { ReactElement } from 'react';
import { Box, Stack } from '@mui/material';

export type CopilotTab = {
    label: string,
    component: ReactElement
}

type CopilotTabsProps = {
    tabs: CopilotTab[]
}

export const CopilotTabs= observer(({ tabs }: CopilotTabsProps) => {
    const [tabValue, setTabValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }

    const A11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }

    return (<>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} {...A11yProps(index)} />
                ))}
            </Tabs>
        </Box>
        <Stack width='100%' style={{ height: '85%' }}>
                {tabs[tabValue].component}
        </Stack>
    </>)
})