type BEIngestStatus = 'New' | 'Running' | 'Done' | 'Aborted' | 'Error' | 'Stranded'
type FEIngestStatus = 'Loading' | 'Waiting' | 'Skipped' | 'Sent'
export type IngestStatus = BEIngestStatus | FEIngestStatus

type ColorDictionary = {
    [key in IngestStatus]?: string;
  };

export const STATUS_COLOR: ColorDictionary = {
    'Waiting': 'grey',
    'Aborted': 'orange',
    'Error': 'red',
    'Stranded': 'red'
}

export const activeIngestStatus = ['Queued', 'Processing']

export const regions = 
[{value: 'US', label: 'US'},
    {value: 'EU', label: 'EU'}]

export const llmTypes = 
[{value: 'OpenAI', label: 'OpenAI'},
    {value: 'Azure OpenAI', label: 'Azure OpenAI'}]

export type Provider =  'OpenAI' | 'Azure OpenAI'
export type EmbeddingModel = 'scop-text-embedding-3-large' | 'scop-embed' | 'text-embedding-ada-002' | 'text-embedding-3-large'