import { observer } from 'mobx-react-lite';
import { MainStore } from '../../stores/MainStore';
import {Button, Grid} from '@material-ui/core';
import React, { useEffect } from 'react';
import { Stack } from '@mui/material';
import { CopilotUploadKA } from './CopilotUploadKA';
import StepConfirmation, { ConfirmationDialogProps } from '../controls/StepConfirmation';
import { SelectionItem } from '../controls/Autocomplete';
import { IngestPayload } from './models';
import { CopilotCaching } from './CopilotCaching';
import { CopilotInputItems, InputItem } from './components/CopilotInputItems';
import { CopilotTab, CopilotTabs } from './components/CopilotTabs';
import useInterval from '../../hooks/interval';
import { activeIngestStatus, regions } from './types';
import { CopilotConfig } from './CopilotConfig';

type CopilotSetupProps = {
    mainStore: MainStore
}

export const CopilotSetup = observer(({ mainStore }: CopilotSetupProps) => {

    const {
        user_email,
        customers, 
        products, 
        manufacturers, 
        models,
        ingestFiles,
        selectedCustomer: customer,
        tenantConfig,
        selectedModel: model,
        productsLoading,
        manufacturersLoading,
        modelsLoading,
        filesLoading,
        HasTriageModels,
        setSelectedCustomer,
        setSelectedProduct,
        setSelectedManufacturer,
        setSelectedModel,
        addNonTriageModel,
        copilotIngest,
        setSelectedRegion,
        refreshFiles,
        refreshFileStatus,
        region,
        selectedFiles
    } = mainStore.copilotStore!

    const [isReady, setIsReady] = React.useState<boolean>(false);
    const [reIngestFiles, setReIngestFiles] = React.useState<boolean>(false);
    const [recreateDisabled, setRecreateDisabled] = React.useState<string>();
    const [launchingIngest, setLaunchingIngest] = React.useState<boolean>(false)
    const [filesInProgress, setfilesInProgress] = React.useState<boolean>(false);
    const [uploaderInProgress, setUploaderInProgress] = React.useState<boolean>(false);
    const [errorDialogProps, setErrorDialogProps] = React.useState<ConfirmationDialogProps | undefined>(undefined)

    useEffect(() => {
        setIsReady(Boolean(customer?.name && customer.name.length > 0 && model?.model_name && model.model_name.length))
    }, [customer, model])

    useEffect(() => {
        if (selectedFiles.length > 0) {
            setReIngestFiles(false)
            setRecreateDisabled("disabled: individual files are selected")
        }
        else if (filesInProgress) {
            setReIngestFiles(false)
            setRecreateDisabled("disabled: model has file ingests in progress")
        }
        else {
            setRecreateDisabled(undefined)
        }
    }, [filesInProgress, selectedFiles])

    useEffect(() => {
        setfilesInProgress(ingestFiles.some(f => activeIngestStatus.includes(f.status)))
    }, [ingestFiles])

    const inputItems: InputItem[] = [
        {
            type: 'selection',
            label: "Region",
            onChange: (item?: any) => {
                setSelectedRegion(item)
            },
            items: regions,
            isReady: () => customers?.length > 0,
            showLoading: () => true,
            selectedItem: region
        },
        {
            type: 'select',
            label: "Customer",
            onChange: (item?: SelectionItem) => setSelectedCustomer(item?.value),
            items: customers.slice().sort((c1, c2) => c1.name.localeCompare(c2.name)).map(tc => {return {value: tc.id, label: tc.name}}),
            selectedItem: customer ? {value: customer.id, label: customer.name} : undefined,
            isReady: () => customers?.length > 0,
            showLoading: () => true,
        }, {
            type: 'select',
            label: "Product Type [Optional]",
            onChange: (item?: SelectionItem) => setSelectedProduct(item?.value),
            items: products.map(tc => {return {value: tc.id, label: tc.producttype_name}}),
            isReady: () => Boolean(customer),
            showLoading: () => productsLoading,
            disabled: !HasTriageModels
        }, {
            type: 'select',
            label: "Manufacturer [Optional]",
            onChange: (item?: SelectionItem) => setSelectedManufacturer(item?.value),
            items: manufacturers.map(tc => {return {value: tc.id, label: tc.manufacturer_name}}),
            isReady: () => Boolean(customer),
            showLoading: () => manufacturersLoading,
            disabled: !HasTriageModels
        }, {
            type: 'select',
            label: "Model",
            onChange: (item?: SelectionItem) => setSelectedModel(item?.value),
            onAddSelectionItem: (item: SelectionItem) => {
                setTimeout(() => {
                    const trimmedModelName = item.value.trimEnd()
                    addNonTriageModel(trimmedModelName)
                    setSelectedModel(trimmedModelName)
                }, 500)
            },
            verifyAdd: (item: SelectionItem) => !models.map(m => m.model_name).includes(item.value),
            items: models.map(tc => {return {value: tc.id, label: tc.model_name}}),
            isReady: () => Boolean(customer),
            showLoading: () => modelsLoading,
            canAdd: () => true,
            disabled: modelsLoading
        }, {
            type: 'checkbox',
            label: "Recreate Model" + (recreateDisabled ? ` (${recreateDisabled})` : ''),
            onChange: () => setReIngestFiles(!reIngestFiles),
            value: reIngestFiles,
            isReady: () => true,
            showLoading: () => false,
            disabled: Boolean(recreateDisabled)
        }
    ]

    const onIngest = async () => {
        setLaunchingIngest(true)
        const document_names = selectedFiles?.map(f => f.document_name) || []

        const ingest_payload: IngestPayload = {
            document_names: document_names,
            recreate: reIngestFiles,
            requested_by_email: user_email
        }
        await copilotIngest(customer!.name, model!.model_name, ingest_payload)
        await refreshFiles()

        setLaunchingIngest(false)
    }

    useInterval(async () => {
        if (filesInProgress && customer?.name && customer.name.length > 0 && model?.model_name && model.model_name.length > 0) 
            await refreshFileStatus()
    }, 2000);

    const isEnabled = () => {
        return customer?.name && model?.model_name && !filesLoading && ingestFiles.length > 0
    }

    const tabs: CopilotTab[] = [
        {
            label: "Knowledge Articles",
            component: <CopilotUploadKA store={mainStore} disabled={!isReady} inProgress={setUploaderInProgress}/>
        },
        {
            label: "Caching",
            component: <CopilotCaching store={mainStore}/>
        },
        {
            label: isEnabled() && !tenantConfig ? "Config [Save to enable Ingest]" : "Config",
            component: <CopilotConfig copilotStore={mainStore!.copilotStore!} />
        }
    ]

    return (<>
        <Grid container spacing={6} style={{ flex:1, padding: 10}}>
            <Grid item xs={6} style={{ height: '100%', overflow: 'auto'}}>
                <Stack direction={'row'}>
                    <CopilotInputItems inputItems={inputItems}/>
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <CopilotTabs tabs={tabs}/>
                <div style={{ paddingRight: 10, display: 'flex', alignItems: 'flex-end', justifyItems: 'flex-end', justifyContent: 'flex-end', gap: '8px' }}>
                    {isEnabled() && !tenantConfig && <div style={{ color: 'orange'}}>Please save a tenant configuration before ingesting!</div>}
                    <Button variant='outlined'
                        style={{ width: '20%' }}
                        onClick={onIngest}
                        disabled={!isEnabled() || uploaderInProgress || launchingIngest || !tenantConfig}
                    >Ingest</Button>
                </div>
            </Grid>
        </Grid>
        {errorDialogProps && <StepConfirmation
            confirmationDialogs={[errorDialogProps]}
            finish={() => { }}
            cancel={() => setErrorDialogProps(undefined)}
            isValid={true}
        />}
    </>)
})