import { Button, createStyles, Dialog, Theme, withStyles } from "@material-ui/core"

export const SidButtonBase = withStyles((theme: Theme) => createStyles({
    root: {
        textTransform: 'capitalize',
        '&:hover': {
            opacity: 0.7
        }
    },
}))(Button)

export const PrimaryButton = withStyles((theme: Theme) => createStyles({
    root: {
        backgroundColor: '#2468FF',
        color: '#F6F7F7',
        '&:hover': {
            color: '#2468FF'
        }
    },
}))(SidButtonBase)

export const SecondaryButton = withStyles((theme: Theme) => createStyles({
    root: {
        background: '#EAF0FF',
        color: '#2468FF',
    },
}))(SidButtonBase)

export const TeriaryButton = withStyles((theme: Theme) => createStyles({
    root: {
        background: 'unset',
        color: '#2468FF',
    }
}))(SidButtonBase)

