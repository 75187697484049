export class Operations {
    static Process: string = 'process'
    static Inject: string = 'inject'
    static Validate: string = 'validate'
    static ManualInjection: string = 'manual_injection'
    static Finalizer: string = 'finalizer'
    static UpdateDashboard: string = 'update_dashboard'
    static UnifiedRoute: string = 'unified_route'
    static WPISimRoute: string = 'wpi_simulator_route'
    static EditTemplate: string = 'edit_template'
    static InjectMetadata: string = 'inject_metadata'
    static CollectCustomersDs: string = 'collect_customers_ds'
    static CopyCsv: string = 'copy_csv'
    static EditOrganizationDefs = 'edit_organization_definitions'
    static DigestWizard: string = 'digest_wizard'
    static UserManager: string = 'user_manager'
}