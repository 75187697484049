import React, { useEffect, useState, useCallback } from 'react'
import { Button, FormControlLabel, LinearProgress, makeStyles, Radio, Typography } from '@material-ui/core'
import { MainStore } from '../../stores/MainStore';
import { observer } from 'mobx-react-lite';
import { useDropzone } from 'react-dropzone'
import { DataGrid, GridColDef, GridRowId } from '@material-ui/data-grid';
import StepConfirmation, { ConfirmationDialogProps, defaultConfirmationDialogProps } from '../controls/StepConfirmation';
import AquantWarningIcon from '../../assets/aq-warning.svg'
import { Stack } from '@mui/material';
import { activeIngestStatus } from './types';

const useStyles = makeStyles((theme) => ({
    separator: {
        width: '100%',
        minHeight: 75,
        backgroundColor: '#ffffff',
        borderStyle: 'dashed',
        border: '1px dashed #858F9F',
        boxSizing: 'border-box',
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#858F9F'
    },
    root: {
        "& .MuiTypography-root": {
            minWidth: '100%',
        }
    },
    textbtn: {
        textTransform: 'none',
        backgroundColor: 'transparent',
        fontSize: "inherit",
        fontWeight: 'inherit',
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        },
        lineHeight: '19px'
    },

}));

type CopilotUploadKAProps = {
    store: MainStore
    disabled?: boolean
    inProgress: (inProgress: boolean) => void
}

export const CopilotUploadKA = observer(({ store, disabled, inProgress }: CopilotUploadKAProps) => {
    const {
        folderName, 
        ingestFiles: files, 
        filesLoading, 
        updateFilesContentMetadata, 
        refreshFiles, 
        addIngestFiles, 
        deleteIngestFiles, 
        fetchModels, 
        selectedCustomer,
        setSelectedFiles,
        selectedFilesIds,
        selectedFiles
    } = store.copilotStore!

    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [uploadProgress, setUploadProgress] = useState<Array<{ isUploading: boolean, uploadedFile?: string, position?: number, total?: number }> | undefined>(undefined)
    const [deleteDialogProps, setDeleteDialogProps] = useState<ConfirmationDialogProps | undefined>(undefined)

    const classes = useStyles()

    const disableAll = () => disabled || filesLoading || isDeleting

    const MyDropzone = () => {
        const onDropAccepted = useCallback(acceptedFiles => {
            handleProcess(acceptedFiles)
        }, [])
        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDropAccepted, accept: ['application/pdf', 'video/mp4'], disabled: disableAll() })

        return (
            <div className={classes.separator}
                {...getRootProps()}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop The KA File Here ...</p> :
                        <p>Drag & Drop your KA File or Click To Select (PDF, MP4)</p>
                }
            </div>
        )
    }

    const handleProcess = async (files: Array<File>) => {
        const newUploadedFiles: Array<{ isUploading: boolean, uploadedFile?: string, position?: number, total?: number }> =
            files.map(f => ({ uploadedFile: f.name, isUploading: true, position: undefined, total: undefined }))
        setUploadProgress(newUploadedFiles)

        const fileNames = files.map(f => f.name)
        const presignedDataUrls = await store.copilotStore!.fetchKaUploadUrlByFileName(fileNames)
        const uploads: Promise<any>[] = []
        presignedDataUrls.forEach((presignedData: any) => {
            const file = files.find(f => presignedData.object_name.toString().endsWith(f.name))
            if (file)
                uploads.push(store.uploadToPresignedUrl(file, presignedData, (position: number, total: number) => {
                    const foundIndex = newUploadedFiles.findIndex(up => up.uploadedFile === file.name)
                    if (foundIndex >= 0) {
                        const copy = [...newUploadedFiles]
                        copy[foundIndex].position = position
                        copy[foundIndex].total = total
                        setUploadProgress(copy)
                    }
                }))
        });
        await Promise.all(uploads)
        await addIngestFiles(fileNames)
        await updateFilesContentMetadata(fileNames, folderName! + '/')
        await refreshFiles()

        setUploadProgress(undefined)
    }

    const normalize = (value: number, uploadProgress: { isUploading: boolean, uploadedFile?: string, position?: number, total?: number }) =>
        uploadProgress && uploadProgress.total ? (value - 0) * 100 / (uploadProgress.total - 0) : 0;

    const columns: GridColDef[] = [
        {
            field: 'document_name',
            headerName: 'Name',
            flex: 3,
            editable: false
        },
        {
            field: 'created_at',
            headerName: 'Last Modified',
            type: 'date',
            flex: 2,
            width: 200,
            editable: false,
            valueFormatter: (value: any) => {
                const localDate = new Date(Date.parse(value.value))
                return localDate.toLocaleString()
            }
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            editable: false
        },
        // {
        //     field: " ",
        //     headerName: "",
        //     flex: 1,
        //     width: 100,
        //     sortable: false,
        //     disableClickEventBubbling: true,
        //     hide: disableActions,
        //     renderCell: (params: GridCellParams) => {
        //         return (<Link href="#" style={{ color: '#17B1E2' }} onClick={() => {
        //             onDownload(params.row as StorageFile)
        //         }}>
        //             Download
        //         </Link>)
        //     }
        // },
    ];

    const setGridSelectedFile = (selected: GridRowId[]) => {
        const selectedFiles = files.filter(f => (selected as string[]).includes(f.id))
        setSelectedFiles(selectedFiles)
    }

    const onDelete = async () => {
        inProgress(true)
        setDeleteDialogProps(undefined)
        setIsDeleting(true)
        await deleteIngestFiles(selectedFiles)
        await fetchModels(selectedCustomer?.id)
        await refreshFiles()
        setIsDeleting(false)
        inProgress(uploadProgress !== undefined)
    }

    const getDeleteConfirmationDialog = () => {
        const saveConfirmProps: ConfirmationDialogProps = {
            ...defaultConfirmationDialogProps,
            title: 'Delete Files & Clear Cache?',
            content: <>
                <div style={{ display: 'flex', marginBottom: 15, marginTop: 15 }} >
                    <Typography variant='subtitle1'>
                        <img src={AquantWarningIcon} alt='warning-icon' style={{ marginRight: 5 }} />
                        This action will remove the selected files and clear all cached responses generated using them.
                    </Typography>
                </div>
                <Typography variant='body1' style={{marginTop: 15}}>
                    Are you sure you want to proceed?
                </Typography>
            </>,
            onYes: onDelete,
            yesLabel: 'Yes & Delete',
            closeLabel: 'No',
        }

        return saveConfirmProps
    }

    useEffect(() => {
         inProgress(uploadProgress !== undefined)
    }, [uploadProgress, inProgress])

    const filteredUploadProgress = uploadProgress && uploadProgress.filter(up => !up.position || normalize(up.position, up) < 100)

    return (
        <div style={{ height: '85%', maxHeight: '85%' }}>
            {<MyDropzone />}
            <div style={{ marginTop: '10px', display: "flex", gap: "10px", alignItems: "center"}}>
            {!uploadProgress && <Button onClick={() => setDeleteDialogProps(getDeleteConfirmationDialog())}
                    disabled={disableAll() || files.length === 0 || selectedFilesIds.length > 10}>Delete Files</Button>}
            <Stack>
                {selectedFilesIds.length > 0 && <div style={{ color: 'orange'}}>! Recreate Model is disabled since individual files are selected !</div>}
                {selectedFilesIds.length > 10 && <div style={{ color: 'red'}}>Delete is currently restricted to batches of up to 10 files. Please unselect files to proceed.</div>}
            </Stack>
            </div>
            {filteredUploadProgress && <div style={{overflowY: 'scroll', height: 'calc(100% - 130px)',  marginTop: 10, maxHeight: '65vh'}}>
                {filteredUploadProgress.length === 0 && <LinearProgress variant="indeterminate"></LinearProgress>}
                {filteredUploadProgress.map(up => up.isUploading &&
                    <div key={up.uploadedFile} style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControlLabel disabled value={''} control={<Radio />} label={up.uploadedFile} />
                        {up.position && <LinearProgress variant="determinate" value={normalize(up.position, up)} />}
                    </div>)}
            </div>}
            {!uploadProgress && <div style={{ height: 'calc(100% - 130px)', marginTop: 10 }}>
                {files && <DataGrid
                    rows={files}
                    columns={columns}
                    pageSize={9}
                    getRowId={row => row.id}
                    checkboxSelection
                    isRowSelectable={(params) => !activeIngestStatus.includes(params.row.status)}
                    selectionModel={selectedFilesIds}
                    onSelectionModelChange={(model: any) => setGridSelectedFile(model.selectionModel)}
                    loading={filesLoading || isDeleting}
                />}
            </div>}
            {deleteDialogProps && <StepConfirmation
                confirmationDialogs={[deleteDialogProps]}
                finish={() => { }}
                cancel={() => setDeleteDialogProps(undefined)}
                isValid={true}
            />}
        </div>
    );
})