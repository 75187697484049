import { MainStore } from '../../stores/MainStore';
import { observer } from 'mobx-react-lite';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';
import { SelectCsv2 } from './SelectCsv2';
import { SelectCsvPartial } from './SelectCsvPartial';

type SelectCsvProps = {
    folderName: string
    store: MainStore
    initialState?: 'Full' | 'Partial'
    onModeSelected?: (mode: 'Full' | 'Partial') => void
}
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div>
                    <Typography>{children}</Typography>
                </div>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const SelectCsv = observer(({ folderName, store, initialState, onModeSelected }: SelectCsvProps) => {
    const [value, setValue] = React.useState(0);

    React.useEffect(() => {
        if (initialState)
        setValue(initialState === 'Full' ? 0 : 1)
    }, [initialState])

    const handleChange: any = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        onModeSelected && onModeSelected(newValue === 0 ? 'Full' :  'Partial')
    };

    return (
        <div style={{ width: '100%' }}>
            <div style={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} >
                    <Tab label="Full" {...a11yProps(0)} />
                    <Tab label="Partial" {...a11yProps(1)} />
                </Tabs>
            </div>
            <TabPanel value={value} index={0}>
                <SelectCsv2 folderName={folderName} store={store} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <SelectCsvPartial folderName={folderName} store={store} />
            </TabPanel>
        </div>
    );
})