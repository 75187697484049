import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme, withStyles, createStyles, Divider} from '@material-ui/core'
import { PrimaryButton, TeriaryButton } from './buttons'

interface IGeneralDialogProps {
  open: boolean
  onClose: () => void
  onSave: () => void
  title?: string
  text?: string
  applyText?: string
  saveDisabled?: boolean
  children?: JSX.Element | JSX.Element[];
}

export const DialogCard = withStyles((theme: Theme) => createStyles({
  paper: {
      borderRadius: '14px',
      minWidth: '40%'
  }
}))(Dialog)

export const GeneralDialog = ({open, onClose, onSave, title, text, applyText, saveDisabled, children}: IGeneralDialogProps) => {
    return (<DialogCard open={open} onClose={onClose}>
              <DialogTitle>{title ? title : "Save Changes"}</DialogTitle>

              <Divider />
              
              <DialogContent>
                <DialogContentText>
                  {text ? text : "Do you want to save changes?"}  
                </DialogContentText>
                {children}
              </DialogContent>
              <DialogActions>
                <TeriaryButton onClick={onClose}>Cancel</TeriaryButton>
                <PrimaryButton onClick={onSave} disabled={saveDisabled}>{applyText ? applyText : 'Save'}</PrimaryButton>
              </DialogActions>
            </DialogCard>)
}