import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { ProgressPushMessage } from '../../models/ProgressPushMessage'
import { MainStore } from '../../stores/MainStore'
import ProgressBar from '../controls/ProgressBar'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'white',
        border: "1px solid #BFC5CD",
        margin: '5px 0',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightLight, // this fails compilation
    }
}));

type ValidateProps = {
    store: MainStore
}

export const Validate = observer(({ store }: ValidateProps) => {
    const classes = useStyles()
    const [state, setState] = React.useState(0)
    const { processProgress } = store

    const onProcess = (runValidations: boolean) => {
        setState(1)
        store.validate()
            .then(() => {
                setState(3)
            })
            .catch(() => setState(2))
    }

    const renderItem = (message: ProgressPushMessage) => {
        return <div key={message.subject} className={classes.root} style={{ marginBottom: message.subject === 'Complete Process' ? 20 : 0 }}>
            <Accordion
                expanded={message.state === 'fail' || message.state === 'warn'}>
                <AccordionSummary
                    expandIcon={<React.Fragment />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <Typography className={classes.heading} style={{ marginRight: 20, marginLeft: 20, width: 160 }}>{message.subject === 'Complete Process' ? 'Overall' : message.subject}</Typography>
                        <ProgressBar
                            style={{ marginLeft: 20 }}
                            fillColor={message.state && message.state === 'fail' ? 'red' : message.state && message.state === 'warn' ? '#eed202' : message.subject === 'Complete Process' ? '#57D89D' : '#17B1E2'}
                            completed={message.progress} />
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {message.message && message.message.split('\n').map((i, key) => {
                            return <Typography key={key} paragraph variant="body1">{i}</Typography>;
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    }



    return (
        <>
            <div style={{ width: '100%' }}>
                <Button variant='outlined' onClick={()=> onProcess(true)}>Validate</Button>
                {processProgress.length > 0 && processProgress.map(item => renderItem(item))}
                {state === 1 && processProgress.length === 0 && <CircularProgress />}
                {state === 2 && <Typography>Error</Typography>}
            </div>
        </>
    )
})