import { Button } from '@material-ui/core'
import { CSSProperties, useEffect, useState } from 'react'

interface IGoButtonProps {
  state: 'idle' | 'running'
  disabled: boolean
  cancel_disabled: boolean
  onGo: () => void
  onCancel: () => void
  style?: CSSProperties
}

export const GoButton = ({state, disabled, cancel_disabled, onGo, onCancel, style}: IGoButtonProps) => {
    const [canceling, setCanceling] = useState(false)

    useEffect(() => {
        if (state === 'idle')
            setCanceling(false)
    }, [state])

    const onCancelClick = () => {
        setCanceling(true)
        onCancel()
    }   

    return (
        <Button style={{ ...style }}
            variant='outlined' 
            onClick={state === "idle" ? onGo : onCancelClick}
            disabled={(state === "idle" && disabled) || (state === "running" && cancel_disabled) || canceling}>
                {state === "idle" ? "Go" : canceling ? "Canceling" : "Cancel"}
        </Button>)
}