import { Box, ClickAwayListener, Divider, List, makeStyles, styled, TextField, Theme, Typography } from "@material-ui/core"
import { useState } from "react"
import { TeriaryButton } from "../controls/buttons"
import BinIcon from '../../assets/bin.svg'
import AquantPlusCircle from '../../assets/aq-plus-circle.svg'


export interface FailedMetric {
    default_threshold: number
    thresholds: {[k: string]: number}
}

const makeClasses = makeStyles((theme: Theme) => ({
    dataGrid: {
        border: 'unset',
        '& .MuiDataGrid-row:hover': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: 'unset',
        },
        '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: theme.palette.background.default
        },
        '& .MuiDataGrid-cell:focus': {
            outline: 'unset',
            cursor: 'text'
        },
        '& .MuiDataGrid-cell': {
            borderBottom: 'unset'
        },
        '& .MuiDataGrid-columnsContainer': {
            borderBottom: 'unset'
        },
        '& .MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '& .MuiDataGrid-row': {
            backgroundColor: 'unset'
        }
    },
    description: {marginTop: 5, marginBottom: 10, color: theme.palette.primary.contrastText},
    tableCellContainer: {
        width: '90%', 
        height: 38, 
        padding: 5, 
        border: '1px solid #dadcdf', 
        borderRadius: 6, 
        display: 'flex', 
        alignItems: 'center'
    }
}))


// wrapper for backwards compatability
const FailedMetricEditor = (props: {val: any, setVal: (val: any) => void}) => {
    const {val, setVal} = props

    const fm: FailedMetric = typeof(val) === 'number' ? {
        default_threshold: val,
        thresholds: {}
    } : {
        default_threshold: val.default_threshold?? val.defaultThreshold, 
        thresholds: val.thresholds
    }

    return <FailedMetricEditorInner failedMetric={fm} setFailedMetric={setVal} />
}

// input validation utils
const validThreshold = (val?: number) => {
    if (val) {
        if (val > 0) {
            if (val % 1 === 0) return true
        }
    }
    return false
}

const validName = (val?: string) =>  !!(val && val.length > 0)

const thresholdMessage = (threshold: number) => {
    if (!threshold) return 'Please enter a threshold' 
    if (threshold <= 0) return 'Threshold must be positive'
    if (threshold % 1 !== 0) return 'Please enter whole number'
    return undefined
}

const CleanField = styled(TextField)(theme => ({
    '& fieldset': {
        borderRadius: 7
    }
}))

const FailedMetricEditorInner = (props: {failedMetric: FailedMetric, setFailedMetric: (fm: FailedMetric) => void}) => {
    const {failedMetric, setFailedMetric} = props
    const classes = makeClasses()

    const [editorOpen, setEditorOpen] = useState(Object.keys(failedMetric.thresholds).length > 0)
    const [editedRow, setEditedRow] = useState<{name: string, threshold: number}>({name: '', threshold: 0})

    const [showValidations, setShowValidations] = useState(false)

    const canAddRow = () => {
        if (showValidations) {
            return validName(editedRow.name) && validThreshold(editedRow.threshold)
        }
        return false
    }

    const tryAddRow = () => {
        if (canAddRow()) {
            setFailedMetric({
                ...failedMetric, 
                thresholds: {...failedMetric.thresholds, [editedRow.name]: editedRow.threshold}
            })
            setShowValidations(false)
            setEditedRow({name: '', threshold: 0})
        }
    }

    return <ClickAwayListener onClickAway={() => {
            setEditedRow({name: '', threshold: 0})
            setEditorOpen(false)
            tryAddRow()
        }}
        >
        <div style={{width: '100%', display: 'flex', flexDirection: 'column', margin: 20 }} >
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', padding: 5, justifyContent: 'space-between'}} >
                <Typography style={{marginRight: 10}} > failed_metric </Typography>

                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                    <Box boxShadow={1} style={{marginRight: editorOpen ? 100 : 0}}>
                        <TextField
                            variant='outlined' 
                            size='small'
                            type='number'
                            value={failedMetric.default_threshold}
                            onChange={e => setFailedMetric({...failedMetric, default_threshold: +e.target.value})}
                        />
                    </Box>

                    {!editorOpen && <TeriaryButton 
                        onClick={() => setEditorOpen(true)}
                        style={{marginLeft: 5}} 
                    >
                        <img src={AquantPlusCircle} alt='open-editor' style={{marginRight: 5}} />
                        Add Customized Threshold
                    </TeriaryButton>}
                </div>
            </div>

            {editorOpen && <Box width='65%' >
                <Divider style={{marginBottom: 20, marginTop: 10}} />

                <Typography className={classes.description} variant='body2'  >
                    Define below customized threshold for specific product type. Not specified product will use the default value.
                </Typography>

                <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: 30 }}>
                    <div style={{display: 'flex', flexDirection: 'column', marginRight: 50 }} >
                        <Typography style={{marginBottom: 15}} >Product Type</Typography>
                        <CleanField
                            variant='outlined' 
                            size='small'
                            value={editedRow.name} 
                            type='text' 
                            onChange={e => {
                                setShowValidations(true)
                                setEditedRow({...editedRow, name: e.target.value})
                            }} 
                            error={showValidations && !validName(editedRow.name)}
                            required
                            helperText={showValidations && !validName(editedRow.name) && 'Please enter product type name'}
                        />
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}} >
                        <Typography style={{marginBottom: 15}} >Threshold</Typography>
                        <CleanField
                            variant='outlined'
                            size='small'
                            value={editedRow.threshold}
                            type='number'
                            onChange={e => {
                                setShowValidations(true)
                                setEditedRow({...editedRow, threshold: +e.target.value})
                            }}
                            error={showValidations && !validThreshold(editedRow.threshold)}
                            helperText={showValidations && thresholdMessage(editedRow.threshold)}
                        />
                    </div>

                    <TeriaryButton 
                        style={{marginLeft: 5, marginRight: 5, paddingTop: 50, maxHeight: 50}} 
                        disabled={!canAddRow()}
                        onClick={tryAddRow} 
                    >
                        Add
                    </TeriaryButton>
                </div>

                <Divider style={{marginTop: 20, marginBottom: 20}} />
                
                <List style={{width: '65'}} >
                    {Object.keys(failedMetric.thresholds).map(k => <div 
                        key={k} 
                        style={{display: 'flex', width: '50%', justifyContent: 'flex-start', alignItems: 'center', paddingBottom: 5}}
                    >
                        <div style={{width: 5, height: 5, border: '5px solid black', borderRadius: '100%', marginRight: 10}} />
                        <Typography style={{marginRight: 10}} > {k} </Typography>
                        <Typography style={{marginRight: 10}} > {'>'} </Typography>
                        <Typography style={{marginRight: 10}} > {failedMetric.thresholds[k]} </Typography>
                        <img 
                            src={BinIcon}
                            alt='remove-item'
                            style={{cursor: 'pointer'}}
                            onClick={() => {
                                let ts = failedMetric.thresholds
                                delete ts[k]
                                setFailedMetric({...failedMetric, thresholds: ts})
                            }}
                        />
                    </div> )}
                </List>
            </Box>}
        </div>
    </ClickAwayListener>
}


export default FailedMetricEditor