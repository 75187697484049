import { useOktaAuth } from "@okta/okta-react"
import { observer } from "mobx-react-lite"
import logger from "./Logger"
import { MainStore } from "./stores/MainStore"

type AppContainerProps = {
    mainStore: MainStore,
    getToken: () => any
    children: any
}

export const AppContainer = observer(({ mainStore, getToken, children }: AppContainerProps) => {
    const { authState, oktaAuth } = useOktaAuth();
    
    if (authState) {
        if (authState.isAuthenticated) {
            if (!mainStore.user) {
                oktaAuth.getUser().then(userInfo => {
                    mainStore.initUser(getToken);
                });
            }
        }
        else if (authState.error) {
            logger.error("Authentication Error", authState.error)
            return <div>Authentication Error!</div>
        }
    }

    return children
})