import { Button, LinearProgress, makeStyles, TextField, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { MainStore } from '../../stores/MainStore';
import { SalesforceLogin } from '../Salesforce components/SalesforceLogin';
import { SelectDashboard } from '../Salesforce components/DashboardSelectionComponent';
import { SelectApp } from '../Salesforce components/AppSelectionComponent';
import { SalesforceApp } from '../../models/SalesforceApp';
import { SalesforceDashboard } from '../../models/SalesforceDashboard';
import { InjectInfo } from '../../models/InjectInfo';
import { observer } from 'mobx-react-lite';
import { OrganizationSelection } from '../wizard/OrganizationSelection';


const useStyles = makeStyles((theme) => ({
    page: {
      margin: '0px 50px 20px 50px',
    },
    step_input: {
      display: 'flex', 
      flexDirection: 'row', 
      marginBottom: '10px'
    }
  }));

type DigestToolProps = {
  mainStore: MainStore
  onSelectApp: (app?: SalesforceApp) => void
  onSelectDashboard: (dashboard?: SalesforceDashboard) => void
}

export const CustomerInformation = observer((props: DigestToolProps) => {
    const {mainStore, onSelectApp, onSelectDashboard} = props
    const {organizations, currentOrg} = mainStore
    const classes = useStyles();
    const [availableApps, setAvailableApps] = useState<SalesforceApp[]>([])
    const [availableDashboards, setAvailableDashboards] = useState<SalesforceDashboard[]>([])
    const [hubspotID, setHubspotID] = useState({id: '', loading: false})

    useEffect(() => {
      if (mainStore.SFToken) {
          const onConnectionAuth = async () => {
              const info: InjectInfo = { app_name: "", ...mainStore.SFToken!}
              setAvailableApps(await mainStore.getAvailableApps(info))
          }
          onConnectionAuth()
      }
    }, [mainStore, mainStore.SFToken])

    useEffect(() => {
      if(mainStore.organizationMetadata?.digest?.hubspot_company_id){
        setHubspotID({id: mainStore.organizationMetadata?.digest?.hubspot_company_id, loading: false})
      } else {
        setHubspotID({id: '', loading: false})
      }
    }, [mainStore.organizationMetadata])

    useEffect(()=>{
      setHubspotID({id: '', loading: true})
    },[mainStore.currentOrg])

    useEffect(()=>{},[hubspotID.loading])

    const onAppSelected = async (app: SalesforceApp) => {
      if (app === undefined) {
          onSelectApp(undefined)
          onSelectDashboard(undefined)
          setAvailableDashboards([])
      }
      else {
          onSelectApp(app)
          const info: InjectInfo = { app_name: app.id, ...mainStore.user!.sfToken! }
          setAvailableDashboards(await mainStore.getDashboards(info))
      }
    }

    const handleHSIDUpdate = () => {
      if(hubspotID.id !== mainStore.organizationMetadata?.digest?.hubspot_company_id){
        const newDigestMD = {...mainStore.getDigestMD()!,hubspot_company_id: hubspotID.id}
        mainStore.updateDigestMD(newDigestMD)
      }
    }

    return (<div className={classes.page}>
              <SalesforceLogin store={mainStore}/>
              {mainStore.SFToken &&
                  <div style={{width: '100%', marginTop: '20px', display:'flex', flexDirection:'row'}}>
                    <div style={{width: '310px', marginRight: '20px'}}>
                      <Typography style={{marginBottom: '10px'}}>Customer</Typography>
                      <OrganizationSelection organizations={organizations} onSelection={async value => await mainStore.setOrganization(value)} selectedOrg={currentOrg}/>
                      <Typography style={{marginBottom: '10px', marginTop: '10px'}}>App</Typography>
                      <SelectApp disabled={!mainStore.SFToken} availableApps={availableApps} onAppSelected={onAppSelected}/>
                      <Typography style={{marginBottom: '10px', marginTop: '10px'}}>Dashboard</Typography>
                      <SelectDashboard disabled={!mainStore.SFToken} availableDashboards={availableDashboards} onDashboardSelected={onSelectDashboard}/>
                    </div>
                    <div>
                      <Typography style={{marginBottom: '10px'}}>HubSpot Company ID</Typography>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        
                          <div style={{marginRight: '20px'}}>
                            {mainStore.currentOrg !== undefined && hubspotID.loading ? 
                              <>
                                <TextField label="Loading" variant="outlined" disabled value=''/>
                                <LinearProgress/>
                              </>
                              : <TextField
                              id="hubspot-id"
                              label="HubSpot Company ID"
                              variant="outlined"
                              value={hubspotID.id}
                              onChange={(e)=>setHubspotID({...hubspotID, id: e.target.value})}
                              disabled={mainStore.currentOrg === undefined}
                              />}
                          </div>
                        <Button variant='outlined'
                          onClick={handleHSIDUpdate}
                          disabled={(mainStore.currentOrg === undefined || hubspotID.loading) || (hubspotID.id !== '' && hubspotID.id === mainStore.organizationMetadata?.digest?.hubspot_company_id)}>
                            Update
                        </Button>
                      </div>
                    </div>
                  </div>}
            </div>)
})