import { Button, CircularProgress, Tooltip } from "@material-ui/core"
import { observer } from "mobx-react-lite"
import { CSSProperties, useEffect, useState } from "react"
import { InjectInfo, TestInfo } from "../../models/InjectInfo"
import { SalesforceApp } from "../../models/SalesforceApp"
import { SalesforceDashboard } from "../../models/SalesforceDashboard"
import { MainStore } from "../../stores/MainStore"
import { SelectApp } from "../Salesforce components/AppSelectionComponent"
import { SelectDashboard } from "../Salesforce components/DashboardSelectionComponent"
import { SalesforceLogin } from "../Salesforce components/SalesforceLogin"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

interface ITemplateTestComponentProps {
    store: MainStore
    test: string
    template: string
    style?: CSSProperties;
}

export const TemplateTestComponent = observer((props: ITemplateTestComponentProps) => {
    const {store, test, template} = props
    const [availableApps, setAvailableApps] = useState<SalesforceApp[]>([])
    const [availableDashboards, setAvailableDashboards] = useState<SalesforceDashboard[]>([])
    const [selectedApp, setSelectedApp] = useState<SalesforceApp>()
    const [selectedDashboard, setSelectedDashboard] = useState<SalesforceDashboard>()
    const [inProgress, setInProgress] = useState<Boolean>(false)

    useEffect(() => {
        store.connectTestNotificationService()
    }, [])

    useEffect(() => {
        if (store.SFToken) {
            const onConnectionAuth = async () => {
                const info: InjectInfo = { app_name: "", ...store.SFToken!}
                setAvailableApps(await store.getAvailableApps(info))
            }
            onConnectionAuth()
        }
        else {
            setSelectedApp(undefined)
            store.testProgress = []
        }
    }, [store, store.SFToken])

    useEffect(() => {
        const complete_m = store.testProgress.find(m => m.progress === 100)
        if (complete_m) setInProgress(false)
    }, [store.testProgress, store.testProgress.length])

    const runTest = () => {
        setInProgress(true)
        const info: TestInfo = {
            test: test,
            app_name: selectedApp!.label,
            dashboard_name: selectedDashboard!.name,
            template: template,
            ...store.user!.sfToken!
        }
        store.testTemplate(info)
    }

    const buttonDisabled = !(store.SFToken && selectedDashboard !== undefined)

    const onAppSelected = async (app: SalesforceApp) => {
        setSelectedApp(app)
        if (app === undefined) {
            setSelectedDashboard(undefined)
            setAvailableDashboards([])
        }
        else {
            const info: InjectInfo = { app_name: app.id, ...store.user!.sfToken! }
            setAvailableDashboards(await store.getDashboards(info))
        }
    }

    const testResults = document.getElementById('test-results')
    if (testResults) testResults.scrollTop = testResults.scrollHeight

    return (
        <div style={{ display: 'flex', flexDirection: 'row', ...props.style}}>
            { store.SFToken && <Tooltip title={test === 'dashboard' ? "Fetch dashboard from SalesForce" : "Run Test"}>
                <Button style={{ marginRight: '15px', height: '56px'}} variant='outlined' onClick={runTest} disabled={buttonDisabled}>
                {test === 'dashboard' ? <CloudDownloadIcon/> : 'Test'}</Button>
                </Tooltip>}
            <div>
                {store.SFToken &&
                    <div style={{width: '310px'}}>
                        <SelectApp disabled={!store.SFToken} availableApps={availableApps} onAppSelected={onAppSelected}/>
                        <SelectDashboard style={{marginTop: '5px'}} disabled={!store.SFToken} availableDashboards={availableDashboards} onDashboardSelected={setSelectedDashboard}/>
                    </div>
                }
                <div style={{ height: '56px'}}>
                    <SalesforceLogin store={store}/>
                </div>
            </div>
            <div id='test-results' style={{marginLeft: '15px', whiteSpace: "pre-line", height: '100px', minWidth: '200px', overflow: 'auto'}}>
                {store.testProgress && store.testProgress.map((item, i) => <div style={{marginRight: '10px'}} key={i}>{item.subject}</div>)}
                {inProgress && <CircularProgress />}
            </div>
            {!inProgress && store.testProgress.length > 0 && 
                <div style={{marginLeft: '10px'}}> 
                    <Button variant='outlined' onClick={() => store.testProgress = []}>Clear</Button>
                </div>
            }
        </div>
    )
})