import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, makeStyles, MenuItem, Select, TextField, Typography} from '@material-ui/core'
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    row: {
      display: "flex",
      gap: "8px",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "8px"
    },
    input: {
      width: "100px"
    },
    progressBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      position: "absolute",
      backgroundColor: "rgba(237, 231, 225, 0.2)",
      zIndex: 50,
    }
  }));


export interface ICloneDetails {
  clone: boolean
  version: string
  fields: boolean
  patches: boolean
  dashboard: boolean
}

interface ICreateTemplateDialogProps {
  versions: string[]
  version: string;
  open: boolean
  onCreate: (version: string, cloneDetails: ICloneDetails) => Promise<void>
  onCancel: () => void
  onClose: () => void
}

export const CreateTemplateDialog = ({versions, version, open, onCreate, onCancel, onClose}: ICreateTemplateDialogProps) => {
    const classes = useStyles();
    const [clonedVersion, setClonedVersion] = useState<string>("");
    const [fields, setFields] = useState(true)
    const [patches, setPatches] = useState(true)
    const [dashboard, setDashboard] = useState(false)
    const [selection, setSelection] = useState<JSX.Element[]>([]);
    const [inProgress, setInProgress] = useState(false)

    useEffect(() => {
      const reversedVersions = versions.slice().reverse()
      setSelection(reversedVersions.map((version, i) => <MenuItem key={i} value={version}>{version}</MenuItem>))
      setClonedVersion(reversedVersions[0])
    }, [versions])  

    const handleCreate = async () => {
      const cloneDetails: ICloneDetails = {
        clone: clonedVersion !== "",
        version: clonedVersion,
        fields: fields,
        patches: patches,
        dashboard: dashboard
      }
      setInProgress(true)
      await onCreate(version, cloneDetails)
      setInProgress(false)
      onClose()
    }

    const handleCancel = () => {
      if (!inProgress) {
        onCancel()
        onClose()
      }
    }

    return (<Dialog open={open} onClose={handleCancel}>
              {inProgress && <div className={classes.progressBox}><CircularProgress/></div>}
              <DialogTitle>Create New Template</DialogTitle>
              <DialogContent>
                <div className={classes.row}>
                  <Typography>
                    Template version: 
                  </Typography>
                  <Typography className={classes.input}>
                    {version}
                  </Typography>
                </div>
                <div className={classes.row}>
                  <Typography>
                    Clone from template: 
                  </Typography>
                  <Select
                    className={classes.input}
                    value={clonedVersion}
                    onChange={(event) => setClonedVersion(event.target.value as string)}>
                    {selection}
                  </Select>
                </div>
                {clonedVersion !== "" &&
                  <FormGroup>
                    <FormControlLabel control={<Checkbox color='primary' checked={fields} onChange={(event) => setFields(event.target.checked)}/>} label="Fields" />
                    <FormControlLabel control={<Checkbox color='primary' checked={patches} onChange={(event) => setPatches(event.target.checked)}/>} label="Patches" />
                    <FormControlLabel control={<Checkbox color='primary' checked={dashboard} onChange={(event) => setDashboard(event.target.checked)}/>} label="Dashboard" />
                  </FormGroup>
                }
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleCreate}>Create</Button>
              </DialogActions>
            </Dialog>)
}