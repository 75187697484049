import { List, ListItem, Typography } from "@material-ui/core"
import { ConfigItem } from "../../models/ConfigItem"
import { CSSProperties, useRef, useState } from "react";
import AquantCancelIcon from '../../assets/aq-cancel-circle.svg'
import AquantWarningIcon from '../../assets/aq-warning.svg'

interface MultipleAssignmentsEditorProps {
    multipleAssignments: {[value: string]: ConfigItem[]}
    clearAssignment: (item: ConfigItem) => void
}

const styles: {[component: string]: CSSProperties} = {
    rowContainer: {display: 'flex', alignItems: 'center', marginBottom: 5},
    deletableContainer: {
        border: '1px solid #DADCDF', 
        borderRadius: 10, 
        position: 'relative', 
        marginLeft: 5, 
        marginRight: 5, 
        width: 'fit-content',
        marginBottom: 5,
        cursor: 'pointer'
    },
}

const MultipleAssignmentsEditor = ({multipleAssignments, clearAssignment}: MultipleAssignmentsEditorProps) => {

    const rows: JSX.Element[] = []

    const [edits, setEdits] = useState(multipleAssignments)

    for (const value in edits) {
        rows.push(<div key={value} style={styles.rowContainer}>

            <div style={{display: 'flex', alignItems: 'center'}} >
                <div style={{background: '#3B3B3B', borderRadius: '50%', border: '4px solid #3B3B3B', width: 2, height: 2}} />
                <Typography variant='body2' style={{marginRight: 10, padding: 5}} > "{value}" </Typography>
            </div>

            <List style={{ display: 'flex', marginLeft: 10, flexWrap: 'wrap' }} >
                {edits[value].map(item => <ListItem
                        style={styles.deletableContainer}
                        onClick={() => {
                            const edited: {[value: string]: ConfigItem[]} = JSON.parse(JSON.stringify(edits))
                            const editedValueList = edited[value].filter(ed => ed.name !== item.name)
                            edited[value] = editedValueList

                            setEdits(edited)
                            clearAssignment(item)
                        }}
                    >
                        <img src={AquantCancelIcon} style={{position: 'absolute', top: -5, left: 'calc(92%)'}} alt='cancel icon' />
                        <Typography variant='body2' style={{paddingRight: 5}}> {item.name} </Typography>
                    </ListItem>
                )}
            </List>
        </div>)
    }

    return <>
        <div style={{display: 'flex', marginBottom: 10}} >
            <img src={AquantWarningIcon} style={{marginRight: 10}} alt='warning icon' />
            <Typography variant='subtitle1' > Multiple values were mapped to a single field </Typography>
        </div>
        <Typography variant='body1' style={{marginBottom: 25}} > Please remove any irrelevant fields if necessary and confirm </Typography>

        {rows}
    </>
}

export default MultipleAssignmentsEditor