import { Log, CoralogixLogger, LoggerConfig, Severity as CoralogixSeverity } from "coralogix-logger";

export interface ILogger {
    debug(text: any):void
    info(text: any):void
    warn(text: any):void
    error(text: any, exception: any):void
}


class Logger implements ILogger {

    logger: CoralogixLogger

    private config = new LoggerConfig({
        applicationName: "aq-si-fe",
        privateKey: "316d4eb7-516a-a680-4a41-6ea0bdfb5dbc",
        subsystemName: window.location.href,
    });

    constructor() {
        CoralogixLogger.configure(this.config);

        // create a new logger with category
        this.logger = new CoralogixLogger("insights_general")

        console.log("logger created")
    }

    debug = (text: string) => {
        console.log('debug', text)
        this.log(CoralogixSeverity.debug, text)
    }

    info = (text: string) => {
        console.log('info', text)
        this.log(CoralogixSeverity.info, text)
    }

    warn = (text: string) => {
        console.log('warn', text)
        this.log(CoralogixSeverity.warning, text)
    }

    error = (text: any, exception: any) => {
        console.log('error', text, exception)
        this.log(CoralogixSeverity.error, exception)
    }

    private log = (severity: CoralogixSeverity, text: any) => {

        // send log to coralogix
        this.logger.addLog(new Log({
            severity: severity,
            text: text,
        }));
    }
}

const logger: ILogger = new Logger()
export default logger;