import { makeStyles, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { MainStore } from '../../stores/MainStore';
import { observer } from 'mobx-react-lite';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { OrganizationSelection } from '../wizard/OrganizationSelection';
import { SelectCsv2 } from '../wizard/SelectCsv2';
import { FinalizeTab } from './FinalizeTab';

const useStyles = makeStyles((theme) => ({
    page: {
      margin: '50px',
    }
  }));

type FinazlierProps = {
  mainStore: MainStore
}

export const Finalizer = observer(({mainStore}: FinazlierProps) => {
    const classes = useStyles();
    const {organizations, currentOrg} = mainStore
    const [expanded, setExpanded] = useState<string | false>('panel1');

    useEffect(() => {
      mainStore.connectTestNotificationService()
    }, [])    

    const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

    return (<div className={classes.page}>
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Select Customer</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div style={{width: '310px', marginRight: '20px'}}>
                    <OrganizationSelection organizations={organizations} onSelection={async value => await mainStore.setOrganization(value)} selectedOrg={currentOrg}/>
                   </div>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} disabled={mainStore.CsvFiles === undefined}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Select Folder</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <SelectCsv2 store={mainStore} folderName={''} disableActions disableUpload/>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} disabled={mainStore.CsvFiles === undefined}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Finalize</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FinalizeTab mainStore={mainStore}/>
                </AccordionDetails>
              </Accordion>
            </div>)
})