import logger from "../Logger"

const DEFAULT_EXPIRATION_MILLISECONDS = 3595000 //60 min

export interface TokenRefreshArgs {
    expiresInMs?: number,
    refreshFunc: () => any,
    onTokenRefresh?: (token: any) => void
    isSuccessful?: (token: any) => boolean
    getExpiresIn?: (token: any) => number
}

export default class TokenRefreshService {
    __timeoutId: any = undefined

    constructor(args: TokenRefreshArgs) {
        const { expiresInMs, refreshFunc, onTokenRefresh, isSuccessful, getExpiresIn} = args
        const refreshTiming = expiresInMs ?? DEFAULT_EXPIRATION_MILLISECONDS
        const refreshToken = async () => {
            logger.info('attempting a token refresh')
            const token = await refreshFunc()
            if (isSuccessful && !isSuccessful(token)) {
                logger.info('token refresh failed')
                return
            }
            const newRefreshTiming = getExpiresIn?.(token) ?? refreshTiming
            onTokenRefresh?.(token)
            logger.info('token refresh successful')
            this.__timeoutId = setTimeout(refreshToken, newRefreshTiming)
        }
        this.__timeoutId = setTimeout(refreshToken, refreshTiming)
    }

    stop = () => clearTimeout(this.__timeoutId)
}