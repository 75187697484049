import { Accordion, AccordionDetails, AccordionSummary, Button, IconButton, TextField, Typography, CircularProgress } from "@material-ui/core";
import Tooltip from '@mui/material/Tooltip';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { MainStore } from "../../stores/MainStore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { GeneralDialog } from "../controls/GeneralDialog";

interface ISelectInputCSVProps {
    mainStore: MainStore
}

interface ITemplateSubCause {
    mainStore: MainStore,
    cause: string,
    subCause: string,
    sentences: any
}

interface ITemplateSentenceData {
    mainStore: MainStore,
    cause: string,
    subCause: string,
    text: string,
    index: number
}

export const MailTemplatesSettings = observer(({mainStore}: ISelectInputCSVProps) => {

    const { digestTemplates } = mainStore

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(null)
    const [renameDialogOpen, setRenameDialogOpen] = useState(null)
    const [causeRenameText, setCauseRenameText] = useState('')
    
    const getTemplates = async () => {
        return await mainStore.getDigestTemplates()
    }

    useEffect(() => {
        getTemplates()
    }, [])

    useEffect(() => {
        
    },[digestTemplates])

    const renderCause = (cause: any, subCauses: any, index: number) => {
        return (
            <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{cause}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <div>
                        <Button
                            style={{ alignSelf: 'flex-start', margin:10, padding: '5px 20px' }}
                            variant="outlined"
                            onClick={()=>mainStore.addNewSubCause('attribution_model', cause)}>
                            Add New Sub Cause
                        </Button>
                        <Button
                            style={{ alignSelf: 'flex-start', margin:10, padding: '5px 20px' }}
                            variant="outlined"
                            onClick={()=>setRenameDialogOpen(cause)}>
                            Rename {cause}
                        </Button>
                        <Button
                            style={{ alignSelf: 'flex-start', margin:10, padding: '5px 20px', color: "red", borderColor: "red" }}
                            variant="outlined"
                            onClick={()=>setDeleteDialogOpen(cause)}>
                            Delete {cause}
                        </Button>
                        <GeneralDialog
                            open={deleteDialogOpen === cause}
                            onClose={()=>setDeleteDialogOpen(null)}
                            onSave={()=>mainStore.deleteCause('attribution_model', cause)}
                            title={`Delete ${cause}`}
                            text={`Are you sure?`}
                            applyText='Delete'
                        />
                        <GeneralDialog
                            open={renameDialogOpen === cause}
                            onClose={()=>setRenameDialogOpen(null)}
                            onSave={()=>mainStore.updateCause('attribution_model', cause, causeRenameText)}
                            title={`Rename ${cause}`}
                            text={`Enter new cause title:`}
                            applyText='Rename'
                        >
                            <TextField
                                id={`rename-cause-${cause}`}
                                variant="outlined"
                                value={causeRenameText}
                                onChange={(e)=>setCauseRenameText(e.target.value)}
                                style={{margin: 20}}
                            />
                        </GeneralDialog>
                    </div>
                    {Object.entries(subCauses).map(([subCause, sentences]: [any, any], index) => <TemplateSubCause mainStore={mainStore} cause={cause} subCause={subCause} sentences={sentences} />)}
                    </div>
                </AccordionDetails>
            </Accordion> 
        )
    }

    return (<div style={{width: '100%'}}>
                {!digestTemplates && <div style={{display:'flex', flexDirection:'column', alignSelf: 'center', width: '100%', alignItems: 'center', margin: 20}}><CircularProgress style={{margin: 20}} />Loading Templates...  </div>}
                {digestTemplates && digestTemplates['attribution_model'] &&
                (<>
                <Button
                        style={{ alignSelf: 'flex-start', margin:10, padding: '5px 20px' }}
                        variant="outlined"
                        onClick={()=>mainStore.addNewCause('attribution_model')}>
                        Add New Cause
                    </Button>
                    {Object.entries(digestTemplates['attribution_model']).map(([cause, subCauses], index)=>renderCause(cause, subCauses, index))}
                    
                </>)}
            </div>)
    
})


const TemplateSubCause = observer(({mainStore, cause, subCause, sentences}: ITemplateSubCause)=>{
    
    const [isEditable, setIsEditable] = useState<boolean>(false)
    const [subCauseText, setSubCauseText] = useState(subCause)

    useEffect(()=>{
        setSubCauseText(subCause)
    },[subCause])

    const handleSave = () => {
        mainStore.updateSubCause('attribution_model', cause, subCause, subCauseText).then(()=>setIsEditable(false))
    }

    const handleDelete = () => {
        mainStore.deleteSubCause('attribution_model', cause, subCause)
    }

    return (
        <div key={`${cause}-${subCause}`} style={{display: 'flex', flexDirection: 'row', border: '1px solid lightgray', padding: 10, margin: 10 }}>
            <div style={{display: 'flex', width: '15%', flexDirection: isEditable ? 'row' : 'column'}}>
                {isEditable ?
                    <TextField
                    id="standard-basic"
                    label={`Edit Sub Cause title`}
                    variant="outlined"
                    value={subCauseText}
                    onChange={(e)=>setSubCauseText(e.target.value)}
                    style={{marginRight: 10, marginBottom: 10}}
                    /> 
                : <div>{subCauseText}</div>}
                {subCause !== 'General' && <div style={{marginTop: 20}}>
                    <IconButton
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        style={{padding: 0}}>
                            {isEditable ?
                            <SaveIcon onClick={handleSave} fontSize="small"/> :
                            <EditIcon onClick={()=>setIsEditable(true)} fontSize="small"/>}
                    </IconButton>
                    {!isEditable && <IconButton
                        disableTouchRipple
                        disableFocusRipple
                        disableRipple
                        style={{padding: 0, margin: '0 10px'}}
                        onClick={()=>console.log('Deleted')}>
                            <DeleteIcon onClick={handleDelete} fontSize="small"/>
                    </IconButton>}
                </div>}
            </div>
            <div style={{display: 'flex', width: '85%', flexDirection:'column'}}>
                {sentences.map((sentence: any, index: any)=>
                    <TemplateSentence
                        mainStore={mainStore}
                        cause={cause}
                        subCause={subCause}
                        text={sentence}
                        index={index}
                    />
                )}
                <Tooltip
                    arrow
                    style={{textAlign: 'center'}}
                    PopperProps={{modifiers: [{name: "offset", options:{offset:[0, -15]}}]}}
                    title={sentences.length >= 10 ? 'Maximum 10 Sentences' : ''}>
                    <span>
                        <Button
                        style={{ alignSelf: 'center', margin:10, padding: '5px 20px' }}
                        variant="outlined"
                        onClick={()=>mainStore.addNewSentence('attribution_model', cause, subCause)}
                        disabled={sentences.length >= 10}>
                            Add New
                        </Button>
                    </span>
                </Tooltip>
            </div>
        </div>)
})

const TemplateSentence = observer(({mainStore, cause, subCause, text, index}: ITemplateSentenceData) => {

    const [isEditable, setIsEditable] = useState(false)
    const [sentence, setSentence] = useState(text)

    useEffect(()=>{
        setSentence(text)
    },[text])

    const handleSave = () => {
        mainStore.updateSentence('attribution_model', cause, subCause, index, sentence).then(()=>setIsEditable(false))
    }

    return <div style={{display: 'flex', flexDirection: 'row', width: '100%'}} key={`${cause}-${subCause}-${index}`}>
        <TextField
            id="standard-basic"
            label={`${subCause} - Sentence ${index + 1}`}
            variant={isEditable ? "outlined" : "filled"}
            value={sentence}
            onChange={(e)=>setSentence(e.target.value)}
            InputProps={{
                readOnly: !isEditable,
            }}
            fullWidth
            style={{marginRight: 10, marginBottom: 10}}
            />
        <IconButton
            disableTouchRipple
            disableFocusRipple
            disableRipple
            style={{padding: 0, margin: '0 10px'}}>
                {isEditable ?
                <SaveIcon onClick={handleSave} fontSize="small"/> :
                <EditIcon onClick={()=>setIsEditable(true)} fontSize="small"/>}
        </IconButton>
        <IconButton
            disableTouchRipple
            disableFocusRipple
            disableRipple
            style={{padding: 0, margin: '0 10px'}}
            onClick={()=>mainStore.deleteSentence('attribution_model', cause, subCause, index)}>
                <DeleteIcon fontSize="small"/>
        </IconButton>
        </div>

})