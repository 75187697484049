import { MainStore } from '../../stores/MainStore';
import React, { useEffect, useState } from 'react';
import { DataGrid, GridCellParams, GridColDef, GridRowId } from '@material-ui/data-grid';
import { Link, Radio, Tooltip, Chip} from '@material-ui/core';
import FileSaver from 'file-saver';
import { CollectFile } from '../../models/CollectFile';

type UploadedFilesGridProps = {
    outputFiles: CollectFile[],
    mainStore: MainStore,
    sessionId: string
}

export const UploadedFilesGrid = ({ outputFiles, mainStore, sessionId }: UploadedFilesGridProps) => {
    const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [sortedFiles, setSortedFiles] = useState<CollectFile[]>([]);

    useEffect(() => {
        if (outputFiles && outputFiles.length > 0) {
            const sortedFiles: CollectFile[] = sortFiles(outputFiles);
            setSortedFiles(sortedFiles);
            sessionId ? setSelectionModel([sortedFiles.filter(file => file.name === sessionId)[0].name]) : 
                                           setSelectionModel([sortedFiles[0].name]);
        }

    }, [outputFiles, sessionId]);

    const onDownload = async (file: CollectFile) => {
        const zipFileName = 'collect_result.zip';
        
        if (isDownloading) return
        setIsDownloading(true);
        FileSaver.saveAs(await mainStore.downloadCollectFile(file.name), zipFileName);
        setIsDownloading(false);
    }

    const sortFiles = (files: CollectFile[]) => {
        const sortedFiles: CollectFile[] = files.slice();

        sortedFiles.sort(function(a,b){
            return (new Date(b.date + ' ' + b.time)).getTime() - (new Date(a.date + ' ' + a.time)).getTime();
        });

        return sortedFiles;
    }

    const handleRenderCell = (params: GridCellParams, limitTags: number) => {
        const tooltipTitle = params.value?.toString() ? params.value?.toString() : "" ;
        const numTags = (params.value as any).length;
        return (
            <Tooltip title={tooltipTitle}>
                <span>
                {(params.value as any).slice(0, limitTags).toString()}
                {numTags > limitTags && ` +${numTags - limitTags}`}
                </span>
            </Tooltip>
        )
    }

    const handleSelection = (rowIds: GridRowId[]) => {
        const newSelectionModel = rowIds

        if (newSelectionModel.length > 1) {
            const selectionSet = new Set(selectionModel);
            const result: any = newSelectionModel.filter(
                (s) => !selectionSet.has(s)
            );

            setSelectionModel(result);
        } else {
            setSelectionModel(newSelectionModel);
        }
    }

    const columns: GridColDef[] = [
        {
            field: "  ",
            headerName: "",
            editable: false,
            width: 62,
            renderCell: (params) => (
                <div>
                    <Radio checked={selectionModel[0] === params.id} 
                     value={params.id} 
                     color="primary"
                     onChange={() => handleSelection([params.id])}/>
                </div>

            )
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 2,
            editable: false,
            hide: true
        },
        {
            field: 'customers',
            headerName: 'Customers',
            flex: 2,
            editable: false,
            renderCell: (params: GridCellParams) => {
                return handleRenderCell(params, 2);
            }
        },
        {
            field: 'datasets',
            headerName: 'Datasets',
            flex: 2.5,
            editable: false,
            renderCell: (params: GridCellParams) => {
                return handleRenderCell(params, 1);
            }
        },
        {
            field: 'user',
            headerName: 'User',
            flex: 2,
            editable: false
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1.5,
            editable: false
        },
        {
            field: 'time',
            headerName: 'Time',
            flex: 1.5,
            editable: false
        },
        {
            field: " ",
            headerName: "",
            flex: 1,
            width: 100,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params: GridCellParams) => {
                return (<Link href="#" style={{ color: '#17B1E2' }} onClick={() => {
                    onDownload(params.row as CollectFile)
                }}>
                    Download
                </Link>)
            }
        }
    ];

    return (
        <div style={{ height: '60vh', width: '100%' }}>
            <div style={{ height: '60vh', marginTop: 10 }}>
                {outputFiles.length > 0 && <DataGrid
                    rows={sortedFiles}
                    columns={columns}
                    pageSize={9}
                    disableSelectionOnClick
                    onCellClick={(params: GridCellParams, event: React.MouseEvent) => {params.colDef.headerName !== "" && handleSelection([params.id])}}
                    getRowId={row => row.name}
                    selectionModel={selectionModel}
                    onSelectionModelChange={(selection) => handleSelection(selection.selectionModel)}
                />}
            </div>
        </div>
    );
}