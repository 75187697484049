import { Button, CircularProgress, makeStyles, Typography, Accordion, AccordionDetails, AccordionSummary, Select, MenuItem, Theme } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { InjectInfo } from '../../models/InjectInfo'
import { ProgressPushMessage } from '../../models/ProgressPushMessage'
import { SalesforceApp } from '../../models/SalesforceApp'
import { SalesforceDashboard } from '../../models/SalesforceDashboard'
import { MainStore } from '../../stores/MainStore'
import ProgressBar from '../controls/ProgressBar'
import { SalesforceLogin } from '../Salesforce components/SalesforceLogin'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    itemRoot: {
        width: '100%',
        backgroundColor: 'white',
        border: "1px solid #BFC5CD",
        margin: '5px 0',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        // fontWeight: theme.typography.fontWeightRegular, // this fails compilation
    }
}))

type InjectProps = {
    store: MainStore
}

export const Inject = observer(({ store }: InjectProps) => {

    const [state, setState] = React.useState(0)
    const { injectProgress } = store
    const classes = useStyles();

    const [app, setApp] = React.useState<string>("")
    const [availableApps, setAvailableApps] = React.useState<SalesforceApp[]>([])
    const [availableDashboards, setAvailableDashboards] = React.useState<SalesforceDashboard[]>([])
    const [dashboard, setDashboard] = React.useState<string>("")

    React.useEffect(() => {
        if (store.SFToken)
        {
            const onConnectionAuth = async () => {
                const info: InjectInfo = { app_name: "", ...store.SFToken!}
                setAvailableApps(await store.getAvailableApps(info))
                store.getInstalledPackageVersions(info)
            }
            onConnectionAuth()
        }
    }, [store.SFToken])

    const onClick = () => {
        if (app !== "" && dashboard !== "" && store.SFToken) {

            const info: InjectInfo = { app_name: availableApps.find(a => a.id === app)!.label, ...store.SFToken }

            setState(1)
            store.inject(availableDashboards.find(d => d.id === dashboard)!.name, info)
                .then(() => setState(3))
                .catch(() => setState(2))
        }
    }

    const renderItem = (message: ProgressPushMessage) => {
        return <div key={message.subject} className={classes.itemRoot} style={{ marginBottom: message.subject === 'Complete Process' ? 20 : 0 }}>
            <Accordion
                expanded={message.state === 'fail'}>
                <AccordionSummary
                    expandIcon={<React.Fragment />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <Typography className={classes.heading} style={{ marginRight: 20, marginLeft: 20, width: 160 }}>{message.subject === 'Complete Process' ? 'Overall' : message.subject}</Typography>
                        <ProgressBar
                            style={{ marginLeft: 20, backgroundColor: 'red' }}
                            fillColor={message.state && message.state === 'fail' ? message.subject.toLowerCase() === 'validation' ? 'yellow' : 'red' : message.subject === 'Complete Process' ? '#57D89D' : '#17B1E2'}
                            completed={message.progress} />
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        {message.message && message.message.split('\n').map((i, key) => {
                            return <Typography key={key} paragraph variant="body1">{i}</Typography>;
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    }

    const renderWithCredentials = () => {
        return <form className={classes.root} noValidate autoComplete="off">
            <div style={{ width: '100%', marginBottom: '15px'}}><SalesforceLogin store={store}/></div>
            <div style={{ width: '100%' }}>
                <Typography>App</Typography>
                <Select style={{ minWidth: '100%', maxHeight: 40, background: '#ffffff' }}
                    variant='outlined'
                    value={app}
                    defaultValue=""

                    onChange={async (event) => {
                        const appId = event.target.value as string
                        setApp(appId)
                        if (appId === "") setDashboard("")
                        else {
                            const info: InjectInfo = { app_name: appId, ...store.SFToken! }
                            setAvailableDashboards(await store.getDashboards(info))
                        }
                    }
                    }
                    disabled={!store.SFToken}
                >
                    <MenuItem key={-1} value="">None</MenuItem>
                    {availableApps.slice().sort().map((sfApp, i) => <MenuItem key={i} value={sfApp.id}>{`${sfApp.label} (${sfApp.version})`}</MenuItem>)}
                </Select>
            </div>
            <div style={{ width: '100%' }}>
            <Typography>Dashboard</Typography>
                <Select style={{ minWidth: '100%', maxHeight: 40, background: '#ffffff' }}
                    variant='outlined'
                    value={dashboard}
                    defaultValue=""

                    onChange={(event) => {
                        setDashboard(event.target.value as string)
                    }}
                    disabled={app === ""}>
                    <MenuItem key={-1} value="">None</MenuItem>
                    {availableDashboards.map((sfDash, i) => <MenuItem key={i} value={sfDash.id}>{`${sfDash.label} (${sfDash.name})`}</MenuItem>)}
                </Select>
            </div>
            <Button variant='outlined' onClick={onClick} disabled={!store.SFToken || app === "" || dashboard === ""}>Inject</Button>
        </form>
    }

    return (
        <>
            <div style={{ width: '100%' }}>
                {renderWithCredentials()}
                {injectProgress.length > 0 && injectProgress.map(item => renderItem(item))}
                {/* {state === 0 && injectProgress.length === 0 && renderWithCredentials()} */}
                {state === 1 && injectProgress.length === 0 && <CircularProgress />}
                {state === 2 && <Typography>Error</Typography>}
            </div>
        </>
    )
})