import React, { useState } from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { LinearProgress, TextField, Checkbox, Chip } from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { AutocompleteOption } from '../../models/AutocompleteOption';

type MultiAutocompleteProps = {
    items: AutocompleteOption[],
    label: string,
    placeholder: string,
    selectAllLabel: string,
    noOptionsText: string,
    limitTags: number,
    onChange: (...params: any[]) => {}
}

const MultiAutocomplete = ({items, 
                            label, 
                            placeholder, 
                            selectAllLabel, 
                            noOptionsText, 
                            limitTags, 
                            onChange}: MultiAutocompleteProps) => {
    const [selectedOptions, setSelectedOptions] = useState<AutocompleteOption[]>([]);
    const isAllSelected = items.length === selectedOptions.length;
    
    const handleClearOptions = () => setSelectedOptions([]);
    const handleToggleSelectAll = (isSelected: Boolean) => {
        if (isSelected) {
            setSelectedOptions(items);
            return onChange(items)
        } else {
          handleClearOptions();
          return onChange([])
        }
    };

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const getOptionSelected = (option: AutocompleteOption, anotherOption: AutocompleteOption) => option.title === anotherOption.title;
    const filter = createFilterOptions();

    return (
        <div> 
            <Autocomplete
                multiple
                id="checkboxes"
                limitTags={limitTags ? limitTags : 5}
                options={items}
                disableCloseOnSelect
                value={selectedOptions}
                onChange={(e, options, reason) => {
                    if (reason === "select-option" || reason === "remove-option") {
                        if ( selectAllLabel && options.find(option => option.title ===  selectAllLabel)) {
                            handleToggleSelectAll(!isAllSelected);
                        } else {
                            setSelectedOptions(options);
                            if(onChange != null) return onChange(options);
                        }
                    } else if (reason === "clear") {
                        handleClearOptions();
                    }
                }}
                getOptionSelected={getOptionSelected}
                noOptionsText={noOptionsText}
                getOptionLabel={option => option.title}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params as any) as any;
                    const result = selectAllLabel ? [{ title: selectAllLabel }, ...filtered] : [...filtered]
                    return result;
                }}
                renderOption={(option, { selected }) => {
                    const selectAllProps =
                        option.title === selectAllLabel
                            ? { checked: isAllSelected }
                            : {};

                    return (
                        <React.Fragment>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                                {...selectAllProps}
                            />
                            {option.title}
                        </React.Fragment>
                    );
                }}
                disabled={!items.length}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                style={{ width: 400, backgroundColor: 'white' }}
                renderTags={(value, getTagProps) => {
                    const numTags = value.length;
            
                    return (
                      <>
                        {value.slice(0, limitTags).map((option, index) => (
                          <Chip
                            {...getTagProps({ index })}
                            key={index}
                            label={option.title}
                          />
                        ))}
            
                        {numTags > limitTags && ` +${numTags - limitTags}`}
                      </>
                    );
                  }}
                renderInput={params => (
                    items.length ? 
                    <TextField {...params} label={label} placeholder={placeholder} variant="outlined" /> :
                    <div>
                        <TextField {...params} label="Loading" variant="outlined" />
                        <LinearProgress/>
                    </div>
                )}
            />
        </div>
    );
    
}

MultiAutocomplete.defaultProps = {
    limitTags: 5,
    items: [],
    onChange: null,
    selectAllLabel: ""
  };

export default MultiAutocomplete;
