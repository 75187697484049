import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  Redirect
} from "react-router-dom";
import { Home } from './Home';
import { ThemeProvider, Typography } from '@material-ui/core';
import CssBaseline from "@material-ui/core/CssBaseline";
import { MainStore } from './stores/MainStore';
import { observer } from 'mobx-react-lite';
import { Operations } from './Operations';
import { AltHome } from './AltHome';
import React from 'react';
import { WPI } from './playground/Wpi';
import { MyAppBar } from './components/AppBar';
import { TemplateEditor } from './components/template editor/TemplateEditor';
import { CollectCustomersDs } from './components/collect customers/CollectCustomersDs';
import WebFont from 'webfontloader'; 
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

import logger from './Logger';
import { sidTheme } from './SidTheme';
import { SFLoginAdapter } from './components/Salesforce components/SFLoginAdapter';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import FeatureFlagsService from './services/FeatureFlagsService'
import { Metadata } from './components/metadata/Metadata';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DigestWizard } from './components/digest wizard/DigestWizard';
import { ManualInjection } from './components/ManualInjection';
import { OrganizationSelection } from './components/wizard/OrganizationSelection';
import { ProcessAndInject3 } from './components/wizard/ProcessAndInject3';
import { AppContainer } from './AppContainer';
import { Finalizer } from './components/finalizer/Finalizer';
import { SalesforceUserManager } from './components/sf user manager/UserManager';
import { CopilotMain } from './components/copilot setup/CopilotMain';

const OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID;
const CALLBACK_PATH = '/login/callback';

const ISSUER = `https://${OKTA_DOMAIN}/oauth2/default`
const HOST = window.location.host
const PROTOCOL = window.location.protocol
const REDIRECT_URI = `${PROTOCOL}//${HOST}${CALLBACK_PATH}`
const SCOPES = 'openid profile email groups'

const okta_config = {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    scopes: SCOPES.split(/\s+/)
}
console.log('redirect: ' + REDIRECT_URI)
const oktaAuth = new OktaAuth(okta_config);
logger.info("app init")

export const LEGACY_MODE = process.env.REACT_APP_ENVIRONMENT === 'legacy'

WebFont.load({
  google: {
    families: ['Noto Sans Display:300,400,500,700']
  }
})

const App = observer(() => {
  if (window.location.pathname.includes('sflogin')) return <Router><Route exact path="/sflogin" component={() => <SFLoginAdapter/>}/></Router>
  const client = useLDClient()
  const [mainStore, setMainStore] = React.useState<MainStore>(new MainStore())

  client && client.identify({
    key: mainStore.user ? mainStore.user.profile.email : 'unknown user',
    email: mainStore.user ? mainStore.user.profile.email : 'unknown user'
  })

  FeatureFlagsService.getClient().identify({
    key: mainStore.user ? mainStore.user.profile.email : 'unknown user',
    email: mainStore.user ? mainStore.user.profile.email : 'unknown user'
  })

  const { organizations, currentOrg } = mainStore
  const flags = useFlags();
  const isAdmin = mainStore.user && mainStore.securityStore && mainStore.securityStore?.isAllowed(Operations.UnifiedRoute)
  const home = isAdmin ? <AltHome mainStore={mainStore} /> : <Home mainStore={mainStore} />

  const history = useHistory();
  
  const triggerLogin = async () => {
    await oktaAuth.signInWithRedirect();
  };

  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  const customAuthHandler = async () => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      await triggerLogin();
    }
  }

  const getToken = () => {
      const actualToken = oktaAuth.getAccessToken()
      // console.log('actual token is: ' + actualToken, 'is expired: ' + oktaAuth.tokenManager.hasExpired(actualToken))
      return actualToken
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={customAuthHandler}>
      <ThemeProvider theme={sidTheme}>
        <CssBaseline />
        <AppContainer mainStore={mainStore} getToken={()=> getToken()}>
          <MyAppBar mainStore={mainStore} onLogout={async () => {
            mainStore.logoutSF()
            console.log(window.location.origin + '/logged_out')
            await oktaAuth.signOut({
              revokeAccessToken: true,
              postLogoutRedirectUri: window.location.origin + '/logged_out'
          });
            setMainStore(new MainStore())
            history.push('/')
          }}/>
          <Switch>
            <Route path={CALLBACK_PATH} component={LoginCallback} /> 
            <SecureRoute exact path="/" component={() => home} />

            {isAdmin && <SecureRoute exact path="/alt" component={() => <Home mainStore={mainStore} />} />}

            {mainStore.securityStore && mainStore.securityStore?.isAllowed(Operations.ManualInjection) && 
            <SecureRoute path="/manual" component={() =>
              <div style={{ padding: "20px" }}>
                {(!organizations || organizations.length === 0) && <Typography>Loading....</Typography>}
                {organizations && organizations.length > 0 && <OrganizationSelection organizations={organizations} onSelection={async value => await mainStore.setOrganization(value)} selectedOrg={currentOrg} />}
                <div style={{ height: '10px' }}></div>
                {mainStore.currentOrg && <ProcessAndInject3 store={mainStore} mode='Manual Injection' />}
              </div>} ></SecureRoute>}
            {mainStore.user && <Route exact path="/" component={() => home} />}
            {<Route exact path="/logged_out" component={() => <Redirect to="/" />} />}
            {mainStore.user && isAdmin && <Route exact path="/alt" component={() => <Home mainStore={mainStore} />} />}
            {mainStore.securityStore && mainStore.securityStore?.isAllowed(Operations.ManualInjection) && <Route path="/manual" component={() => <ManualInjection store={mainStore} />}></Route>}
            {mainStore.securityStore && mainStore.securityStore?.isAllowed(Operations.Finalizer) && <Route path="/finalizer" component={() => <Finalizer mainStore={mainStore} />}></Route>}
            {mainStore.securityStore && mainStore.securityStore?.isAllowed(Operations.WPISimRoute) && <Route path="/wpi" component={() => <WPI></WPI>}></Route>}
            {mainStore.securityStore && mainStore.securityStore?.isAllowed(Operations.EditTemplate) && <Route path="/template" component={() => <TemplateEditor store={mainStore}/>}></Route>}
            {mainStore.securityStore && mainStore.securityStore?.isAllowed(Operations.InjectMetadata) && <Route path="/metadata" component={() => <Metadata store={mainStore}/>}></Route>}
            {mainStore.securityStore && mainStore.securityStore?.isAllowed(Operations.CollectCustomersDs) && flags.collectCustomersLatestDataset && <Route path="/collect" component={() => <CollectCustomersDs mainStore={mainStore} organizations={organizations} />}></Route>}
            {mainStore.securityStore && mainStore.securityStore?.isAllowed(Operations.DigestWizard) && <Route path="/digest" component={() => <DigestWizard mainStore={mainStore}/>}></Route>}
            {mainStore.securityStore && mainStore.securityStore?.isAllowed(Operations.UserManager) && <Route path="/usermanager" component={() => <SalesforceUserManager store={mainStore}/>}></Route>}
            {mainStore.copilotStore && <Route path="/copilot" component={() => <CopilotMain mainStore={mainStore}/>}></Route>}
          </Switch>
        </AppContainer>
      </ThemeProvider>
    </Security>)
})

export default App;
