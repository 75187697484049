import Select from '@material-ui/core/Select';
import { Box, FormControl, FormHelperText, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import { ConfigItem, LogicalItem} from '../../models/ConfigItem';
import Checkbox from '@material-ui/core/Checkbox';
import { observer } from 'mobx-react-lite';
import { MainStore } from '../../stores/MainStore';
import { useEffect, useState } from 'react';
import { runInAction } from 'mobx';
import { getMultipleAssignments, shouldDisplayMultipleAssignments , getResultByLogicalExpression} from '../../Utils';
import { ConfirmationDialogProps, defaultConfirmationDialogProps } from '../controls/StepConfirmation';
import MultipleAssignmentsEditor from './MultipleAssignmentsEditor';
import AquantWarningIcon from '../../assets/aq-warning.svg'
import FeatureFlagsService from '../../services/FeatureFlagsService';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Tooltip } from '@material-ui/core'
import Stack from '@mui/material/Stack';

type MapperProps = {
    mainStore: MainStore
}

const useStyles = makeStyles(() => ({
    frame: {
        background: '#ffffff',
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        height: 62,
        margin: '5px 0',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '4px',
        border: '1px solid #BFC5CD'
    }
}))

export const getMappingConfirmProps = (mainStore: MainStore,
    saveConfig: () => void,
    clearAssignment: (item: ConfigItem) => void,
    saveAssignments: () => void,
    cancelAssignments: () => void) => {
    let res: ConfirmationDialogProps[] = []

    if (mainStore.selectedMappingItems.length <= 0) return undefined

    const saveConfirmProps: ConfirmationDialogProps = {
        ...defaultConfirmationDialogProps,
        title: 'Save new configuration?',
        content: <>
            <div style={{ display: 'flex', marginBottom: 15 }} >
                <img src={AquantWarningIcon} alt='warning-icon' style={{ marginRight: 5 }} />
                <Typography variant='subtitle2'>
                    Changes to the mapping configuration haven't been saved
                </Typography>
            </div>
            <Typography variant='body1' >
                To automatocally retreive these last settings, save your changes
            </Typography>
        </>,
        onYes: saveConfig,
        yesLabel: 'Save',
        onNo: () => { },
        noLabel: 'Skip',
        closeLabel: 'Cancel',
    }

    const multipleAssignments = getMultipleAssignments(mainStore.configuration)

    if (multipleAssignments) {
        const multiAssignConfirmProps: ConfirmationDialogProps = {
            title: 'Are you sure?',
            content: <MultipleAssignmentsEditor
                multipleAssignments={multipleAssignments}
                clearAssignment={clearAssignment}
            />,
            onYes: () => {
                saveAssignments()
                if (!mainStore.mappingValid) {
                    saveConfirmProps.content = <>
                        <div style={{ display: 'flex', marginBottom: 15 }} >
                            <img src={AquantWarningIcon} alt='warning-icon' style={{ marginRight: 5 }} />
                            <Typography variant='subtitle1'>
                                Current mapping is not valid
                            </Typography>
                        </div>
                        <Typography variant='body1' >
                            You will only be able to advance with a valid mapping
                        </Typography>
                    </>
                    saveConfirmProps.onNo = undefined
                }
            },
            yesLabel: 'Confirm',
            onClose: cancelAssignments,
            closeLabel: 'Cancel',
        }

        // compare with loaded multi assigned
        let shouldDisplay = shouldDisplayMultipleAssignments(multipleAssignments, mainStore.loadedConfigMultipleAssignments)

        if (shouldDisplay) res.push(multiAssignConfirmProps)
    }

    res.push(saveConfirmProps)

    return res
}

export const Mapper = observer(({ mainStore }: MapperProps) => {

    const classes = useStyles()
    
    const [dependentItemsToBeMapped, setDependentItems] = useState<string[]>([])
    const [index, setIndex] = useState<number>(1)
    const [dependentSelectionValues, setDependentSelectionValues] = useState<Record<string, string[]>>({})


    const {
        configuration,
        csvHeaders: headers,
        addSelectedMappingItems,
        selectedMappingItems,
        updateConfigItem,
        featureFlags
    } = mainStore

    const configItems = configuration || []

    useEffect(() => {
        for (var item of configItems){
            if (!item.dependencies || item.value === 'n/a') continue
            const dependencyResult = getResultByLogicalExpression(configItems, item)

            if (dependencyResult !== true){
                setDependentItems(prevNames=> [...prevNames, ...item.dependencies!.dependencyValues])
            }
        }

        var observedItems = configItems.filter(config => config?.valueSelectedObserved === true)
        if (observedItems){
            observedItems.forEach(observedItem => {
                if (observedItem.value && observedItem.valueSelectedObservedFilter) {
                    dependentSelectionValues[observedItem.name] = observedItem.valueSelectedObservedFilter(observedItem.value, mainStore)
                    setDependentSelectionValues(dependentSelectionValues)
                    setIndex(index + 1)
                }
            });
        }
    }, [configuration, configItems, mainStore, dependentSelectionValues])


    const setDependentItemsWrapper = (item:ConfigItem) => {
        //find if this item is a dependency
        const foundDependentItem = configItems.find(mappedItem => mappedItem.dependencies?.dependencyValues.some(depend => depend.includes(item.name)))
        if (foundDependentItem !== undefined){
            if (foundDependentItem.value === 'n/a'){
                setDependentItems(prevNames => prevNames.filter(el => !foundDependentItem.dependencies!.dependencyValues.includes(el)))
            }
            else{
                const dependencyResult = getResultByLogicalExpression(configItems, foundDependentItem)

                if (dependencyResult === true){
                    setDependentItems(prevNames => prevNames.filter(el => !foundDependentItem.dependencies!.dependencyValues.includes(el)))
                }
                else{
                    setDependentItems(prevNames=> [...prevNames, ...foundDependentItem.dependencies!.dependencyValues])
                }
            }
        }

        //find if this item is dependent
        if (item.dependencies){
            if (item.value === 'n/a'){
                setDependentItems(prevNames => prevNames.filter(el => !item.dependencies!.dependencyValues.includes(el)))
            }
            else{
                const dependencyResult = getResultByLogicalExpression(configItems, item)

                if (dependencyResult === true){
                    setDependentItems(prevNames => prevNames.filter(el => !item.dependencies!.dependencyValues.includes(el)))
                }
                else{
                    setDependentItems(prevNames=> [...prevNames, ...item.dependencies!.dependencyValues])
                }
            }
        }
    }

    const onSelectionChanged = (updateFunction: () => void, item: ConfigItem) => {
        runInAction(() => updateFunction())
        addSelectedMappingItems(item)
    }

    const uniqueCategories = Array.from(new Set(configItems.map(x=>x.category))).filter(cat => cat !== undefined);
    const labelFeature = FeatureFlagsService.getClient().variation('allow-mandatory-labels', false)
    const getHeirarchyInfo = (item:ConfigItem) => {
        return `${item.name} level can only be mapped along with 
        ${item.dependencies?.dependencyValues.join(item.dependencies?.logicalContext === LogicalItem.AND ? ' and ' : ' or ')}. 
        please make sure you've mapped the fields mentioned if you want to assign value to this field`;
    }

    const getDependentHierarchyReason = (item:ConfigItem) => {
        var topHierarchyName = ''
        const foundDependentItem = configItems.find(mappedItem => mappedItem.dependencies?.dependencyValues.some(depend => depend.includes(item.name)))
        if (foundDependentItem !== undefined){
            topHierarchyName = foundDependentItem.name
        }

        return `${item.name} has to be mapped to enable ${topHierarchyName}. 
        please assign a value or remove value from ${topHierarchyName}`;
    }

    const onSelectItemChange = (configItem:ConfigItem, value:any) => {
        //find customer name in nlp
        if (configItem.valueSelectedObserved && configItem.valueSelectedObservedFilter){
            dependentSelectionValues[configItem.name] = configItem.valueSelectedObservedFilter(value, mainStore)
            setDependentSelectionValues(dependentSelectionValues)
        }
        updateConfigItem(configItem, value)
    }

    const renderTooltip = (item: ConfigItem) => {
        let info = item.tooltip
        if (featureFlags['dependent-mapping-fields'] && item.dependencies && item.value !== 'n/a'){
            info = [info, getHeirarchyInfo(item)].join('\n')
        }
        return info ? <Tooltip title={info} placement="right" arrow>
                        <InfoOutlinedIcon color='primary'>
                        </InfoOutlinedIcon>
                    </Tooltip> : undefined
    }

    const renderItems = (items: ConfigItem[]) => {

        return (
            items && items.map((item, i) => {
                
                if (item.isInvisible) return (<></>)
                
                // by default, trying to find a csv header with the same name (unless a value has been defined)
                if (item.type === 'string')
                    runInAction(() => item.value = item.value && headers.includes(item.value) ? item.value : 'n/a')

                const [isOpen, setIsOpen] = useState(false)

                const customRenderItems = item.observerOn ?  dependentSelectionValues[item.observerOn] : []

                return (<div key={i} style={{ display: 'flex', alignItems: 'center' }} >
                    <div className={classes.frame}
                        style={{
                            backgroundColor: selectedMappingItems.findIndex(it => it.name === item.name) > -1 ? '#fffff1' : 'white',
                            border: isOpen ? '2px solid #000000' : '1px solid #BFC5CD',
                            height: item.type === 'custom' ? 'auto' : 62,
                            alignItems: 'center',
                        }}
                    >
                        {item.type !== 'custom' &&
                            <Typography align='center'  style={{ marginRight: 20, marginLeft: 20, display: 'flex', alignItems: 'center'}}>
                                {item.name}
                                {renderTooltip(item)}
                            </Typography>
                        }
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: item.type === 'custom' ? '100%' : undefined }}>
                            {item.type === 'string' && !item.category &&
                            <div style={{ marginRight: 10, minWidth: 250, padding: '5px' }}>
                                <FormControl error={item.labelValueDependent && item.value !== 'n/a' && item.label ===''} style={{ display: 'flex'}}> 
                                    <Box boxShadow={1}>
                                        <TextField
                                            style={{ minWidth: '100%', background: '#ffffff', zIndex: 0 }}
                                            size='small'
                                            variant='outlined'
                                            value={item.label ?? ""}
                                            label='custom label'
                                            onChange={event => {
                                                runInAction(()=> item.label = event.target.value)
                                                addSelectedMappingItems(item)
                                            }} 
                                        />
                                    </Box>
                                    {labelFeature && item.labelValueDependent && item.value !== 'n/a' && item.label ==='' && <FormHelperText>Please add custom label to field</FormHelperText>}
                                </FormControl> 
                            </div>}
                            <div style={{ 
                                marginRight: 10, 
                                minWidth: 318, 
                                padding: 5,
                                width: item.type === 'custom' ? '100%' : undefined
                            }}
                            >
                                {item.type === 'bool' && <Checkbox color='primary' checked={item.value} onChange={(event) => onSelectionChanged(() => item.value = event.target.checked, item)}></Checkbox>}
                                {item.type === 'free_text' && <TextField
                                            style={{ minWidth: '100%', background: '#ffffff' }}
                                            size='small'
                                            variant='outlined'
                                            value={item.value}
                                            type='text'
                                            onChange={event => onSelectionChanged(() => updateConfigItem(item, event.target.value), item)}
                                        />}

                                {item.type === 'string' && 
                                    <FormControl error={item.value === 'n/a' && (item.isRequired || dependentItemsToBeMapped.includes(item.name))} style={{ display: 'flex'}}>
                                        <Box boxShadow={1}>
                                            <Select style={{ minWidth: '100%', maxHeight: 40, background: '#ffffff'}}
                                                    onOpen={() => setIsOpen(true)}
                                                    onClose={() => setIsOpen(false)}
                                                    variant='outlined'
                                                    value={item.value || 'n/a'}
                                                    onChange={(event) => {
                                                        runInAction(()=> item.value = event.target.value || 'n/a')
                                                        mainStore.addSelectedMappingItems(item)
                                                        if (featureFlags['dependent-mapping-fields']) setDependentItemsWrapper(item)
                                                        }}>
                                                <MenuItem value={'n/a'}>n/a</MenuItem>
                                                {headers.slice().sort().map((header, i) => <MenuItem key={i} value={header}>{header}</MenuItem>)}
                                            </Select>
                                        </Box>
                                        {item.value === 'n/a' && (item.isRequired || dependentItemsToBeMapped.includes(item.name)) && 
                                            <Stack direction="row" spacing={1}>
                                                <FormHelperText>Please map column to field</FormHelperText>
                                                {dependentItemsToBeMapped.includes(item.name) && <Tooltip title={getDependentHierarchyReason(item)} placement="right" arrow>
                                                    <InfoOutlinedIcon color='error' fontSize='small'>
                                                    </InfoOutlinedIcon>
                                                </Tooltip>}
                                            </Stack>}
                                    </FormControl>}
                                {item.type === 'int' &&
                                    <Box boxShadow={1}>
                                        <TextField
                                            style={{ minWidth: '100%', background: '#ffffff' }}
                                            size='small'
                                            variant='outlined'
                                            value={item.value}
                                            type='number'
                                            onChange={event => onSelectionChanged(() => updateConfigItem(item, parseFloat(event.target.value)), item)}
                                        />
                                    </Box>}
                                {item.type === 'select' && item.options &&
                                    <Box boxShadow={1}>
                                        <FormControl fullWidth>
                                            <Select value={item.allowMultiple ? item.value as any[] : item.value} style={{ minWidth: '100%', maxHeight: 40, background: '#ffffff' }}
                                                variant='outlined'
                                                defaultValue={item.allowMultiple ? [] : ''}
                                                multiple={item.allowMultiple}
                                                onChange={(event) => onSelectItemChange(item, event.target.value)}>
                                                {item.hasEmptyItem && <MenuItem key={-1} value={undefined}>{'None'}</MenuItem>}
                                                {item.options.map((i, index) => <MenuItem key={index} value={i}>
                                                    {item.displayProperty ? i[item.displayProperty] : i}
                                                </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                }
                                {index && item.type === 'custom' && item.render && item.render({
                                    val: item.value,
                                    setVal: (val: any) => onSelectionChanged(() => updateConfigItem(item, val), item),
                                    itemsSelection: customRenderItems
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>)
            }))
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch' }}>
            <div style={{ maxWidth: '100%', flexGrow: 1 }}>
                <div style={{ margin: '20px 0' }}>
                    <Typography>Required</Typography>
                    {renderItems(configItems.filter(item => !item.category && item.isRequired && item.type === 'string'))}
                </div>
                <div style={{ margin: '20px 0' }}>
                    <Typography>Optional</Typography>
                    {renderItems(configItems.filter(item => !item.category && !item.isRequired && item.type === 'string'))}
                </div>
                <div style={{ margin: '20px 0' }}>
                    <Typography>Configuration</Typography>
                    {renderItems(configItems.filter(item => !item.category && item.type !== 'string'))}
                </div>
                {featureFlags['nlp_aas'] && uniqueCategories && uniqueCategories.map(cat => <div key={cat} style={{ margin: '20px 0' }}>
                    <Typography>{cat}</Typography>
                    {renderItems(configItems.filter(item => item.category === cat))}
                </div>)}
            </div>
        </div>
    )
})