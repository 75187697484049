import { TextField } from '@material-ui/core';
import React, { useEffect } from 'react'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

interface IVersionSelectionProps {
    versions: string[]
    version: string
    onSetVersion: (version: string) => void
}

const filter = createFilterOptions<versionOptionType>();

interface versionOptionType {
    inputValue?: string;
    title: string;
}

export const VersionSelection = ({ versions, version, onSetVersion }: IVersionSelectionProps) => {
    const [value, setValue] = React.useState<versionOptionType | null>(null);

    useEffect(() => {
        setValue({inputValue: version, title: version})
    }, [version])

    const innerVersions = versions.map(v => {
        const versionOption: versionOptionType = {title: v}
        return versionOption
    })

    const setVersion = (version: versionOptionType | null) => {
        setValue(version)
        version ? onSetVersion(version.title) : onSetVersion("")
    }

    return (
        <div>
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setVersion({
                        title: newValue,
                    });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                    setVersion({
                      title: newValue.inputValue,
                    });
                } else {
                    setVersion(newValue);
                }
              }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Create "${params.inputValue}"`,
                  });
                }
        
                return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            options={innerVersions}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                  }
                // Regular option
                return option.title;
            }}
            renderOption={(option) => option.title}
            style={{ width: 300, backgroundColor: 'white' }}
            freeSolo
            renderInput={(params) => (
                <TextField {...params} label="Template Version" variant="outlined" />
            )}
        />
        </div>
    )
}